import { Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import AboutComponent from "./AboutComponent";
import VendorHubComponent from "./VendorHubComponent";
import SupportComponent from "./SupportComponent";
import PrivacyComponent from "./PrivacyComponent";
import TermsComponent from "./TermsComponent";
import RefundComponent from "./RefundComponent";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";

const QuickLinkDetails = () => {
    const { link } = useParams();
    const responsive = useResponsiveStyles();
    const renderComponent = () => {
        switch (link) {
            case 'privacy':
                return <PrivacyComponent />;
            case 'aboutUs':
                return <AboutComponent />;
            case 'customerSupport':
                return <SupportComponent />;
            case 'vendorHub':
                return <VendorHubComponent />;
            case 'refund':
                return <RefundComponent />;
            case 'termsOfUse':
                return <TermsComponent />;
            default:
                return <Typography variant="h3">Page Not Found</Typography>;
        }
    };
    return (
        <Box sx={{ backgroundColor: '#EEEEEE' }}>
            <Container
                sx={{
                    backgroundColor: '#FFF',
                    minHeight: '100vh',
                    border: '1px solid #CCCCCC',
                    maxWidth: 'fit-content',
                    width: responsive.isMobile ? '98%' : '60%',
                    padding: responsive.isMobile ? 3 : 6,
                    paddingX: responsive.isMobile ? 3 : '56px !important',
                }}
            >
                {renderComponent()}
            </Container>
        </Box>
    )
}

export default QuickLinkDetails;