import { Avatar, Box, Grid, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import ItemsImageComponent from '../../../components/common/ItemsImageComponent'
import CustomButton from '../../../components/common/CustomButton'
import useResponsiveStyles from '../../../hooks/useResponsiveStyles'

const TypoWithStrike = ({ name }) => {
    return (
        <Typography
            // variant='subtitle'
            variant='body2'
            sx={{
                // fontWeight: 400,
                // lineHeight: 1.57143,
                // fontSize: '0.875rem',
                textDecoration: 'line-through',
                color: (theme) => theme.palette.error.light
            }}
        >
            {name}
        </Typography>
    )
}

const OrderItemCard = ({ order }) => {

    const { isMobile } = useResponsiveStyles()

    return (
        <>
            <Grid container spacing={1.5} pt={'0.5rem'} >
                <Grid item xs={12} md={6}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <ItemsImageComponent s3key={order?.itemImageKey} name={order?.itemName} />
                        <ListItemText sx={{ flex: '1 1 auto' }}>
                            <Typography variant='body1'>{order?.itemName}</Typography>
                            <Typography variant='body2' sx={{ margin: '4px 0px 0px' }} color={'text.disabled'} noWrap>#{order?.hsn}</Typography>
                        </ListItemText>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container columnSpacing={1.5} columns={12}  height={"100%"}>
                        {isMobile && <Grid item xs={3}></Grid>}
                        <Grid item xs={3} md={4}>
                            <Stack direction={'column'} height={"100%"} justifyContent={"center"} sx={{ textAlign: 'right', pb: 1 }}>
                                <Typography variant='body2' >{order?.quantity ? `${order?.quantity}${order?.unit}` : '--'}</Typography>
                                <TypoWithStrike name={order?.previousQuantity && order?.previousQuantity !== order?.quantity ? `${order?.previousQuantity}/${order?.previousUnit}` : ''} />
                            </Stack>
                        </Grid>
                        <Grid item xs={3} md={4}>
                            <Stack direction={'column'} height={"100%"} justifyContent={"center"} sx={{ textAlign: 'right', pb: 1 }}>
                                <Typography variant='body2' >{order?.rate ? `₹${order?.rate}/${order?.unit}` : '--'}</Typography>
                                <TypoWithStrike name={order?.previousRate && order?.previousRate !== order?.rate ? order?.previousRate : ''} />
                            </Stack>
                        </Grid>
                        <Grid item xs={3} md={4}>
                            <Stack direction={'column'} height={"100%"} justifyContent={"center"} sx={{ textAlign: 'right', pb: 1 }}>
                                <Typography variant='subtitle2' >₹{order?.total}</Typography>
                                <TypoWithStrike name={order?.previousTotal && order?.previousTotal !== order?.total ? `₹${order?.previousTotal}` : ''} />
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} borderBottom={`2px dashed rgba(244, 246, 248, 0.7)`}></Grid>
            {/* <Stack direction={'row'} alignItems={'center'} paddingTop={'24px'} paddingBottom={'24px'} borderBottom={`2px dashed rgb(244, 246, 248)`} key={order?.itemId}>
                <ItemsImageComponent s3key={order?.itemImageKey} name={order?.itemName} />
                <ListItemText sx={{ flex: '1 1 auto' }}>
                    <Typography variant='body1'>{order?.itemName}</Typography>
                    <Typography variant='body2' sx={{ margin: '4px 0px 0px' }} color={'text.disabled'} noWrap>#{order?.itemId}</Typography>
                </ListItemText>
                <Box>
                    <Stack>
                        <Typography variant='body2' noWrap>{order?.quantity ? `${order?.quantity}${order?.unit}` : '--'}</Typography>
                    </Stack>
                    <Stack>
                        <TypoWithStrike name={order?.previousQuantity && order?.previousQuantity !== order?.quantity ? `${order?.previousQuantity}/${order?.previousUnit}` : ''} />
                    </Stack>
                </Box>
                <Box sx={{ width: '110px', textAlign: 'right' }}>
                    <Stack>
                        <Typography variant='body2' noWrap>{order?.rate ? `₹${order?.rate}/${order?.unit}` : '--'}</Typography>
                    </Stack>
                    <Stack>
                        <TypoWithStrike name={order?.previousRate && order?.previousRate !== order?.rate ? order?.previousRate : ''} />
                    </Stack>
                </Box>
                <Box sx={{ width: '110px', textAlign: 'right' }}>
                    <Stack
                        alignItems={order?.total != 0 && "flex-end"}
                    >
                        <Typography variant='subtitle2' noWrap>₹{order?.total}</Typography>
                    </Stack>
                    <Stack>
                        <TypoWithStrike name={order?.previousTotal && order?.previousTotal !== order?.total ? `₹${order?.previousTotal}` : ''} />
                    </Stack>
                </Box>
            </Stack> */}
        </>
    )
}

export default OrderItemCard