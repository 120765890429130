import React from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, styled } from '@mui/material';
import { qualityIcon } from '../../assets/images/landing';
import { singleVendorIcon } from '../../assets/images/landing';
import { nextDayIcon } from '../../assets/images/landing';
import CustomAllTypography from '../../components/common/CustomAllTypography';

export const SectionContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(3),
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 800,
  fontSize: '20px',
  marginBottom: theme.spacing(5),
  marginTop: theme.spacing(5),
}));

const CardWrapper = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(4),
}));

const CardImage = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(4),
  width: '96px',
  height: '96px',
  color: 'transparent',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0),
  marginTop: '1rem',
  fontWeight: 700,
}));

const CardSubtitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0),
  fontWeight: 'normal',
}));

const Section1 = () => {
  return (
    <SectionContainer id="why">
      <Title variant='h2'>Typography Styles</Title>
      {/* <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <CardWrapper>
            <CardContent>
              <CardImage src={'https://www.hyperpure.com/assets/images/quality.svg'} alt="Consistency" loading="lazy" />
              <CardTitle variant='h4'>Quality</CardTitle>
              <CardSubtitle variant='h5'>Consistency</CardSubtitle>
            </CardContent>
          </CardWrapper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardWrapper>
            <CardContent>
              <CardImage src={'https://www.hyperpure.com/assets/images/singleVendor.svg'} alt="Marketplace" loading="lazy" />
              <CardTitle variant='h4'>Multi Vendor</CardTitle>
              <CardSubtitle variant="h5">Marketplace</CardSubtitle>
            </CardContent>
          </CardWrapper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardWrapper>
            <CardContent>
              <CardImage src={'https://www.hyperpure.com/assets/images/nextDay.svg'} alt="Delivery" loading="lazy" />
              <CardTitle variant="h4">Next Day</CardTitle>
              <CardSubtitle variant="h5">Delivery</CardSubtitle>
            </CardContent>
          </CardWrapper>
        </Grid>
      </Grid> */}
      <Box>
        <CustomAllTypography
        variant={'h1'}
        name={'Nunito-h1'}
        />
        <CustomAllTypography
        variant={'h2'}
        name={'Nunito-h2'}
        />
        <CustomAllTypography
        variant={'h3'}
        name={'Nunito-h3'}
        />
        <CustomAllTypography
        variant={'h4'}
        name={'Nunito-h4'}
        />
        <CustomAllTypography
        variant={'h5'}
        name={'Nunito-h5'}
        />
        <CustomAllTypography
        variant={'h6'}
        name={'Nunito-h6'}
        />
        <CustomAllTypography
        variant={'body1'}
        name={'Nunito-body1'}
        />
         <CustomAllTypography
        variant={'body2'}
        name={'Nunito-body2'}
        />
        <CustomAllTypography
        variant={'subtitle1'}
        name={'Nunito-subtitle1'}
        />
        <CustomAllTypography
        variant={'subtitle2'}
        name={'Nunito-subtitle2'}
        />
      </Box>
    </SectionContainer>
  );
};

export default Section1;
