import { Box, Stack, Typography } from '@mui/material'
import { TableColumns } from '../order-list/TableColumns'
import { OrderListHeader } from '../order/place-order/OrderListComp'
import PrivateVendorOrderListRow from './PrivateVendorOrderListRow'
import CustomSearchbar from '../../components/common/CustomSearchbar'

const PrivateVendorOrderTable = ({ items, handleQuantityChange, title, isRead, isPrevQuant = true, name = "my-vendors-items", isSearch = false, searchText, setSearchText }) => {

  const columns = TableColumns(name)

  return (
    <>
      <Stack spacing={2}
        width={"100%"}
        direction={{
          xs: "column",
          sm: "row"
        }}
        justifyContent={{ xs: "flex-start", sm: "space-between" }}
        mb={'1rem'}
      >
        <Typography variant='h6' >{title}</Typography>
        {isSearch && <CustomSearchbar
          placeholder={"Search Items..."}
          text={searchText}
          handleTextChange={(e) => setSearchText(e.currentTarget.value)}
          handleTextClear={(e) => setSearchText("")}
          sx={{ width: { xs: "100%", sm: "40%", md: "40%" } }}
        />}
      </Stack>
      <Box sx={{ width: '100%', overflow: 'auto' }}>
        <OrderListHeader headLabel={columns} />
        <Stack
          sx={{
            overflowY: "auto",
            minWidth: 700,
            maxHeight: "60vh",
            "-webkit-overflow-scrolling": "touch",
            scrollBehavior: "smooth",
            // "&::-webkit-scrollbar": {
            //   display: "none",
            // },
          }}
        >
          {
            items?.map((item, index) => (
              <PrivateVendorOrderListRow key={item.id} row={item} columns={columns} handleQuantityChange={handleQuantityChange} action={'action'} index={index} isRead={isRead} isPrevQuant={isPrevQuant} />
            ))
          }
        </Stack>
      </Box>
    </>
  )
}

export default PrivateVendorOrderTable