import { Stack, Typography } from "@mui/material";

export default function AskForPrice() {
    return (
        <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
                // fontSize: "10px",
                color: (theme) => theme.palette.info.main,
                // bgcolor: (theme) => theme.palette.info.lighter,
                width: "fit-content",
                padding: "2px 7px",
                textAlign: 'center',
                borderRadius: "8px",
            }}
        >
            <Typography
                sx={{
                    fontSize: "10px",
                    // color: (theme) => theme.palette.primary.main,
                    // bgcolor: (theme) => theme.palette.primary.lighter,
                    // width: "fit-content",
                    // padding: "2px 7px",
                    // textAlign: 'center',
                    // borderRadius: "8px",
                }}
                noWrap
            >Ask for</Typography>
            <Typography
                sx={{
                    fontSize: "10px",
                    // color: (theme) => theme.palette.primary.main,
                    // bgcolor: (theme) => theme.palette.primary.lighter,
                    // width: "fit-content",
                    // padding: "2px 7px",
                    // textAlign: 'center',
                    // borderRadius: "8px",
                }}
            >Price</Typography>
        </Stack>
    )
}
