import React, { useEffect, useState } from "react";
import { Typography, useTheme, Container, Avatar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Joyride, { STATUS } from "react-joyride";
import { motion } from "framer-motion";
import SuitableVendors from "../dashboard/sections/SuitableVendors";
import AddToCartAnimation, { AddToCartComp } from "./AddToCartAnimation";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import rightClick from "../../assets/images/right_click.png";
import { setOrderListTour, setSavePrfernces } from "../../redux/slices/appReducer";
import { steps } from "./steps";
// import { setSelectedCategory } from "../../redux/slices/appReducer";
import { setSelectedCategoryOnExplore } from "../../redux/slices/appReducer";
import { getSuitableVendorsList } from "../../redux/slices/appReducer";
import { useGetSuitableVendorListMutation, useSavePreferncesMutation } from "../../redux/api/api";
import { useParams } from "react-router-dom";
import { beveragesImage, dipsaCondimentsImage, frozenImage } from "../../assets/categories";
import GetImageComponent from "../../components/common/GetImageComponent";
const disableScrollClass = "disable-scroll";

//categories component
const CategoriesComponent = ({
  handleAddToCart,
  selectedCategories,
  category,
  index,
  dispatch,
}) => {

  const isSelected = selectedCategories?.some(
    (selectedCategoryId) => selectedCategoryId === category?.id
  );

  

  const handleClick = () => {
    const updatedCategories = isSelected
      ? selectedCategories.filter((itemId) => itemId !== category.id)
      : [...selectedCategories, category.id];
    dispatch(setSelectedCategoryOnExplore(updatedCategories));
    // savePrefernces({orgId, payload:{key: 'selectedCategories', value: JSON.stringify(updatedCategories)}});
    handleAddToCart(category, isSelected ? 0 : 1);
  };

  return (
    <div className="item">
      <motion.div
        animate={{
          scale: isSelected ? 1.1 : 1,
        }}
        whileHover={{ scale: 1.2 }}
        transition={{
          type: "spring",
          stiffness: 150,
          damping: 100,
          duration: 0.5,
        }}
        key={category.id}
        layout
        style={{
          position: "relative",
          width: "90px",
          height: "90px",
          padding: "0.3rem 0.3rem 0rem 0.3rem",
          borderRadius: "1.1rem",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          justifyItems: "flex-end",
          alignItems: "center",
          // backgroundColor: "rgb(241, 244, 249)",
          // theme.palette.grey[300]
        }}
        className={`select-category-item-${index}`}
        onClick={handleClick}
      >
        {/* <Avatar
          src={beveragesImage}
          // src={category?.image}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            background: "transparent",
          }}
        ></Avatar> */}
        <GetImageComponent s3Key={category?.parent_category_image} style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            background: "transparent",
          }}/>
        {isSelected && (
          <div
            style={{
              position: "absolute",
              zIndex: 4,
              height: "3rem",
              width: "3rem",
              borderRadius: "1.1rem",
              top: -20,
              right: -20,
            }}
          >
            <img src={rightClick} alt="right-image" />
          </div>
        )}
      </motion.div>
      <Typography
        variant="body1"
        sx={{
          // wordWrap: "break-word",
          mt: "8px",
          fontSize: "13px",
          fontWeight: "400",
          // width:'100%',
          // overflow: "hidden",
          // display: "-webkit-box",
          textAlign: "center",
          // WebkitBoxOrient: "vertical",
          // WebkitFontSmoothing:'antialiased',
          // WebkitLineClamp: 2,
          // WebkitBoxOrient:'vertical',
          // alignSelf:'center'
        }}
      >
        {category.parent_category}
      </Typography>
    </div>
  );
};

//expolre and select vendor component
const ExploreAndSelectVendor = () => {
  //hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const [savePrefernces] = useSavePreferncesMutation();

  const user = useSelector((state)=> state.auth.user)
  const userId = user?.userId

  //states
  const pincode = useSelector((state)=> state.app.preference.selectedPinCode?.pincode);
  const [getSuitableVendorList, {data: vendoListData}] = useGetSuitableVendorListMutation();
  const categories = useSelector((state) => state.auth.allSystemCategory);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isProductVisible, setIsProductVisible] = useState(false);
  const [animationDirection, setAnimationDirection] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currIndex, setCurrIndex] = useState(null);
  const selectedCategory = useSelector(
    (state) => state.app.selectedCategoryOnExplore
  );
  



  const isOrderListTour = useSelector(
    (state) => state.app.preference.isOrderListTour
  );
  const [isOpen, setIsOpen] = React.useState(false);

  // const {orgId} = useParams();
  const orgId = useSelector((state)=> state.app.preference.selectedOrgId);

  const handleCartOpen = () => {
    setIsOpen(!isOpen);
    if(!isOrderListTour){
      setCurrIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleCloseCart = () => {
    setIsOpen(false);
  };


  // tour call back function
  const handleTourCallback = (data) => {
    const { status, index, action, type } = data;

    if (
      status === STATUS.FINISHED ||
      action === "skip" ||
      action === "close" ||
      action === "reset"
    ) {
      setCurrIndex(null);
      const payload = {key:'isOrderListTour', value:'true'}
      dispatch(setSavePrfernces(payload));
      savePrefernces({ payload});
    } else if (["step:after", "error:target_not_found"].includes(type)) {
      const nextStepIndex = index + (action === "previous" ? -1 : 1);
      if (index === 0) {
        setCurrIndex(nextStepIndex);
      } else if (index === 1) {
        setCurrentCategory(categories[0])
        setCurrIndex(nextStepIndex);
      } else if (index === 2) {
        setCurrIndex(nextStepIndex);
      } else if (index === 3) {
        setCurrIndex(nextStepIndex);
      } else if (index === 4) {
        setCurrIndex(nextStepIndex);
      } else if (index === 5) {
        setCurrIndex(null);
        setCurrentCategory(null);
        dispatch(setOrderListTour(true))
        dispatch(setSelectedCategoryOnExplore([]))
        // savePrefernces({orgId, payload:{key: 'selectedCategories', value: JSON.stringify([])}});
      }
    }
  };

  //add to cart
  const handleAddToCart = (category, animation) => {
    setAnimationDirection(animation ? 1 : 0);
    setIsAnimating(true);
    setIsProductVisible(true);
    setCurrentCategory(category);
    if (currIndex === 0) {
      setCurrIndex(1);
    } else if (currIndex === 1) {
      dispatch(setOrderListTour(false))
      setTimeout(()=>{
        dispatch(setOrderListTour(false))
        setCurrIndex(2);
      },1000)
    }
    setTimeout(() => {
      setIsAnimating(false);
      setAnimationDirection(null);
      setCurrentCategory(null);
      setIsProductVisible(false);
    }, 1000); // Set timeout to match animation duration
  };

  //remove item from cart
  const handleRemoveItem = (categoryItem) => {
    const updatedCategories = selectedCategory.filter(
      (itemId) => itemId !== categoryItem.id
    );
    dispatch(setSelectedCategoryOnExplore(updatedCategories));
    // savePrefernces({orgId, payload:{key: 'selectedCategories', value: JSON.stringify(updatedCategories)}});
  };

  React.useEffect(() => {
    if (!isOrderListTour) {
      document.body.classList.add(disableScrollClass);
    } else {
      document.body.classList.remove(disableScrollClass);
    }

    return () => {
      document.body.classList.remove(disableScrollClass);
    };
  }, [isOrderListTour]);


  React.useEffect(() => {
    (
      async () => {
        await getSuitableVendorList({orgId, payload: {
          vendorOnly: 'false',  
          categories: selectedCategory,
          subCategories: [],
          singleVendor: false,
          pincode
        }}, true);
      }
    )()
  }, [selectedCategory, pincode]);


  //prev arrow
  const CustomPrevArrow = (props) => {
    const { className, onClick, style } = props;
    return (
      <div
        className={`arrow ${className}`}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          left: "-0.5rem",
          top: "6rem",
          zIndex: 1,
        }}
        onClick={onClick}
      >
        <KeyboardDoubleArrowLeftIcon
          style={{ color: theme.palette.primary.main, fontSize: "2.5rem" }}
        />
      </div>
    );
  };

  //next arrow
  const CustomNextArrow = (props) => {
    const { className, onClick, style } = props;
    return (
      <div
        className={`arrow ${className}`}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          right: "-0.5rem",
          top: "6rem",
          zIndex: 1,
          transition: "background-color 0.3s ease", // Smooth transition for the background colo
        }}
        onClick={onClick}
      >
        <KeyboardDoubleArrowRightIcon
          style={{ color: theme.palette.primary.main, fontSize: "2.5rem" }}
        />
      </div>
    );
  };

  //setting for slider
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    fade: false,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  if (window.innerWidth <= 768) {
    // Mobile & Tablet
    settings.slidesToShow = 2;
  } else if (window.innerWidth <= 992) {
    // Tablet & Small Desktop
    settings.slidesToShow = 5;
  } else {
    // Large Desktop
    settings.slidesToShow = 8;
  }

  return (
    <Container maxWidth="xl" sx={{ position: "relative" }}>
      <Joyride
        steps={steps}
        run={!isOrderListTour}
        callback={handleTourCallback}
        showSkipButton
        scrollToFirstStep
        showProgress
        continuous={true}
        scrollOffset={100}
        disableScrolling={true}
        disableScrollParentFix={true}
        stepIndex={currIndex}
        debug={true}
        styles={{
          spotlight: {
            borderRadius: "50%",
          },

          options: {
            primaryColor: theme.palette.primary.main,
            spotlightPadding: 0,
            width: "350px",
          },
        }}
      />
      <AddToCartComp
        cartCount={selectedCategory?.length}
        cart = {false}
        animationDirection={animationDirection}
        selectedCategories={categories.filter((category) =>
          selectedCategory?.includes(category.id)
        )}
        currentCategory={currentCategory}
        handleRemoveItem={handleRemoveItem}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleCloseCart={handleCloseCart}
        handleCartOpen={handleCartOpen}
      />
      {
        isAnimating && <AddToCartAnimation
        isAnimating={isAnimating}
        isProductVisible={isProductVisible}
        animationDirection={animationDirection}
        currentCategory={currentCategory}
        handleCloseCart={handleCloseCart}
      />
      }
      <div className="catSliderSection">
        <div className="continer-fluid">
          <h2 >Featured Categories</h2>
          <Slider {...settings} className="cat_slider_main">
            {categories.map((category, index) => (
              <div className="item" key={index}>
                <CategoriesComponent
                  className={"item"}
                  key={index}
                  index={index}
                  category={category}
                  handleAddToCart={handleAddToCart}
                  selectedCategories={selectedCategory}
                  dispatch={dispatch}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {<SuitableVendors suitableVendors={vendoListData} /> }
    </Container>
  );
};

export default ExploreAndSelectVendor;
