import { Box, CircularProgress } from "@mui/material";

export function CircularProgressWithIcon({ value, size, thickness, color, icon, categoryId }) {

    return (
        <>
            <Box position="relative" display="inline-flex" >
                <CircularProgress
                    variant="determinate"
                    value={value ?? 100}
                    size={size ?? 56}
                    thickness={thickness ?? 2.5}
                    sx={{
                        color: color ?? "black"
                    }}
                />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {icon}
                </Box>
            </Box>
        </>
    )
}