import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useResponsive } from '../../../../hooks/useResponsive'
import { horecaApi, useCreateZohoDetailMutation, useGetZohoDataByOrgIdQuery, useUpdateZohoDetailMutation, useUploadInventoryFileMutation } from '../../../../redux/api/api';
import { openMessage } from '../../../../redux/slices/showMessageSlice';
import { downloadExcel, VisuallyHiddenInput } from '../../../inventory/list';
import Iconify from '../../../../components/common/Iconify';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getIn, useFormik } from 'formik';
import CustomTextField from '../../../../components/common/CustomTextField';
import CustomButton from '../../../../components/common/CustomButton';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

const InvetoryAndZohoDetail = ({ orgId }) => {

  const mdDown = useResponsive('down', 'md');
  const dispatch = useDispatch();
  const [downloadSampleTemplates] = horecaApi.endpoints.downloadSampleTemplates.useLazyQuery();
  const [uploadInventoryFile, { isLoading }] = useUploadInventoryFileMutation();
  const { data: zohoOrgData } = useGetZohoDataByOrgIdQuery(orgId);
  const [createZohoDetail, { isLoading: zohoLoading }] = useCreateZohoDetailMutation();




  const formik = useFormik({
    initialValues: {
      clientId: zohoOrgData?.clientId ?? '',
      clientSecret: zohoOrgData?.clientSecret ?? '',
      zohoCode: zohoOrgData?.zohoCode ?? '',
      organizationId: zohoOrgData?.organizationId ?? '',
      orgId: orgId
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const result = await createZohoDetail({ orgId, payload: values });
      if (result.data) {
        dispatch(openMessage({ message: 'Zoho details updated!', messageSeverity: 'success' }))
      } else if (result.error) {
        dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }))
      } else {
        dispatch(openMessage({ message: 'Not able to update details at this moment!', messageSeverity: 'error' }))
      }
    }
  })

  const [selectedInventory, setSelectedInventory] = useState("horecaInventory");

  const handleInvntoryChange = (event) => {
    const value = event.target.value;
    if (!zohoOrgData) {
      if (selectedInventory != value) {
        setSelectedInventory((prevSelected) =>
          prevSelected === value ? "" : value
        );
      }
      formik.resetForm();
    }
  };


  useEffect(() => {
    zohoOrgData?.id ? setSelectedInventory('zohoInventory') : setSelectedInventory('horecaInventory')
  }, [zohoOrgData])

  const sampleDownload = async () => {
    const result = await downloadSampleTemplates({ orgType: 'vendor', templateType: 'sample_items' });
    if (result?.data) {
      downloadExcel(result?.data?.img)
      dispatch(openMessage({ message: 'Sample downloaded successfully!', messageSeverity: 'success' }))
    }
  }

  const uploadInventory = async (newFile) => {
    if (newFile) {
      const formData = new FormData();
      formData.append("file", newFile.target.files[0]);
      const result = await uploadInventoryFile({
        payload: formData,
        orgId: orgId,
      });
      if (result?.data) {
        dispatch(openMessage({ message: 'File uploaded successfully!', messageSeverity: 'success' }))
        // fetchData();
        setTimeout(() => {
          // fetchData();
        }, 3000)
      }
    }
  }






  return (
    <Grid>
      <Stack direction={mdDown ? "column" : "row"}>
        {[
          { id: "horecaInventory", name: "Horeca1 inventory" },
          { id: "zohoInventory", name: "Zoho inventory" },
        ].map((obj, index) => {
          return (
            <FormGroup key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedInventory.includes(
                      obj.id
                    )}
                    disabled={zohoOrgData?.id}
                    onChange={handleInvntoryChange}
                    value={obj.id}
                  />
                }
                label={obj.name}
              />
            </FormGroup>
          );
        })}
      </Stack>
      {
        selectedInventory === 'zohoInventory' && <>
          <Grid container spacing={2} mt={'1rem'}>
            <Grid item xs={12}>
              <CustomTextField
                name={'clientId'}
                label={'Client Id'}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name={'clientSecret'}
                label={'Client Secret'}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name={'zohoCode'}
                label={'Zoho Code'}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name={'organizationId'}
                label={'Organization Id'}
                formik={formik}
              />
            </Grid>
          </Grid>
          {
            !zohoOrgData?.id && <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mt: '2rem' }}>
              <CustomButton
                label={'Update Zoho Detail'}
                onClick={formik.handleSubmit}
                loading={zohoLoading}
              />
            </Stack>
          }
        </>
      }
      {
        (selectedInventory === 'horecaInventory' || zohoOrgData) && <Grid item xs={16} sm={8} mt={'1rem'}>
          <Grid container columns={16} gap={2} justifyContent={"flex-end"}>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              onClick={sampleDownload}
              startIcon={<Iconify icon="mingcute:file-download-line" />}
            >
              Sample Download
            </Button>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<UploadFileIcon />}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Upload'
              )}
              <VisuallyHiddenInput type="file" onChange={(e) => uploadInventory(e)} />
            </Button>
          </Grid>
        </Grid>
      }

    </Grid>
  )
}

export default InvetoryAndZohoDetail