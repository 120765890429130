import { Container, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import UserPage from './user-view'
import { horecaApi, useGetRolesQuery } from '../../../redux/api/api';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../../components/common/Iconify';
import CreateNewOrganizationDialogue from '../common/CreateNewOrganizationDialogue';
import { useState } from 'react';
import PageHeader from '../../../components/common/PageHeader';
import { useRouter } from '../../../routes/hooks/useRouter';
import useQueryParams from '../../../routes/hooks/useQueryParams';
import { setCurrSelectedPage, setRowsPerPage } from '../../../redux/slices/appReducer';

const Vendors = () => {

  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const { data: rolesData } = useGetRolesQuery();
  const [getAllVendors, { data: vendorsList, isLoading: getAllVendorsLoading }] = horecaApi.endpoints.getAllVendors.useLazyQuery();

  const dispatch = useDispatch();
  // const [page, setPage] = useState(0);
  const page = useSelector((state)=> state.app.currSelectedPage.vendorPage)
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsPerPage = useSelector((state)=> state.app.rowsPerPageData.vendorPage)
  const [search, setSearch] = useState('');
  const router = useRouter();
  const params = useQueryParams();

  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null)

  const [createOrganizationDialogue, setCreateOrganizationDialogue] = useState(false);


  const [isView, setIsView] = useState(false);

  useEffect(() => {
    (
      async () => await getAllVendors({
        orgId,
        search,
        page,
        rowsPerPage,
      })
    )()
  }, [search, page, rowsPerPage, selectedOrgId, createOrganizationDialogue])

  const handleOpenOrganizationDialogue = () => {
    setCreateOrganizationDialogue(true);
  }

  useEffect(() => {
    let r = 'vendor';
    let id;
    if (rolesData && rolesData?.length > 0) {
      id = (rolesData.find(role => role.roleName == r) || {}).id
    }
    setSelectedRoleId(id);
  }, [])


  const handleCloseOrganizationDialogue = () => {
    setSelectedOrgId(null)
    setCreateOrganizationDialogue(false);
    setIsView(false)
    router.push(`/org/${orgId}/vendors`);
  }


  useEffect(() => {
    if (params?.vendorId) {
      setSelectedOrgId(params?.vendorId)
      setCreateOrganizationDialogue(true);
    }
  }, [params])

  const handelOnEditClick = (selectedOrgId, type) => {
    setSelectedOrgId(selectedOrgId)
    setCreateOrganizationDialogue(true);
    if (type === 'edit') {
      setIsView(false)
    } else {
      setIsView(true)
    }
  }


  const setRowsPerPage1 = (val) => {
    dispatch(setRowsPerPage({name: 'vendorPage', value: val}));
  }

  const setPage1 = (val) =>{
    dispatch(setCurrSelectedPage({name: 'vendorPage', value: val}));
  }

  return (
    <Container maxWidth="xl" >
      <PageHeader
        title={"Vendors"}
        variant1={"contained"}
        onClick1={handleOpenOrganizationDialogue}
        startIcon1={<Iconify icon="eva:plus-fill" />}
        label1={"New Vendor"}
        // linkArray={[
        //   { title: "Dashboard", path: `/` },
        //   { title: "Vendors", },
        // ]}
      />
      <UserPage data={vendorsList} setSelectedOrgId={setSelectedOrgId} onEdit={handelOnEditClick} setPage={setPage1} setRowsPerPage={setRowsPerPage1} setSearch={setSearch} page={page} rowsPerPage={rowsPerPage} search={search} searchPlaceholder={"vendor"} isLoading={getAllVendorsLoading} />
      <CreateNewOrganizationDialogue
        handelCloseDialogue={handleCloseOrganizationDialogue}
        open={createOrganizationDialogue}
        type={'vendor'}
        rolesData={rolesData}
        selectedOrgId={selectedOrgId}
        selectedRoleId={selectedRoleId}
        disabled={isView ? true : false}
        label={isView ? 'Vendor Detail' : 'Edit Vendor Detail'}
      />
    </Container>
  )
}

export default Vendors