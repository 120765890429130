import { Grid, IconButton, Stack, Typography } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomBreadCrumb from './CustomBreadCrumb'
import CustomButton from './CustomButton'
import Iconify from './Iconify';

export default function PageHeader({
    title, linkArray, handleBackClick, backIconSx, statusLabel, titleBelowComponent,
    onClick1, startIcon1, endIcon1, loading1, variant1, label1, sx1, size1, disabled1, colorButton1, widthButton1, heightButton1, props1, type1, visuallyHiddenInput1, handleVisuallyHiddenInput1,
    onClick2, startIcon2, endIcon2, loading2, variant2, label2, sx2, size2, disabled2, colorButton2, widthButton2, heightButton2, props2, type2, visuallyHiddenInput2, handleVisuallyHiddenInput2, isEdit=false, onEdit,isLoading
}) {
    return (
        <Grid container mb={{ xs: 2, sm: 4 }} columns={16} rowGap={2}>
            <Grid item xs={16} sm={8} >
                <Stack direction={'row'} justifyContent={"flex-start"} alignItems={"center"} height={"100%"}>
                    {handleBackClick && <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        height={"100%"}
                        width={"fit-content"}
                    >
                        {/* sx={{
                  padding: 0, minWidth: '20px', minHeight: '20px', color: 'white', ':hover': {
                    background: 'none'
                  }
                }} */}
                        <IconButton size="small" onClick={handleBackClick} 
                        // sx={{padding: 0.5, minWidth: '20px', minHeight: '20px'}}
                        >
                            <ArrowBackIosNewIcon sx={{ ...backIconSx , 
                                // fontSize: '16px'
                                }} />
                        </IconButton>
                    </Stack>}
                    <Stack
                        direction={"column"}
                        alignItems={"flex-start"}
                        height={"100%"}
                        width={"100%"}
                    >
                        <Stack direction={'row'} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                            {title && <Typography variant="h4" noWrap textTransform={'capitalize'}>{title} {isEdit && <IconButton onClick={onEdit}><Iconify icon="fluent:edit-20-filled"/></IconButton>}</Typography>}
                            {statusLabel}
                        </Stack>
                        {linkArray?.length > 0 && <CustomBreadCrumb linkArray={linkArray} />}
                        {titleBelowComponent}
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={16} sm={8} >
                <Grid container columns={16} gap={2} justifyContent={"flex-end"}>
                    {label1 && <CustomButton
                        onClick={onClick1}
                        startIcon={startIcon1}
                        endIcon={endIcon1}
                        loading={loading1}
                        variant={variant1}
                        label={label1}
                        sx={sx1}
                        size={size1}
                        disabled={disabled1}
                        color={colorButton1}
                        width={widthButton1}
                        height={heightButton1}
                        props={props1}
                        type={type1}
                        visuallyHiddenInput={visuallyHiddenInput1}
                        handleVisuallyHiddenInput={handleVisuallyHiddenInput1}
                    />}
                    {label2 && <CustomButton
                        onClick={onClick2}
                        startIcon={startIcon2}
                        endIcon={endIcon2}
                        loading={loading2}
                        variant={variant2}
                        label={label2}
                        sx={sx2}
                        size={size2}
                        disabled={disabled2}
                        color={colorButton2}
                        width={widthButton2}
                        height={heightButton2}
                        props={props2}
                        type={type2}
                        visuallyHiddenInput={visuallyHiddenInput2}
                        handleVisuallyHiddenInput={handleVisuallyHiddenInput2}
                        isLoading={isLoading}
                    />}
                </Grid>
            </Grid>
        </Grid>
    )
}