import { Box, Grid,Stack } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { bgGradient } from "../../../../theme/css";

import Logo from "../../../../components/common/Logo";
import { useResponsive } from "../../../../hooks/useResponsive";

// ----------------------------------------------------------------------

const  AuthView = ({children}) => {
  const theme = useTheme();

  const isLgDown = useResponsive("down", "lg");

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: "/assets/background/overlay_3.jpg",
        }),
        minHeight: "100vh",
        padding:'15px'
      }}
    >
      <Logo
        sx={{
          marginTop: "1rem",
        }}
      />

      <Grid container>
        {!isLgDown && (
          <Grid item sx={{ height: 1 }} xs={0} md={0} lg={6}>
            <Stack
              display={"flex"}
              flexDirection={"column"}
              flexGrow={1}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src="/assets/illustrations/illustration_dashboard.png"
                alt=""
                style={{ display: "inline-block", verticalAlign: "bottom" }}
              />
            </Stack>
          </Grid>
        )}
        <Grid item sx={{ height: 1 }} xs={12} md={12} lg={6}>
          <Stack
            display={"flex"}
            flexDirection={"column"}
            flexGrow={1}
            alignItems={"center"}
            justifyContent={"center"}
            height={1}
            sx={{
              padding: isLgDown ? "80px 0px 0" : "80px 64px 0",
              marginX: "auton",
              marginY: "auto",
              width: "100%",
            }}
          >
            {children}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AuthView;