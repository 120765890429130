import { Container, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import Iconify from '../../../../../components/common/Iconify'
import MyUsers from './user/MyUsers'
import MyRoles from './role/MyRoles'
import CreateRoleDialogue from '../CreateRoleDialogue'
import CreateUserDialogue from '../CreateUserDialogue'
import PageHeader from '../../../../../components/common/PageHeader'
import CustomButton from '../../../../../components/common/CustomButton'

const MyTeams = () => {

  const [openRole, setOpenRole] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  const handleCloseRole = () => {
    setOpenRole(false);
  }

  const handleCloseUser = () => {
    setOpenUser(false);
  }

  return (
    <Container maxWidth={'xl'}>
      <PageHeader
        title={"My Team"}
        // linkArray={[
        //   { title: "Dashboard", path: `/` },
        //   { title: "My Team", },
        // ]}
      />
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={'1rem'}>
        <Typography variant='h4'>
          Users
        </Typography>
        <CustomButton
          onClick={() => setOpenUser(true)}
          startIcon={<Iconify icon="ic:round-plus" />}
          variant={"contained"}
          label={"Create New User"}
        />
      </Stack>
      <MyUsers refreshUser={openUser} />
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={'2rem'} mb={'1rem'}>
        <Typography variant='h4'>
          Roles
        </Typography>
        <CustomButton
          onClick={() => setOpenRole(true)}
          startIcon={<Iconify icon="ic:round-plus" />}
          variant={"contained"}
          label={"Create New Role"}
        />
      </Stack>
      <MyRoles refreshRole={openRole} />
      <CreateRoleDialogue
        open={openRole}
        handelCloseDialogue={handleCloseRole}
      />
      <CreateUserDialogue
        open={openUser}
        handelCloseDialogue={handleCloseUser}
      />
    </Container>
  )
}

export default MyTeams