import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CreateNewOutlet from "./CreateNewOutlet";
import Iconify from "../../../../../components/common/Iconify";

const CreateOutlerDialogue = ({ handelCloseDialogue, open, outletId, disabled, setEnabled }) => {

  return (
    <Dialog
      maxWidth={"lg"}
      scroll={"paper"}
      open={open}
      onClose={handelCloseDialogue}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4">
          Create Outlet
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handelCloseDialogue}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          {/* <CloseIcon /> */}
          <Iconify icon="material-symbols:cancel-outline" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          '&::-webkit-scrollbar': {
            display: "none"
          },
        }}
      >
        <CreateNewOutlet outletId={outletId} handelClose={handelCloseDialogue} disabled={disabled} setEnabled={setEnabled} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateOutlerDialogue;