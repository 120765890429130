import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';

import { convertDateFormat, fDateTime, formatDate, formatDateAndTime, getFormattedDate } from '../../utils/formatNumbers';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function OrderTimeline({ title, subheader, list, orderHistoryStatus, ...other }) {


  return (
    <Box {...other}>
      {/* <CardHeader title={title} subheader={subheader} /> */}

      <Timeline
        sx={{
          m: 0,
          // p: 3,
          p: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {orderHistoryStatus?.map((item, index) => (
          <OrderItem key={item.id} item={item} lastTimeline={index === orderHistoryStatus.length - 1} />
        ))}
      </Timeline>
    </Box>
  );
}

OrderTimeline.propTypes = {
  list: PropTypes.array,
  subheader: PropTypes.string,
  title: PropTypes.string,
};

// ----------------------------------------------------------------------

function OrderItem({ item, lastTimeline }) {

  const { status, message, createdAt } = item;

  let time = formatDate(createdAt)

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (lastTimeline && 'success') ||
            'grey'
          }
        />
        {lastTimeline ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
          {status === 'shipped' ? status : message ? message : status}

        </Typography>

        <Typography variant="caption" sx={{ color: 'text.disabled', textTransform: 'capitalize' }}>
          {time}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

OrderItem.propTypes = {
  item: PropTypes.object,
  lastTimeline: PropTypes.bool,
};
