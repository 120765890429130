import { Box, Typography } from "@mui/material";

const SupportComponent = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>Customer Support</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="subtitle1">Purchase Protection</Typography>
                <Typography variant="body2">As a B2B platform, we are committed to ensuring Purchase Protection for customers by offering genuine products, secure payments and easy grievance redressal for items purchased through Horeca1. However, please note that Horeca1 is a retailer/wholesaler platform ONLY, and claims no responsibilities for Manufacturer’s warranties, post-sale services, etc which are exclusively the domain of the Manufacturer / Company Authorised Seller.</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="subtitle1">Grievance Redressal</Typography>
                <Typography variant="body2">For any issues related to purchases on HCX Global, Customers can contact our Customer Service via email - sales@horeca1.com. Our Customer Service Representatives will escalate the matter to respective parties to get the issue resolved at the earliest.</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="subtitle1">Shipping & Delivery</Typography>
                <Typography variant="body2">As a Bulk Supplies platform, we deliver within 2 working days. However most of our deliveries happen the next business day itself. Please note that as of now we do not deliver on Sundays. Hence, Customers are requested to plan their orders accordingly.</Typography>
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    A Shipping fee may be applicable. The shipping fee will be refunded in the following cases:
                    {'\n#'} Order cancellation by the customer before delivery;
                    {'\n#'} Order declined by the seller;
                    {'\n#'} Order shipment lost during transit or undelivered
                    {'\n'}The shipping fee will not be refunded to customers in the event of return of a delivered order.
                </Typography>

            </Box>
        </Box>
    )
}

export default SupportComponent;