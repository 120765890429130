import { alpha } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

// ----------------------------------------------------------------------

export function overrides(theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          maxWidth: '100%',
          display: 'inline-block',
          verticalAlign: 'bottom',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.grey[900], 0.8),
        },
        invisible: {
          background: 'transparent',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedInherit: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.grey[800],
          '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey[800],
          },
        },
        sizeLarge: {
          minHeight: 48,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.customShadows.card,
          borderRadius: Number(theme.shape.borderRadius) * 2,
          position: 'relative',
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       [`& .${outlinedInputClasses.notchedOutline}`]: {
    //         borderColor: alpha(theme.palette.grey[500], 0.24),
    //       },
    //     },
    //   },
    // },
    MuiPaper: {
      // defaultProps: {
      //   // elevation: theme.palette.mode === "dark" ? 0 : 5,
      //   // boxShadow: theme.palette.mode !== "dark" ? 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px' : 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 12px 24px -4px'
      // },
      styleOverrides: {
        root: {
          boxShadow: theme.palette.mode !== "dark" ? 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px' : 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 12px 24px -4px',
          borderRadius: "16px"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
        },
        root: {
          borderBottom: "1px dashed rgba(145, 158, 171, 0.2)"
        }
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[800],
        },
        arrow: {
          color: theme.palette.grey[800],
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
    // MuiFormControl: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiInputLabel-root': {
    //         color: '', // Change label color within form control
    //       },
    //     },
    //   },
    // },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            color: theme.palette.grey[500], // Default label color
            fontSize: '0.8rem', // Default label font size
          },
          '&:focus-within .MuiFormLabel-root': {
            color: theme.palette.grey[900], // Label color when form control is focused
            fontSize: '1rem'
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            // Modify label styles here
            color: theme.palette.grey[500], // Change label color
            fontSize: '0.8rem', // Change label font size
          },
          '& .MuiInputBase-input': {
            // Modify input text styles here
            color: theme.palette.text.primary, // Change input text color
            height: '1rem'
          },
          '& .MuiInputBase-input::placeholder': {
            // Modify placeholder styles here
            color: theme.palette.grey[500], // Change placeholder color
            fontStyle: 'italic', // Change placeholder font style
          },
          '&:focus-within': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[900], // Change border color when focused
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            // Modify label styles here
            color: theme.palette.grey[500], // Change label color
            fontSize: '0.5rem', // Change label font size
          },
          '& .MuiOutlinedInput-notchedOutline': {
            // borderColor: theme.palette.grey[300], // Default border color
            // innerWidth:'8px'
            // padding: '0px 0px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            // borderColor: 'transparent', // Set border color to transparent
            // boxShadow: `0 0 3px 0px #121164`
          },
          // '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          //   borderColor: theme.palette.grey[900], // Border color on focus
          // },
          '&.Mui-focused': {
            '& .MuiInputLabel-root': {
              color: theme.palette.grey[500], // Change label color on focus
            },
            '& .MuiOutlinedInput-notchedOutline': {
              // borderColor: theme.palette.grey[900], // Border color on focus
              border: theme.palette.mode !== "dark" ? `1px solid ${theme.palette.grey[900]}` : `1px solid white`,
              // border: `1px solid ${theme.palette.primary.main}`
            },
          },
          '& .MuiOutlinedInput-input': {
            // padding: '10px 10px',
            height: '1rem',
            fontSize: "0.875rem"
          }
        },
      },
    },
    // MuiTextfield: {
    //   styleOverrides: {
    //     root: {
    //       padding:'0px 0px',
    //       '& label.Mui-focused': {
    //         color: 'black',
    //         borderBottomColor: '',
    //       },
    //       '& .MuiInput-underline:after': {
    //         borderBottomColor: '',
    //       },
    //       '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //           borderColor: '',
    //         },
    //         '&:hover fieldset': {
    //           borderColor: '',
    //         },
    //         '&.Mui-focused fieldset': {
    //           borderColor: '',
    //         },
    //         '& .MuiInputLabel-root': {
    //           // Modify label styles here
    //           color: theme.palette.grey[500], // Change label color
    //           fontSize: '0.8rem', // Change label font size
    //         },
    //       },
    //     },
    //   }
    // },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.action.hover, // Default background color for TableHead
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected, // Background color when row is selected
          },
          '&.Mui-selected:hover': {
            backgroundColor: theme.palette.action.hover, // Background color when row is selected and hovered
          },
          '&:hover': {
            backgroundColor: theme.palette.action.hover, // Background color on hover
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: '1rem',
          // height: "1rem",
          // maxHeight: "1rem"
        },
        outlined: {
          minHeight: '1rem',
          // height: "1rem",
          // maxHeight: "1rem"
        },
        input: {
          minHeight: '1rem',
          // height: "1rem",
          // maxHeight: "1rem"
        }
        // root: {
        //   '& .MuiInputBase-root': {
        //     height: '1rem', // Maintain the desired input height
        //   },
        //   '& .MuiOutlinedInput-root': {
        //     height: '1rem', // Maintain the desired input height
        //   },
        //   '& .MuiOutlinedInput-input': {
        //     height: '1rem', // Maintain the desired input height
        //   },
        // },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-scrollButtons': {
            display: 'none', // Initially hide scroll buttons
          },
          // boxShadow: 'rgba(145, 158, 171, 0.08) 0px -2px 0px 0px inset',
          boxShadow: theme.palette.mode === 'dark' ? 'rgba(145, 158, 171, 0.08) 0px -2px 0px 0px inset' : 'rgba(145, 158, 171, 0.08) 0px -2px 0px 0px inset'
          // '& .MuiTabs-scrollButtons.Mui-disabled': {
          //   display: 'none', // Keep disabled scroll buttons hidden
          // },
          // '& .MuiTabs-scrollableX:not(:hover)': {
          //   '& .MuiTabs-scrollButtons.Mui-end': {
          //     display: 'flex', // Show right scroll button when content overflows to the right (not on hover)
          //   },
          // },
          // '& .MuiTabs-scrollableX:not(:hover)': {
          //   '& .MuiTabs-scrollButtons.Mui-beginning': {
          //     display: 'flex', // Show left scroll button when content overflows to the left (not on hover)
          //   },
          // },
          // '&:hover .MuiTabs-scrollableX:not(:hover)': {
          //   '& .MuiTabs-scrollButtons.Mui-beginning': {
          //     display: 'flex', // Show left scroll button when content overflows to the left (not on hover)
          //   },
          // },
          // '&:hover .MuiTabs-scrollButtons': {
          //   display: 'flex', // Show both scroll buttons on hover
          // },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': {
            height: '7px',
            width: '7px'
          },
          '&:hover': {
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#b4bcc3',
              borderRadius: '8px',
            },
            // '&::-webkit-scrollbar': {
            //   height: '7px',
            //   width: '7px'
            // },
          },
          overflowY: 'auto',
          overflowX: 'auto',
          maxHeight: 440
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': {
            // height: '7px',
            // width: '7px',
            display:"none"
          },
          '&:hover': {
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#b4bcc3',
              borderRadius: '8px',
            },
          },
          overflowY: 'auto',
          overflowX: 'auto',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: theme.palette.mode === 'dark' ? 'rgb(52, 62, 72)' : 'rgb(246, 247, 248)'
        }
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          '&::-webkit-scrollbar': {
            display: "none"
          }
        }
      }
    }
  };
}