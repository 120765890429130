import { useState } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CustomLabel from '../../components/common/label/CustomLabel';
import Iconify from '../../components/common/Iconify';
import GetImageComponent from '../../components/common/GetImageComponent';
import { getStatusColor } from '../system-admin/customer/user-table-row';
import VendorLogoImageComponent from '../../components/common/VendorLogoImageComponent';

// ----------------------------------------------------------------------

export default function MyCustomerTableRow({
  selected,
  orgId,
  name,
  avatarUrl,
  company,
  role,
  isVerified,
  status,
  orgAdmin,
  handleClick,
  setSelectedOrgId,
  onEdit
}) {
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const onEditClick = () => {
    // setSelectedOrgId(orgId)
    onEdit(orgId)
  }


  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>

        <TableCell >
          <Stack direction="row" alignItems="center" spacing={2}>
            <VendorLogoImageComponent s3Key={avatarUrl} name={name} />
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>{company}</TableCell>

        <TableCell>{role}</TableCell>

        <TableCell align="left">
          <Stack>
            <Typography variant='subtitle2' fontSize={'14px'} fontWeight={400}>{orgAdmin?.firstName}</Typography>
            <Typography variant='subtitl2' fontSize={'14px'} fontWeight={400}>{orgAdmin?.mobileNumber}</Typography>
          </Stack>
        </TableCell>

        {/* <TableCell>
          <CustomLabel color={getStatusColor(status)}>{status}</CustomLabel>
        </TableCell> */}

        <TableCell padding='checkbox'>
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        <MenuItem onClick={onEditClick}>
          <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={handleCloseMenu} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}

// MyCustomerTableRow.propTypes = {
//   avatarUrl: PropTypes.any,
//   company: PropTypes.any,
//   handleClick: PropTypes.func,
//   isVerified: PropTypes.any,
//   name: PropTypes.any,
//   role: PropTypes.any,
//   selected: PropTypes.any,
//   status: PropTypes.string,
// };
