import { Avatar, Box, IconButton, MenuItem, Paper, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomButton from '../../../components/common/CustomButton'
import Iconify from '../../../components/common/Iconify';
import CreateNewCategoryDialogue from './CreateNewCategoryDialogue';
import { useCreateNewCategoryMutation, useGetAllCategoriesWithSubCategoriesQuery } from '../../../redux/api/api';
import { TableColumns } from '../../order-list/TableColumns';
import TableLoadingData from '../../../components/common/TableLoadingData';
import { stringAvatar } from '../../../utils/defaultIcon';
import GetImageComponent from '../../../components/common/GetImageComponent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableNoData from '../../../views/list/table-no-data';
import CustomMenu from '../../order/common/CustomMenu';
import { openMessage } from '../../../redux/slices/showMessageSlice';
import { useDispatch } from 'react-redux';

const MyCatgeory = () => {

    const {data: categoryData, isLoading: categoryLoading, refetch} = useGetAllCategoriesWithSubCategoriesQuery();
    const [selectedCategory, setSelectedCategroy] = useState({});

    const headLable = TableColumns('category');
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
  
    
    const [openCreateNewCat, setOpenCreateNewCat] = useState(false);

    const CloseOpenCreateNewCat = () => {
        setOpenCreateNewCat(false)
    }

    const [popovers, setPopovers] = useState({});

  const handleClickPopover = (event, rowIndex) => {
    setPopovers((prevState) => ({
      ...prevState,
      [rowIndex]: {
        anchorEl: event.currentTarget,
        open: true,
      },
    }));
  };

  const handleClosePopover = (rowIndex) => {
    setPopovers((prevState) => ({
      ...prevState,
      [rowIndex]: {
        anchorEl: null,
        open: false,
      },
    }));
  };


  const [currOp, setCurrOp] = useState('create');

  

  const menuItems = [
    {
      id: "view",
      label: "View",
      icon: <Iconify width={24} icon="carbon:view-filled" />,
      handler: () => (setOpenCreateNewCat(true), setCurrOp('view'))
    },
    {
      id: "edit",
      label: "Edit",
      icon: <Iconify width={24} icon="fluent:edit-28-filled" />,
      handler: () => (setOpenCreateNewCat(true), setCurrOp('edit'))
    }
  ];

  const handleClick = (event, catId, userDetail) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    // setCategoryId(catId)
   setSelectedCategroy( categoryData?.filter((cate)=> cate.id === catId))
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
    // setCategoryId(null)
    setSelectedCategroy({})
  };

  const dispatch = useDispatch();
    const [createNewCategory, {isLoading}] = useCreateNewCategoryMutation();
  

  const handleCreatnewCategory = async (d, callback) => {
    const response = await createNewCategory(d);
            if(response?.data){
                dispatch(openMessage({
                    message: response.data.message,
                    messageSeverity: "success",
                  }))
                  CloseOpenCreateNewCat();
                  refetch();
                  callback();
            }else if(response?.error){
               dispatch( openMessage({
                message: response.error.data.message,
                messageSeverity: "error",
              }))
            }else{
                dispatch(openMessage({
                    message: 'Not able to create an new category',
                    messageSeverity: "error",
                  }))
            }
  }
  

  return (
    <Box>
        <CustomMenu anchorEl={anchorEl} open={openMenu} onClose={handleClose} menuItems={menuItems} />
         <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={'1rem'}>
        <Typography variant='h4'>
          Category
        </Typography>
        <CustomButton
          onClick={() => (setOpenCreateNewCat(true), setCurrOp('create'))}
          startIcon={<Iconify icon="ic:round-plus" />}
          variant={"contained"}
          label={"Create New Category"}
        />
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium" stickyHeader>
          <TableHead sx={{ height: '60px' }}>
            {headLable?.map((column) => (
              <TableCell
                key={column?.id}
                align={column?.align ?? 'left'}
                padding={column?.padding ?? 'normal'}
                sx={column?.style}
              >
                {column?.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {categoryLoading ?
              <TableLoadingData colSpan={headLable?.length} /> :
              categoryData?.length > 0 ? (
                categoryData?.map((row, rowIndex) => {
                  const popoverState = popovers[rowIndex] || { anchorEl: null, open: false };
                  let nameColor = stringAvatar({ name: `${row?.parent_category}`, color: "rgb" })?.item;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} >
                      {headLable.map((column, headLableIndex) => {
                        let value = null;
                        const style = { ...column?.style };
                        switch (column?.id) {
                          case "srno":
                            value = headLableIndex + 1;
                            break;
                          case 'parent_category':
                            value = (
                              <Stack direction={'row'} gap={0.5}>
                                <Avatar> <GetImageComponent s3Key={row?.parent_category_image} /> </Avatar>
                                <Typography variant="body2">{row?.parent_category}</Typography>
                              </Stack>
                            );
                            break;
                          default:
                            let splitArray = column?.id.split(".");
                            value = row;
                            for (let key of splitArray) {
                              if (value && value.hasOwnProperty(key)) {
                                value = value[key];
                              } else {
                                value = null;
                                break;
                              }
                            }
                            break;
                        }
                        return (
                          <TableCell key={column?.id} align={column?.align} padding={column?.padding} sx={{ border: ((categoryData?.length - 1) === rowIndex) && '0px', ...column?.style }}>
                            {
                                column?.id === 'srno' && <Typography>#{rowIndex+1}</Typography>
                            }
                            {(column?.id === 'action' && <IconButton onClick={(event) => handleClick(event, row?.id, row)}>
                                <MoreVertIcon />
                              </IconButton>)}
                            {column?.id === 'parent_category' && (
                              <Stack direction={'row'} gap={'1rem'}>
                                {row?.parent_category_image ?
                                  <Avatar sx={{ width: 43, height: 43 }}>
                                    <GetImageComponent s3Key={row?.parent_category_image} />
                                  </Avatar> :
                                  <Avatar
                                    sx={{
                                      width: 43,
                                      height: 43,
                                      bgcolor: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},0.5)` : `rgba(${nameColor?.sx?.bgcolor},0.7)`,
                                      color: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},1)` : `rgba(255,255,255,0.9)`
                                    }}
                                    children={nameColor?.children}
                                  />}
                                <Typography variant="body2" sx={{ fontWeight: 550 }}>{row?.parent_category}</Typography>
                              </Stack>
                            )}
                            {column?.id === "subCategoryDetails" && (
                              <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  ...style, fontWeight: 400, lineHeight: '1.57143', fontSize: '0.875rem'
                                }}>
                                  {row?.subCategoryDetails?.length > 0 ? (
                                    <span>
                                      {row?.subCategoryDetails[0]?.category_name}
                                    </span>
                                  ): null}
                                  {row?.subCategoryDetails?.length > 0 && (
                                    <IconButton onClick={(event) => handleClickPopover(event, rowIndex)}>
                                      <Iconify icon="material-symbols-light:keyboard-double-arrow-right" />
                                    </IconButton>
                                  )}
                                </Typography>
                                <Popover
                                  id={popoverState.open ? 'rate-popover' : undefined}
                                  open={popoverState.open}
                                  anchorEl={popoverState.anchorEl}
                                  onClose={() => handleClosePopover(rowIndex)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                >
                                  {row?.subCategoryDetails?.map((cat, index) => (
                                    <MenuItem key={index}>
                                      <Typography variant='body2'>{cat?.category_name}</Typography>
                                    </MenuItem>
                                  ))}
                                </Popover>
                              </Stack>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableNoData query={"Users"} colSpan={headLable?.length} />
              )}
          </TableBody>
          </Table>
          </TableContainer>
            <CreateNewCategoryDialogue
                open={openCreateNewCat}
                onClose={CloseOpenCreateNewCat}
                currType={currOp}
                disabled={currOp === 'view' ? true : false}
                currCategoryData={selectedCategory}
                createNewCategory={handleCreatnewCategory}
                isLoading={isLoading}
            />
    </Box>
  )
}

export default MyCatgeory