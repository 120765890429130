import { Box, Typography } from "@mui/material";

const AboutComponent = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>Horeca1 - Our Story</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant="body2"><span style={{ fontWeight: 'bold' }}>Horeca1 - </span>Built by Restaurateurs, Built for Restaurateurs!</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: 13 }}>Hospitality Industry has a lot to worry about, Sourcing & Procurement will no longer be one of them.</Typography>
            </Box>
            <Typography variant="body2" sx={{ fontStyle: 'italic', fontSize: 13 }}>In 2019, when Rakeysh & Saket announced they’re shutting down their hugely successful decade-old gastropub <span style={{ fontWeight: 'bold' }}>Rockville - Bar & Diner, to help the Food Services industry tide over a chronic and endemic problem - Procurement</span> - it was met with shocked reactions. That is because Rockville in the past decade had attained a cult-status amongst its patrons & in the local geography for its signature brand of Music, Food & classy ambience.</Typography>
            <Typography variant="body2">It was their decade-long stint as a restaurateur that gave them a first-hand feel of the
                <span style={{ fontWeight: 'bold', fontStyle: 'italic', marginLeft: 2 }}>huge gap in the Hospitality supply-chain.</span>
                <span style={{ fontStyle: 'italic' }}>It was simply stuck in the past.</span>
                <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>The traditional suppliers simply didn’t understand the growing needs of a rapidly evolving Hospitality Industry, especially the Food Services Industry, nor were they able to keep pace with the demand. There was a clear & immediate need for evolution of the Hospitality Supply Chain.</span></Typography>
            <Typography variant="body2">
                <span style={{ fontWeight: 'bold' }}>Horeca1 - </span>
                a one-stop
                <span style={{ fontWeight: 'bold' }}>“B2B Hospitality Procurement-Platform”,</span>
                delivering exceptional value to both Customers and Suppliers alike. Tailor-made for B2B procurement, the platform aims at providing end-to-end strategic sourcing services under 1-roof. </Typography>
            <Typography variant="body2">Say goodbye to the headaches of unreliable suppliers and inconsistent costs. Horeca1 is here to make purchasing smooth, simple, and efficient—so you can focus on what you do best: creating amazing experiences for your customers.</Typography>
            <Typography variant="body2">Join us and discover the future of hospitality procurement—where you can source everything you need, anytime, anywhere.</Typography>
        </Box>
    )
}

export default AboutComponent;