import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from "@mui/material";
import Iconify from "../../components/common/Iconify";
import CustomTextField from "../../components/common/CustomTextField";
import CustomButton from "../../components/common/CustomButton";
import CustomMuiTelInput from "../../components/common/CustomMuiTelInput";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";

const CreateMyVendorDialogue = ({ onClose, open, formik, onCreateVendor, isDisabled }) => {

  const {
    isDesktop,
    isTablet,
    isMobile,
    isRandom,
  } = useResponsiveStyles()

  const handleChange1 = (value, name) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>On-board private vendor</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Iconify icon="material-symbols:cancel-outline" />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'orgName'}
              label={'Shop Name'}
              formik={formik}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomMuiTelInput
              name={'phoneNumber'}
              label={'Phone Number'}
              formik={formik}
              fullWidth
            />
          </Grid>
        </Grid>
        <Typography sx={{ mt: '0.5rem', fontWeight: 600, mb: '0.5rem' }}>Contact Person</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'firstName'}
              label={'First Name'}
              formik={formik}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'lastName'}
              label={'Last Name'}
              formik={formik}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'email'}
              label={'Email'}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomMuiTelInput
              name={'contactPersonNumber'}
              label={'Mobile Number'}
              formik={formik}
              fullWidth
            />
          </Grid>
        </Grid>
        <Typography sx={{ mt: '0.5rem', fontWeight: 600, mb: '0.5rem' }}>Address</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              name={'addresses.line1'}
              label={'Address'}
              formik={formik}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'addresses.landmark'}
              label={'Landmark'}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'addresses.city'}
              label={'City'}
              formik={formik}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'addresses.state'}
              label={'State'}
              formik={formik}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'addresses.zipcode'}
              label={'Zipcode'}
              formik={formik}
              required={true}
            />
          </Grid>
        </Grid>
        <Stack direction={'row'} width='100%' justifyContent={'center'} alignItems={'center'} mt={'1rem'}>
          <CustomButton variant="contained" onClick={onCreateVendor} disabled={isDisabled} label={"Create"} width={isMobile ? "100%" : "50%"} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CreateMyVendorDialogue;