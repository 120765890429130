import { useEffect, useState } from "react";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Box, Button, Link } from "@mui/material";
import CustomButton from "../../../components/common/CustomButton";
import { openMessage } from "../../../redux/slices/showMessageSlice";
import { useDispatch } from "react-redux";
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";

const OTPPanel = ({ otpVerified, handleResendOtp, verifyingOtp }) => {
    const dispatch = useDispatch()

    // State variables to manage OTP input, minutes, and seconds
    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(59);
    const responsive = useResponsiveStyles();
    // useEffect to handle countdown logic
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (seconds > 0) {
    //             setSeconds(seconds - 1);
    //         }
    //         if (seconds === 0) {
    //             clearInterval(interval);
    //         }
    //     }, 1000);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [seconds]);

    useEffect(() => {
        // Function to handle the countdown logic
        const interval = setInterval(() => {
            // Decrease seconds if greater than 0
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            // When seconds reach 0, decrease minutes if greater than 0
            if (seconds === 0) {
                if (minutes === 0) {
                    // Stop the countdown when both minutes and seconds are 0
                    clearInterval(interval);
                } else {
                    // Reset seconds to 59 and decrease minutes by 1
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000); // Run this effect every 1000ms (1 second)

        return () => {
            // Cleanup: stop the interval when the component unmounts
            clearInterval(interval);
        };
    }, [seconds]);

    // handleChange function to update OTP value
    const handleChange = (newValue) => {
        if (/^\d*$/.test(newValue)) {
            // Update OTP value if it contains only digits
            setOtp(newValue);
        }
    }

    // const onResendClick = () => {
    //     setSeconds(30)
    //     handleResendOtp()
    // }

    const onResendClick = async () => {
        handleResendOtp()
        setMinutes(1);
        setSeconds(59);
    };

    return (
        <Box sx={{
            display: 'flex',
            // width:'30%',
            flexDirection: 'column',
            alignItems: 'center',
            justifycontent: 'center'
        }}>
            <Box sx={{ padding: 1, borderRadius: 10 }}>
                <MuiOtpInput 
                    sx={{ gap: responsive?.isMobile ? 1.5 : 2.5,
                        '& .MuiInputBase-input': {
                            px: 1
                        }
                     }}
                    value={otp} onChange={handleChange} length={6} autoFocus={true} onKeyUp={(e) => e.key === "Enter" && (!otp || otp.length < 6 ? dispatch(openMessage({ message: 'Please enter 6 digit otp', messageSeverity: 'error' })) : otpVerified(otp))} />
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '8px 0px'
                }}>
                    {seconds > 0 || minutes > 0 ? (
                        <p>
                            Time Remaining:{" "}
                            <span style={{ fontWeight: 600 }}>
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                            </span>
                        </p>
                    ) : (
                        <p>Didn't receive code?</p>
                    )}
                    <Link
                        component={seconds > 0 && Button}
                        disabled={seconds > 0 || minutes > 0}
                        sx={{
                            cursor: "pointer",
                        }}
                        underline="hover"
                        onClick={onResendClick}
                    >
                        Resend OTP
                    </Link>
                </Box>
                <CustomButton
                    onClick={() => otpVerified(otp)}
                    variant="contained"
                    label={verifyingOtp ? "Verifying OTP..." : "Verify OTP"}
                    endIcon={verifyingOtp && <></>}
                    disabled={!otp || otp.length < 6}
                    width={"100%"}
                    loading={verifyingOtp}
                />
            </Box>
        </Box>
    );
}

export default OTPPanel;