import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material";
import CustomButton from "../../../components/common/CustomButton";

const BecomeAnNewOrgDialogue = ({ handelCloseDialogue, open, handleAddNewOrg, orgName, handleChangeOrgName, type }) => {


  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      onClose={handelCloseDialogue}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            // padding:'1rem'
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          Becoming an {type}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} mt={'2rem'}>
          <Grid item xs={12}>
            <TextField
              name="orgName"
              fullWidth
              size="medium"
              value={orgName}
              label='Org Name'
              onChange={(e) => handleChangeOrgName(e)}
            />
            <Stack direction={'row'} justifyContent={'center'} mt={'2rem'}>
              <CustomButton variant="contained" onClick={() => handleAddNewOrg()} label={"Submit"} />
            </Stack>
          </Grid>

        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default BecomeAnNewOrgDialogue;
