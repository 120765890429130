import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Container, Grid, Stack, Typography, useTheme } from '@mui/material';
import CustomBreadCrumb from '../../../components/common/CustomBreadCrumb';
import OrganizationProfile from '../sections/organization/OrganizationProfile';
import UserProfile from '../sections/user-roles/UserProfile';
import { determineOrgType, getOrgType } from '../../../utils/org';
import BecomeAnNewOrgDialogue from './BecomeAnNewOrgDialogue';
import { useAddNewOrgTypeMutation, useGetRolesQuery } from '../../../redux/api/api';
import { openMessage } from '../../../redux/slices/showMessageSlice';
import { motion } from 'framer-motion';
import { useState } from 'react';
import WeSupportInventory from '../sections/user-roles/WeSupportInventory';
import CustomButton from '../../../components/common/CustomButton';
import { capitalizeFirstLetter } from '../../../utils/stringFormating';
import PageHeader from '../../../components/common/PageHeader';
import useResponsiveStyles from '../../../hooks/useResponsiveStyles';


const AnimatedText = ({ children, size }) => {
  const theme = useTheme()

  const {
    isDesktop,
    isTablet,
    isMobile,
    isRandom,
  } = useResponsiveStyles()

  return (
    <motion.span
      style={{
        fontSize: isMobile ? "18px" : "25px",
        textShadow: theme.palette.mode === "light" ? '10px 10px #F0F0F0' : `10px 10px ${theme.palette.text.disabled}`,
      }}
      animate={{
        color: ['#A0D468', '#4FC1E9', '#FFCE54', '#FC6E51', '#ED5565', '#AC92EC'],
      }}
      transition={{
        duration: 4,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'linear',
      }}
    >
      {children}
    </motion.span>
  );
};


const CustomSettings = () => {

  const dispatch = useDispatch();
  const { orgId } = useParams();
  const [addNewOrgType] = useAddNewOrgTypeMutation();
  const { data: rolesData } = useGetRolesQuery();

  const user = useSelector((state) => state.auth.user)
  const [roleId, setRoleId] = useState(null);
  const [value, setValue] = React.useState(0);
  const [onboard, setOnboard] = React.useState(false);
  const [orgName, setOrgName] = useState(user?.orgDetail[0]?.orgName);

  const type = determineOrgType(user?.orgDetail)

  const orgType = getOrgType(user, orgId);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeOrg = (role) => {
    let r = role;
    let id;
    if (rolesData && rolesData.length > 0) {
      id = (rolesData.find(role => role.roleName == r) || {}).id
    }
    // handelSetRole(id, r);
    setRoleId(id);
    setOnboard(true)
  }


  const handleClose = () => {
    setOnboard(false)
  }

  const handleChangeOrgName = (e) => {
    setOrgName(e.target.value);
  }



  const handleAddNewOrg = async () => {
    const result = await addNewOrgType({
      userId: user?.userId, type, payload: {
        orgName: orgName,
        mobileNumber: user?.mobileNumber,
        type: type,
        role: roleId,
      }
    });
    if (result?.data) {
      setOnboard(false)
      dispatch(openMessage({
        message: 'Successfully on-boarded!',
        messageSeverity: "success"
      }));
    } else if (result?.error) {
      dispatch(openMessage({
        message: result?.error?.data?.message,
        messageSeverity: "error"
      }));
    } else {
      dispatch(openMessage({
        message: 'Not able to add',
        messageSeverity: "error"
      }));
    }
  }


  return (
    <Container maxWidth={'xl'}>
      <Box sx={{ width: '100%' }}>
        {/* <Grid container height={"100px"} >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Stack
              direction={"column"}
              justifyContent={"flex-start"}
              // alignItems={"center"}
              height={"100%"}
            >
              <Typography variant="h4">Settings</Typography>
              <CustomBreadCrumb
                linkArray={[
                  { title: "Dashboard", path: `/` },
                  { title: "Settings", },
                ]}
              />
            </Stack>
            <Stack
              direction={"column"}
              justifyContent={"flex-start"}
              // alignItems={"center"}
              height={"100%"}
            >
              {(type == "vendor" || type == "customer") &&
                <CustomButton
                  variant='text' onClick={() => handleChangeOrg(type)}
                  label={<AnimatedText>Want to be {type && capitalizeFirstLetter(type)}?</AnimatedText>}
                />
              }
            </Stack>
          </Stack>
        </Grid> */}
        <PageHeader
          title={"Settings"}
          // linkArray={[
          //   { title: "Dashboard", path: `/` },
          //   { title: "Settings", }
          // ]}
          label1={(type == "vendor" || type == "customer") && <AnimatedText>Want to be {type && capitalizeFirstLetter(type)}?</AnimatedText>}
          variant1='text' onClick1={() => handleChangeOrg(type)}
        />
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="primary "
          aria-label="secondary tabs example"
        >
          <Tab value={0} label="General" />
          <Tab value={1} label="Organization" />
          {orgType === 'vendor' && <Tab value={2} label="We Support" />}
        </Tabs>
        {value === 0 && <UserProfile />}
        {value === 1 && <OrganizationProfile />}
        {value === 2 && <WeSupportInventory />}
        {/* we support -> zoho and all for configuration */}
      </Box>
      <BecomeAnNewOrgDialogue
        open={onboard}
        handelCloseDialogue={handleClose}
        handleAddNewOrg={handleAddNewOrg}
        handleChangeOrgName={handleChangeOrgName}
        orgName={orgName}
        type={type}
      />
    </Container>
  );
}

export default CustomSettings;