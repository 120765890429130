import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import ItemsLoading from './ItemsLoading'

function TableLoadingData({ colSpan }) {
    return (
        <TableRow ><TableCell colSpan={colSpan ?? 6} sx={{ py: 6 }}><ItemsLoading /></TableCell></TableRow>
    )
}

export default TableLoadingData