import { useFormik } from "formik";
import CustomButton from "../../../../components/common/CustomButton";
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { openMessage } from "../../../../redux/slices/showMessageSlice";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from "../../../../components/common/CustomTextField";
import CustomMuiTelInput from "../../../../components/common/CustomMuiTelInput";
import Iconify from "../../../../components/common/Iconify";
import { useRemovedAssignUserRoleMutation, useUpdateUserProfileMutation } from "../../../../redux/api/api";

export default function UserDialogue({ open, handelCloseDialogue, data, disabled, resetData, setEnable }) {
    const dispatch = useDispatch();
    const [updateUserProfile, { isLoading: updateUserProfileLoading }] = useUpdateUserProfileMutation();

    const [removedAssignUserRole, { isLoading: removedAssignUserRoleLoading }] = useRemovedAssignUserRoleMutation();

    const deleteMember = async (userRoleOrgId, userId, orgId, roleId) => {
        const result = await removedAssignUserRole({
            orgId: orgId,
            userRoleOrgId: userRoleOrgId,
            roleId: roleId,
            userId: userId,
        });
        if (result.data) {
            await resetData()
            dispatch(
                openMessage({
                    message: result?.data?.message,
                    messageSeverity: "success",
                })
            );
        } else if (result.error) {
            dispatch(
                openMessage({
                    message: result?.error?.data?.message,
                    messageSeverity: "error",
                })
            );
        } else {
            dispatch(
                openMessage({
                    message: "There is some issue while deleting an user!",
                    messageSeverity: "error",
                })
            );
        }
    };

    const initialValues = {
        firstName: data?.firstName ?? '',
        lastName: data?.lastName ?? '',
        mobileNumber: data?.mobileNumber ?? ''
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .required('First name is required'),
            lastName: Yup.string()
                .required('Last name is required')
        }),
        validateOnBlur: true,
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const result = await updateUserProfile({ userId: data?.userId, payload: values });
            if (result.data) {
                await resetData()
                formik.resetForm();
                handelCloseDialogue();
                dispatch(openMessage({ message: "User details changed Successfully!", messageSeverity: 'success' }))
            } else if (result.error) {
                dispatch(openMessage({ message: result.error.data.message, messageSeverity: 'error' }))
            } else {
                dispatch(openMessage({ message: 'Not Able to Add change User details', messageSeverity: 'error' }))
            }
        },
    })

    const handleClose = () => {
        handelCloseDialogue();
        formik.resetForm();
    }

    return (
        <Dialog
            maxWidth={"sm"}
            scroll={"paper"}
            open={open}
            // onClose={handleClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                    },
                },
            }}
        >
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle>{disabled ? "User Detail" : "Edit User"}</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: '1rem' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <CustomTextField
                                name="firstName"
                                label="First Name"
                                formik={formik}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <CustomTextField
                                name="lastName"
                                label="Last Name"
                                formik={formik}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CustomMuiTelInput
                                name={'mobileNumber'}
                                label={'Mobile Number'}
                                formik={formik}
                                // props={{ disabled: true }}
                            />
                        </Grid>
                    </Grid>
                    {(data?.orgRoleMapping)?.length > 0 &&
                        <>
                            <Grid item xs={12} md={12} mt={2} mb={2}>
                                <Typography variant="subtitle1" noWrap>Roles</Typography>
                            </Grid>
                            <Grid container sx={{ border: "1px solid rgba(145, 158, 171, 0.2)", borderRadius: "8px" }} >
                                <Grid item xs={12} md={12} sx={{ borderBottom: "1px dashed rgba(145, 158, 171, 0.2)" }}>
                                    <Grid container  >
                                        <Grid item xs={6} md={7}>
                                            <Typography variant="subtitle2" noWrap padding={"8px"}>Organization</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <Typography variant="subtitle2" noWrap padding={"8px"}>Role</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={1} />
                                    </Grid>
                                </Grid>
                                {(data?.orgRoleMapping)?.map((org, index) => (
                                    <Grid key={index} item xs={12} md={12} sx={{ borderBottom: (data?.orgRoleMapping)?.length - 1 != index ? "1px dashed rgba(145, 158, 171, 0.2)" : "none" }}>
                                        <Grid container >
                                            <Grid item xs={6} md={7}>
                                                <Typography variant="body2" noWrap padding={"8px"}>{org.orgName}</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={4}>
                                                <Typography variant="body2" noWrap padding={"8px"}>{org.roleName}</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={1}>
                                                <Box sx={{ justifyContent: "flex-end", display: "flex" }} width={"100%"}>
                                                    {!disabled && <IconButton onClick={() => deleteMember(org?.id, data?.userId, org?.orgId, org?.id)}>
                                                        <Iconify icon="bi:trash" sx={{ color: 'red' }} />
                                                    </IconButton>}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </>}
                    <CustomButton
                        sx={{ mt: '1rem' }}
                        onClick={disabled ? setEnable : formik.handleSubmit}
                        label={disabled ? "Edit" : updateUserProfileLoading || removedAssignUserRoleLoading ? "Saving..." : "Save"}
                        width={"100%"}
                        loading={updateUserProfileLoading || removedAssignUserRoleLoading}
                        endIcon={disabled ? <Iconify width={14} icon="fluent:edit-28-filled" /> : <></>}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    )
}
