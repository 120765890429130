import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, useTheme } from "@mui/material";
import Nav from "./navigation/Navigation";
import Main from "./Main";
import Header from "./Header";
import ShowMessage from "../components/common/ShowMessage";
import { horecaApi } from "../redux/api/api";
import { useSelector } from "react-redux";
import OrganizationProfileDialogue from "../pages/settings/sections/organization/OrganzationProfileDialogue";
import { getOrgData } from "../utils/org";
import { SidebarConfig1 } from "./navigation/configNavigation";
import IsPageLoading from "../components/common/IsPageLoading";
import { WebSocketProvider } from "../hooks/WebsocketProvider";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const DashboardLayout = ({ children }) => {
  const [openNav, setOpenNav] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const [getMe, { isLoading }] = horecaApi.endpoints.getMe.useLazyQuery();
  const user = useSelector((state) => state.auth.user);

  // -- org id --
  // const {orgId} = useParams();
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);

  useEffect(() => {
    (async () => {
      if (user) {
        await getMe(user?.userId); //org id
      }
    })();
  }, []);

  const nva = SidebarConfig1();

  //get sstatus of that organization

  const orgData = React.useMemo(() => getOrgData(user, orgId));

  useEffect(() => {
    if (orgData?.status === "pending" || orgData?.status === "rejected" || orgData?.status === "drafted") {
      setIsDisabled(true);
    } else if (orgData?.status === "approved") {
      setIsDisabled(false);
    } else {
      setIsDisabled(false);
    }
    const redirectTo = sessionStorage.getItem('redirectAfterLogin');
    if(redirectTo) {
      sessionStorage.removeItem('redirectAfterLogin');
      navigate(redirectTo);
    }
  }, [orgData?.status, orgId]);

  const handelCloseDialogue = () => {
    setIsDisabled(false);
  };

  const theme = useTheme();

  // const [filteredNavConfig, setFilteredNavConfig] = useState([]);

  // useEffect(() => {
  //   // Check if primaryOrgId exists
  //   if (orgData?.primaryOrgId != null) {
  //     // Filter out the "outlets" item if primaryOrgId exists
  //     const filteredConfig = nva.filter((item) => item.id !== "outlets");
  //     setFilteredNavConfig(filteredConfig);
  //   } else {
  //     // No need to filter if primaryOrgId is null or undefined
  //     setFilteredNavConfig(nva);
  //   }
  // }, [orgId, nva]);

  return (
    <WebSocketProvider>
      <>
        {!isLoading ? (
          <>
            <Header onOpenNav={() => setOpenNav(true)} />
            <Box
              sx={{
                minHeight: 1,
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                backgroundColor: theme.palette.mode === "dark" && "rgb(22, 28, 36)",
              }}
            >
              <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} navConfig={nva} />
              <Main>{children}</Main>
            </Box>
          </>
        ) : (
          <IsPageLoading />
        )}
        <ShowMessage />
        <OrganizationProfileDialogue open={isDisabled} handelCloseDialogue={handelCloseDialogue} />
      </>
    </WebSocketProvider>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
