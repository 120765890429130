import { Helmet } from "react-helmet-async";
import {
  Container,
  Paper,
  Box,
  Stack,
  Typography,
  useTheme,
  Card,
  TextField,
  Grid,
} from "@mui/material";
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom'
import ProfilePic from "../../../../components/common/ProfilePic";
import CustomTextField from "../../../../components/common/CustomTextField";
import { useUpdateUserProfileMutation } from "../../../../redux/api/api";
import { openMessage } from "../../../../redux/slices/showMessageSlice";
import CustomButton from "../../../../components/common/CustomButton";

// ----------------------------------------------------------------------

const UserProfile = () => {

  const theme = useTheme();
  // const {orgId} = useParams();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();

  const initialValues = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    mobileNumber: user?.mobileNumber,
    address: {
      line1: user?.address?.line1 ?? "",
      landmark: user?.address?.landmark ?? "",
      city: user?.address?.city ?? "",
      state: user?.address?.state ?? "",
      pincode: user?.address?.pincode ?? "",
    }
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const result = await updateUserProfile({ userId: user?.userId, payload: values });
      if (result?.data) {
        dispatch(openMessage({ message: 'User profile updated successfully', messageSeverity: 'success' }));
      } else if (result?.error) {
        dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
      } else {
        dispatch(openMessage({ message: 'Not Able to update porfile data', messageSeverity: 'error' }));
      }
    }
  })

  return (
    <>

      <Paper
        sx={{
          height: "290px",
          marginBottom: "24px",
          borderRadius: "16px",
          position: "relative",
          overflow: "hidden",
          boxShadow: theme.shadows[1],
          mt: '2rem'
        }}
      >
        <Box
          sx={{
            height: "80%",
            backgroundPosition: "center center",
            background:
              "linear-gradient(rgba(32, 10, 105, 0.8), rgba(32, 10, 105, 0.8)) center center / cover no-repeat, url(https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_4.jpg);",
            color: "rgb(255, 255, 255);",
          }}
        >
          <Stack
            left={"24px"}
            bottom={"24px"}
            zIndex={10}
            position={"absolute"}
            paddingTop={"0px"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={'center'}
            justifyItems={'flex-start'}
          >
            <ProfilePic s3key={user?.profilePicKey} orgId={orgId} userId={user?.userId} name={`${user.firstName} ${user.lastName}`} />
            <Stack sx={{ ml: "24px", textAlign: "unset" }} height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'} flex={1} gap={'1rem'} mt='1rem'>
              <Typography sx={{ fontSize: "2rem", fontWeight: 700, lineHeight: '1.5rem' }}>
                {user.firstName}{" "}{user.lastName}
              </Typography>
              <Typography
                sx={{ margin: "4px 0 0", display: "block", lineHeight: '1.5rem', fontSize: '1rem', fontWeight: 700, opacity: 0.48, color: 'black', }}
              >
                {user?.orgDetail[0]?.roleDetail[0]?.roleName}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Paper>
      <Card sx={{ bgcolor: 'background.paper', padding: '1.5rem' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'firstName'}
              label='First Name'
              formik={formik}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'lastName'}
              label='Last Name'
              formik={formik}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'email'}
              label='Email'
              formik={formik}

            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'mobileNumber'}
              label='Mobiole Number'
              formik={formik}
              disabled={true}
              required={true}
            />
          </Grid>
        </Grid>
        <Typography variant="h5" mt={'1rem'} mb={'0.5rem'}>Address</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'address.line1'}
              label='Address'
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'address.landmark'}
              label='Landmark'
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'address.city'}
              label='City'
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'address.state'}
              label='State'
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={'address.pincode'}
              label='Pincode'
              formik={formik}
            />
          </Grid>
        </Grid>
        <Stack direction={'row'} sx={{ justifyContent: 'center', mt: '2rem' }}>
          <CustomButton width="320px" onClick={formik.handleSubmit} disabled={isLoading} label={"Update Details"} />
        </Stack>
      </Card>
      <Outlet />
    </>
  );
}

export default UserProfile;
