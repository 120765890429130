import Vendors from '../pages/system-admin/vendor/Vendors';
import Customer from '../pages/system-admin/customer/Customer';
import AddNewOrg from '../pages/settings/sections/organization/AddNewOrg';
import AddNewCustomer from '../pages/system-admin/customer/AddNewCustomer';
import Inventory from '../pages/inventory';
import SalesOrder from '../pages/sales-order/SalesOrder';
import MyOutlets from '../pages/settings/sections/organization/outlets/MyOutlets';
import MyTeams from '../pages/settings/sections/user-roles/my-team/MyTeams';
import ItemDetail from '../pages/inventory/details';
import OrderDetail from '../pages/order/order-details/OrderDetail';
import OrderDetails from '../pages/sales-order/OrderDetails';
import MyCustomer from '../pages/my-customers/MyCustomer';
import MyCustomerDetailsPage from '../pages/my-customers/MyCustomerDetailsPage';
import ExploreAndSelectVendor from '../pages/order-list/ExploreAndSelectVendor';
import OrderList from '../pages/order/order-list/OrderList';
import ExploreItems from '../pages/order-list/ExploreItems';
import Invoices from '../pages/invoices/Invoices';
import InvoiceDetail from '../pages/invoices/InvoiceDetail';
import PlaceOrder from '../pages/order/place-order/PlaceOrder';
import EditOrderList from '../pages/order-list/EditOrderList';
import PrivateVendors from '../pages/private-vendors/PrivateVendors';
import ExplorePorducts from '../pages/instant-order/ExplorePorducts';

import InstantOrderCart from '../pages/instant-order/InstantOrderCart';

import CreateOrderForPrivateVendor from '../pages/private-vendors/CreateOrderForPrivateVendor';
import PrivateVendorOrderDetail from '../pages/private-vendors/PrivateVendorOrderDetail';
import OrderHistory from '../pages/order/order-history/OrderHistory';
import Category from '../pages/system-admin/category/Category';
import CustomerDashboard2 from '../pages/dashboard/sections/CustomerDashboard2';


const systemAdminRoutes = [
  { path: '/org/:orgId/vendors', element: <Vendors /> },
  { path: '/org/:orgId/customers', element: <Customer /> },
  { path: '/org/:orgId/vendors/add-new', element: <AddNewOrg /> },
  { path: '/org/:orgId/customers/add-new', element: <AddNewCustomer /> },
  { path: '/org/:orgId/category', element: <Category /> },
];

const vendorRoutes = [
  { path: '/org/:orgId/inventory-items', element: <Inventory /> },
  { path: '/org/:orgId/sales-order', element: <SalesOrder /> },
  { path: '/org/:orgId/my-outlets', element: <MyOutlets /> },
  { path: '/org/:orgId/my-team', element: <MyTeams /> },
  { path: '/org/:orgId/dashboard', element: <CustomerDashboard2 /> },
  { path: '/org/:orgId/inventory-items/:itemId/details', element: <ItemDetail /> },
  { path: '/org/:orgId/order-history/:orderId', element: <OrderDetail /> },
  { path: '/org/:orgId/order-details', element: <OrderDetails /> },
  { path: '/org/:orgId/my-customers', element: <MyCustomer /> },
  { path: '/org/:orgId/my-customers/:selectedId', element: <MyCustomerDetailsPage /> },
];

const customerRoutes = [
  { path: '/org/:orgId/explore', element: <ExploreAndSelectVendor /> },
  { path: '/org/:orgId/orders', element: <OrderList /> },
  { path: '/org/:orgId/order-list/create?', element: <ExploreItems /> },
  { path: '/org/:orgId/my-outlets', element: <MyOutlets /> },
  { path: '/org/:orgId/my-team', element: <MyTeams /> },
  { path: '/org/:orgId/dashboard', element: <CustomerDashboard2/> },
  { path: '/org/:orgId/invoice', element: <Invoices /> },
  { path: '/org/:orgId/invoice/:invoiceId', element: <InvoiceDetail /> },
  { path: '/org/:orgId/orders/:vendorId/order-list/:orderListId?', element: <PlaceOrder /> },
  { path: '/org/:orgId/orders/:vendorId/order-list/:orderListId/edit', element: <EditOrderList /> },
  { path: '/org/:orgId/my-vendors', element: <PrivateVendors /> },
  { path: '/org/:orgId/my-vendors/:privateVendorId', element: <CreateOrderForPrivateVendor /> },
  { path: '/org/:orgId/order-history/:orderId/private', element: <PrivateVendorOrderDetail /> },
  { element: <ExplorePorducts />, path: '/org/:orgId/explore-products'},
  { element: <InstantOrderCart />, path: '/org/:orgId/cart'},
  { path: '/org/:orgId/order-history', element: <OrderHistory /> },
  { path: '/org/:orgId/order-history/:orderId', element: <OrderDetail /> },
  { path: '/org/:orgId/myVendors/:selectedId', element: <MyCustomerDetailsPage /> },


];

const getRoleSpecificRoutes = (orgType) => {
  switch (orgType) {
    case 'system_admin':
      return systemAdminRoutes;
    case 'vendor':
      return vendorRoutes;
    case 'customer':
      return customerRoutes;
    default:
      return [];
  }
};

export default getRoleSpecificRoutes;
