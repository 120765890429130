import { createSlice } from "@reduxjs/toolkit";
import { horecaApi } from "../api/api";

// order history status filter type
export const orderStatusFilterButtons = {
  all: {
    id: "ALL",
    name: "All",
    value: 0,
    count: 0,
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(33, 43, 54)"
  },
  // drafted: {
  //   id: "DRAFTED",
  //   name: "Drafted",
  //   value: 1,
  //   count: 0,
  //   color: "rgb(99, 115, 129)",
  //   backgroundColor: "rgba(145, 158, 171, 0.16)"
  // },
  payment_pending: {
    id: "PAYMENT_PENDING",
    name: "Payment Pending",
    value: 1,
    count: 0,
    color: "rgb(183, 110, 0)",
    backgroundColor: "rgba(255, 171, 0, 0.16)"
  },
  payment_failed: {
    id: "PAYMENT_FAILED",
    name: "Payment Failed",
    value: 2,
    count: 0,
    color: "rgb(183, 110, 0)",
    backgroundColor: "rgba(255, 171, 0, 0.16)"
  },
  // scheduled: {
  //   id: "SCHEDULED",
  //   name: "Scheduled",
  //   value: 3,
  //   count: 0,
  //   color: "rgb(183, 110, 0)",
  //   backgroundColor: "rgba(255, 171, 0, 0.16)"
  // },
  confirmed: {
    id: "CONFIRMED",
    name: "Confirmed",
    value: 4,
    count: 0,
    color: "rgb(183, 110, 0)",
    backgroundColor: "rgba(255, 171, 0, 0.16)"
  },
  packed: {
    id: "PACKED",
    name: "Packed",
    value: 5,
    count: 0,
    color: "rgb(183, 110, 0)",
    backgroundColor: "rgba(255, 171, 0, 0.16)"
  },
  shipped: {
    id: "SHIPPED",
    name: "Shipped",
    value: 6,
    count: 0,
    color: "rgb(183, 110, 0)",
    backgroundColor: "rgba(255, 171, 0, 0.16)"
  },
  
  delivered: {
    id: "DELIVERED",
    name: "Delivered",
    value: 7,
    count: 0,
    color: "rgb(183, 110, 0)",
    backgroundColor: "rgba(255, 171, 0, 0.16)"
  },
  fulfilled: {
    id: "FULFILLED",
    name: "Fulfilled",
    value: 8,
    count: 0,
    color: "rgb(17, 141, 87)",
    backgroundColor: "rgba(34, 197, 94, 0.16)"
  },
  void: {
    id: "VOID",
    name: "Void",
    value: 9,
    count: 0,
    color: "rgb(183, 29, 24)",
    backgroundColor: "rgba(255, 86, 48, 0.16)"
  },
  privatevendor: {
    id: "PRIVATE",
    name: "Private",
    value: 10,
    count: 0,
    color: "rgb(17, 141, 87)",
    backgroundColor: "rgba(34, 197, 94, 0.16)"
  }
}

// initial state
const initialState = {
  orderList: {
    orderList: {},
    orderListSearch: "",
    orderListFilter: {
      orderListSummaryFilter: {
        totalOrderLists: true,
        draftedOrderLists: false,
        scheduledOrderLists: false
      }
    }
  },
  orderHistory: {
    orderHistoryList: [],
    orderHistorySearch: "",
    orderHistoryFilter: {
      dateFilter: {
        startDate: null,
        endDate: null,
      },
      orderStatusFilterButtons,
      orderStatusFilter: orderStatusFilterButtons.all.value,
    }
  },
  newOrder: {
    customerId: '',
    vendorId: '',
    orderListId: '',
    items: [],
  },
  invoices: {
    invoicesFilter: {
      invoiceSearch: "",
      dateFilter: {
        startDate: null,
        endDate: null,
      }
    }
  },
  updateOrder: {
    items: [],
  },
  privateVendor: {
    orderListId: '',
    items: []
  }
};

// ==============================|| SLICE - MENU ||============================== //

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderListSearch(state, action) {
      state.orderList.orderListSearch = action.payload
    },
    setOrderListSummaryFilter(state, action) {
      if (state.orderList.orderListFilter.orderListSummaryFilter.hasOwnProperty(action.payload)) {
        // Reset all filters to false except the one specified
        for (const filter in state.orderList.orderListFilter.orderListSummaryFilter) {
          if (filter !== action.payload) {
            state.orderList.orderListFilter.orderListSummaryFilter[filter] = false;
          } else {
            state.orderList.orderListFilter.orderListSummaryFilter[filter] = true;
          }
        }
      }
    },
    setOrderHistorySearch(state, action) {
      state.orderHistory.orderHistorySearch = action.payload
    },
    setOrderHistoryStartDateFilter(state, action) {
      state.orderHistory.orderHistoryFilter.dateFilter.startDate = action.payload
    },
    setOrderHistoryEndDateFilter(state, action) {
      state.orderHistory.orderHistoryFilter.dateFilter.endDate = action.payload
    },
    setOrderHistoryStatusFilter(state, action) {
      state.orderHistory.orderHistoryFilter.orderStatusFilter = action.payload
    },
    addAdnUpateItemToOrder(state, action) {
      state.newOrder.items = action.payload;
    },
    setInvoiceListSearch(state, action) {
      state.invoices.invoicesFilter.invoiceSearch = action.payload;
    },
    setInvoiceListStartDateFilter(state, action) {
      state.invoices.invoicesFilter.dateFilter.startDate = action.payload;
    },
    setInvoiceListEndDateFilter(state, action) {
      state.invoices.invoicesFilter.dateFilter.endDate = action.payload;
    },
    updateAnItemToOrder(state, action) {
      state.updateOrder.items = action.payload;
    },
    addMoreItemsToPrivateVendor(state, action) {
      const { index, value, name } = action.payload;
      state.privateVendor.items[index][name] = value;
    },
    addNewItemToPrivateVendor(state, action) {
      state.privateVendor.items = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      horecaApi.endpoints.getOrderHistory.matchFulfilled,
      (state, { payload }) => {
        state.orderHistory.orderHistoryList = payload.orderHistory;
        // state.orderHistory.orderHistoryFilter.orderStatusFilterButtons.all.count = payload.totalCount
        Object.keys(payload.orderCounts).forEach((key) => {
          const value = payload.orderCounts[key];

          // Find the corresponding button object by id
          const buttonId = key.toUpperCase();
          const button = Object.values(state.orderHistory.orderHistoryFilter.orderStatusFilterButtons).find(btn => btn.id === buttonId);

          // If a matching button is found, update its count
          if (button) {
            button.count = value;
          }
        });
      }
    );
    builder.addMatcher(
      horecaApi.endpoints.getPrivateVendorOrderListDetails.matchFulfilled,
      (state, {payload}) => {
        state.privateVendor.items = (payload?.orderListDetails?.items && payload?.orderListDetails?.items?.length > 0) ? payload.orderListDetails?.items?.map(item => ({
          name: item.name || '',
          quantity: 0,
          preQuantity: item.quantity || 0,
          unit: item.unit || '',
          itemId: item.id || '',
      })) : [{
          name: '',
          quantity: '',
          unit: '',
          prevQuantity: '',
      }];
      }
    );
    builder.addMatcher(
      horecaApi.endpoints.privateVendorOrderHistory.matchFulfilled,
      (state, {payload}) => {
        state.orderHistory.orderHistoryFilter.orderStatusFilterButtons.privatevendor.count = payload?.orderCounts?.PrivateVendor;
      }
    );
  }
});

export default orderSlice.reducer;

export const {
  setOrderListSearch,
  setOrderListSummaryFilter,
  setOrderHistorySearch,
  setOrderHistoryStartDateFilter,
  setOrderHistoryEndDateFilter,
  setOrderHistoryStatusFilter,
  addAdnUpateItemToOrder,
  setInvoiceListSearch,
  setInvoiceListStartDateFilter,
  setInvoiceListEndDateFilter,
  updateAnItemToOrder,
  addMoreItemsToPrivateVendor,
  addNewItemToPrivateVendor
} = orderSlice.actions;
