import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import CustomTextField from '../../../../components/common/CustomTextField'

const BusinessDetail = ({ formik, isFssi = true }) => {
  return (
    <Grid pt={'1rem'}>
      <Typography variant='h5'> Business Details </Typography>
      <Grid container spacing={3} mt={'0rem'}>

        <Grid item xs={12} md={6}>
          <CustomTextField
            name={"businessDetail.panNumber"}
            label={"Pan number"}
            formik={formik}
            required={!formik.values.businessDetail.gstUin}
          // size={'small'}
          />
        </Grid>
        {
          isFssi && <Grid item xs={12} md={6}>
            <CustomTextField
              name={"businessDetail.fssi"}
              label={"FSSAI"}
              formik={formik}
            />
          </Grid>
        }
        <Grid item xs={6} md={6}>
          <CustomTextField
            name={"businessDetail.gstUin"}
            // placeholder={"Enter Your gstUin number"}
            label={"GST/UIN"}
            formik={formik}
            required={!formik.values.businessDetail.panNumber}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BusinessDetail