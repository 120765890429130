import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useResponsiveStyles from '../../hooks/useResponsiveStyles';

const ReviewOrderListDialogue = ({ content, open, onClose }) => {
  const isMobile = useResponsiveStyles();
  return (
    <Dialog 
      maxWidth={"lg"}
      scroll={"paper"}
      open={open}
      onClose={onClose} // Call onClose function when dialog is closed
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            padding:isMobile && '0rem'
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>Review</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose} // Call onClose function when close icon is clicked
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: 'inherit',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: isMobile && '1rem', padding: isMobile && 0, pb: isMobile && '1rem'}}>
        {content}
      </DialogContent>
    </Dialog>
  )
}

export default ReviewOrderListDialogue;
