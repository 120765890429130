import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { openMessage } from "../../../../../redux/slices/showMessageSlice";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { horecaApi, useAssingUserToOrgMutation, useGetRolesByOrgIdQuery, useRemovedAssignUserRoleMutation } from "../../../../../redux/api/api";
import CustomButton from "../../../../../components/common/CustomButton";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Iconify from "../../../../../components/common/Iconify";
import useResponsiveStyles from "../../../../../hooks/useResponsiveStyles";

const AssignUserDialogue = ({
  open,
  handleClose,
  orgId,
  orgUsersList,
  selectedOrgId,
}) => {

  // const { data: selectedUsersList1 } = useGetAssingnedUsersQuery(selectedOrgId);
  const [getAssingnedUsers, { data: selectedUsersList1 }] = horecaApi.endpoints.getAssingnedUsers.useLazyQuery();
  const { data: rolesData } = useGetRolesByOrgIdQuery(orgId);
  const { isMobile } = useResponsiveStyles();
  const [removedAssignUserRole] = useRemovedAssignUserRoleMutation();

  const [assingUserToOrg, { isLoading: assingUserToOrgLoading }] = useAssingUserToOrgMutation();



  const dispatch = useDispatch();

  const handelCloseDialogue = () => {
    handleClose();
  };

  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");

  useEffect(() => {
    (
      async () => {
        open && await getAssingnedUsers(selectedOrgId);
      }
    )()
  }, [open, selectedOrgId])

  const handleAddMember = async () => {
    const result = await assingUserToOrg({
      orgId: selectedOrgId,
      body: {
        orgId: selectedOrgId,
        roleId: selectedRoleId,
        userId: selectedUserId,
      },
    });

    if (result.data) {
      await getAssingnedUsers(selectedOrgId);
      dispatch(
        openMessage({
          message: "user added successfully",
          messageSeverity: "success",
        })
      );
    } else if (result.error) {
      dispatch(
        openMessage({
          message: result.error.data.message,
          messageSeverity: "error",
        })
      );
    } else {
      dispatch(
        openMessage({
          message: "There is some issue while adding an user!",
          messageSeverity: "error",
        })
      );
    }
    //fetch data gain
    await getAssingnedUsers(selectedOrgId);
    setSelectedUserId("");
    setSelectedRoleId("");
  };

  const deleteMember = async (userRoleOrgId, userId, orgId, roleId) => {
    const result = await removedAssignUserRole({
      orgId: orgId,
      userRoleOrgId: userRoleOrgId,
      roleId: roleId,
      userId: userId,
    });
    if (result.data) {
      await getAssingnedUsers(selectedOrgId);
      dispatch(
        openMessage({
          message: result?.data?.message,
          messageSeverity: "success",
        })
      );
    } else if (result.error) {
      dispatch(
        openMessage({
          message: result?.error?.data?.message,
          messageSeverity: "error",
        })
      );
    } else {
      dispatch(
        openMessage({
          message: "There is some issue while deleting an user!",
          messageSeverity: "error",
        })
      );
    }
  };

  const handelChange = (e) => {
    setSelectedUserId(e.target.value);
  };

  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    let noPermissionRole = false;
    let role
    if (rolesData?.length > 0) {
      role = rolesData.find((role) => role?.id === roleId);

      if (role) {
        const { permissions } = role;
        if (!permissions || permissions.length === 0 || (permissions.length === 1 && permissions[0]?.permissionCategory === "basic")) {
          noPermissionRole = true;
        }
      }
    }

    if (noPermissionRole) {
      dispatch(
        openMessage({
          // message: `Please assign some permissions to the ${<strong style={{ color: 'red' }}>{(role && role?.roleName) ?? "Current"}</strong>} role!`,
          message: <>{role ? <strong style={{ color: 'black' }}>{role?.roleName}</strong> : "This"} role does not have permission assign permission first !</>,
          messageSeverity: "error",
        })
      );
    } else {
      setSelectedRoleId(roleId);
    }
  };

  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      onClose={handelCloseDialogue}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>Assign User To Outlet!</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handelCloseDialogue}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: isMobile ? 1 : 2 }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box>
              <Select
                value={selectedUserId}
                onChange={handelChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ width: "90%" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250
                    },
                  }
                }}
              >
                <MenuItem disabled value="">
                  <em>Select User</em>
                </MenuItem>
                {orgUsersList?.map((_user) => {
                  // Check if the user is already selected
                  const isSelected = selectedUsersList1?.some(
                    (selectedUser) =>
                      selectedUser.userDetail.id === _user.userDetail.id
                  );
                  // Display the user in the dropdown only if it's not selected
                  // if (!isSelected) {
                  if (!isSelected && _user?.userDetail?.active) {
                    return (
                      <MenuItem
                        key={_user?.userDetail?.id}
                        value={_user?.userDetail?.id}
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        <em>{`${_user?.userDetail?.firstName} ${_user?.userDetail?.lastName}`}</em>
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>
            </Box>
            <Box mt={"1rem"}>
              <Select
                value={selectedRoleId}
                onChange={handleRoleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ width: "90%" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250
                    },
                  }
                }}
              >
                <MenuItem disabled value="">
                  <em>Select Role</em>
                </MenuItem>
                {rolesData?.map((_role) => (
                  <MenuItem key={_role?.id} value={_role?.id}>
                    <em>{`${_role?.roleName}`}</em>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box>
            <CustomButton
              onClick={handleAddMember}
              variant={"contained"}
              label={assingUserToOrgLoading ? "Saving..." : "Assign"}
              disabled={(selectedUserId === "" || selectedRoleId === "") ? true : false}
              loading={assingUserToOrgLoading}
              endIcon={<PersonAddAltIcon sx={{ height: 16 }} />}
            />
          </Box>
        </Box>
        <Box marginTop={"2rem"}>
          <Typography variant="subtitle1" marginBottom={"1rem"} noWrap>Assigned Users</Typography>
          {selectedUsersList1 && selectedUsersList1.length > 0 ? (
            selectedUsersList1.map((user, index) => (
              isMobile ? <Grid
                container
                key={index}
                spacing={3}
                width={"100%"}
                alignItems={"start"}
              >
                <Grid item xs={2}>
                  <Typography sx={{ color: "grey" }} variant="body2">
                    <AccountCircleIcon/>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box>
                    <Typography noWrap>{`${user?.userDetail?.firstName} ${user?.userDetail?.lastName}`}</Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      //style={{ marginLeft: "8px" }}
                    >
                      {user.roleDetail?.roleName}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginLeft: "8px" }}
                  >
                    {user.roleDetail?.roleName}
                  </Typography>
                </Grid> */}
                <Grid item xs={2}>
                  {
                    user?.roleDetail?.roleName !== "customer" && user?.roleDetail?.roleName !== "vendor" &&
                    <IconButton
                      aria-label="Delete"
                      onClick={() =>
                        deleteMember(
                          user.id,
                          user.userId,
                          user.orgId,
                          user.roleId
                        )
                      }
                    >
                      {/* <DeleteIcon style={{ color: "red" }} /> */}
                      <Iconify icon="material-symbols-light:delete-outline" color={"red"} />
                    </IconButton>
                  }
                </Grid>
              </Grid> :
                <Grid
                  container
                  key={index}
                  spacing={2}
                  width={"100%"}
                  alignItems={"center"}
                >
                  <Grid item xs={1}>
                    <Typography sx={{ color: "grey" }} variant="body2">
                      <AccountCircleIcon style={{ marginRight: "8px" }} />
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography noWrap>{`${user?.userDetail?.firstName} ${user?.userDetail?.lastName}`}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginLeft: "8px" }}
                    >
                      {user.roleDetail?.roleName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {
                      user?.roleDetail?.roleName !== "customer" && user?.roleDetail?.roleName !== "vendor" &&
                      <IconButton
                        aria-label="Delete"
                        onClick={() =>
                          deleteMember(
                            user.id,
                            user.userId,
                            user.orgId,
                            user.roleId
                          )
                        }
                      >
                        {/* <DeleteIcon style={{ color: "red" }} /> */}
                        <Iconify icon="material-symbols-light:delete-outline" color={"red"} />
                      </IconButton>
                    }
                  </Grid>
                </Grid>
            ))
          ) : (
            <Typography>No User Found please First Add The User!</Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AssignUserDialogue;