import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CustomLabel from '../../components/common/label/CustomLabel';
import Iconify from '../../components/common/Iconify';
import { Box } from '@mui/material';
import moment from "moment";
import { color } from 'framer-motion';
import BpCheckbox from '../../components/common/CustomCheckbox';
import CustomTableCell from './table-cell';
import OrderDeliveredDialogue from '../../pages/sales-order/OrderDeliveredDialogue';

// ----------------------------------------------------------------------

export default function CustomTableRow({ rowData, selected, handleClick, onOrderDeliver, rowSelctionRequired = true, handleEditRecord, selectedId, onPriceChange }) {

  const [open, setOpen] = useState(null);
  const [openDelivery, setOpenDelivery] = useState(false);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const onClose = () => {
    setOpenDelivery(false)
  }

  return (
    <>
      <TableRow hover tabIndex={-1}
        role={rowSelctionRequired ? "checkbox" : undefined}
        selected={selected}
      >
        {rowSelctionRequired && <TableCell>
          <BpCheckbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>}
        {
          rowData.map((_row) => (
            <CustomTableCell key={_row.headerKey} onPriceChange={onPriceChange} setOpenDelivery={setOpenDelivery} selectedId={selectedId} {..._row} rowData={rowData} />
          ))
        }

        <TableCell padding='checkbox' >
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        <MenuItem onClick={() => handleEditRecord(selectedId)}>
          <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {/* <MenuItem onClick={handleCloseMenu} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>
      {openDelivery && <OrderDeliveredDialogue
        open={openDelivery}
        handelClose={onClose}
        orderId={selectedId}
        onOrderDeliver={onOrderDeliver}
      />}
    </>
  );
}

