import { Box, Button, Grid, Typography } from "@mui/material";
import { SectionContainer } from "./Section1";
import { brand7, brand9, product1, product10, product2, product3, product4, product5, product6, product7, product8, product9 } from "../../assets/images";
import { Visibility } from "@mui/icons-material";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import { useSelector } from "react-redux";
import { horecaApi } from "../../redux/api/api";
import { useEffect } from "react";
import GetImageComponent from "../../components/common/GetImageComponent";

const TrendingProducts = ({ setLoginOpen, location }) => {
    const responsive = useResponsiveStyles();
    const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
    const [getTrendProductByPinCode, { data }] = horecaApi.endpoints.getTrendProductByPinCode.useLazyQuery();
    useEffect(() => {
        if (location) {
            getTrendProductByPinCode({ pinCode: location.pincode })
        }
    }, [location])
    const data1 = [
        {
            name: 'Eastmade - Cumin (Jeera) Whole, 500 gm',
            quantity: '1 pc',
            discount: '23% OFF',
            image: product1,
            price: '189',
            originalPrice: '245.7'
        },
        {
            name: 'MDH - Jeera Powder, 100 gm',
            quantity: '1 pack',
            discount: '36% OFF',
            image: product2,
            price: '96.5',
            originalPrice: '139.65'
        },
        {
            name: 'MDH - Kitchen King Masala, 500 gm',
            quantity: '1 pack',
            discount: '24% OFF MRP',
            image: product3,
            price: '373.7',
            originalPrice: '455'
        },
        {
            name: 'Eastmade - Cassia (Dalchini/Taj), 100 gm',
            quantity: '1 pack',
            discount: '43% OFF',
            image: product4,
            price: '33.1',
            originalPrice: '57.75'
        },
        {
            name: 'Eagle - Yellow Chilli Powder, 100 gm',
            quantity: '1 pack',
            discount: '',
            image: product5,
            price: '57.4',
            originalPrice: ''
        },
        {
            name: 'Eastmade - Sesame White (Till), 1 Kg',
            quantity: '1 kg',
            discount: '33% OFF',
            image: product6,
            price: '226.5',
            originalPrice: '308.7'
        },
        {
            name: 'Lijjat - Punjabi Masala Papad, 200 gm',
            quantity: '1 pc',
            discount: '21% OFF',
            image: product7,
            price: '85.8',
            originalPrice: '106'
        },
        {
            name: 'Eastmade - Sweet Saunf, 200 gm (2 gm x 100 Pcs)',
            quantity: '1 pack',
            discount: 'BESTSELLER',
            image: product8,
            price: '40',
            originalPrice: ''
        },
        {
            name: 'Ambika - Appalam No.2, 280 gm',
            quantity: '1 pack',
            discount: 'BESTSELLER',
            image: product9,
            price: '161.4',
            originalPrice: '231'
        },
        {
            name: 'Trust - Classic White Sugar, 5 Kg',
            quantity: '5 kg',
            discount: '21% OFF',
            image: product10,
            price: '300.3',
            originalPrice: '367.5'
        }
    ]
    if (responsive?.isMobile) {
        return (
            <Box sx={{ padding: 1 }}>
                <Box sx={{ padding: 2, paddingX: 1, backgroundColor: '#F1F2F6', borderRadius: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                        <Typography variant="h3" fontWeight={500} sx={{ textAlign: "center", color: '#373737' }}>
                            Top Picks
                        </Typography>
                    </Box>
                    <Grid container spacing={1}>
                        {
                            data && data.map((item, index) => {
                                return index < 10 && <Grid key={`${index}tp`} item xs={6} sm={2.4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ flexGrow: 1, border: '.5px solid #d3d3d3', borderRadius: 2, backgroundColor: '#FFF', padding: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <GetImageComponent s3Key={item.item_image_key} style={{ height: 80, width: 80 }} />
                                        <Box sx={{ flexGrow: 1, padding: 1, paddingBottom: 0, paddingTop: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <Typography variant="subtitle1" color="textSecondary">{item.name}</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 1 }}>
                                                <Typography variant="subtitle1">₹ {item.rate}</Typography>
                                                <Typography variant="body2" color="textSecondary">1 pack</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            })
                        }
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'end', paddingTop: 2 }}><Button size='large' onClick={() => setLoginOpen(true)}>View All</Button></Box>
                </Box>
            </Box>
        )
    }
    return (
        <Box sx={{ padding: 4, paddingX: 8 }}>
            <Box sx={{ padding: 4, backgroundColor: '#F1F2F6', borderRadius: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3 }}>
                    <Button sx={{ visibility: 'hidden' }} size='large'>View All</Button>
                    <Typography variant="h3" fontWeight={500} sx={{ textAlign: "center", color: '#373737' }}>
                        Top Picks
                    </Typography>
                    <Button size='large' onClick={() => setLoginOpen(true)}>View All</Button>
                </Box>
                <Grid container spacing={3}>
                    {
                        data && data.map((item, index) => {
                            return index < 10 && <Grid key={`${index}tp`} item sm={2.4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ flexGrow: 1, border: '.5px solid #d3d3d3', borderRadius: 2, backgroundColor: '#FFF', padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {/* <Box component="img" src={item.image} width={'70%'} height={150} /> */}
                                    <GetImageComponent s3Key={item.item_image_key} style={{ height: 150, width: '70%' }} />
                                    <Box sx={{ flexGrow: 1, paddingBottom: 0, paddingTop: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <Typography variant="subtitle1" color="textSecondary">{item.name}</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                                            <Typography variant="subtitle1">₹ {item.rate}</Typography>
                                            <Typography variant="body2" color="textSecondary">1 pack</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        })
                    }
                </Grid>
            </Box>
        </Box>
    )
}

export default TrendingProducts;