import { Avatar, Stack } from '@mui/material'
import GetImageComponent from './GetImageComponent'
import { noImage } from '../../assets/images';

const ItemsImageComponent = ({ s3key, width = "48px", height = "48px", borderRadius = "12px", marginRight = "16px", name = "Item" }) => {

  return (
    <Stack sx={{ borderRadius }}>
      {s3key ?
        <Avatar sx={{ width: '48px', height: '48px', borderRadius: '12px', marginRight: marginRight }}>
          <GetImageComponent s3Key={s3key} />
        </Avatar> :
        <Avatar
          src={noImage}
          sx={{
            width,
            height,
            borderRadius,
            marginRight,
            bgcolor: (theme) => theme.palette.primary.lighter,
            color: (theme) => theme.palette.primary.main
          }}
        />
      }
    </Stack>
  )
}

export default ItemsImageComponent