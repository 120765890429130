import { Box, Rating, Typography } from "@mui/material";
import GetImageComponent from "../../components/common/GetImageComponent";
import VendorDeals from "./VendorDeals";
import VendorProducts from "./vendorProducts";
import StarIcon from '@mui/icons-material/Star';
import VendorLogoImageComponent from "../../components/common/VendorLogoImageComponent";
import SocialMediaShare from "../../components/SocialMediaShare";

const ExploreProductMobile = ({ vendorDetails, categoryDetails, avgRating }) => {
    return (
        <Box sx={{ padding: 1, paddingY: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', gap: 3, alignItems:'center', paddingX:1 }}>
                    {/* <GetImageComponent s3Key={vendorDetails?.parent_category_image} /> */}
                    {/* <GetImageComponent s3Key={vendorDetails?.org_logo_key} style={{ borderRadius: 8, height: 56 }} /> */}
                    <Typography sx={{ fontSize: 26, fontWeight: 'bolder' }}>
                        {vendorDetails?.org_name}
                    </Typography>
                    <SocialMediaShare title={'Check out this vendor!'}/>
                </Box>
                <Box sx={{ padding: 1, borderRadius: 2, background: 'linear-gradient(rgb(255, 255, 255) -6.71%, rgb(235, 235, 242) 56.19%, rgb(223, 223, 231) 106.56%)' }}>
                    <Box sx={{ backgroundColor: '#FFF', border: '1px solid rgba(2, 6, 12, 0.15)', borderRadius: 1, padding: 1.5, display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', gap: 1, backgroundColor: '#0C5120', padding: 1, paddingY: 0.2, borderRadius: 1, alignItems: 'center' }}>
                                <Typography sx={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>{avgRating}</Typography>
                                <StarIcon sx={{ color: 'white', fontSize: 16 }} />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'space-between', alignItems:'center', flexGrow:1 }}>
                                <Typography variant="subtitle2">(50+ ratings)</Typography>
                                
                            </Box>
                        </Box>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'medium', color: '#FF7442' }}>
                            <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>72</span> successful deliveries till now.
                        </Typography>
                    </Box>
                </Box>
                {/* <VendorDeals vendorDetails={vendorDetails} /> */}
                <VendorProducts vendorDetails={vendorDetails} categoryDetails={categoryDetails}/>
            </Box>
        </Box>
    )
}

export default ExploreProductMobile;