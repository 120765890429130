import React from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CustomTextField from "../../../../components/common/CustomTextField";
// import { useDeleteContactPersonsMutation } from "../../../redux/apis/api";
// import { openMessage } from "../../../redux/slices/showMessageSlice";
// import CustomStaticPhoneInput from "../../../components/common/CustomStaticPhoneInput";
import { MuiTelInput } from "mui-tel-input";
import { getIn } from "formik";
import CustomMuiTelInput from "../../../../components/common/CustomMuiTelInput";

const ContactPerson = ({ formik, newPerson, organizationId }) => {

  //   const dispatch = useDispatch();
  //   const [deleteContactPersons] = useDeleteContactPersonsMutation();

  //   const handleAddNewPerson = async () => {
  //     const { contactPersons } = formik.values;

  //     const lastFieldHasValue =
  //       contactPersons[contactPersons.length - 1].name &&
  //       contactPersons[contactPersons.length - 1].mobileNumber;

  //     if (lastFieldHasValue) {
  //       const updatedContactPersons = [
  //         ...formik.values.contactPersons,
  //         newPerson,
  //       ];
  //       await formik.setFieldValue("contactPersons", updatedContactPersons);

  //     } else {
  //       // await formik.validateField(
  //       //   `contactPersons[${contactPersons.length - 1}].name`,
  //       //   "Contact Person is Required"
  //       // );
  //       // await formik.validateField(
  //       //   `contactPersons[${contactPersons.length - 1}].mobileNumber`,
  //       //   "Mobile Number is Required"
  //       // );
  //     }
  //   };

  //   const handleDeletePerson = async (id, indexToRemove) => {
  //     if (id) {
  //       // Delete contact person by ID
  //       const result = await deleteContactPersons({
  //         orgId: organizationId,
  //         id: id,
  //       });
  //       if (result.data) {
  //         dispatch(
  //           openMessage({
  //             message: "Person Deleted successfully",
  //             messageSeverity: "success",
  //           })
  //         );
  //         if (formik.values.contactPersons.length > 1) {
  //           const updatedArray = formik.values.contactPersons.filter(
  //             (_, index) => index !== indexToRemove
  //           );
  //           formik.setFieldValue("contactPersons", updatedArray);
  //         }
  //       } else if (result.error) {
  //         dispatch(
  //           openMessage({
  //             message: result.error.data.message,
  //             messageSeverity: "error",
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           openMessage({
  //             message: "Not able to delete Person at this moment!",
  //             messageSeverity: "error",
  //           })
  //         );
  //       }
  //     } else {
  //       // Delete contact person by index if it doesn't have an ID
  //       if (formik.values.contactPersons.length > 1) {
  //         const updatedArray = formik.values.contactPersons.filter(
  //           (_, index) => index !== indexToRemove
  //         );
  //         formik.setFieldValue("contactPersons", updatedArray);
  //       }
  //     }
  //   };

  const handleChange1 = (value, index) => {
    formik.setFieldValue(`contactPersons[${index}].mobileNumber`, value);
  };

  return (
    <Box width={"100%"}  >
      {/* <Stack direction={"row"} justifyContent={"space-between"} pt={"2rem"} pb={"0.5rem"}>
        <Typography variant="h6">Contact Person</Typography> */}
        {/* <Button onClick={handleAddNewPerson}>Add New Person</Button> */}
      {/* </Stack> */}
      {/* <Box > */}
      <Grid item xs={12} pt={3} pb={2}>
        <Typography variant="h6" >Contact Person</Typography>
      </Grid>
      {formik.values?.contactPersons.map((person, index) => {
        return (
          <Grid container key={index} spacing={3} mb={3}>
            <Grid item xs={12} md={6} >
              <CustomTextField
                name={`contactPersons[${index}].name`}
                // placeholder={"Enter Contact Person Name"}
                label={"Enter Name"}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} md={6} >
              {/* <MuiTelInput
                defaultCountry="IN"
                // onChange={(e)=>handleChange1(e, index)}
                onChange={(e) => formik.setFieldValue(`contactPersons[${index}].mobileNumber`, e)}
                value={getIn(formik.values, `contactPersons[${index}].mobileNumber`)}
                // disableDropdown
                label={'Mobile Number'}
                forceCallingCode
                name={"orgMobileNumber"}
                onBlur={formik.handleBlur}
                error={
                  getIn(formik.touched, `contactPersons[${index}].mobileNumber`) &&
                  Boolean(getIn(formik.errors, `contactPersons[${index}].mobileNumber`))
                }
                helperText={
                  getIn(formik.touched, `contactPersons[${index}].mobileNumber`) &&
                  getIn(formik.errors, `contactPersons[${index}].mobileNumber`)
                }
                sx={{ width: "100%" }}
                /> */}
              <CustomMuiTelInput
                formik={formik}
                name={`contactPersons[${index}].mobileNumber`}
                label={'Mobile Number'}
                fullWidth
              />
            </Grid>
            {/* {formik.values.contactPersons.length > 1 && (
                <IconButton onClick={() => handleDeletePerson(person.id, index)}>
                  <DeleteIcon />
                </IconButton>
              )} */}
          </Grid>
        );
      })}
      {/* </Box> */}
    </Box>
  );
};

export default ContactPerson;