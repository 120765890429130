import { configureStore } from "@reduxjs/toolkit";
import logger from 'redux-logger'
import { horecaApi } from "./api/api";
import commonReducer from './slices/commonSlice';
import orderListReducer from './slices/orderListSlice';
import authReducer from './slices/authSlice';
import appReducer from "./slices/appReducer";
import showMessageSlice from "./slices/showMessageSlice";
import orderReducer from './slices/orderSlice';
import notificationReducer from './slices/notificationSlice';


const store = configureStore({
  reducer: {
    [horecaApi.reducerPath]: horecaApi.reducer,
    common: commonReducer,
    orderList: orderListReducer,
    auth: authReducer,
    app: appReducer,
    showMessage: showMessageSlice,
    order: orderReducer,
    notifications: notificationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([logger, horecaApi.middleware]),
});

const { dispatch } = store;

export { store, dispatch };


