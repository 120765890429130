import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteZipCodeByIdMutation } from '../../../../redux/api/api';
import { openMessage } from '../../../../redux/slices/showMessageSlice';
import { useDispatch } from 'react-redux';
import CustomTextField from '../../../../components/common/CustomTextField';
import Iconify from '../../../../components/common/Iconify';
import CustomButton from '../../../../components/common/CustomButton';

const DeliversAt = ({ formik, zipCode, organizationId, disabled}) => {


  const dispatch = useDispatch();
  const [zipCodeById] = useDeleteZipCodeByIdMutation();

  const handelAddNewDeliveryPoint = async () => {
    const { deliversAt } = formik.values;



    const lastFieldHasValue =
      deliversAt.length > 0 && deliversAt[deliversAt.length - 1].zipcode !== "";

    if (lastFieldHasValue) {
      const addNewZipCode = [...deliversAt, zipCode];
      await formik.setFieldValue("deliversAt", addNewZipCode);
    } else {
      await formik.setFieldError(
        `deliversAt[${deliversAt.length - 1}].zipcode`,
        "This field is required"
      );
    }
  };

  const handelDeletePinCode = async (id, indexToRemove) => {
    if (id) {
      const result = await zipCodeById({ orgId: organizationId, id: id });
      if (result.data) {
        dispatch(
          openMessage({
            message: "zip code delted successfully",
            messageSevirity: "success",
          })
        );
        if (formik.values.deliversAt.length > 1) {
          const updatedArray = formik.values.deliversAt.filter(
            (_, index) => index !== indexToRemove
          );
          formik.setFieldValue("deliversAt", updatedArray);
        }
      } else if (result.error) {
        dispatch(
          openMessage({
            message: result.error.data.message,
            messageSevirity: "error",
          })
        );
      } else {
        dispatch(
          openMessage({
            message: "Not able to delete zip code at this moment!",
            messageSevirity: "error",
          })
        );
      }
    } else {
      if (formik.values.deliversAt.length > 1) {
        const updatedArray = formik.values.deliversAt.filter(
          (_, index) => index !== indexToRemove
        );
        formik.setFieldValue("deliversAt", updatedArray);
      }
    }
  };



  return (
    <>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Delivery Points</Typography>
        <CustomButton
          onClick={handelAddNewDeliveryPoint}
          startIcon={<Iconify icon="eva:plus-fill" />}
          variant={"contained"}
          label={"Add New Delivery Point"}
        />
      </Stack>
      <Box
        sx={{ display: "flex", flexWrap: "wrap", gap: "2rem", mt: "1rem" }}
      >
        {formik?.values?.deliversAt.map((pincode, index) => {
          return (
            <>
              <div
                key={index}
                style={{
                  maxWidth: "200px",
                  width: "100%",
                  display: "flex",
                  gap: "1rem",
                  alignItems: "flex-end",
                }}
              >
                <CustomTextField
                  name={`deliversAt[${index}].zipcode`}
                  placeholder={"Enter Zip Code"}
                  label={"Zip Code"}
                  formik={formik}
                  disabled={disabled}
                />
                {formik.values.deliversAt.length > 1 && (
                  <IconButton
                    disabled={disabled}
                    onClick={() => handelDeletePinCode(pincode.id, index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </>
          );
        })}
      </Box>
    </>
  )
}

export default DeliversAt