export const homeLanding = require('./home-landing-compress.jpg');
export const hero1 = require('./hero1.jpg');
export const hero2 = require('./hero2.jpg');
export const whiteBg = require('./logo_hp_white.png');
export const blackBg = require('./logo_hp_black.png');
export const qualityIcon = require('./quality.svg');
export const singleVendorIcon = require('./singleVendor.svg');
export const nextDayIcon = require('./nextDay.svg');
export const noSticker = require('./noSticker.png');
export const h1Logo = require('./h1Logo.png');
export const emptyCart = require('./empty_cart.png');