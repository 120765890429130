import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  TextField,
  Button,
} from '@mui/material';
import OTPComponent from '../order/common/OTPComponent';
import { useState } from 'react';
import { useMarkAsDeliverMutation, useMarkAsDeliverSkipOTPMutation, useResendDeliveryOTPMutation } from '../../redux/api/api';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../components/common/Iconify';
import CustomButton from '../../components/common/CustomButton';
import { openMessage } from '../../redux/slices/showMessageSlice';

const OrderDeliveredDialogue = ({ handelClose, open, orderId, onOrderDeliver }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(['', '', '', '']);
  const isDeliveryPerson = useSelector((state) => state.auth.isDeliveryPerson);
  const [markAsDeliver] = useMarkAsDeliverMutation();
  const [markAsDeliverSkipOTP] = useMarkAsDeliverSkipOTPMutation();
  const [resendDeliveryOTP] = useResendDeliveryOTPMutation();
  const [message, setMessage] = useState('');
  const handleChange = (value) => {
    setMessage(value);
  };
  const updateStatusOrder = async (skipOTP = false) => {
    let result;
    let _payload = {
      message: message
    }
    if (skipOTP) {
      _payload = {
        isSkipedVerification: true,
        skipReason: message
      }
      result = await markAsDeliverSkipOTP({ orderId, payload: _payload });
    } else {
      result = await markAsDeliver({ orderId, otp: otp.join(''), payload: _payload });
    }
    if (result?.data) {
      if (onOrderDeliver) {
        onOrderDeliver();
      }
      dispatch(openMessage({ message: 'Order delivered successfully.', messageSeverity: 'success' }));
      handelClose();
    } else if (result?.error) {
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
    } else {
      dispatch(openMessage({ message: 'Not able to update an order status at this moment!', messageSeverity: 'error' }));
    }
  }
  const handleResendOTP = () => {
    setOtp(['', '', '', '']);
    resendDeliveryOTP({ orderId });
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>{`Mark order as delivered?`}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={handelClose}>
          <Iconify icon='zondicons:close-outline' />
        </IconButton>
      </Box>
      <DialogContent>
        <Box>
          <TextField
            label="Message"
            fullWidth
            variant="outlined"
            value={message}
            onChange={(e) => handleChange(e.target.value)}
            multiline
            rows={4}
          />
          <OTPComponent otp={otp} setOtp={setOtp} handleResendOTP={handleResendOTP} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'start', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
          <CustomButton
            onClick={handelClose}
            variant={"contained"}
            label={"Cancel"}
            color={"primary"}
          />
          <CustomButton
            onClick={() => updateStatusOrder(false)}
            variant={"contained"}
            label={"Confirm"}
            color={"secondary"}
          />
        </Box>
        {!isDeliveryPerson && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            color="primary"
            sx={{ textDecoration: 'underline' }}
            onClick={() => updateStatusOrder(true)}
          >
            Skip OTP & Confirm
          </Button>
        </Box>}
      </DialogActions>
    </Dialog>
  );
};

export default OrderDeliveredDialogue;