import React from 'react';
import { Container, Grid, Typography, Button, Box, Avatar } from '@mui/material';
import CustomButton from '../../components/common/CustomButton';
import useResponsiveStyles from '../../hooks/useResponsiveStyles';

const TalkToUs = () => {
  const responsive = useResponsiveStyles();
  if (responsive?.isMobile) {
    return (
      <Container sx={{ paddingTop: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', backgroundColor: '#dbedf2', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: 2, padding: 3 }}>
          <Typography variant="h3" fontWeight={500}>Connect with Us</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: 2 }}>
            <Box>
              <Typography variant='body1' sx={{ textAlign: 'center' }}>
                Can’t find what you’re looking for? Have a bulk order? Have a query? <br />
                Simply say HELLO to us… & we’ll take care of it. Chat now…</Typography>
            </Box>
            <Box component="a"
              href="https://wa.me/+917710920002" sx={{
                display: 'flex', gap: 1, textDecoration: 'none', alignItems: 'center', border: '1px solid #5ED16A', borderRadius: 2, padding: 1,
                '&:hover': {
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // shadow on hover
                  cursor: 'pointer'
                },
              }}>
              <Box>
                <Avatar sx={{ width: 56, height: 56 }} src="/assets/icons/watsApp.png"></Avatar>
              </Box>
              <Typography variant='body2'>Click here to contact us through <span style={{ fontWeight: 'bold' }}>WhatsApp</span></Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
  return (
    <Container sx={{ paddingTop: 5 }}>
      {/* <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" className="sb">
            Talk To Us!
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Typography variant="body1" className="sample">
            Can’t find what you’re looking for? Have a bulk order? Have a query? <br />
            Simply say HELLO to us… & we’ll take care of it. Chat now…
          </Typography>
        </Grid>
        <Grid item md={3}>
          <CustomButton
            variant="outlined"
            props={{
              href: "https://wa.me/+917710920002",
              target: 'blank'
            }}
            sx={{
              fontSize: 23,
              backgroundColor: 'rgb(37, 211, 102)',
              color: 'rgb(255, 255, 255)',
              '&:hover': {
                backgroundColor: 'rgb(25, 159, 75)', // Change the color on hover if needed
              },
            }}
            label={"Whatsapp"}
          />
        </Grid>
      </Grid> */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', backgroundColor: '#dbedf2', borderRadius: 2, padding: 3 }}>
        <Typography variant="h3" fontWeight={500}>Connect with Us</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Box>
            <Typography variant='body1'>
              Can’t find what you’re looking for? Have a bulk order? Have a query? <br />
              Simply say HELLO to us… & we’ll take care of it. Chat now…</Typography>
          </Box>
          <Box component="a"
            href="https://wa.me/+917710920002" sx={{
              display: 'flex', textDecoration: 'none', gap: 1, width: '25%', alignItems: 'center', border: '1px solid #5ED16A', borderRadius: 2, padding: 1,
              '&:hover': {
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // shadow on hover
                cursor: 'pointer'
              },
            }}>
            <Box>
              <Avatar sx={{ width: 56, height: 56 }} src="/assets/icons/watsApp.png"></Avatar>
            </Box>
            <Typography variant='body1' >Click here to contact us through <span style={{ fontWeight: 'bold' }}>WhatsApp</span></Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default TalkToUs;