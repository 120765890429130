import React from 'react';
import { Grid, InputAdornment, List, ListItem, MenuItem, Select, TextField, Typography } from "@mui/material";
import useResponsiveStyles from '../../hooks/useResponsiveStyles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const PrivateVendorOrderListRow = ({ row, columns, handleQuantityChange, index, isRead, isPrevQuant = true }) => {

  const { isMobile } = useResponsiveStyles()

  const handleQuantityChange1 = (event) => {
    // let inputValue = event.target.value;

    // // Remove leading zeros if the input is greater than zero
    // if (parseInt(inputValue) >= 0) {
    //   inputValue = parseInt(inputValue).toString();
    // }

    // Call the handleQuantityChange function with the modified value
    handleQuantityChange(event.target.name, Number(event.target.value), index);
  };

  const handleUnitChange = (event) => {
    handleQuantityChange(event?.target.name, event.target.value, index)
  };

  const handleNameChange = (event) => {
    handleQuantityChange(event?.target.name, event.target.value, index)
  }

  const handleDecrement = () => {
    handleQuantityChange('quantity', Number(row.quantity)-1, index);
  }

  const handleIncrement = () => {
    handleQuantityChange('quantity', Number(row.quantity)+1, index);
  }

  return (
    <List
      key={row?.id}
      sx={{
        width: "100%",
        // borderBottom: (theme) => "1px dashed rgba(145, 158, 171, 0.2)",
        ":hover": {
          transition: !(isMobile && isRead) && "0.3s all",
          bgcolor: (theme) => !(isMobile && isRead) && theme.palette.action.selected,
        },
      }}
    >
      <ListItem sx={{ width: '100%' }}>
        {columns.map((column, index) => {
          let value = null;
          let data = null;
          const style = { ...column?.style };

          switch (column?.id) {
            case "quantity": {
              data = (
                <ListItem key={column?.id} sx={{ ...style }} >
                  {
                    isRead
                      ?
                      <Typography sx={{ width: "100%", textAlign: column?.align }}>{row?.quantity}</Typography>
                      :
                      <TextField
                        name="quantity"
                        size="medium"
                        label="Quantity"
                        fullWidth
                        type="text"
                        defaultValue={0}
                        value={row?.quantity ? row?.quantity : 0}
                        onChange={handleQuantityChange1}
                        // InputProps={{
                        //   disableUpDown: true,
                        // }}
                        InputProps={{
                          inputProps: {
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            style: { textAlign: "center" }
                          },
                          disableUpDown: true,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              onClick={() =>
                                handleDecrement(row?.id, row?.name, row?.unit, parseInt(row?.quantity) - 1)
                              }
                            >
                              {row?.quantity > 0 && (
                                <RemoveIcon style={{ cursor: "pointer" }} />
                              )}
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={() =>
                                handleIncrement(row?.id, row?.name, row?.unit, parseInt(row?.quantity) + 1)
                              }
                            >
                              <AddIcon style={{ cursor: "pointer" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                  }
                </ListItem>
              );
              break;
            }
            case "name": {
              data = (
                <ListItem key={column?.id} sx={{ ...style }}>
                  {
                    isRead
                      ?
                      <Typography sx={{ width: "100%", textAlign: column?.align }}>{row?.name}</Typography>
                      :
                      <TextField
                        name="name"
                        size="medium"
                        label="Name"
                        type="text"
                        fullWidth
                        onChange={handleNameChange}
                        value={row?.name}
                      />
                  }
                </ListItem>
              );
              break;
            }
            case "unit":
              data = (
                <ListItem key={column?.id} sx={{ ...style }}>
                  {
                    isRead
                      ?
                      <Typography sx={{ width: "100%", textAlign: column?.align }}>{row?.unit}</Typography>
                      :
                      <Select
                        value={(row?.unit).toLowerCase()}
                        onChange={handleUnitChange}
                        label="Unit"
                        fullWidth
                        name='unit'
                        size='medium'
                      >
                        {['kg', 'g', 'mg', 'lb', 'ltr', 'ml', 'pc'].map(unit => (
                          <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                        ))}
                      </Select>
                  }
                </ListItem>
              );
              break;
            case "itemId": {
              data = (
                <ListItem key={column?.id} sx={{ ...style }}>
                  <Typography sx={{ width: "100%", textAlign: column?.align }}>{row[column.id] ? isMobile ? row[column.id] : `#${row[column.id]}` : ''}</Typography>
                </ListItem>
              );
              break;
            }
            default: {
              // let splitArray = column?.id?.split(".");
              // value = row;

              // for (let key of splitArray) {
              //   if (value && value.hasOwnProperty(key)) {
              //     value = value[key];
              //   } else {
              //     value = null;
              //     break;
              //   }
              // }
              data = (
                <ListItem sx={{ ...style }}>
                  <Typography sx={{ width: "100%", textAlign: column?.align }}>{row[column?.id]}</Typography>
                </ListItem>
              );
              break;
            }
          }
          return <React.Fragment key={index}>{data}</React.Fragment>;
        })}
      </ListItem>
    </List>
  );
};

export default PrivateVendorOrderListRow