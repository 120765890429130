


import React, { useRef, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Snackbar, SnackbarContent, Stack, Typography } from "@mui/material";
import Iconify from "../../../components/common/Iconify";
import { thankyouGif } from "../../../assets/images";
import chequePay from "../../../assets/images/chequePay.png";
import payOnDelivery from "../../../assets/images/payOnDelivery.png";
import horecaPay from "../../../assets/images/horecaPay.png";
import onlinePay from "../../../assets/images/onlinePay.png";
import CustomButton from "../../../components/common/CustomButton";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";
import FullScreenLoader from "../common/FullScreenLoader";
import axios from "axios";
import { useUpdateOrderStatusMutation } from "../../../redux/api/api";
import { useNavigate } from 'react-router-dom';

const loadScript = src => new Promise((resolve) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = () => {
    console.log('razorpay loaded successfully');
    resolve(true);
  };
  script.onerror = () => {
    console.log('error in loading razorpay');
    resolve(false);
  };
  document.body.appendChild(script);
});


const PaymentPage = ({ handleClose, open, isThankYou, afterPayClick, isLoading, price, paymentData }) => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [updateOrderStatus] = useUpdateOrderStatusMutation();
  const [loading, setLoading] = useState(false)
  const { isMobile } = useResponsiveStyles();
  const [openWarning, setOpenWarning] = useState(false);
  const submitPayment = async () => {
    paymentDone('payByGateway')
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (res) {
      const options = {
        ...paymentData.razorpayOptions,
        handler: function (response) {
          const data = {
            orderCreationId: paymentData.razorpayOptions.order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          const webHookUrl = process.env.REACT_APP_WEBHOOK_URL;
          axios.post(webHookUrl, data).then((res) => {
            if (res.data.status) {
              const successUrl = paymentData.razorpayOptions.success_url;
              const pathOnly = successUrl.replace(/^https?:\/\/[^/]+/, '');
              if (pathOnly) {
                rzp.close();
                navigate(pathOnly);
              }
              // window.location.href = paymentData.razorpayOptions.success_url;
            } else {
              const failureUrl = paymentData.razorpayOptions.failure_url;
              if (failureUrl) {
                // Remove the domain part
                const pathOnly = failureUrl.replace(/^https?:\/\/[^/]+/, '');
                rzp.close();
                navigate(pathOnly);
              }
            }
          })
        },
      };
      console.log("Initalizing the payment gateway")
      const rzp = new window.Razorpay(options);
      rzp.on('payment.failed', async function (response) {
        const orderId = paymentData.orderId
        const status = 'payment_failed'
        const msg = `Payment failed`
        await updateOrderStatus({ orderId: orderId, status: status, payload: { message: msg } });
        window.location.href = paymentData.razorpayOptions.failure_url;
        // if (updateRes) {
        //   // window.location.href = paymentData.razorpayOptions.failure_url;
        //   const failureUrl = paymentData.razorpayOptions.failure_url;
        //   if (failureUrl) {
        //     // Remove the domain part
        //     const pathOnly = failureUrl.replace(/^https?:\/\/[^/]+/, '');

        //     if (rzp) {
        //       console.log('Closing Razorpay popup'); // Debugging log
        //       rzp.close(); // This closes the popup
        //     } else {
        //       console.log('rzp object is not defined'); // Debugging log
        //     }
        //     navigate(pathOnly);
        //   }
        //   // if (failureUrl.startsWith('/')) {
        //   //   navigate(failureUrl);
        //   // }
        // }
      });
      rzp.open();
    }
    // if need safex in furture 

    // if (formRef.current) {
    //   formRef.current.submit(); // Programmatically trigger form submission
    // }
  }

  const handlePaymentClick = () => {
    isThankYou ? handleClose() : setOpenWarning(true)
  }
  const paymentDone = async (option) => {
    setLoading(open)
    await afterPayClick(option)
    setLoading(open)
  }
  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>Payment</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        //onClick={handleClose}
        onClick={handlePaymentClick}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Iconify icon="material-symbols:cancel-outline" />
      </IconButton>
      <DialogContent sx={{ p: 2, pt: 1 }}>
        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
          {
            isThankYou
              ?
              <>
                <Typography variant="body1"> Thank you for making the payment!</Typography>

                <img src={thankyouGif} />

                <CustomButton width={"100%"} variant="contained" onClick={handleClose} label={"Close"} />

              </>
              :
              <>
                <Typography variant="h4" color={'primary'} sx={{ mb: 2 }}>₹{price}</Typography>
                {/* <img src={shoppingGif} />

                <PaymentForm isLoading={isLoading} paymentData={paymentData} /> */}
                <Box sx={{ width: '100%', p: isMobile ? 1 : 2, boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px' }}>
                  {paymentData?.paymentOptions?.payByCheque && <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box component="img" src={chequePay} width={50} height={50} sx={{ padding: 1, border: '1px solid #DDDFE5', borderRadius: 1 }} />
                    <Box onClick={() => paymentDone('payByCheque')} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, cursor: 'pointer' }}>
                      <Box>
                        <Typography variant="subtitle1">Pay by Cheque</Typography>
                        <Typography variant="caption" sx={{ color: 'grey' }}>Pay by Cheque</Typography>
                      </Box>
                      <KeyboardArrowRightIcon />
                    </Box>
                  </Box>}
                  {paymentData?.paymentOptions?.payByCheque && <Divider sx={{ my: isMobile ? 1 : 2, ml: 6 }} />}
                  {paymentData?.paymentOptions?.payOnDelivery && <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box component="img" src={payOnDelivery} width={50} height={50} sx={{ padding: 1, border: '1px solid #DDDFE5', borderRadius: 1 }} />
                    <Box onClick={() => paymentDone('payOnDelivery')} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, cursor: 'pointer' }}>
                      <Box>
                        <Typography variant="subtitle1">Pay on Delivery</Typography>
                        <Typography variant="caption" sx={{ color: 'grey' }}>Pay by Cash / Card / UPI on Delivery</Typography>
                      </Box>
                      <KeyboardArrowRightIcon />
                    </Box>
                  </Box>}
                  {paymentData?.paymentOptions?.payOnDelivery && <Divider sx={{ my: isMobile ? 1 : 2, ml: 6 }} />}
                  {paymentData?.paymentOptions?.payWithDiscco && <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box component="img" src={horecaPay} width={50} height={50} sx={{ padding: 1, border: '1px solid #DDDFE5', borderRadius: 1 }} />
                    <Box onClick={() => paymentDone('payWithDiscco')} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, cursor: 'pointer' }}>
                      <Box>
                        <Typography variant="subtitle1">Pay By Discco</Typography>
                        <Typography variant="caption" sx={{ color: 'grey' }}>Pay using Discco Balance</Typography>
                      </Box>
                      <KeyboardArrowRightIcon />
                    </Box>
                  </Box>}
                  {paymentData?.paymentOptions?.payWithDiscco && <Divider sx={{ my: isMobile ? 1 : 2, ml: 6 }} />}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} onClick={submitPayment}>
                    <Box component="img" src={onlinePay} width={50} height={50} sx={{ padding: 1, border: '1px solid #DDDFE5', borderRadius: 1 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, cursor: 'pointer' }}>
                      <Box>
                        <Typography variant="subtitle1">Pay Online</Typography>
                        <Typography variant="caption" sx={{ color: 'grey' }}>Pay using UPI / Card / Net Banking</Typography>
                      </Box>
                      <KeyboardArrowRightIcon />
                    </Box>
                  </Box>
                </Box>
                <form
                  ref={formRef}
                  name="paymentForm"
                  method="POST"
                  enctype="application/x-www-form-urlencoded"
                  action={paymentData?.paymentForm}
                >
                  <input name="me_id" value={paymentData?.me_id} type="hidden" />
                  <input
                    name="merchant_request"
                    value={paymentData?.merchant_request}
                    type="hidden"
                  />
                  <input
                    name="hash"
                    value={paymentData?.hash}
                    type="hidden"
                  />
                </form>
              </>
          }
        </Stack>
      </DialogContent>
      <Snackbar
        open={openWarning}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          message="Do you want to cancel this payment? After canceling, the order will be marked as payment pending in the order history section."
          action={
            <>
              <Button size="small" variant="contained" onClick={() => setOpenWarning(false)}>
                Cancel
              </Button>
              <Button sx={{ ml: 2 }} size="small" variant="contained" onClick={() => handleClose()}>
                OK
              </Button>
            </>
          }
        />
      </Snackbar>
      {loading && <FullScreenLoader />}
    </Dialog>
  );
};

export default PaymentPage
