import { Badge, Box, Drawer, Grid, IconButton, InputAdornment, styled, TextField, Tooltip, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import GetImageComponent from "../../../components/common/GetImageComponent";
import { useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import Iconify from "../../../components/common/Iconify";
import ProductSearchResult from "../vendorProducts/ProductSearchResult";
import ProductList from "./ProductList";
import FavoriteIcon from '@mui/icons-material/Favorite';
import ReviewWishListDialogue from "./ReviewWishListDialogue";
import ListAltIcon from '@mui/icons-material/ListAlt';

const CategorySection = ({ vendorDetails, categoryDetails }) => {
    const [selectedCategory, setSelectedCategory] = useState();
    const [searchText, setSearchText] = useState('');
    const [openReviewWishlist, setOpenReviewWishlist] = useState(false);
    const [wishList, setWishList] = useState([]);
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchText('');
    };
    const addToWishlist = (_item) => {
        const indexToRemove = wishList.findIndex((_obj) => _obj.id == _item.id);
        if (indexToRemove > -1) {
            setWishList(wishList.filter((_, index) => index !== indexToRemove));
        } else {
            setWishList([...wishList, _item]);
        }
    }
    const onListCreated = () => {
        setWishList([]);
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <Box display="flex" justifyContent="center" alignItems="center" gap={1} sx={{ width: '100%' }} >
                <TextField
                    variant="outlined"
                    fullWidth
                    size="medium"
                    value={searchText}
                    onChange={handleSearchChange}
                    placeholder={`Search product in ${vendorDetails?.org_name}`}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 2, // Customize the border radius here
                            backgroundColor: '#F9FAFC', // Customize the background color here
                            border: '1px solid rgb(231, 232, 235)'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent', // Optional: make the border transparent
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'gray', // Optional: border color on hover
                        },
                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'blue', // Optional: border color when focused
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: '#595e75', // Customize the placeholder color here
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {/* <SearchIcon sx={{ color: '#171E30' }} /> */}
                                <Iconify icon="mingcute:search-line" />
                            </InputAdornment>
                        ),
                        endAdornment: searchText ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear search"
                                    onClick={handleClearSearch}
                                    edge="end"
                                    size="small"
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                    }}
                />
                <Tooltip title='Click to save order list'>
                    <IconButton size="large" disabled={wishList.length == 0} onClick={() => setOpenReviewWishlist(true)}>
                        <Badge badgeContent={wishList.length} color="error" >
                            <ListAltIcon fontSize="large" sx={{ color: '#757575' }} />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </Box>
            {
                searchText &&
                <ProductSearchResult wishList={wishList} addToWishlist={addToWishlist} searchText={searchText} />
            }
            {!searchText && <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* <Typography variant="h6">
                    Shop by category
                </Typography> */}
                <Box>
                    <Grid container spacing={2}>
                        {
                            categoryDetails && categoryDetails?.map((cat, index) => {
                                return (
                                    <Grid key={`${index}cat`} item xs={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box onClick={() => setSelectedCategory(cat)} sx={{
                                            flexGrow: 1
                                        }}>
                                            <Box sx={{
                                                backgroundColor: '#E6F3F3', borderRadius: 1.5,
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    transform: 'scale(1.05)',
                                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                                                },
                                                padding: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}>
                                                <GetImageComponent s3Key={cat?.parent_category_image} style={{ width: "100px", height: "100px" }} />
                                            </Box>
                                            <Typography variant="body2" sx={{ fontWeight: selectedCategory && cat.id == selectedCategory.id ? 'bold' : 'medium', textAlign: 'center' }}>
                                                {cat?.parent_category}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Box>
            }
            {!searchText && <ProductList wishList={wishList} addToWishlist={addToWishlist} selectedCategory={selectedCategory}  />}
            {openReviewWishlist && <ReviewWishListDialogue onListCreated={onListCreated} open={openReviewWishlist} addToWishlist={addToWishlist} onClose={() => setOpenReviewWishlist(false)} wishList={wishList} />}
        </Box>
    )
}

export default CategorySection;