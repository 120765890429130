import { Avatar, Stack } from '@mui/material';
import GetImageComponent from './GetImageComponent';
import { stringAvatar } from '../../utils/defaultIcon';

const VendorLogoImageComponent = ({ s3Key, style = { width: "90px", height: "50px" }, name = "Organization" }) => {
  let nameColor = stringAvatar({ name, color: "rgb" })?.user;

  return (
    <Stack
      width={style?.width}
      height={style?.height}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        borderRadius: !s3Key && "8px",
        padding: !s3Key && "0.3rem",
        border: !s3Key && "1px dashed grey"
      }}
    >
      {s3Key ?
        <GetImageComponent
          s3Key={s3Key}
          style={{
            height: style?.height,
            width: style?.width,
            padding: "0.3rem",
            border: "1px dashed grey",
            borderRadius: "8px",
            ...style
          }}
        /> :
        <Avatar
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            bgcolor: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},0.5)` : `rgba(${nameColor?.sx?.bgcolor},0.7)`,
            color: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},1)` : `rgba(255,255,255,0.9)`
          }}
          variant={"square"}
          children={nameColor?.children}
        />
      }
    </Stack>
  )
}

export default VendorLogoImageComponent