import React from 'react'
import AuthLayout from '../pages/auth/layout/AuthLayout';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import SingupView from '../pages/auth/sections/SingupView';
import LoginView from '../pages/auth/sections/LoginView';
import { element } from 'prop-types';
import MainLanding from '../pages/landing/MainLanding';
import MainLandingLayout from '../pages/landing/MainLandingLayout';
import ExplorePorducts from '../pages/instant-order/ExplorePorducts';
import PrivateVendorOrderView from '../pages/private-vendors/PrivateVendorOrderView';
import QuickLinkDetails from '../pages/quick-links/QuickLinkDetails';

const PublicRoutes = () => {
    const publicRoutes = [
        {
          element: (
            <AuthLayout>
              <Outlet />
            </AuthLayout>
          ),
          children: [
            { path: 'signup', element: <SingupView /> },
            { path: 'login', element: <LoginView /> },
            { path: '*', element: <Navigate to="/" replace /> },
          ],
        },
        { element: <QuickLinkDetails />, path: '/company/:link'},
        {
          element: (
            <MainLandingLayout>
              <Outlet />
            </MainLandingLayout>
          ),
          children: [
            { path: 'signup', element: <MainLanding />, index: true, path: '/'},
            { element: <ExplorePorducts />, path: '/explore-products'},
            { element: <PrivateVendorOrderView />, path: '/private-vendor-orders'},
          ]
        }
      ];

  const routes =  useRoutes(publicRoutes);
  
  return routes;
}

export default PublicRoutes