import React from "react";
import { Button, IconButton, alpha } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../components/common/CustomButton";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import { useDispatch } from "react-redux";
import { openMessage } from "../../redux/slices/showMessageSlice";

const EnterQuantity = ({
  initialQuantity = 1,
  maxQuantity = 999,
  quantity,
  setQuantity,
  quantity1,
  data,
  setQuantity1,
  handleAddToCart1,
  handleAddToCart,
  removeItem,
}) => {
  const { isMobile } = useResponsiveStyles();
  const dispatch = useDispatch();
  const handleIncrease = () => {
    if(quantity1 + 1 > 1000) {
      dispatch(openMessage({ message: "Quantity cannot exceed 1000.", messageSeverity: "error" }));
    } else {
      //setQuantity1(quantity1 + 1);
      handleAddToCart(data?.itemId, 1, data?.vendorId);
    }
  };

  const handleDecrease = () => {
    if (quantity1 > 1) {
      //setQuantity1(quantity1 - 1);
      handleAddToCart(data?.itemId, -1, data?.vendorId);
    } else {
      handleAddToCart(data?.itemId, -1, data?.vendorId);
    }
  };

  const handleRemove = async () => {
    removeItem();
  };

  const isDisabled = data?.rate ? false : true;
  return (
    <>
      {quantity1 !== 0 ? (
        <Button
          variant="contained"
          disabled={isDisabled}
          size={isMobile ? "small" : 'medium'}
          sx={{
            color: "white",
            fontSize:isMobile?11:13,
            bgcolor: (theme) => '#E95F62',
            ":hover": {
              bgcolor: (theme) => '#E95F62',
            },
          }}
          startIcon={
            quantity1 > 1 ? (
              <IconButton
                onClick={handleDecrease}
                sx={{
                  padding: 0,
                  minWidth: "20px",
                  minHeight: "20px",
                  color: "white",
                  ":hover": {
                    background: "none",
                  },
                }}
              >
                <RemoveIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleRemove}
                sx={{
                  padding: 0,
                  minWidth: "20px",
                  minHeight: "20px",
                  color: "white",
                  ":hover": {
                    background: "none",
                  },
                }}
              >
                <RemoveIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )
          }
          endIcon={
            <IconButton
              onClick={handleIncrease}
              sx={{
                padding: 0,
                minWidth: "20px",
                minHeight: "20px",
                color: "white",
                ":hover": {
                  background: "none",
                },
              }}
            >
              <AddIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          }
        >
          {quantity1}
        </Button>
      ) : (
        <CustomButton sx={{ backgroundColor: '#E95F62', color:'#FFF', fontSize:isMobile?11:13 }} size={isMobile? 'small' : 'medium'} variant="contained" onClick={handleAddToCart1} label={"Add"} disabled={isDisabled} />
      )}
    </>
  );
};

export default EnterQuantity;
