import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';

const OTPComponent = ({ otp, handleResendOTP, setOtp }) => {
    const [counter, setCounter] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (counter > 0) {
            const timer = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
            return () => clearInterval(timer);
        } else {
            setIsDisabled(false);
        }
    }, [counter]);

    const handleChange = (e, index) => {
        const value = e.target.value;
        const newOtp = [...otp];
        // Handle backspace/delete key
        if (e.key === 'Backspace' || e.key === 'Delete') {
            if (newOtp[index] === '' && index > 0) {
                document.getElementById(`otp-input-${index - 1}`).focus();
            } else {
                newOtp[index] = '';
            }
        } else if (/^[0-9]$/.test(value)) {
            newOtp[index] = value;

            // Move to the next input field
            if (index < 3) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
        setOtp(newOtp);
    };

    const resendOTP = () => {
        setCounter(30);
        setIsDisabled(true);
        handleResendOTP();
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
            <Typography variant="h6" gutterBottom>
                Enter OTP
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                {otp.map((_, index) => (
                    <TextField
                        key={index}
                        id={`otp-input-${index}`}
                        variant="outlined"
                        type="text"
                        inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                        value={otp[index]}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleChange(e, index)}
                        sx={{ width: 40, marginRight: 1 }}
                    />
                ))}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%' }}>
                <Typography variant="body2">
                    {counter > 0 ? `Resend OTP in ${counter}s` : 'You can now resend the OTP.'}
                </Typography>
                <Button
                    color="primary"
                    sx={{ textDecoration: 'underline' }}
                    disabled={counter > 0}
                    onClick={resendOTP}
                >
                    Resend OTP
                </Button>
            </Box>
        </Box>
    );
};

export default OTPComponent;
