import React from "react";
import { Badge, useTheme, Button, Avatar, ClickAwayListener, Typography, Card, Paper, Box, IconButton, Stack, Popover } from "@mui/material"
import { motion } from "framer-motion";
import Iconify from "../../components/common/Iconify";
import { useResponsive } from "../../hooks/useResponsive";
import { useRouter } from "../../routes/hooks/useRouter";
import { frozenImage } from "../../assets/categories";
import GetImageComponent from "../../components/common/GetImageComponent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setSelectedCategory } from "../../redux/slices/appReducer";
import { useSavePreferncesMutation } from "../../redux/api/api";
import CustomButton from "../../components/common/CustomButton";

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "100%" },
  center: { opacity: 0, x: ["50%", "0%"], y: ["50%", "0%"], scale: [0.5, 1] }, // Animation for product going inside the cart
};

const variants1 = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "100%" },
  center: { opacity: 1, x: ["50%", "65%"], y: ["50%", "0%"], scale: [0.5, 0.8] }, // Animation for product going inside the cart
};


export const CartItem = ({ category, handleDeleteFromCart }) => {
  return (
    <motion.li
      style={{
        listStyleType: "none",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack direction={'row'} gap={'1rem'} alignItems={'center'}>
        <Avatar
          // src={category?.image}
          // src={frozenImage}
          alt={category?.parent_category}
          sx={{ width: 50, height: 50, marginRight: 2 }}
        ><GetImageComponent s3Key={category?.parent_category_image} /></Avatar>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            maxWidth: "150px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: 2,
          }}
        >
          {category?.parent_category}
        </Typography>
      </Stack>
      <IconButton onClick={() => handleDeleteFromCart(category)}>
        <Iconify icon="bi:trash" sx={{ color: "red" }} />
      </IconButton>
    </motion.li>
  )
};



const Cart = ({
  isOpen,
  variants,
  selectedCategories,
  handleDeleteFromCart,
  theme,
  router
}) => {

  // const {orgId} = useParams(); 
  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const dispatch = useDispatch();
  const [savePrefernces] = useSavePreferncesMutation();
  const user = useSelector((state) => state.auth.user)
  const userId = user?.userId
  const suitableVendors = useSelector((state) => state.app.suitableVendors);

  const selectedCategory1 = useSelector(
    (state) => state.app.preference.selectedCategories
  );

  const selectedCategory2 = useSelector(
    (state) => state.app.selectedCategoryOnExplore
  );
  const handleSaveAndProceed = () => {
    const combinedSelectedCategoriesSet = new Set([...selectedCategory1, ...selectedCategory2]);
    const combinedSelectedCategories = [...combinedSelectedCategoriesSet];
    dispatch(setSelectedCategory(combinedSelectedCategories));
    savePrefernces({

      payload: {
        key: "selectedCategories",
        value: JSON.stringify(combinedSelectedCategories),
      },
    });
    router.push(`/org/${orgId}/order-list/create?vendorId=${suitableVendors[0]?.vendorDetails?.id}`)

  }

  return (
    <Paper
      animate={
        isOpen ? "open" : "none"
      }
      variants={variants}
      style={{
        display: !isOpen && "none",
        // display:'none',
        position: "fixed",
        zIndex: 5,
        top: '20%',
        right: "50px",
        maxWidth: "350px",
        maxHeight: "400px", // Set maximum height
        overflow: "auto",
        padding: '1.5rem 0.5rem',
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {selectedCategories?.length > 0 ? (
        <ul style={{ margin: 0, padding: 0 }}>
          {selectedCategories.map((category, index) => (
            <CartItem
              key={index}
              category={category}
              handleDeleteFromCart={handleDeleteFromCart}
            />
          ))}
          {/* <Button
            className="cart-icon-button"
            disabled={suitableVendors?.length > 0 ? false : true}
            variant="contained"
            sx={{ width: "100%", marginTop: "10px", textTransform: 'capitalize' }}
            onClick={handleSaveAndProceed}
          >
            Save and proceed
          </Button> */}
          <CustomButton
            props={{ className: 'cart-icon-button' }}
            disabled={suitableVendors?.length > 0 ? false : true}
            variant="contained"
            width="100%"
            sx={{ marginTop: "10px", textTransform: 'capitalize' }}
            onClick={handleSaveAndProceed}
            label={"Save and proceed"}
          />
        </ul>
      ) : (
        <Typography p={3} fontWeight={700} color={theme.palette.warning.main}>
          No Categories Selected! Please select at least one or more categories so
          we can serve you better. 😊
        </Typography>
      )}
    </Paper>
  )
}



export const AddToCartComp = ({ isAnimating,
  isProductVisible,
  cartCount,
  animationDirection,
  selectedCategories,
  currentCategory,
  handleRemoveItem,
  cart,
  isOpen, handleCartOpen, handleCloseCart
}) => {
  const theme = useTheme();

  const router = useRouter();
  const handleDeleteFromCart = (category) => {
    handleRemoveItem(category)
  }


  const mdDown = useResponsive('down', 'md')

  return (
    <ClickAwayListener onClickAway={handleCloseCart}>
      <Box sx={{}}>
        {isProductVisible && (
          <motion.div
            animate={{
              y: isAnimating ? (animationDirection ? mdDown ? [0, 250] : [-200, 120] : mdDown ? [200, 0] : [20, -200]) : 1, // Y-axis animation for dropping effect
              opacity: isAnimating ? (animationDirection ? [0, 1] : [0, 1, 0]) : 1, // Opacity animation for fading effect
            }}
            // variants={variants}
            transition={{
              duration: animationDirection ? 1.2 : 1, // Animation duration
              ease: "easeInOut", // Easing function
            }}
            style={{
              position: "absolute",
              zIndex: 5,
              top: mdDown ? '5%' : "25%",
              right: "50%",
              left: '50%'
            }}
          >
            <Avatar
              // src={currentCategory.image}
              alt={currentCategory?.parent_category}
              sx={{ width: mdDown ? '3.5rem' : '5rem', height: mdDown ? '3.5rem' : '5rem', marginRight: 1 }}
            >
              <GetImageComponent s3Key={currentCategory?.parent_category_image} /></Avatar>
            <Typography sx={{ fontSize: '10px' }}>{currentCategory?.parent_category}</Typography>
          </motion.div>
        )}

        <motion.div
          animate={cart ? { rotate: [0, 360], transition: { duration: 1 }, x: ["30%"], y: ['10%'], scale: [1] } : ''}
          variants={variants1}
          style={{
            position: isAnimating ? 'none' : "fixed",
            zIndex: 5,
            backgroundColor: isAnimating ? "" : 'black',
            borderRadius: '50%',
            padding: '1rem',
            bottom: isAnimating ? "85%" : "",
            top: isAnimating ? mdDown ? '2%' : "11%" : isAnimating ? '10%' : '13%',
            right: isAnimating ? "50%" : mdDown ? '20px' : '50px',
            cursor: 'pointer',
          }}
          onClick={handleCartOpen}
          className="cart-icon"
        >
          <Badge badgeContent={isAnimating ? 0 : cartCount} color="primary" >
            <Iconify
              width={isAnimating ? mdDown ? '30rem' : "40rem" : "1.5rem"}
              icon={isAnimating ? "solar:cart-bold" : "mdi:cart-plus"}
              sx={{ color: isAnimating ? theme.palette.primary.light : "white", opacity: '0.8' }}
            />
          </Badge>
        </motion.div>
        <Cart
          isOpen={isOpen}
          isAnimating={isAnimating}
          handleDeleteFromCart={handleDeleteFromCart}
          theme={theme}
          selectedCategories={selectedCategories}
          // key={}
          variants={variants}
          router={router}
        />
      </Box>
    </ClickAwayListener>
  );
};


const AddToCartAnimation = ({
  isAnimating,
  isProductVisible,
  animationDirection,
  currentCategory,
  handleCloseCart
}) => {
  const theme = useTheme();


  const mdDown = useResponsive('down', 'md')

  return (
    <ClickAwayListener onClickAway={handleCloseCart}>
      <Box sx={{}}>
        {isProductVisible && (
          <motion.div
            animate={{
              y: isAnimating ? (animationDirection ? mdDown ? [0, 250] : [-200, 120] : mdDown ? [200, 0] : [20, -200]) : 1, // Y-axis animation for dropping effect
              opacity: isAnimating ? (animationDirection ? [0, 1] : [0, 1, 0]) : 1, // Opacity animation for fading effect
            }}
            // variants={variants}
            transition={{
              duration: animationDirection ? 1.2 : 1, // Animation duration
              ease: "easeInOut", // Easing function
            }}
            style={{
              position: "absolute",
              zIndex: 5,
              top: mdDown ? '5%' : "25%",
              right: "50%",
              left: '50%'
            }}
          >
            <Avatar
              // src={currentCategory.image}
              alt={currentCategory?.parent_category}
              sx={{ width: mdDown ? '3.5rem' : '5rem', height: mdDown ? '3.5rem' : '5rem', marginRight: 1 }}
            >
              <GetImageComponent s3Key={currentCategory?.parent_category_image} /> </Avatar>
            <Typography sx={{ fontSize: '10px' }}>{currentCategory?.parent_category}</Typography>
          </motion.div>
        )}

        <motion.div
          animate={!isAnimating ? { rotate: [0, 360], transition: { duration: 1 }, x: ["30%"], y: ['10%'], scale: [1] } : {
            rotate: [0], opacity: [0.5, 1], x: ["50%"], y: ["40%", "50%"],
            scale: [0.1, 0.7], transition: { duration: 1 }
          }}
          variants={variants1}
          style={{
            position: isAnimating ? 'absolute' : "none",
            zIndex: 5,
            backgroundColor: isAnimating ? "" : 'black',
            borderRadius: '50%',
            padding: '1rem',
            bottom: isAnimating ? "85%" : "",
            top: isAnimating ? mdDown ? '2%' : "11%" : isAnimating ? '10%' : '13%',
            right: isAnimating ? "50%" : mdDown ? '20px' : '50px',
            cursor: 'pointer',
          }}
          className="cart-icon"
        >
          <Iconify
            width={isAnimating ? mdDown ? '30rem' : "40rem" : "1.5rem"}
            icon={isAnimating ? "solar:cart-bold" : "mdi:cart-plus"}
            sx={{ color: isAnimating ? theme.palette.primary.light : "white", opacity: '0.8' }}
          />
        </motion.div>
      </Box>
    </ClickAwayListener>
  );
};

export default AddToCartAnimation;

