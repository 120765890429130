import { Container, Divider, Grid, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomStatusIcon from "../order/common/CustomStatusIcon";
import { horecaApi, useGetInvoiceDetailQuery } from "../../redux/api/api";
import { useParams } from "react-router-dom";
import VendorLogoImageComponent from "../../components/common/VendorLogoImageComponent";
import { formatDateAndTime } from "../../utils/formatNumbers";
import Iconify from "../../components/common/Iconify";
import PageHeader from "../../components/common/PageHeader";
import { useRouter } from "../../routes/hooks/useRouter";
import { capitalizeFirstLetter } from "../../utils/stringFormating";
import { openMessage } from "../../redux/slices/showMessageSlice";
import { useDispatch } from "react-redux";
import { downloadPDF } from "./Invoices";
import { Viewer, Worker} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css'
import ItemsLoading from "../../components/common/ItemsLoading";



export default function InvoiceDetail() {
    const { orgId, invoiceId } = useParams();

    const dispatch = useDispatch();
    const [invoiceDetail, setInvoiceDetail] = useState({})

    const [downloadInvoice] = horecaApi.endpoints.downloadInvoice.useLazyQuery();
    const { data: invoiceDetailResponse } = useGetInvoiceDetailQuery(invoiceId)


    const router = useRouter()

    useEffect(() => {
        if (invoiceDetailResponse) {
            setInvoiceDetail(invoiceDetailResponse)
        }
    }, [invoiceDetailResponse])

    const goBack = () => {
        router.push(`/org/${orgId}/invoice`)
    }

    const [base64Invoice, setBase64Invoice] = useState();

    const handleDownloadInvoice = async () => {
        const result = await downloadInvoice(invoiceDetailResponse?.orderId);
        if (result?.data) {
            downloadPDF(result?.data?.img);
            dispatch(openMessage({ message: 'Invoice downloaded!', messageSeverity: 'success' }));
        } else if (result?.error) {
            dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
        } else {
            dispatch(openMessage({ message: 'Not able to download an invoice', messageSeverity: 'error' }));
        }
    }


    const base64toBlob = (data) => {
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);
        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
        return new Blob([out], { type: 'application/pdf' });
    };

    useEffect(()=>{
        (
            async () => {
                if(invoiceDetailResponse?.orderId){
                    const result = await downloadInvoice(invoiceDetailResponse?.orderId);
                    if(result?.data){
                    const blob = base64toBlob(result?.data?.img)
                    const url = URL.createObjectURL(blob);
                    setBase64Invoice(url)
                }
                }
            }
        )()
    },[invoiceDetailResponse])

    return (
        <>
            <Container maxWidth='xl'>         
                <PageHeader
                    handleBackClick={goBack}
                    title={invoiceDetail?.invoiceNumber ? `Invoice #${invoiceDetail?.invoiceNumber}` : "Invoice"}
                    statusLabel={invoiceDetail?.invoiceStatus && (
                        <CustomStatusIcon
                            color={capitalizeFirstLetter(invoiceDetail?.invoiceStatus) === "Paid" ? "rgb(17, 141, 87)" : "rgb(183, 110, 0)"}
                            backgroundColor={capitalizeFirstLetter(invoiceDetail?.invoiceStatus) === "Paid" ? "rgba(34, 197, 94, 0.16)" : "rgba(255, 171, 0, 0.16)"}
                            value={capitalizeFirstLetter(invoiceDetail?.invoiceStatus)}
                        />
                    )}
                    titleBelowComponent={invoiceDetail?.createdDate && <Typography variant='body1' color={'text.disabled'} noWrap>
                        {`${formatDateAndTime(invoiceDetail?.createdDate).formattedDateNew} ${formatDateAndTime(invoiceDetail?.createdDate).formattedTimeNew}`}
                    </Typography>}
                />
                <Stack
                    direction={"row"}
                >
                    <Tooltip title={"Download"}>
                        <IconButton onClick={handleDownloadInvoice}>
                            <Iconify width={20} icon="eva:cloud-download-fill" />
                        </IconButton>
                    </Tooltip>
                </Stack>

                <Grid
                    component={Paper}
                    item
                    xs={12} sm={12} md={12} lg={8} xl={8}
                    p={{ xs: 2, sm: 4 }}
                >
                    <Grid item marginBottom={"20px"}>
                        <Grid container spacing={4} direction={"column"} >
                            <Grid item>
                                <Grid container columns={16} >
                                    <Grid item
                                        xs={16} sm={8}
                                    >
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                        >
                                            <VendorLogoImageComponent s3Key={invoiceDetail?.vendorLogoKey} name={invoiceDetail?.vendorName} />
                                        </Stack>
                                    </Grid>
                                    <Grid item
                                        xs={16} sm={8}
                                    >
                                        <Stack
                                            direction="column"
                                            justifyContent="space-between"
                                            alignItems={{ xs: "flex-start", sm: "flex-end" }}
                                            spacing={1}
                                            width={"100%"}
                                        >
                                            {invoiceDetail?.invoiceStatus && (
                                                <CustomStatusIcon
                                                    color={capitalizeFirstLetter(invoiceDetail?.invoiceStatus) === "Paid" ? "rgb(17, 141, 87)" : "rgb(183, 110, 0)"}
                                                    backgroundColor={capitalizeFirstLetter(invoiceDetail?.invoiceStatus) === "Paid" ? "rgba(34, 197, 94, 0.16)" : "rgba(255, 171, 0, 0.16)"}
                                                    value={capitalizeFirstLetter(invoiceDetail?.invoiceStatus)}
                                                />
                                            )}
                                            <Typography variant={"h6"} noWrap >
                                                {invoiceDetail?.invoiceNumber ?? ""}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container columns={16} rowGap={2}>
                                    <Grid item
                                        xs={16} sm={8}
                                    >
                                        <Stack
                                            justifyContent={"flex-start"}
                                        >
                                            <Typography variant={"subtitle2"} noWrap gutterBottom>
                                                {"Invoice From"}
                                            </Typography>
                                            <Typography variant={"body2"} noWrap >
                                                {invoiceDetail?.vendorName ?? ""}
                                            </Typography>
                                            <Typography variant={"body2"} >
                                                {invoiceDetail?.vendorAddress?.pincode ? `${invoiceDetail?.vendorAddress?.landmark ? invoiceDetail?.vendorAddress?.landmark + ", " : ""}${invoiceDetail?.vendorAddress?.line1 ? invoiceDetail?.vendorAddress?.line1 + ", " : ""}${invoiceDetail?.vendorAddress?.line2 ? invoiceDetail?.vendorAddress?.line2 + ", " : ""}${invoiceDetail?.vendorAddress?.city ? invoiceDetail?.vendorAddress?.city + ", " : ""}${invoiceDetail?.vendorAddress?.state ? invoiceDetail?.vendorAddress?.state + ", " : ""}${invoiceDetail?.vendorAddress?.pincode ?? ""}` : ""}
                                            </Typography>
                                            <Typography variant={"body2"} noWrap >
                                                {invoiceDetail?.vendorNumber ? "Phone: " + invoiceDetail?.vendorNumber : ""}
                                            </Typography>
                                            <Typography variant={"body2"} noWrap >
                                                {invoiceDetail?.vendorBusinessDetails?.gstUin ? "GST: " + invoiceDetail?.vendorBusinessDetails?.gstUin : ""}
                                            </Typography>
                                            <Typography variant={"body2"} noWrap >
                                                {invoiceDetail?.vendorBusinessDetails?.panNumber ? "PAN: " + invoiceDetail?.vendorBusinessDetails?.panNumber : ""}
                                            </Typography>
                                            <Typography variant={"body2"} noWrap >
                                                {invoiceDetail?.vendorBusinessDetails?.fssi ? "FSSAI: " + invoiceDetail?.vendorBusinessDetails?.fssi : ""}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item
                                        xs={16} sm={8}
                                    >
                                        <Stack
                                            justifyContent={"flex-start"}
                                        >
                                            <Typography variant={"subtitle2"} noWrap gutterBottom>
                                                {"Invoice To"}
                                            </Typography>
                                            <Typography variant={"body2"} noWrap >
                                                {invoiceDetail?.customerName ?? ""}
                                            </Typography>
                                            <Typography variant={"body2"}  >
                                                {invoiceDetail?.customerBillingAddress?.pincode ? `${invoiceDetail?.customerBillingAddress?.landmark ? invoiceDetail?.customerBillingAddress?.landmark + ", " : ""}${invoiceDetail?.customerBillingAddress?.line1 ? invoiceDetail?.customerBillingAddress?.line1 + ", " : ""}${invoiceDetail?.customerBillingAddress?.line2 ? invoiceDetail?.customerBillingAddress?.line2 + ", " : ""}${invoiceDetail?.customerBillingAddress?.city ? invoiceDetail?.customerBillingAddress?.city + ", " : ""}${invoiceDetail?.customerBillingAddress?.state ? invoiceDetail?.customerBillingAddress?.state + ", " : ""}${invoiceDetail?.customerBillingAddress?.pincode ?? ""}` : ""}
                                            </Typography>
                                            <Typography variant={"body2"} noWrap >
                                                {invoiceDetail?.customerNumber ? "Phone: " + invoiceDetail?.customerNumber : ""}
                                            </Typography>
                                            <Typography variant={"body2"} noWrap >
                                                {invoiceDetail?.customerBusinessDetails?.gstUin ? "GST: " + invoiceDetail?.customerBusinessDetails?.gstUin : ""}
                                            </Typography>
                                            <Typography variant={"body2"} noWrap >
                                                {invoiceDetail?.customerBusinessDetails?.panNumber ? "PAN: " + invoiceDetail?.customerBusinessDetails?.panNumber : ""}
                                            </Typography>
                                            <Typography variant={"body2"} noWrap >
                                                {invoiceDetail?.customerBusinessDetails?.fssi ? "FSSI: " + invoiceDetail?.customerBusinessDetails?.fssi : ""}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container columns={16}>
                                    <Grid item
                                        xs={16} sm={8}
                                    >
                                        {invoiceDetail?.createdDate &&
                                            <Stack justifyContent={"flex-start"}>
                                                <Typography variant={"subtitle2"} noWrap>{"Date Created"}</Typography>
                                                <Typography variant={"body2"} noWrap >
                                                    {formatDateAndTime(invoiceDetail?.createdDate).formattedDateNew}
                                                </Typography>
                                            </Stack>
                                        }
                                    </Grid>
                                    <Grid item
                                        xs={16} sm={8}
                                    >
                                        {invoiceDetail?.dueDate &&
                                            <Stack justifyContent={"flex-start"}>
                                                <Typography variant={"subtitle2"} noWrap>{"Due Date"}</Typography>
                                                <Typography variant={"body2"} noWrap >
                                                    {formatDateAndTime(invoiceDetail?.dueDate).formattedDateNew}
                                                </Typography>
                                            </Stack>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div
                            style={{
                                height: '750px',
                            }}
                        >
                            { base64Invoice ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"><Viewer fileUrl={base64Invoice} /></Worker> : <ItemsLoading/>}
                        </div>
                    <Divider
                        orientation="horizontal"
                        sx={{
                            borderColor: "rgba(145, 158, 171, 0.2)",
                            borderStyle: "dashed",
                        }}
                    />
                    <Grid item padding={"24px 0px 0px"} >
                        <Stack
                            direction={{
                                xs: "column",
                                md: "row"
                            }}
                            justifyContent={"space-between"}
                            rowGap={4}
                        >
                            <Stack direction={"column"} >
                                {/* <Typography variant={"subtitle2"}  >
                                    {"NOTES"}
                                </Typography>
                                <Typography variant={"body2"}  >
                                    {"We appreciate your business. Should you need us to add VAT or extra notes let us know!"}
                                </Typography> */}
                            </Stack>
                            <Stack direction={"column"}>
                                {/* <Typography variant={"subtitle2"} noWrap sx={{
                                    textAlign: {
                                        xs: "start",
                                        md: "end"
                                    }
                                }}>
                                    {"Have a Question?"}
                                </Typography>
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    noWrap
                                >
                                    <Typography variant={"subtitle2"} noWrap >
                                        {"Contact us:"}
                                    </Typography>
                                    <Typography variant={"body2"} noWrap sx={{ textAlign: "end" }}>
                                        {invoiceDetail?.vendorNumber}
                                    </Typography> */}
                                {/* </Stack> */}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}