import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Stack, Grid } from '@mui/material';
import TextFiledWithPrefilledData from '../../components/common/TextFiledWithPrefilledData';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderListName } from '../../redux/slices/orderListSlice';
import { openMessage } from '../../redux/slices/showMessageSlice';
import { orderProcessing } from '../../assets/images';
import CustomButton from '../../components/common/CustomButton';


const CreateOrderListDialogue = ({ setOrderListDialogue, orderListDialogue, handleCreateOrderList, isOrderListLoading, isShowMessage, message }) => {

  const [editName, setEditName] = useState(false);
  const dispatch = useDispatch();

  const name = useSelector((state) => state.orderList.orderListName);

  const handelEditName = () => {
    if (name) {
      setEditName(true)
    } else {
      dispatch(openMessage({ message: 'Order list name required', messageSeverity: 'error' }))
    }
  }

  const handelSaveName = () => {
    // if(name){

    // }else{
    //   dispatch(openMessage({message:'Order list name required', messageSeverity: 'error'}))
    // }
    setEditName(false)
  }

  const handelCreateNewOrderList = () => {
    if (name) {
      handleCreateOrderList()
    } else {
      dispatch(openMessage({ message: 'Order list name required', messageSeverity: 'error' }))
    }
  }

  const handelCloseOrderListDialogue = () => {
    setOrderListDialogue(false);
  }



  const handleChange = (e) => {
    dispatch(setOrderListName(e.target.value));
  };


  return (
    <Dialog
      maxWidth={
        isShowMessage
          ? 'xs' : "sm"}
      scroll={"paper"}
      open={orderListDialogue}
      // open={true}
      onClose={handelCloseOrderListDialogue}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  //Set your width here
            padding: '1rem',
            minHeight: '300px'
          },
        },
      }}
    >
      {
        isShowMessage
          ?
          <Grid container={12}>
            <Grid item xs={12}>
              <Stack direction={'row'} mt='1rem' justifyContent={'center'} alignItems={'center'}>
                <img src={orderProcessing} style={{ width: '100px', height: '100px' }} />
              </Stack>
              <Stack direction={'row'} mt='1rem' justifyContent={'center'} alignItems={'center'}>
                {message}
              </Stack>
            </Grid>
          </Grid>
          :
          <>
            <DialogTitle>
              <TextFiledWithPrefilledData name={'name'} editName={editName} handelEditName={handelEditName}
                handelSaveName={handelSaveName} handleChange={handleChange} value={name} />
            </DialogTitle>
            <DialogContent sx={{ mt: '2rem' }}>
            </DialogContent>
            <Stack alignItems={'center'} >
            <CustomButton
              onClick={handelCreateNewOrderList}
              label={"Create New Order List"}
              sx={{ mt: '2rem', }}
              disabled={isOrderListLoading}
            />
            </Stack>
          </>
      }
    </Dialog>
  )
}

export default CreateOrderListDialogue;