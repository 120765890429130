import { Stack, Typography } from "@mui/material";
import React from "react";
import { OrderListHeader, OrderListRow } from "./OrderListComp";
import { TableColumns } from "../../order-list/TableColumns";
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";

const OrderListTable = ({
  items,
  handleQuantityChange,
  action,
  onDelete,
  title,
  prevOrders,
  disabled,
  handleIncrement,
  handleDecrement,
  handleRemove,
  maxHeight = "60vh",
}) => {
  const columns = TableColumns("orderList");

  const { isMobile } = useResponsiveStyles();

  return (
    <>
      <Typography variant="h6" mb={"1rem"}>
        {title}
      </Typography>
      {!isMobile && <OrderListHeader headLabel={columns} />}
      <Stack
        sx={{
          overflowY: "auto",
          maxHeight: maxHeight,
          "-webkit-overflow-scrolling": "touch",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {items?.map((item) => (
          <OrderListRow
            key={item.id}
            row={item}
            columns={columns}
            handleQuantityChange={handleQuantityChange}
            action={action}
            onDelete={onDelete}
            prevOrders={prevOrders}
            disabled={disabled}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            handleRemove={handleRemove}
          />
        ))}
      </Stack>
    </>
  );
};

export default OrderListTable;
