import { useEffect, useMemo, useState } from "react";
import SvgColor from "../../components/common/SvgColor";
import { getOrgData, getOrgType } from "../../utils/org";
import { useSelector } from "react-redux";
import { horecaApi } from "../../redux/api/api";

// ----------------------------------------------------------------------

const icon = (input) => {
  const isSvgString = typeof input === 'string' && input.trim().startsWith('<svg');

  return (
    <SvgColor
      src={isSvgString ? `data:image/svg+xml;base64,${btoa(input)}` : `/assets/icons/navbar/${input}.svg`}
      sx={{ width: 24, height: 24 }}
    />
  );
};



export const SidebarConfig1 = (cofigName) => {

  const user = useSelector((state) => state.auth.user);

  const currOrgId = useSelector((state) => state.app.preference?.selectedOrgId);

  const orgType = getOrgType(user, currOrgId);

  const [myRoles, setMyRoles] = useState([]);
  
  const loadRoles = async () => {
    try {
      const result = getOrgData(user, currOrgId)      
      if (result?.roleDetail) {
        setMyRoles(result?.roleDetail);
      }
    } catch (error) {
      // Handle error
    }
  };


  useEffect(() => {
    (async () => {
      await loadRoles();
    })();
  }, [currOrgId]);

  const sidebar = useMemo(() => {

    const checkPermission = (routeId) => {
      const result = getOrgData(user, currOrgId)
        if(result?.roleDetail[0]?.roleName === 'customer' || result?.roleDetail[0]?.roleName === 'system_admin' || result?.roleDetail[0]?.roleName === 'vendor'){
          return true;
        }else{
          return myRoles?.some((role) =>
            role?.permissions?.some((permission) => permission?.permissionName === routeId)
          ); 
        }
    };

    switch (orgType) {
      case "vendor":
        return [
          {
            id: "home",
            title: "home",
            path: "/",
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M240 212h-20v-85.66l9.17 9.17a4 4 0 1 0 5.66-5.66l-98.34-98.34a12 12 0 0 0-17 0l-98.32 98.34a4 4 0 1 0 5.66 5.66l9.17-9.17V212H16a4 4 0 0 0 0 8h224a4 4 0 0 0 0-8M44 118.34l81.17-81.17a4 4 0 0 1 5.66 0L212 118.34V212h-56v-60a4 4 0 0 0-4-4h-48a4 4 0 0 0-4 4v60H44ZM148 212h-40v-56h40Z"/></svg>'),
          },
          {
            id: "salesOrder",
            title: "Sales Order",
            path: `/org/${currOrgId}/sales-order`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M164 152a4 4 0 0 1-4 4H96a4 4 0 0 1 0-8h64a4 4 0 0 1 4 4m-4-36H96a4 4 0 0 0 0 8h64a4 4 0 0 0 0-8m52-68v168a12 12 0 0 1-12 12H56a12 12 0 0 1-12-12V48a12 12 0 0 1 12-12h38.08a44 44 0 0 1 67.84 0H200a12 12 0 0 1 12 12M92 64v4h72v-4a36 36 0 0 0-72 0m112-16a4 4 0 0 0-4-4h-32.83A43.7 43.7 0 0 1 172 64v8a4 4 0 0 1-4 4H88a4 4 0 0 1-4-4v-8a43.7 43.7 0 0 1 4.83-20H56a4 4 0 0 0-4 4v168a4 4 0 0 0 4 4h144a4 4 0 0 0 4-4Z"/></svg>'),
          },
          {
            id: "inventory",
            title: "inventory",
            path: `/org/${currOrgId}/inventory-items`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="m221.76 69.66l-88-48.18a12 12 0 0 0-11.52 0l-88 48.18A12 12 0 0 0 28 80.18v95.64a12 12 0 0 0 6.24 10.52l88 48.18a11.95 11.95 0 0 0 11.52 0l88-48.18a12 12 0 0 0 6.24-10.52V80.18a12 12 0 0 0-6.24-10.52M126.08 28.5a3.94 3.94 0 0 1 3.84 0L216.67 76L178.5 96.89a4 4 0 0 0-.58-.4l-88-48.18Zm1.92 96L39.33 76l42.23-23.13l88.67 48.54Zm-89.92 54.8a4 4 0 0 1-2.08-3.5V83.29l88 48.16v94.91Zm179.84 0l-85.92 47v-94.85l40-21.89V152a4 4 0 0 0 8 0v-46.82l40-21.89v92.53a4 4 0 0 1-2.08 3.5Z"/></svg>'),
          },
          {
            id: "myCustomers",
            title: "my customers",
            path: `/org/${currOrgId}/my-customers`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28m0 192a92 92 0 1 1 92-92a92.1 92.1 0 0 1-92 92m36-72a24 24 0 0 1-24 24h-8v12a4 4 0 0 1-8 0v-12h-20a4 4 0 0 1 0-8h36a16 16 0 0 0 0-32h-24a24 24 0 0 1 0-48h8V72a4 4 0 0 1 8 0v12h20a4 4 0 0 1 0 8h-36a16 16 0 0 0 0 32h24a24 24 0 0 1 24 24"/></svg>'),
          },
          {
            id: "outlets",
            title: "my outlets",
            path: `/org/${currOrgId}/my-outlets`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M84 56V24a4 4 0 0 1 8 0v32a4 4 0 0 1-8 0m36 4a4 4 0 0 0 4-4V24a4 4 0 0 0-8 0v32a4 4 0 0 0 4 4m32 0a4 4 0 0 0 4-4V24a4 4 0 0 0-8 0v32a4 4 0 0 0 4 4m92 60v8a36 36 0 0 1-36 36h-.41a92.53 92.53 0 0 1-35.76 48H208a4 4 0 0 1 0 8H32a4 4 0 0 1 0-8h36.17A92.34 92.34 0 0 1 28 136V88a4 4 0 0 1 4-4h176a36 36 0 0 1 36 36m-40 16V92H36v44a84.28 84.28 0 0 0 48.21 76h71.58A84.28 84.28 0 0 0 204 136m32-16a28 28 0 0 0-24-27.71V136a91.8 91.8 0 0 1-2.2 19.94A28 28 0 0 0 236 128Z"/></svg>'),
          },
          {
            id: "myTeam",
            title: "my team",
            path: `/org/${currOrgId}/my-team`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M236 112a52.06 52.06 0 0 0-52-52a53 53 0 0 0-7.77.59a52 52 0 0 0-96.46 0A53 53 0 0 0 72 60a52 52 0 0 0-20 100v48a12 12 0 0 0 12 12h128a12 12 0 0 0 12-12v-48a52.07 52.07 0 0 0 32-48m-40 96a4 4 0 0 1-4 4H64a4 4 0 0 1-4-4v-45.41A52 52 0 0 0 72 164h112a52 52 0 0 0 12-1.41Zm-12-52h-18.88l6.76-27a4 4 0 0 0-7.76-1.94l-7.24 29H132V128a4 4 0 0 0-8 0v28H99.12l-7.24-29a4 4 0 1 0-7.76 2l6.76 27H72a44 44 0 0 1 0-88a45 45 0 0 1 5.34.33A51.7 51.7 0 0 0 76 80a4 4 0 0 0 8 0a44 44 0 0 1 88 0a4 4 0 0 0 8 0a51.7 51.7 0 0 0-1.34-11.67A45 45 0 0 1 184 68a44 44 0 0 1 0 88"/></svg>'),
          },
          {
            id: "dashboard",
            title: "Dashboard",
            path: `/org/${currOrgId}/dashboard`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28m91.91 96h-32.06A60.07 60.07 0 0 0 132 68.15V36.09A92.13 92.13 0 0 1 219.91 124M124 124H76.17A52.05 52.05 0 0 1 124 76.17Zm0 8v47.83A52.05 52.05 0 0 1 76.17 132Zm8 0h47.83A52.05 52.05 0 0 1 132 179.83Zm0-8V76.17A52.05 52.05 0 0 1 179.83 124Zm-8-87.91v32.06A60.07 60.07 0 0 0 68.15 124H36.09A92.13 92.13 0 0 1 124 36.09M36.09 132h32.06A60.07 60.07 0 0 0 124 187.85v32.06A92.13 92.13 0 0 1 36.09 132M132 219.91v-32.06A60.07 60.07 0 0 0 187.85 132h32.06A92.13 92.13 0 0 1 132 219.91"/></svg>'),
          },
          {
            title: "settings",
            path: `/org/${currOrgId}/settings`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M128 84a44 44 0 1 0 44 44a44.05 44.05 0 0 0-44-44m0 80a36 36 0 1 1 36-36a36 36 0 0 1-36 36m83.93-32.49q.13-3.51 0-7l15.83-19.79a4 4 0 0 0 .75-3.53A103.6 103.6 0 0 0 218 75.9a4 4 0 0 0-3-2l-25.19-2.8a89 89 0 0 0-4.95-4.95L182.07 41a4 4 0 0 0-2-3a104 104 0 0 0-25.25-10.5a4 4 0 0 0-3.53.74l-19.78 15.83q-3.51-.14-7 0L104.7 28.24a4 4 0 0 0-3.53-.75A103.6 103.6 0 0 0 75.9 38a4 4 0 0 0-2 3l-2.8 25.19a89 89 0 0 0-4.95 4.95L41 73.93a4 4 0 0 0-3 2a104 104 0 0 0-10.5 25.25a4 4 0 0 0 .74 3.53l15.83 19.78q-.14 3.51 0 7L28.24 151.3a4 4 0 0 0-.75 3.53A103.6 103.6 0 0 0 38 180.1a4 4 0 0 0 3 2l25.19 2.8c1.58 1.71 3.24 3.37 4.95 4.95l2.8 25.2a4 4 0 0 0 2 3a104 104 0 0 0 25.28 10.46a4 4 0 0 0 3.53-.74l19.78-15.83q3.51.13 7 0l19.79 15.83a4 4 0 0 0 2.5.88a4 4 0 0 0 1-.13A103.6 103.6 0 0 0 180.1 218a4 4 0 0 0 2-3l2.8-25.19c1.71-1.58 3.37-3.24 4.95-4.95l25.2-2.8a4 4 0 0 0 3-2a104 104 0 0 0 10.46-25.28a4 4 0 0 0-.74-3.53Zm.17 42.83l-24.67 2.74a4 4 0 0 0-2.55 1.32a76 76 0 0 1-6.48 6.48a4 4 0 0 0-1.32 2.55l-2.74 24.66a95.5 95.5 0 0 1-19.64 8.15l-19.38-15.51a4 4 0 0 0-2.5-.87h-.24a74 74 0 0 1-9.16 0a4 4 0 0 0-2.74.87l-19.37 15.5a95.3 95.3 0 0 1-19.65-8.13l-2.74-24.67a4 4 0 0 0-1.32-2.55a76 76 0 0 1-6.48-6.48a4 4 0 0 0-2.55-1.32l-24.66-2.74a95.5 95.5 0 0 1-8.15-19.64l15.51-19.38a4 4 0 0 0 .87-2.74a78 78 0 0 1 0-9.16a4 4 0 0 0-.87-2.74l-15.5-19.37a95.3 95.3 0 0 1 8.13-19.65l24.67-2.74a4 4 0 0 0 2.55-1.32a76 76 0 0 1 6.48-6.48a4 4 0 0 0 1.32-2.55l2.74-24.66a95.5 95.5 0 0 1 19.64-8.15l19.38 15.51a4 4 0 0 0 2.74.87a74 74 0 0 1 9.16 0a4 4 0 0 0 2.74-.87l19.37-15.5a95.3 95.3 0 0 1 19.65 8.13l2.74 24.67a4 4 0 0 0 1.32 2.55a76 76 0 0 1 6.48 6.48a4 4 0 0 0 2.55 1.32l24.66 2.74a95.5 95.5 0 0 1 8.15 19.64l-15.51 19.38a4 4 0 0 0-.87 2.74a78 78 0 0 1 0 9.16a4 4 0 0 0 .87 2.74l15.5 19.37a95.3 95.3 0 0 1-8.13 19.65"/></svg>'),
          },
        ].filter((route) => checkPermission(route.id));
      case "customer":
        return [
          {
            id: "home",
            title: "home",
            path: "/",
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M240 212h-20v-85.66l9.17 9.17a4 4 0 1 0 5.66-5.66l-98.34-98.34a12 12 0 0 0-17 0l-98.32 98.34a4 4 0 1 0 5.66 5.66l9.17-9.17V212H16a4 4 0 0 0 0 8h224a4 4 0 0 0 0-8M44 118.34l81.17-81.17a4 4 0 0 1 5.66 0L212 118.34V212h-56v-60a4 4 0 0 0-4-4h-48a4 4 0 0 0-4 4v60H44ZM148 212h-40v-56h40Z"/></svg>'),
            children: [
              { id: "explore", title: "explore", path: "/explore" },
              { id: "items", title: "items", path: "/order-list/create" },
            ],
          },
          {
            id: "orderList",
            title: "order list",
            path: `/org/${currOrgId}/orders`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M36 64a4 4 0 0 1 4-4h176a4 4 0 0 1 0 8H40a4 4 0 0 1-4-4m4 68h64a4 4 0 0 0 0-8H40a4 4 0 0 0 0 8m80 56H40a4 4 0 0 0 0 8h80a4 4 0 0 0 0-8m124-44c0 14.51-8.56 29-25.44 43.07a143.5 143.5 0 0 1-24.77 16.51a4 4 0 0 1-3.58 0a143.5 143.5 0 0 1-24.77-16.51C148.56 173 140 158.51 140 144a28 28 0 0 1 52-14.41A28 28 0 0 1 244 144m-8 0a20 20 0 0 0-40 0a4 4 0 0 1-8 0a20 20 0 0 0-40 0c0 25.8 35.54 46.83 44 51.47c8.46-4.64 44-25.67 44-51.47"/></svg>'),
          },
          {
            id: "orderHistory",
            title: "order History",
            path: `/org/${currOrgId}/order-history`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M208 36h-28V24a4 4 0 0 0-8 0v12H84V24a4 4 0 0 0-8 0v12H48a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12M48 44h28v12a4 4 0 0 0 8 0V44h88v12a4 4 0 0 0 8 0V44h28a4 4 0 0 1 4 4v36H44V48a4 4 0 0 1 4-4m160 168H48a4 4 0 0 1-4-4V92h168v116a4 4 0 0 1-4 4m-41.17-86.83a4 4 0 0 1 0 5.66l-48 48a4 4 0 0 1-5.66 0l-24-24a4 4 0 0 1 5.66-5.66L116 170.34l45.17-45.17a4 4 0 0 1 5.66 0"/></svg>'),
          },
          {
            id: "invoices",
            title: "invoices",
            path: `/org/${currOrgId}/invoice`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M100 104a4 4 0 0 1 4-4h64a4 4 0 0 1 0 8h-64a4 4 0 0 1-4-4m4 36h64a4 4 0 0 0 0-8h-64a4 4 0 0 0 0 8m124 52a28 28 0 0 1-28 28H88a28 28 0 0 1-28-28V64a20 20 0 0 0-40 0c0 7.78 6.34 12.75 6.4 12.8a4 4 0 1 1-4.8 6.4c-.39-.29-9.6-7.34-9.6-19.2a28 28 0 0 1 28-28h136a28 28 0 0 1 28 28v108h12a4 4 0 0 1 2.4.8c.39.29 9.6 7.34 9.6 19.2m-120 0c0-7.78-6.34-12.75-6.4-12.8a4 4 0 0 1 2.4-7.2h92V64a20 20 0 0 0-20-20H59.57A27.9 27.9 0 0 1 68 64v128a20 20 0 0 0 40 0m112 0c0-6-3.74-10.3-5.5-12H112.61a23.3 23.3 0 0 1 3.39 12a27.94 27.94 0 0 1-8.42 20H200a20 20 0 0 0 20-20"/></svg>'),
          },
          {
            id: "myVendors",
            title: "my vendors",
            path: `/org/${currOrgId}/my-vendors`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M184 36a4 4 0 0 0-1.15.17l-160 48A4 4 0 0 0 20 88v24a4 4 0 0 0 4 4h8a20.11 20.11 0 0 1 20 19.61a19.6 19.6 0 0 1-5.72 14.14A20.92 20.92 0 0 1 31.46 156H24a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h200a12 12 0 0 0 12-12V88a52.06 52.06 0 0 0-52-52m.57 8a44.06 44.06 0 0 1 43.24 40H51.25ZM196 104a28 28 0 1 1-53.29-12h50.58a28 28 0 0 1 2.71 12m-56 84H84v-4a28 28 0 0 1 56 0Zm88-4a4 4 0 0 1-4 4h-76v-4a36 36 0 0 0-72 0v4H28v-24h3.46A29 29 0 0 0 52 155.35a27.52 27.52 0 0 0 8-19.89A28.14 28.14 0 0 0 32 108h-4V92h106.07a36 36 0 1 0 67.86 0H228Z"/></svg>'),
          },
          {
            id: "outlets",
            title: "my outlets",
            path: `/org/${currOrgId}/my-outlets`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M84 56V24a4 4 0 0 1 8 0v32a4 4 0 0 1-8 0m36 4a4 4 0 0 0 4-4V24a4 4 0 0 0-8 0v32a4 4 0 0 0 4 4m32 0a4 4 0 0 0 4-4V24a4 4 0 0 0-8 0v32a4 4 0 0 0 4 4m92 60v8a36 36 0 0 1-36 36h-.41a92.53 92.53 0 0 1-35.76 48H208a4 4 0 0 1 0 8H32a4 4 0 0 1 0-8h36.17A92.34 92.34 0 0 1 28 136V88a4 4 0 0 1 4-4h176a36 36 0 0 1 36 36m-40 16V92H36v44a84.28 84.28 0 0 0 48.21 76h71.58A84.28 84.28 0 0 0 204 136m32-16a28 28 0 0 0-24-27.71V136a91.8 91.8 0 0 1-2.2 19.94A28 28 0 0 0 236 128Z"/></svg>'),
          },
          {
            id: "myTeam",
            title: "my team",
            path: `/org/${currOrgId}/my-team`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M236 112a52.06 52.06 0 0 0-52-52a53 53 0 0 0-7.77.59a52 52 0 0 0-96.46 0A53 53 0 0 0 72 60a52 52 0 0 0-20 100v48a12 12 0 0 0 12 12h128a12 12 0 0 0 12-12v-48a52.07 52.07 0 0 0 32-48m-40 96a4 4 0 0 1-4 4H64a4 4 0 0 1-4-4v-45.41A52 52 0 0 0 72 164h112a52 52 0 0 0 12-1.41Zm-12-52h-18.88l6.76-27a4 4 0 0 0-7.76-1.94l-7.24 29H132V128a4 4 0 0 0-8 0v28H99.12l-7.24-29a4 4 0 1 0-7.76 2l6.76 27H72a44 44 0 0 1 0-88a45 45 0 0 1 5.34.33A51.7 51.7 0 0 0 76 80a4 4 0 0 0 8 0a44 44 0 0 1 88 0a4 4 0 0 0 8 0a51.7 51.7 0 0 0-1.34-11.67A45 45 0 0 1 184 68a44 44 0 0 1 0 88"/></svg>'),
          },
          {
            id: "dashboard",
            title: "Dashboard",
            path: `/org/${currOrgId}/dashboard`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28m91.91 96h-32.06A60.07 60.07 0 0 0 132 68.15V36.09A92.13 92.13 0 0 1 219.91 124M124 124H76.17A52.05 52.05 0 0 1 124 76.17Zm0 8v47.83A52.05 52.05 0 0 1 76.17 132Zm8 0h47.83A52.05 52.05 0 0 1 132 179.83Zm0-8V76.17A52.05 52.05 0 0 1 179.83 124Zm-8-87.91v32.06A60.07 60.07 0 0 0 68.15 124H36.09A92.13 92.13 0 0 1 124 36.09M36.09 132h32.06A60.07 60.07 0 0 0 124 187.85v32.06A92.13 92.13 0 0 1 36.09 132M132 219.91v-32.06A60.07 60.07 0 0 0 187.85 132h32.06A92.13 92.13 0 0 1 132 219.91"/></svg>'),
          },
          // {
          //   id: "disco",
          //   title: "Discco",
          //   path: ``,
          //   icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M116 68.1V16a4 4 0 0 0-8 0v52.1a84 84 0 1 0 8 0m71.89 79.9h-40c-1-37.34-15.73-60.22-25.35-71.25A76.12 76.12 0 0 1 187.89 148M112 226.68C105 220 85.26 197.61 84.06 156h55.88c-1.2 41.61-20.94 64-27.94 70.68M84.06 148c1.2-41.61 21-64 27.94-70.68c7 6.64 26.74 29.07 27.94 70.68Zm17.36-71.25c-9.62 11-24.35 33.91-25.35 71.25h-40a76.12 76.12 0 0 1 65.35-71.25M36.11 156h40c1 37.34 15.73 60.22 25.35 71.25A76.12 76.12 0 0 1 36.11 156m86.47 71.25c9.62-11 24.35-33.91 25.35-71.25h40a76.12 76.12 0 0 1-65.35 71.25M252 88a4 4 0 0 1-4 4h-12v12a4 4 0 0 1-8 0V92h-12a4 4 0 0 1 0-8h12V72a4 4 0 0 1 8 0v12h12a4 4 0 0 1 4 4m-40-48a4 4 0 0 1-4 4h-20v20a4 4 0 0 1-8 0V44h-20a4 4 0 0 1 0-8h20V16a4 4 0 0 1 8 0v20h20a4 4 0 0 1 4 4"/></svg>'),
          // },
          {
            id:'settings',
            title: "settings",
            path: `/org/${currOrgId}/settings`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M128 84a44 44 0 1 0 44 44a44.05 44.05 0 0 0-44-44m0 80a36 36 0 1 1 36-36a36 36 0 0 1-36 36m83.93-32.49q.13-3.51 0-7l15.83-19.79a4 4 0 0 0 .75-3.53A103.6 103.6 0 0 0 218 75.9a4 4 0 0 0-3-2l-25.19-2.8a89 89 0 0 0-4.95-4.95L182.07 41a4 4 0 0 0-2-3a104 104 0 0 0-25.25-10.5a4 4 0 0 0-3.53.74l-19.78 15.83q-3.51-.14-7 0L104.7 28.24a4 4 0 0 0-3.53-.75A103.6 103.6 0 0 0 75.9 38a4 4 0 0 0-2 3l-2.8 25.19a89 89 0 0 0-4.95 4.95L41 73.93a4 4 0 0 0-3 2a104 104 0 0 0-10.5 25.25a4 4 0 0 0 .74 3.53l15.83 19.78q-.14 3.51 0 7L28.24 151.3a4 4 0 0 0-.75 3.53A103.6 103.6 0 0 0 38 180.1a4 4 0 0 0 3 2l25.19 2.8c1.58 1.71 3.24 3.37 4.95 4.95l2.8 25.2a4 4 0 0 0 2 3a104 104 0 0 0 25.28 10.46a4 4 0 0 0 3.53-.74l19.78-15.83q3.51.13 7 0l19.79 15.83a4 4 0 0 0 2.5.88a4 4 0 0 0 1-.13A103.6 103.6 0 0 0 180.1 218a4 4 0 0 0 2-3l2.8-25.19c1.71-1.58 3.37-3.24 4.95-4.95l25.2-2.8a4 4 0 0 0 3-2a104 104 0 0 0 10.46-25.28a4 4 0 0 0-.74-3.53Zm.17 42.83l-24.67 2.74a4 4 0 0 0-2.55 1.32a76 76 0 0 1-6.48 6.48a4 4 0 0 0-1.32 2.55l-2.74 24.66a95.5 95.5 0 0 1-19.64 8.15l-19.38-15.51a4 4 0 0 0-2.5-.87h-.24a74 74 0 0 1-9.16 0a4 4 0 0 0-2.74.87l-19.37 15.5a95.3 95.3 0 0 1-19.65-8.13l-2.74-24.67a4 4 0 0 0-1.32-2.55a76 76 0 0 1-6.48-6.48a4 4 0 0 0-2.55-1.32l-24.66-2.74a95.5 95.5 0 0 1-8.15-19.64l15.51-19.38a4 4 0 0 0 .87-2.74a78 78 0 0 1 0-9.16a4 4 0 0 0-.87-2.74l-15.5-19.37a95.3 95.3 0 0 1 8.13-19.65l24.67-2.74a4 4 0 0 0 2.55-1.32a76 76 0 0 1 6.48-6.48a4 4 0 0 0 1.32-2.55l2.74-24.66a95.5 95.5 0 0 1 19.64-8.15l19.38 15.51a4 4 0 0 0 2.74.87a74 74 0 0 1 9.16 0a4 4 0 0 0 2.74-.87l19.37-15.5a95.3 95.3 0 0 1 19.65 8.13l2.74 24.67a4 4 0 0 0 1.32 2.55a76 76 0 0 1 6.48 6.48a4 4 0 0 0 2.55 1.32l24.66 2.74a95.5 95.5 0 0 1 8.15 19.64l-15.51 19.38a4 4 0 0 0-.87 2.74a78 78 0 0 1 0 9.16a4 4 0 0 0 .87 2.74l15.5 19.37a95.3 95.3 0 0 1-8.13 19.65"/></svg>'),
          },
        ].filter((route) => checkPermission(route.id));
      case "system_admin":
        return [
          {
            id: "home",
            title: "home",
            path: "/",
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M240 212h-20v-85.66l9.17 9.17a4 4 0 1 0 5.66-5.66l-98.34-98.34a12 12 0 0 0-17 0l-98.32 98.34a4 4 0 1 0 5.66 5.66l9.17-9.17V212H16a4 4 0 0 0 0 8h224a4 4 0 0 0 0-8M44 118.34l81.17-81.17a4 4 0 0 1 5.66 0L212 118.34V212h-56v-60a4 4 0 0 0-4-4h-48a4 4 0 0 0-4 4v60H44ZM148 212h-40v-56h40Z"/></svg>'),
          },
          {
            id: "vendors",
            title: "vendors",
            path: `/org/${currOrgId}/vendors`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M184 36a4 4 0 0 0-1.15.17l-160 48A4 4 0 0 0 20 88v24a4 4 0 0 0 4 4h8a20.11 20.11 0 0 1 20 19.61a19.6 19.6 0 0 1-5.72 14.14A20.92 20.92 0 0 1 31.46 156H24a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h200a12 12 0 0 0 12-12V88a52.06 52.06 0 0 0-52-52m.57 8a44.06 44.06 0 0 1 43.24 40H51.25ZM196 104a28 28 0 1 1-53.29-12h50.58a28 28 0 0 1 2.71 12m-56 84H84v-4a28 28 0 0 1 56 0Zm88-4a4 4 0 0 1-4 4h-76v-4a36 36 0 0 0-72 0v4H28v-24h3.46A29 29 0 0 0 52 155.35a27.52 27.52 0 0 0 8-19.89A28.14 28.14 0 0 0 32 108h-4V92h106.07a36 36 0 1 0 67.86 0H228Z"/></svg>'),
          },
          {
            id: "customers",
            title: "customers",
            path: `/org/${currOrgId}/customers`,
            icon: icon("ic_customer"),
          },
          {
            id: "category",
            title: "category",
            path: `/org/${currOrgId}/category`,
            icon: icon("ic_categroy"),
          },
          {
            id: 'settings',
            title: "settings",
            path: `/org/${currOrgId}/settings`,
            icon: icon('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M128 84a44 44 0 1 0 44 44a44.05 44.05 0 0 0-44-44m0 80a36 36 0 1 1 36-36a36 36 0 0 1-36 36m83.93-32.49q.13-3.51 0-7l15.83-19.79a4 4 0 0 0 .75-3.53A103.6 103.6 0 0 0 218 75.9a4 4 0 0 0-3-2l-25.19-2.8a89 89 0 0 0-4.95-4.95L182.07 41a4 4 0 0 0-2-3a104 104 0 0 0-25.25-10.5a4 4 0 0 0-3.53.74l-19.78 15.83q-3.51-.14-7 0L104.7 28.24a4 4 0 0 0-3.53-.75A103.6 103.6 0 0 0 75.9 38a4 4 0 0 0-2 3l-2.8 25.19a89 89 0 0 0-4.95 4.95L41 73.93a4 4 0 0 0-3 2a104 104 0 0 0-10.5 25.25a4 4 0 0 0 .74 3.53l15.83 19.78q-.14 3.51 0 7L28.24 151.3a4 4 0 0 0-.75 3.53A103.6 103.6 0 0 0 38 180.1a4 4 0 0 0 3 2l25.19 2.8c1.58 1.71 3.24 3.37 4.95 4.95l2.8 25.2a4 4 0 0 0 2 3a104 104 0 0 0 25.28 10.46a4 4 0 0 0 3.53-.74l19.78-15.83q3.51.13 7 0l19.79 15.83a4 4 0 0 0 2.5.88a4 4 0 0 0 1-.13A103.6 103.6 0 0 0 180.1 218a4 4 0 0 0 2-3l2.8-25.19c1.71-1.58 3.37-3.24 4.95-4.95l25.2-2.8a4 4 0 0 0 3-2a104 104 0 0 0 10.46-25.28a4 4 0 0 0-.74-3.53Zm.17 42.83l-24.67 2.74a4 4 0 0 0-2.55 1.32a76 76 0 0 1-6.48 6.48a4 4 0 0 0-1.32 2.55l-2.74 24.66a95.5 95.5 0 0 1-19.64 8.15l-19.38-15.51a4 4 0 0 0-2.5-.87h-.24a74 74 0 0 1-9.16 0a4 4 0 0 0-2.74.87l-19.37 15.5a95.3 95.3 0 0 1-19.65-8.13l-2.74-24.67a4 4 0 0 0-1.32-2.55a76 76 0 0 1-6.48-6.48a4 4 0 0 0-2.55-1.32l-24.66-2.74a95.5 95.5 0 0 1-8.15-19.64l15.51-19.38a4 4 0 0 0 .87-2.74a78 78 0 0 1 0-9.16a4 4 0 0 0-.87-2.74l-15.5-19.37a95.3 95.3 0 0 1 8.13-19.65l24.67-2.74a4 4 0 0 0 2.55-1.32a76 76 0 0 1 6.48-6.48a4 4 0 0 0 1.32-2.55l2.74-24.66a95.5 95.5 0 0 1 19.64-8.15l19.38 15.51a4 4 0 0 0 2.74.87a74 74 0 0 1 9.16 0a4 4 0 0 0 2.74-.87l19.37-15.5a95.3 95.3 0 0 1 19.65 8.13l2.74 24.67a4 4 0 0 0 1.32 2.55a76 76 0 0 1 6.48 6.48a4 4 0 0 0 2.55 1.32l24.66 2.74a95.5 95.5 0 0 1 8.15 19.64l-15.51 19.38a4 4 0 0 0-.87 2.74a78 78 0 0 1 0 9.16a4 4 0 0 0 .87 2.74l15.5 19.37a95.3 95.3 0 0 1-8.13 19.65"/></svg>'),
          },
        ];
      default:
        return [];
    }
  }, [orgType, myRoles]);

  return sidebar;
};