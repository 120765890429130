import { Container } from '@mui/material'
import React from 'react'
import SalesOrders from '.'

const SalesOrder = () => {
  return (
    <Container maxWidth='xl'>
        <SalesOrders/>
    </Container>
  )
}

export default SalesOrder