
export const highlightText = (text, query, theme) => {
    const parts = text?.split(new RegExp(`(${query})`, "gi"));
    return parts?.map((part, index) => (
      <span
        key={index}
        style={
          part?.toLowerCase() === query?.toLowerCase()
            ? { fontWeight: "bold", color: theme.palette.warning.main }
            : {}
        }
      >
        {part}
      </span>
    ));
  };