import React from "react";
import { Stack, Typography, Link, useTheme } from "@mui/material";
import { useRouter } from "../../../routes/hooks/useRouter";
import { useFormik } from "formik";
import CustomTextField from "../../../components/common/CustomTextField";
import * as Yup from "yup";
import { useState } from "react";
import { useGetRolesQuery, useSignupMutation } from "../../../redux/api/api";
import { useDispatch } from "react-redux";
import { openMessage } from "../../../redux/slices/showMessageSlice";
import ThankYouDialogue from "./ThankYouDialogue";
import CustomButton from "../../../components/common/CustomButton";
import CustomMuiTelInput from "../../../components/common/CustomMuiTelInput";
import { validatePhoneNumber } from "./LoginView";
import ChooseRolesDialogue from "./ChooseRolesDialogue";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, "First name must contain only characters")
    .required("First name is required")
    .min(2, "Minimum 2 characters are required")
    .max(50, "Maximum 50 characters are allowed"),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Last name must contain only characters")
    .required("Last name is required")
    .min(2, "Minimum 2 characters are required")
    .max(50, "Maximum 50 characters are allowed"),
  orgName: Yup.string()
    .required("Organization name is required")
    .min(2, "Minimum 2 characters are required")
    .max(500, "Maximum 500 characters are allowed"),
  // mobileNumber: Yup.string()
  //   .required('Required')
  mobileNumber: Yup.string().test('isValidPhoneNumber', 'Invalid phone number', function (value) {
    const { country } = this.parent;
    return !validatePhoneNumber(value, country);
  }),

    // .transform(value => value.replace(/\s/g, ""))
    // .matches(/^\+(?:[0-9] ?){11}[0-9]$/, 'Mobile number must contain 10 digits only'),
});

const SingupView = () => {
  const router = useRouter();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [signup] = useSignupMutation();
  const { data: rolesData } = useGetRolesQuery();

  const [signUpDialogue, setSignUpDialogue] = useState(true);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [currRole, setCurrRole] = useState();
  const [currId, setCurrId] = useState();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      orgName: "",
      mobileNumber: "",
      role: currId,
      type: currRole,
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {

      values.mobileNumber = values.mobileNumber.replace(/\s/g, "");
      const result = await signup(values);

      if (result.data) {
        dispatch(openMessage({ message: "User Onboarded, Login and fill further details", messageSeverity: "success" }));
        setOpenThankYou(true);
      } else if (result.error) {
        dispatch(
          openMessage({
            message: result.error.data.message,
            messageSeverity: "error",
          })
        );
      } else {
        dispatch(openMessage({ message: 'Not able to signup at this moment', messageSeverity: "error" }));
      }
    },
  });

  const handleChange1 = (value) => {
    formik.setFieldValue("mobileNumber", value);
  };

  const handelSetRole = (id, role) => {
    setCurrRole(role)
    setCurrId(id)
    setSignUpDialogue(false);
  }



  return (
    <>
      <Stack spacing={3} minWidth={"100%"}>
        <Typography variant="h4">Signup As <span style={{ textTransform: 'capitalize', color: theme.palette.primary.main }}>{formik.values.type}</span></Typography>
        <Stack display={"flex"} flexDirection={"row"} gap={"4px"}>
          <Typography variant="body2" sx={{ mb: 5 }}>
            Already have an account?
            <Link
              variant="subtitle2"
              sx={{ ml: 0.5, cursor: "pointer" }}
              underline="hover"
              onClick={() => router.push("/")}
            >
              Sign in
            </Link>
          </Typography>
        </Stack>
        <Stack gap={"1rem"} width={"100%"}>
          <Stack direction={"row"} spacing={2}>
            <CustomTextField
              name={"firstName"}
              label={"First Name"}
              formik={formik}
            />
            <CustomTextField
              name={"lastName"}
              label={"Last Name"}
              formik={formik}
            />
          </Stack>

          <CustomTextField
            name={"orgName"}
            label={"Organization"}
            formik={formik}
          />
          {/* <MuiTelInput
            defaultCountry="IN"
            onChange={(e) => formik.setFieldValue('mobileNumber', e)}
            value={getIn(formik.values, "mobileNumber")}
            forceCallingCode
            name={"mobileNumber"}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, "mobileNumber") &&
              Boolean(getIn(formik.errors, "mobileNumber"))
            }
            helperText={
              getIn(formik.touched, "mobileNumber") &&
              getIn(formik.errors, "mobileNumber")
            }
          /> */}
          <CustomMuiTelInput
          name={'mobileNumber'}
          formik={formik}
          label={'Mobile Number'}
          />
        </Stack>
        <CustomButton
          onClick={formik.handleSubmit}
          variant="contained"
          width={'100%'}
          label={"Create Account"}
        />
      </Stack>
      <ChooseRolesDialogue
        handelSetRole={handelSetRole}
        open={signUpDialogue}
        rolesData={rolesData}
      />
      <ThankYouDialogue
        open={openThankYou}
      />
    </>
  );
};

export default SingupView;
