import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  IconButton,
  useTheme,
  Stack,
  Drawer,
  alpha,
  ListItemButton,
  Typography,
} from "@mui/material";
import { usePathname } from "../../routes/hooks/usePathname";
import RouterLink from "../../routes/components/RouterLink";
import { useResponsive } from "../../hooks/useResponsive";
import Logo from "../../components/common/Logo";
import { NAV } from "../common/configLayout";
import Iconify from "../../components/common/Iconify";
import { setSideOen } from "../../redux/slices/commonSlice";
import { bgGradient } from "../../theme/css";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { NavLink } from "react-router-dom";
import { horecaLogo } from "../../assets/images";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import AccountPopover from "../common/AccountPopover";
// ----------------------------------------------------------------------

const Nav = ({ openNav, onCloseNav, navConfig }) => {
  const { isMobile } = useResponsiveStyles();
  const pathname = usePathname();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isSideOpen = useSelector((state) => state.common.isSideOpen);

  const upLg = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);




  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ paddingTop: isMobile? 4:6 }}>
      {navConfig?.map((item) => (
        <NavItem key={item.title} item={item} isSideOpen={isSideOpen} />
      ))}
    </Stack>
  );


  const renderContent = (
    // <Scrollbar
    //   sx={{
    //     height: 1,
    //     "& .simplebar-content": {
    //       height: 1,
    //       display: "flex",
    //       flexDirection: "column",
    //       maxHeight: "100vh",
    //     },
    //   }}
    // >
    <>
      {/* {isSideOpen && <Logo sx={{ mt: 3, ml: 4 }} />} */}
      {/* <Logo sx={{ mt: 3, ml: 4 }} /> */}
      {
        !upLg ?
          <Box sx={{ display: 'flex', width: '100%', minHeight: 70, flexDirection:'column', alignItems: 'center', padding: isSideOpen ? 2 : 1, paddingTop: 2, paddingBottom: 0 }}>
            {isSideOpen && <Box component={NavLink} to="/" >
              <Box component="img" src={horecaLogo} alt="Brand" width="120px" />
            </Box>}
            <AccountPopover/>
          </Box> :
          <Box sx={{ display: 'flex', width: '100%', minHeight: 70, justifyContent: isSideOpen ? 'left' : 'center', padding: isSideOpen ? 2 : 1, paddingLeft: isSideOpen ? 4 : 1, paddingTop: 2, paddingBottom: 0 }}>
            {isSideOpen && <Box component={NavLink} to="/" >
              <Box component="img" src={horecaLogo} alt="Brand" width="120px" />
            </Box>}
          </Box>
      }
      {renderMenu}
      {/* {renderMenu1} */}

      <Box sx={{ flexGrow: 1 }} /></>
  );

  const handelSideOpen = () => {
    dispatch(setSideOen(!isSideOpen));
  };


  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: isSideOpen ? NAV.WIDTH : 90 },
        transition: "width 0.5s",
        backgroundColor: '#F5F6FC',
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.96),
        }),
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            //paddingTop: !isSideOpen && "4rem",
            position: "fixed",
            width: isSideOpen ? NAV.WIDTH : 90,
            transition: "width 0.5s",
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          <IconButton sx={{
            padding: 0, height: 28, width: 28, position: 'absolute', background: '#FFF', right: -14, top: 80, border: '1px solid #DBDBDB', borderRadius: 2, zIndex: 9999
          }} onClick={handelSideOpen}>
            {!isSideOpen ? <ChevronRightIcon fontSize="small" sx={{ color: '#000' }} /> : <ChevronLeftIcon fontSize="small" sx={{ color: '#000' }} />}
          </IconButton>
          {/* <IconButton
            sx={{
              zIndex: 100,
              position: "absolute",
              top: "15%", // Position at the top of the Box
              left: "100%", // Center horizontally
              transform: "translateX(-50%)", // Center horizontally
              borderRadius: "50%", // Make it circular
              borderStyle: "solid", // Set the border style to dashed
              borderWidth: "1px", // Set border width
              borderColor: (theme) => theme.palette.divider,
              backgroundColor: theme.palette.background.paper,
            }}
            onClick={handelSideOpen}
            size="small"
          >
            {isSideOpen ? (
              <Iconify icon="material-symbols-light:navigate-before" />
            ) : (
              <Iconify icon="material-symbols-light:navigate-next" />
            )}
          </IconButton> */}
          {renderContent}
        </Box>
      ) : (
        <>
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            PaperProps={{
              sx: {
                width: NAV.WIDTH,
              },
            }}
          >
            {renderContent}
          </Drawer>
        </>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default Nav;




const NavItem = ({ item, level, isSideOpen }) => {

  const pathname = usePathname();
  const [open, setOpen] = useState(false);
  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for the popover

  // const isActive = (path) => {
  //   return pathname === path || pathname.startsWith(path + "/");
  // };

  const isActive = (path) => {
    const trimmedPath = pathname
    return trimmedPath === path || trimmedPath.startsWith(path + "/");
  };

  const active = isActive(item.path) || (item.children && item.children.some((child) => isActive(child.path)));

  const handleToggle = (event) => {
    if (!isSideOpen) {
      setOpen(!open);
      setAnchorEl(event.currentTarget); // Set anchor element for the popover
    } else {
      setOpen(!open);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    !isSideOpen && setOpen(false)
  }, [isSideOpen])

  return (
    <>
      {isSideOpen && <ListItemButton
        component={RouterLink}
        to={`${item.path}`}
        // to={`org/${orgId}${item.path}`}
        sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: "body2",
          color: "text.secondary",
          display: !isSideOpen && "flex",
          justifyContent: !isSideOpen && "center",
          textTransform: "capitalize",
          fontWeight: "fontWeightMedium",
          ...(active && {
            color: "primary.main",
            fontWeight: "fontWeightSemiBold",
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            "&:hover": {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
            },
          }),
        }}
        onClick={handleToggle}
      >
        <Stack
          display={"flex"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Stack direction={'row'}>
            <Box
              component="span"
              sx={{
                width: isSideOpen ? 24 : 50,
                height: isSideOpen ? 24 : 40,
                mr: isSideOpen && 2,
              }}
            >
              {item.icon}
              {!isSideOpen && (
                <Typography sx={{ fontSize: '10px' }} noWrap>{item.title}</Typography>
              )}
            </Box>
            {isSideOpen && <Box component="span">{item.title}</Box>}
          </Stack>

          {/* {item.children && (
            <Box component="span" alignSelf={"flex-end"}>
              {open ? <ExpandMore /> : <ChevronRight />}
            </Box>
          )} */}
        </Stack>
      </ListItemButton>}
      {
        !isSideOpen &&
        <ListItemButton
          component={RouterLink}
          to={`${item.path}`}
          // to={`org/${orgId}${item.path}`}
          sx={{
            minHeight: 44,
            borderRadius: 0.75,
            typography: "body2",
            color: "text.secondary",
            display: !isSideOpen && "flex",
            justifyContent: !isSideOpen && "center",
            textTransform: "capitalize",
            fontWeight: "fontWeightMedium",
            ...(active && {
              color: "primary.main",
              fontWeight: "fontWeightSemiBold",
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
              },
            }),
          }}
          onClick={handleToggle}
        >
          <Stack
            display={"flex"}
            direction={"column"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {item.icon}
            <Typography sx={{ fontSize: '10px' }} noWrap>{item.title}</Typography>
          </Stack>
        </ListItemButton>
      }

      {/* Popover for nested items */}
      {/* {
        open && isSideOpen
        ?

          item.children &&
          item.children.map((child) => (
            <NestedMenuItem
              key={child.path}
              item={child}
              level={level + 1}
              isSideOpen={isSideOpen}
            />
          ))
        :
        <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {item.children &&
          item.children.map((child) => (
            <NestedMenuItem
              key={child.path}
              item={child}
              level={level + 1}
              isSideOpen={isSideOpen}
            />
          ))}
      </Popover>
      } */}
    </>
  );
}


const NestedMenuItem = ({ key, item, level, isSideOpen }) => {
  const pathname = usePathname();

  // Function to check if a path is active
  const isActive = (path) => {
    return pathname === path || pathname.startsWith(path + "/");
  };

  const active =
    isActive(item.path) ||
    (item.children && item.children.some((child) => isActive(child.path)));

  return (
    <>
      <ListItemButton
        component={RouterLink}
        to={item.path}
        sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: "body2",
          color: "text.secondary",
          display: !isSideOpen && "flex",
          justifyContent: !isSideOpen && "center",
          textTransform: "capitalize",
          fontWeight: "fontWeightMedium",
          textAlign: "center",
          ...(active && {
            color: "primary.main",
            fontWeight: "fontWeightSemiBold",
            color: (theme) => alpha(theme.palette.primary.main, 1),
            "&:hover": {
              color: (theme) => alpha(theme.palette.primary.main, 1),
            },
          }),
        }}
      >
        <Iconify icon={"radix-icons:dot-filled"} />
        {item.title}
      </ListItemButton>
    </>
  );
};
