import { Box, Button, Grid, IconButton, ListItemText, Stack, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import ItemsImageComponent from '../../../components/common/ItemsImageComponent'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Iconify from '../../../components/common/Iconify';
import { horecaApi } from '../../../redux/api/api';
import AskForPrice from '../../../components/common/AskForPrice';
import CustomButton from '../../../components/common/CustomButton';
import useResponsiveStyles from '../../../hooks/useResponsiveStyles';


export const InstantOrderItemsHead = () => {
    const { isMobile } = useResponsiveStyles();
    return (
        <Stack direction={'row'} alignItems={'center'} paddingTop={'24px'} paddingBottom={'24px'} borderBottom={`2px dashed rgb(244, 246, 248)`} key={'order?.itemI'} width={'100%'}>
            <ListItemText sx={{ flex: '1 1 auto' }}>
                <Typography variant={isMobile ? 'subtitle2' : 'body1'} >{'Item Name'}</Typography>
            </ListItemText>
            <Box>
                <Stack>
                    <Typography variant={isMobile ? 'subtitle2' : 'body1'} noWrap sx={{ width: '150px' }}>{'Item Quantity'}</Typography>
                </Stack>
            </Box>
            <Box sx={{ width: '110px', textAlign: 'right' }}>
                <Stack>
                    <Typography variant={isMobile ? 'subtitle2' : 'body1'} noWrap>{'Item Unit'}</Typography>
                </Stack>
            </Box>
            <Box sx={{ width: '110px', textAlign: 'right' }}>
                <Stack>
                    <Typography variant={isMobile ? 'subtitle2' : 'body1'} noWrap>{'Item Price'}</Typography>
                </Stack>
            </Box>
        </Stack>
    )
}


const InstantOrderItemsCard = ({ item, handleAddToCart, removeCartItem }) => {

    const { orgId } = useParams()
    const { isMobile } = useResponsiveStyles();
    const [quantity, setQuantity] = useState(item?.quantity);

    const handleIncrease = () => {
        setQuantity(quantity + 1);
        handleAddToCart(item?.itemId, 1, item?.vendorId)
    };

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            handleAddToCart(item?.itemId, -1, item?.vendorId)
        } else {
            // setQuantity(quantity - 1);
            handleAddToCart(item?.itemId, -1, item?.vendorId)
        }
    };

    const handleRemove = () => {
        removeCartItem(item?.id);
    }

    const [price, setPrice] = useState(0);
    const [getItemPrice] = horecaApi.endpoints.getItemPrice.useLazyQuery();

    const getPrice = async (itemId, itemQuantity) => {
        const result = await getItemPrice({ orgId, itemId, itemQuantity });
        setPrice(result?.data);
    }

    useEffect(() => {
        getPrice(item?.itemId, quantity)
    }, [item])

    return (
        <Box width={'100%'}>
            {!isMobile && <Stack direction={'row'} alignItems={'center'} paddingTop={'24px'} paddingBottom={'24px'}
                borderBottom={`2px dashed rgb(244, 246, 248)`}
                key={'order?.itemI'}>
                <Stack sx={{ flex: '1 1 auto' }} direction={isMobile ? 'column' : 'row'}>
                    <ItemsImageComponent s3key={item?.inventoryDataDump?.item_image_key} name={item?.inventoryDataDump?.name} />
                    <ListItemText sx={{ flex: '1 1 auto' }}>
                        <Typography variant='body2' fontSize={isMobile && '12px'}>{item?.inventoryDataDump?.name}</Typography>
                        <Typography variant='body2' fontSize={isMobile && '10px'} sx={{ margin: '4px 0px 0px' }} color={'text.disabled'} noWrap>HSN {item?.inventoryDataDump?.hsn}</Typography>
                        <CustomButton onClick={handleRemove} variant='text' label={<Iconify icon='material-symbols:delete' />} />
                    </ListItemText>
                </Stack>
                <Box>
                    <Stack sx={{ width: '150px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {
                                quantity > 1
                                    ?
                                    <IconButton onClick={handleDecrease} disabled={quantity <= 1}>
                                        <RemoveIcon />
                                    </IconButton>
                                    :
                                    <IconButton onClick={handleRemove} >
                                        <Iconify icon="material-symbols-light:delete" />
                                    </IconButton>
                            }
                            <TextField
                                value={quantity}
                                fontSize={isMobile && '10px'}
                                onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value, 10) || 1))}
                                inputProps={{ style: { textAlign: 'center' }, min: 1 }}
                                size="small"
                                type="number"
                                disabled={true}
                                sx={{ width: 150 }}
                            />
                            <IconButton onClick={handleIncrease}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ width: '110px', textAlign: 'right' }}>
                    <Stack>
                        <Typography variant='body2' fontSize={isMobile && '12px'} noWrap>{item?.inventoryDataDump?.unit}</Typography>
                    </Stack>
                </Box>
                <Box sx={{ width: '110px', textAlign: 'right' }}>
                    <Stack alignItems={"flex-end"}>
                        {price == 0 ?
                            <AskForPrice />
                            :
                            <Typography variant='subtitle2' fontSize={isMobile && '12px'} noWrap>₹{price}</Typography>
                        }
                    </Stack>
                </Box>
            </Stack>}
            {
                isMobile &&
                <Box sx={{ py: 1.5, borderBottom: '1px solid #DCE1E5' }}>
                    <Grid container >
                        <Grid item xs={2.5}>
                            <ItemsImageComponent s3key={item?.inventoryDataDump?.item_image_key} name={item?.inventoryDataDump?.name} />
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' fontSize={isMobile && '12px'}>{item?.inventoryDataDump?.name}</Typography>
                            <Typography variant='body2' fontSize={isMobile && '12px'} sx={{ margin: '4px 0px 0px' }} color={'text.disabled'} noWrap>HSN {item?.inventoryDataDump?.hsn}</Typography>
                            <Box sx={{ display:'flex', justifyContent:'start' }}>
                                    {price == 0 ?
                                        <AskForPrice />
                                        :
                                        <Typography variant='subtitle2' fontSize={isMobile && '12px'} noWrap>₹{price}</Typography>
                                    }
                                </Box>
                        </Grid>
                        <Grid item xs={4.5}>
                            <Stack spacing={1}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {
                                        quantity > 1
                                            ?
                                            <IconButton onClick={handleDecrease} disabled={quantity <= 1}>
                                                <RemoveIcon />
                                            </IconButton>
                                            :
                                            <IconButton onClick={handleRemove} >
                                                <Iconify icon="material-symbols-light:delete" />
                                            </IconButton>
                                    }
                                    <TextField
                                        value={quantity}
                                        fontSize={isMobile && '10px'}
                                        onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value, 10) || 1))}
                                        inputProps={{ style: { textAlign: 'center' }, min: 1 }}
                                        size="small"
                                        type="number"
                                        disabled={true}
                                        sx={{ width: 150 }}
                                    />
                                    <IconButton onClick={handleIncrease}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                                
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    )
}

export default InstantOrderItemsCard