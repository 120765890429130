import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from "@mui/material";
import Iconify from "../../components/common/Iconify";
import CustomButton from "../../components/common/CustomButton";

const ReviewOrderDialogue = ({ open, handelCloseDialogue, createOrder, content }) => {

  return (
    <Dialog
      maxWidth={"lg"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            // maxWidth: "350px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>Review Items</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handelCloseDialogue}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        {/* <CloseIcon /> */}
        <Iconify icon="material-symbols:cancel-outline" />
      </IconButton>
      <DialogContent>
        {content}
        <Stack direction={'row'} justifyContent={'center'} gap={'1rem'} sx={{ mt: '1rem' }}>
          <CustomButton variant="contained" onClick={createOrder} label={"Place Order"} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewOrderDialogue