import { getIn } from 'formik'
import { MuiTelInput } from 'mui-tel-input'

const CustomMuiTelInput = ({ formik, name, label, props, disabled = false, autoFocus = false, onKeyUp = () => { }, fullWidth = false }) => {
  return (
    <MuiTelInput
      defaultCountry="IN"
      onChange={(e) => formik.setFieldValue(name, e)}
      value={getIn(formik.values, name)}
      aria-invalid={!!getIn(formik.errors, name)}
      name={name}
      disabled={disabled}
      label={label}
      forceCallingCode
      disableFormatting={true}
      onBlur={formik.handleBlur}
      error={getIn(formik.touched, name) && Boolean(getIn(formik.errors, name))}
      helperText={getIn(formik.touched, name) && getIn(formik.errors, name)}
      {...props}
      autoFocus={autoFocus}
      onKeyUp={onKeyUp}
      fullWidth={fullWidth}
    />
  )
}

export default CustomMuiTelInput