import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import { alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { account } from "../../_mock/account";
import { useDispatch, useSelector } from "react-redux";
import { setLogOut } from "../../redux/slices/authSlice";
import { useRouter } from "../../routes/hooks/useRouter";
import { LightAndDarkSwitch } from "../../components/common/LightAndDarkSwitch";
import { setSavePrfernces } from "../../redux/slices/appReducer";
import {  useSavePreferncesMutation } from "../../redux/api/api";
import GetImageComponent from "../../components/common/GetImageComponent";
import { setScrollbarBackground } from "../../theme/css";
import UpdateOrganizationMenu from "../../pages/settings/sections/organization/UpdateOrganizationMenu";
import { stringAvatar } from "../../utils/defaultIcon";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
    path: "settings",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [savePrefernces] = useSavePreferncesMutation();
  const [open, setOpen] = useState(null);

  const orgId = useSelector((state) => state.app.preference.selectedOrgId);

  const user = useSelector((state) => state.auth.user);

  let nameColor = stringAvatar({ name: `${user?.firstName} ${user?.lastName}`, color: "rgb" })?.item;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (path) => {
    if (path) {
      router.push(`/org/${orgId}/${path}`);
      setOpen(null);
    } else {
      setOpen(null);
    }
  };

  const handelLogOut = () => {
    dispatch(setLogOut());
    router.reload("/");
  };

  const mode = useSelector((state) => state.app.preference.mode);

  const toggleThemeMode = () => {
    const payload = { key: "mode", value: mode === "light" ? "dark" : "light" };
    dispatch(setSavePrfernces(payload));
    savePrefernces({ payload });
    setScrollbarBackground(payload?.mode);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        {user?.profilePicKey ?
          <Avatar
            alt={account.displayName}
            sx={{
              width: 36,
              height: 36,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
            }}
          >
            {user?.profilePicKey ? (
              <GetImageComponent s3Key={user?.profilePicKey} />
            ) : (
              user.firstName.charAt(0).toUpperCase()
            )}
          </Avatar> :
          <Avatar
            sx={{
              width: 36,
              height: 36,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
              bgcolor: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},0.5)` : `rgba(${nameColor?.sx?.bgcolor},0.7)`,
              color: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},1)` : `rgba(255,255,255,0.9)`
            }}
            children={nameColor?.children}
          />}
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ py: 1.5, px: 2, borderBottom: '1px dashed rgba(145, 158, 171, 0.2)' }}>
          <Typography variant="subtitle2" noWrap>
            Welcome! {user.firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user.email}
          </Typography>
        </Box>

        {/* <Box
          sx={{
            py: 0.8,
            px: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: '1px dashed rgba(145, 158, 171, 0.2)'
          }}
        >
          <Typography variant="subtitle2" noWrap>Mode</Typography>
          <LightAndDarkSwitch
            onClick={toggleThemeMode}
            checked={mode === "light" ? false : true}
          />
        </Box> */}

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleClose(option?.path)}
            sx={{ py: 1.5, borderBottom: '1px dashed rgba(145, 158, 171, 0.2)' }}
          >
            {option.label}
          </MenuItem>
        ))}

        {/* <MenuItem
          disableRipple
          disableTouchRipple
          width={"100%"}
          sx={{ px: 1, borderBottom: '1px dashed rgba(145, 158, 171, 0.2)' }}
        >
          <UpdateOrganizationMenu
          />
        </MenuItem> */}

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handelLogOut}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
};