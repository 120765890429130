import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import AppItemsTimeLine from './AppItemsTimeLine'
import ReferAndEarn from './ReferAndEarn'
import ToDoTask from './ToDoTasks'
import OrdersCard from './OrdersCard'
import { horecaApi } from '../../../redux/api/api'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { color } from 'framer-motion'

const CustomerDashboard2 = () => {




  const orgId = useSelector((state) => state.app.preference.selectedOrgId)



  const [getMyToDo, { data: toDoData }] = horecaApi.endpoints.getMyToDo.useLazyQuery();


  useEffect(() => {
    (
      async () => {
        orgId && await getMyToDo(orgId);
      }
    )()
  }, [])


  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" sx={{ mb: 5 }}>
        Alert 🔔
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={8}>
          <AppItemsTimeLine
            title="Inventory Items"
            subheader="(+43%) than last year"
            chart={{
              series: [
                { label: 'Milk', value: 400, color: 'primary' },
                { label: 'Apple', value: 430, color: 'error' },
                { label: 'Mango', value: 448 },
                { label: 'Rice', value: 100 },
                { label: 'Roses', value: 540 },
                { label: 'Milks', value: 580 },
                { label: 'Dahi', value: 690 },
                { label: 'Bear', value: 1100 },
                { label: 'Fish', value: 1200 },
                { label: 'Chicken', value: 1380 },
              ]
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ReferAndEarn />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <OrdersCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ToDoTask title="Tasks"
            data={toDoData}
            list={[
              { id: '1', name: 'Edit Order List' },
              { id: '2', name: 'Make First Order' },
              { id: '3', name: 'Your Items Has to delivered' },
              { id: '4', name: 'You have to Edit an Order' },
              { id: '5', name: 'New Offer Checkout' },
            ]} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CustomerDashboard2