import { Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useResponsive } from "../../../../hooks/useResponsive";
import CustomGroupLogo from "../../common/CustomGroupLogo";
import { useRouter } from "../../../../routes/hooks/useRouter";
import { useSelector } from "react-redux";
import VendorLogoImageComponent from "../../../../components/common/VendorLogoImageComponent";
import { dateFormatter, formatDateAndTime } from "../../../../utils/formatNumbers";
import CustomButton from "../../../../components/common/CustomButton";

export default function OrderListItem({ responsive, listItem }) {
    // const downSm = useResponsive('down', 'sm')

    // const onlySm = useResponsive('only', 'sm')

    // const upMd = useResponsive('up', 'md')

    const theme = useTheme()

    const orgId = useSelector((state) => state.app.preference.selectedOrgId)
    const router = useRouter();
    const placeOrder = (listData) => {
        router.push(`/org/${orgId}/orders/${listData?.vendorOrgId}/order-list/${listData?.id}`)
    }

    const EditOrderList = (listData) => {
        // router.push(`/org/${orgId}/orders/${listData?.vendorOrgId}/order-list/${listData?.id}/edit`)
        router.push(`/org/${orgId}/explore-products?vendorId=${listData.vendorDetail.id}`)
    }

    const onlyXl = useResponsive('only', 'xl')

    const smDown = useResponsive('down', 'sm')

    return (
        <>
            <Grid
                component={theme.palette.mode !== "dark" && Paper}
                container
                columns={16}
                gap={2}
                alignItems={"center"}
                sx={{
                    padding: "1rem",
                    border: `1px solid ${theme.palette.mode === "dark" ? "rgba(145, 158, 171, 0.16)" : "rgba(145, 158, 171, 0.16)"}`,
                    borderRadius: "16px",
                    overflow: "auto",
                    transition: 'box-shadow 0.3s',
                    '&:hover': {
                        boxShadow: theme.palette.mode !== "dark" && 'rgba(145, 158, 171, 0.16) 0px 20px 40px -4px',
                        cursor: "pointer",
                        backgroundColor: theme.palette.mode === "dark" && theme.palette.background.paper
                    }
                }}
            >
                <Grid
                    item
                    xs={16}
                    // sm={1.5}
                    sm={2.1}
                    // md={1.5}
                    md={1.38}
                    lg={1.38}
                    xl={1}
                >
                    <VendorLogoImageComponent s3Key={listItem?.vendorDetail?.orgLogoKey} name={listItem?.vendorDetail?.orgName != "demobaba12" ? listItem?.vendorDetail?.orgName : undefined} />
                </Grid>
                <Grid
                    item
                    xs={16}
                    sm={8.6}
                    md={4.76}
                    // lg={5.6}
                    lg={5.3}
                    xl={5.6}
                >
                    <Typography variant={"subtitle1"} noWrap >{listItem?.name}</Typography>
                    <Typography variant={"body2"} sx={{ color: 'text.disabled' }} noWrap={!smDown}>
                        {listItem?.vendorDetail?.addresses[0]?.pincode ? `${listItem?.vendorDetail?.addresses[0]?.landmark ? listItem?.vendorDetail?.addresses[0]?.landmark + ", " : ""}${listItem?.vendorDetail?.addresses[0]?.line1 ? listItem?.vendorDetail?.addresses[0]?.line1 + ", " : ""}${listItem?.vendorDetail?.addresses[0]?.line2 ? listItem?.vendorDetail?.addresses[0]?.line2 + ", " : ""}${listItem?.vendorDetail?.addresses[0]?.city ? listItem?.vendorDetail?.addresses[0]?.city + ", " : ""}${listItem?.vendorDetail?.addresses[0]?.state ? listItem?.vendorDetail?.addresses[0]?.state + ", " : ""}${listItem?.vendorDetail?.addresses[0]?.pincode ?? ""}` : ""}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={16}
                    sm={4.3}
                    md={3.15}
                    lg={2.84}
                    xl={4.96}
                >
                    {listItem?.orderListCategories?.length > 0 &&
                        <CustomGroupLogo srcList={listItem?.orderListCategories} size={48} maxLogos={onlyXl ? 10 : smDown ? 6 : 4} />
                    }
                </Grid>
                <Grid
                    item
                    xs={16}
                    sm={5}
                    md={3.6}
                    lg={3.56}
                    xl={2.32}
                >
                    <Stack direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}>
                        <Typography variant={"body2"} noWrap>{listItem?.lastPlacedAt ? `Last ordered:` : "No order placed"}&nbsp;</Typography>
                        <Typography variant={"body2"} noWrap>{listItem?.lastPlacedAt ? `${formatDateAndTime(listItem?.lastPlacedAt).formattedDateNew}` : ""}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={!smDown && "space-between"} alignItems={"center"}>
                        <Typography sx={{ color: "text.disabled" }} variant={"body2"} noWrap>
                            {
                                listItem?.upcomingSchedules?.particularDate || listItem?.upcomingSchedules?.daily || listItem?.upcomingSchedules?.weekly ?
                                    `Upcoming schedule:` : "No upcoming schedule"
                            }&nbsp;
                        </Typography>
                        <Typography sx={{ color: "text.disabled" }} variant={"body2"} noWrap>
                            {
                                listItem?.upcomingSchedules?.particularDate ?
                                    dateFormatter(listItem?.upcomingSchedules?.particularDate)
                                    :
                                    listItem?.upcomingSchedules?.daily ?
                                        formatDateAndTime(listItem?.upcomingSchedules?.daily).formattedDateNew
                                        :
                                        listItem?.upcomingSchedules?.weekly ?
                                            formatDateAndTime(listItem?.upcomingSchedules?.weekly).formattedDateNew
                                            :
                                            ""
                            }
                        </Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={16}
                    sm={2.6}
                    md={1.88}
                    lg={1.7}
                    xl={1.21}
                >
                    <Stack gap={0.5} width={'100%'}>
                        <CustomButton
                            onClick={(e) => { placeOrder(listItem) }}
                            variant={'outlined'}
                            label={"Place Order"}
                            width={'100%'}
                        />
                        <CustomButton
                            onClick={(e) => { EditOrderList(listItem) }}
                            variant={'contained'}
                            label={"Edit"}
                            width={'100%'}
                        />
                    </Stack>
                </Grid>
            </Grid>
            {/* <Grid
                component={theme.palette.mode !== "dark" && Paper}
                container
                gap={(downSm && responsive) ? 0.5 : 2}
                direction={(downSm && responsive) ? "column" : "row"}
                justifyContent={(downSm && responsive) ? "center" : "space-between"}
                alignItems={(downSm && responsive) ? "flex-start" : "center"}
                sx={{
                    padding: "1rem",
                    border: `1px solid ${theme.palette.mode === "dark" ? "rgba(145, 158, 171, 0.16)" : "rgba(145, 158, 171, 0.16)"}`,
                    borderRadius: "1rem",
                    transition: 'box-shadow 0.3s',
                    '&:hover': {
                        boxShadow: theme.palette.mode !== "dark" && 'rgba(145, 158, 171, 0.16) 0px 20px 40px -4px',
                        cursor: "pointer",
                        backgroundColor: theme.palette.mode === "dark" && theme.palette.background.paper
                    },
                    overflow: "auto",
                    whiteSpace: (downSm && responsive) ? "normal" : "nowrap",
                    // backgroundColor: theme.palette.mode === "dark" && "rgb(22, 28, 36)"
                }}
            >
                {(downSm && responsive) ? (
                    <>
                        <OrderListFirstItem listItem={listItem} responsive={responsive} />
                        <OrderListSecondItem listItem={listItem} />
                    </>
                ) : (
                    <>
                        <Stack direction="row" spacing={onlySm ? 1 : "10px"} justifyContent={"space-between"} alignItems={"center"}>
                            <OrderListFirstItem listItem={listItem} responsive={responsive} />
                        </Stack>
                        <Stack direction="row" spacing={onlySm ? 1 : "10px"} justifyContent={"space-between"} alignItems={"center"}>
                            <OrderListSecondItem listItem={listItem} />
                        </Stack>
                    </>
                )}
            </Grid> */}
        </>
    );
}

// function OrderListFirstItem({ listItem, responsive }) {
//     const {
//         id,
//         items,
//         lastPlacedAt,
//         name,
//         orderListCategories,
//         upcomingSchedules,
//         vendorDetail,
//         vendorOrgId
//     } = listItem

//     const upMd = useResponsive('up', 'md')


//     return (
//         <>
//             <GetImageComponent
//                 s3Key={vendorDetail?.orgLogoKey ? vendorDetail?.orgLogoKey : ''}
//                 style={{
//                     height: "50px",
//                     width: "80px",
//                     border: "1px dashed #c9ced3",
//                     borderRadius: "8px",
//                     cursor: "none"
//                 }}
//             />
//             <Grid
//                 item
//                 sx={{
//                     width: {
//                         md: "30vw",
//                         lg: "27vw"
//                     }
//                 }}
//             >
//                 <Typography variant={"subtitle1"} noWrap >{name}</Typography>
//                 <Typography variant={"body2"} sx={{ color: 'text.disabled' }} noWrap={upMd}>
//                     {vendorDetail?.addresses[0]?.pincode ? `${vendorDetail?.addresses[0]?.landmark ? vendorDetail?.addresses[0]?.landmark + ", " : ""}${vendorDetail?.addresses[0]?.line1 ? vendorDetail?.addresses[0]?.line1 + ", " : ""}${vendorDetail?.addresses[0]?.line2 ? vendorDetail?.addresses[0]?.line2 + ", " : ""}${vendorDetail?.addresses[0]?.city ? vendorDetail?.addresses[0]?.city + ", " : ""}${vendorDetail?.addresses[0]?.state ? vendorDetail?.addresses[0]?.state + ", " : ""}${vendorDetail?.addresses[0]?.pincode ?? ""}` : ""}
//                 </Typography>
//             </Grid>
//         </>
//     )
// }

// function OrderListSecondItem({ listItem }) {
//     const {
//         id,
//         items,
//         lastPlacedAt,
//         name,
//         orderListCategories,
//         upcomingSchedules,
//     } = listItem

//     // const {orgId} = useParams();
//     const orgId = useSelector((state) => state.app.preference.selectedOrgId)
//     const router = useRouter();
//     const placeOrder = (listData) => {
//         router.push(`/org/${orgId}/orders/${listData?.vendorOrgId}/order-list/${listData?.id}`)
//     }

//     const smDown = useResponsive('down',)

//     return (
//         <>
//             {orderListCategories?.length > 0 &&
//                 <Grid item>
//                     <CustomGroupLogo srcList={orderListCategories} size={48} maxLogos={smDown ? 6 : 4} />
//                 </Grid>
//             }
//             <Grid item>
//                 <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
//                     <Typography variant={"body2"} noWrap>{lastPlacedAt ? `Last order placed: ` : "No order placed"}</Typography>
//                     <Typography variant={"body2"} noWrap>{lastPlacedAt ? `${format(lastPlacedAt, 'MM/dd/yyyy')}` : ""}</Typography>
//                 </Stack>
//                 <Typography sx={{ color: "text.disabled" }} variant={"body2"} noWrap>
//                     {
//                         upcomingSchedules?.daily ?
//                             `Upcoming schedule: ${format(upcomingSchedules?.daily, 'MM/dd/yyyy')}`
//                             :
//                             upcomingSchedules?.weekly ?
//                                 `Upcoming schedule: ${format(upcomingSchedules?.weekly, 'MM/dd/yyyy')}`
//                                 :
//                                 "No upcoming schedule"
//                     }
//                 </Typography>
//             </Grid>
//             <Grid item >
//                 <Button variant='outlined' onClick={(e) => { placeOrder(listItem) }} >
//                     Place Order
//                 </Button>
//             </Grid>
//         </>
//     )
// }