import { useDispatch, useSelector } from "react-redux";
import { CustomizedMenu } from "../../../../components/common/CustomizedMenu";
import { useEffect, useState } from "react";
import { setSavePrfernces } from "../../../../redux/slices/appReducer";
import { useSavePreferncesMutation } from "../../../../redux/api/api";
import { useRouter } from "../../../../routes/hooks/useRouter";
import { capitalizeFirstLetter } from "../../../../utils/stringFormating";
import { determineOrgType } from "../../../../utils/org";

export default function UpdateOrganizationMenu({ variant }) {

    const router = useRouter();

    const dispatch = useDispatch();

    const createOrgList = (org) => {
        if (org) {
            return { id: org?.id, label: `${capitalizeFirstLetter(org?.type)} - ${org?.orgName}` }
        }
        return null
    }

    const orgId = useSelector((state) => state.app.preference.selectedOrgId);

    const user = useSelector((state) => state.auth.user);
    const [orgListAnchorEl, setOrgListAnchorEl] = useState(null);
    const type = determineOrgType(user?.orgDetail)
    const [selectedOrg, setSelectedOrg] = useState(null)

    const [orgMenuList, setOrgMenuList] = useState([])

    const orgList = useSelector((state) => state.auth?.user?.orgDetail)

    const [savePrefernces] = useSavePreferncesMutation();

    // useEffect(() => {
    //     if (orgList?.length > 0) {
    //         setOrgMenuList([])
    //         orgList?.map((org) => {
    //             let organization = createOrgList(org)
    //             if (organization !== null) {
    //                 setOrgMenuList((prevState) => [
    //                     ...prevState,
    //                     organization,
    //                 ]);
    //             }
    //         })
    //     }
    // }, [orgList])

    useEffect(() => {
        if (orgList?.length > 0) {
            setOrgMenuList([]);
            orgList
                ?.filter(org => ((type == "vendor" || type == "customer") && !org?.primaryOrgId) || (type != "vendor" && type != "customer")) // Filter out those have primaryOrgId
                ?.map((org) => {
                    let organization = createOrgList(org);
                    if (organization !== null) {
                        setOrgMenuList((prevState) => [
                            ...prevState,
                            organization,
                        ]);
                    }
                });
        }
    }, [orgList]);

    const handleChangeOrg = (orgId) => {
        const payload = { key: "selectedOrgId", value: orgId };
        dispatch(setSavePrfernces(payload));
        savePrefernces({ payload });
        router.push(`/`);
        closeOrgList()
    };

    const handleSelectOrg = (e) => {
        setSelectedOrg(null)
        setSelectedOrg(e.currentTarget.value)
        handleChangeOrg(e.currentTarget.value)
    }

    const openOrgList = (event) => {
        event.preventDefault();
        setOrgListAnchorEl(event.currentTarget);
    };

    const closeOrgList = () => {
        setOrgListAnchorEl(null);
    };

    return (
        <CustomizedMenu
            anchorEl={orgListAnchorEl}
            handleClick={openOrgList}
            handleClose={closeOrgList}
            label={`${capitalizeFirstLetter(user?.orgDetail?.find((org) => org?.id == orgId)?.type)} - ${user?.orgDetail?.find((org) => org?.id == orgId)?.orgName}`}
            noDataMsg={"No Organizations"}
            options={orgMenuList}
            selectedOption={selectedOrg}
            setSelectedOption={handleSelectOrg}
            variant={variant}

        />
    )
}