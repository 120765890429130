import { Avatar, Box, Container, Divider, Grid, Paper, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material'
import React from 'react'
import Accordion from '@mui/material/Accordion';
// import {AccordionGroup} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionGroup from '@mui/material/AccordionGroup';
import AccordionDetails from '@mui/material/AccordionDetails';
import PageHeader from '../../components/common/PageHeader'
import { useRouter } from '../../routes/hooks/useRouter'
import ProfilePic from '../../components/common/ProfilePic';
import GetImageComponent from '../../components/common/GetImageComponent';
import CustomLabel from '../../components/common/label/CustomLabel';
import { useState } from 'react';
import SalesOrders from '../sales-order';
import QwikChats from './QwikChats';
import PaymnetOptions from './PaymnetOptions';
import RatingsSection from './RatingsSection';
import { useParams } from 'react-router-dom';
import { useGetOrganizationByIdQuery, useGetStatisticsQuery } from '../../redux/api/api';
import VendorLogoImageComponent from '../../components/common/VendorLogoImageComponent';
// import { getStatusColor } from '../settings/sections/organization/OrganizationProfile';
import { getStatusColor } from '../system-admin/customer/user-table-row';
import { getOrgType } from '../../utils/org';
import { useSelector } from 'react-redux';
import Iconify from '../../components/common/Iconify';


const RenderBox = ({ data }) => {
  const theme = useTheme();
  return (
    <Stack sx={{ border: `1px dashed ${theme.palette.grey[400]}`, padding: 2, mb: 2, borderRadius: '8px', maxWidth: 110 }}>
      <Typography fontWeight={500} fontSize={'20px'}>{data?.value}</Typography>
      <Typography color={'text.disabled'} fontSize={'14px'}>{data?.label}</Typography>
    </Stack>
  )
}

const RenderContent = ({ add }) => {
  return (
    <Stack>
      <Typography variant='subtitle1' >{add.id}</Typography>
      <Typography variant='body2' color={'text.disabled'}>{add.label}</Typography>
    </Stack>
  )
}


const CustomerTabs = ({ setValue, value }) => {



  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        indicatorColor="primary "
        aria-label="secondary tabs example"
      >
        <Tab value={0} label="Sales Orders" />
        {/* <Tab value={1} label="Chat" />          */}
      </Tabs>
    </Box>
  )
}

const getAddressLabel = (add) => {
  return add[0]?.pincode ? <span>{add[0]?.line1}, {add[0]?.landmark}, {add[0]?.city}, {add[0]?.state}, {add[0]?.pincode}</span> : '--'
}


const MyCustomerDetailsPage = () => {

  const { selectedId, orgId } = useParams();

  const user = useSelector((state) => state.auth.user);
  const { data: orgData } = useGetOrganizationByIdQuery(selectedId);
  const { data: statisticsData } = useGetStatisticsQuery({ vendorId: orgId, customerId: selectedId });
  const [value, setValue] = useState(0);



  const router = useRouter();

  const goBack = () => {
    router.back();
  }

  const orgType = getOrgType(user, orgId);

  return (
    <Container maxWidth="xl">
      <PageHeader handleBackClick={goBack} title={orgType === 'vendor' ? 'Customer Details' : 'Vendor Details'} />
      <Grid container spacing={3}>
        <Grid container item xs={12} md={4} spacing={3}>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary>
                <Stack direction={'column'} width={'100%'} alignItems={'center'} gap={'0.5rem'} mt={'1rem'}>
                  <VendorLogoImageComponent s3Key={orgData?.orgLogoKey} style={{ width: '80px', height: '80px' }} name={orgData?.orgName} />
                  <Stack alignItems={'center'}>
                    <Typography sx={{ fontWeight: 600 }}>{orgData?.orgName}</Typography>
                    <Typography sx={{ color: 'text.disabled', fontWeight: 600, textTransform: 'capitalize' }}>{orgData?.type}</Typography>
                  </Stack>
                  <Stack direction={'row'} spacing={2} mt={'1rem'}>
                    {
                      [{ label: 'Order Count', value: statisticsData?.orderCount ?? 0 },
                      //  { label: 'Revenue', value: statisticsData?.totalOrderPrice ?? 0 },
                         { label: 'Average Price Per Order', value: statisticsData?.averageOrderPrice ?? 0 }].map((row, index) => (
                        <RenderBox key={index} data={row} />
                      ))
                    }
                  </Stack>
                  <Stack width={'100%'} mt={'1rem'}>
                    <Stack direction={'row'} gap={0.5} alignItems={'center'}>
                      <Typography variant='subtitle1'>Details</Typography>
                      <Iconify icon="iconamoon:arrow-down-2-light" />
                    </Stack>
                    <Divider sx={{ borderStyle: 'dashed', marginTop: 2, marginBottom: 2 }} />
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <CustomLabel color={getStatusColor(orgData?.status)}>{orgData?.status}</CustomLabel>
                <Box display={'flex'} flexDirection={'column'} gap={'0.5rem'} mt={'1rem'}>
                  {
                    [{ id: 'Org Email', label: orgData?.orgEmail }, { id: 'Phone Number', label: orgData?.phoneNumber }, { id: 'Address', label: orgData?.addresses && getAddressLabel(orgData?.addresses.filter((address) => orgData?.type === 'customer' ? address.billing === false : address.billing === true)) }].map((add, index) => (
                      <RenderContent add={add ? add : []} key={index} />
                    ))
                  }
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <CustomerTabs value={value} setValue={setValue} />
          <Stack sx={{ mt: '2rem' }}>
            {value === 0 && <SalesOrders other={false} vendId={orgType === 'vendor' ? orgId : selectedId} custId={orgType === 'vendor' ? selectedId : orgId} type={orgType} />}
            {/* {value === 1 && <QwikChats/>} */}
            {/* {value === 2 && <>Add Offers</>} */}
            {/* {value === 3 && <PaymnetOptions/>} */}
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Paper sx={{ padding: '1rem', mt: '2rem' }}>
            <RatingsSection />
          </Paper>
        </Grid>
      </Grid>
      {/* </AccordionGroup> */}
    </Container>
  )
}

export default MyCustomerDetailsPage