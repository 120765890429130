import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import Iconify from "../../components/common/Iconify";
import CustomButton from "../../components/common/CustomButton";

const ChangeOrgDialogue = ({ open, handleOnCancel, handleOnConfirmed, handleOnConfirmedLoading = false }) => {

  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "350px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>Confirm</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleOnCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Iconify icon="material-symbols:cancel-outline" />
      </IconButton>
      <DialogContent>
        <Typography>
          Are you sure you want to change the organization ?
        </Typography>
        <Stack direction={'row'} gap={'1rem'} justifyContent={'center'} alignItems={'center'} mt={4}>
          <CustomButton variant="outlined" onClick={handleOnCancel} label={"Cancel"} />
          <CustomButton variant="contained" onClick={handleOnConfirmed} label={"Yes"} loading={handleOnConfirmedLoading} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};


export default ChangeOrgDialogue;