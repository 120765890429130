import { useState } from 'react';
import { Select, MenuItem, Typography, InputLabel } from '@mui/material';
import Iconify from '../../components/common/Iconify';
import useResponsiveStyles from '../../hooks/useResponsiveStyles';

const ShopProductSort = ({ type, menuOptions, selectedOption, handleSelectCategory, name }) => {

  const [open, setOpen] = useState(false);

  const handleMenuItemClick = (optionValue) => {
    handleSelectCategory(optionValue);
    setOpen(false);
  };

  const {isMobile} = useResponsiveStyles();

  return (
    <>
      <Select
        value={selectedOption || ''}
        onChange={(e) => handleMenuItemClick(e.target.value)}        
        displayEmpty
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disableUnderline
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
        sx={{ fontSize: '14px', '.MuiSelect-select': { fontSize: isMobile?'10px':'12px',  padding: isMobile ? '8px' : '12px', },  '& .MuiSelect-icon': { fontSize: isMobile ? '10px' : '12px' },'& .MuiOutlinedInput-root': {
          padding: isMobile ? '8px' : '12px', // Adjust padding of the root element if needed
        },
        '& .MuiOutlinedInput-input': {
          padding: isMobile ? '8px' : '12px', // Adjust padding of the input element if needed
        },}}
      >
        <MenuItem value="" disabled>
          <em>{type}</em>
        </MenuItem>
        {menuOptions?.map((option) => (
          <MenuItem key={option.id} value={option}>
            {option[name]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default ShopProductSort;
