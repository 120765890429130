import React, { useEffect, useState } from 'react'
import { RenderHeader } from './OrderDetail'
import { Avatar, Box, Divider, FormControl, IconButton, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
import GetImageComponent from '../../../components/common/GetImageComponent'
import { paymentMode, paymentModeOptions } from '../../../constants/constants'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveIcon from '@mui/icons-material/Save';
import { useSelector } from 'react-redux'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
        sx: {
            '& .MuiTypography-root': {
                fontSize: 14,
            },
        },
    },
};

const RightSide = ({ customerInfo, delivery, shipping, changePaymentMode, paymentInfo, orgType, status, vendorInfo, onEdit }) => {
    const [selectedPaymentMode, setSelectedPaymentMode] = useState('');
    const paymentModes = useSelector((state) => state.auth.paymentModes);
    useEffect(() => {
        if (paymentInfo?.paymentMode) {
            setSelectedPaymentMode(paymentInfo?.paymentMode)
        }
    }, [paymentInfo])
    const handlePaymentModeChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedPaymentMode(value);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <RenderHeader heading={orgType === 'vendor' ? 'Customer Info' : 'Vendor Info'} />
            <Box sx={{ display: 'flex', padding: 3, gap: 3, width: '100%' }}>
                <Box sx={{ width: '15%' }}>
                    <Avatar>
                        <GetImageComponent s3Key={orgType === 'customer' ? vendorInfo?.orgLogoKey : customerInfo?.orgLogoKey} />
                    </Avatar>
                </Box>
                <Box sx={{ width: '85%', display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Typography variant='h6'>{orgType === 'customer' ? vendorInfo?.orgName : customerInfo?.orgName}</Typography>
                    <Typography noWrap sx={{ width: '100%' }}>{orgType === 'customer' ? vendorInfo?.orgEmail : customerInfo?.orgEmail}</Typography>{/*vendorInfo?.orgEmail, vendorInfo?.orgPhoneNumber*/}
                    <Box sx={{ color: 'text.grey' }}>{orgType === 'customer' ? vendorInfo?.orgPhoneNumber : customerInfo?.orgPhoneNumber}</Box>
                </Box>
            </Box>
            <Divider sx={{ borderStyle: "dashed" }} />
            <RenderHeader heading={'Delivery'} showEdit={orgType == 'vendor' ? true : false} onEdit={onEdit} />
            <Stack direction={'column'} gap={'12px'} padding={'24px'}>
                <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                    <span style={{ flexShrink: 0, width: '120px', color: 'rgb(99, 115, 129)' }}>Ship by</span>
                    {delivery?.shipBy}
                </Stack>
                <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                    <span style={{ flexShrink: 0, width: '120px', color: 'rgb(99, 115, 129)' }}>Speedy</span>
                    {delivery?.type}
                </Stack>
                <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                    <span style={{ flexShrink: 0, width: '120px', color: 'rgb(99, 115, 129)' }}>Tracking No.</span>
                    <Typography sx={{ textDecoration: 'underline', color: 'inherit' }}>{delivery?.trackingNumber}</Typography>
                </Stack>
                <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                    <span style={{ flexShrink: 0, width: '120px', color: 'rgb(99, 115, 129)' }}>Tracking url</span>
                    <Typography sx={{ textDecoration: 'underline', color: 'inherit' }}>{delivery?.trackingUrl}</Typography>
                </Stack>
            </Stack>
            <Divider sx={{ borderStyle: "dashed" }} />
            <RenderHeader heading={'Shipping'} />
            <Stack sx={{ direction: 'column', gap: '12px', padding: '24px', }}>
                <Stack sx={{ direction: 'row' }}>
                    <span style={{ flexShrink: 0, width: '120px', color: 'rgb(99, 115, 129)' }}>Address</span>
                    {shipping?.customerAddress?.pincode ? `${shipping?.customerAddress?.landmark ? shipping?.customerAddress?.landmark + ", " : ""}${shipping?.customerAddress?.line1 ? shipping?.customerAddress?.line1 + ", " : ""}${shipping?.customerAddress?.line2 ? shipping?.customerAddress?.line2 + ", " : ""}${shipping?.customerAddress?.city ? shipping?.customerAddress?.city + ", " : ""}${shipping?.customerAddress?.state ? shipping?.customerAddress?.state + ", " : ""}${shipping?.customerAddress?.pincode ?? ""}` : ""}
                </Stack>
                <Stack sx={{ direction: 'row' }}>
                    <span style={{ flexShrink: 0, width: '120px', color: 'rgb(99, 115, 129)' }}>Phone Number</span>
                    {shipping?.phoneNumber}
                </Stack>
            </Stack>
            <Divider sx={{ borderStyle: "dashed" }} />
            <RenderHeader heading={'Payment'} />
            <Stack sx={{ direction: 'column', gap: '12px', padding: '24px', }}>
                {/* <Stack sx={{ direction: 'row' }}>
                    <span style={{ flexShrink: 0, width: '120px', color: 'rgb(99, 115, 129)' }}>Payment Type</span>
                    {paymentInfo?.paymentType}
                </Stack> */}
                <Stack sx={{ flexDirection: 'column', gap: 1, width: '100%' }}>
                    <Box sx={{ width: '120px', color: 'rgb(99, 115, 129)' }}>Payment Mode</Box>
                    {/* {paymentInfo?.paymentMode} */}
                    {customerInfo?.availablePaymentModes ?
                        (orgType === 'vendor' && status == 'fulfilled') || (orgType === 'customer' && ['fulfilled', 'delivered'].includes(status)) || paymentInfo?.paymentMode == 'payByGateway' ?
                            <Typography variant='body2'>{paymentMode[paymentInfo?.paymentMode]}</Typography> :
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <FormControl sx={{ width: '70%' }} size="medium">
                                    <Select
                                        value={selectedPaymentMode}
                                        onChange={handlePaymentModeChange}
                                    // IconComponent={KeyboardArrowDownIcon}
                                    // sx={{
                                    //     borderRadius: 1,
                                    //     backgroundColor: '#FFF',
                                    //     '& .MuiOutlinedInput-notchedOutline': {
                                    //         borderColor: '#ABAAAE'
                                    //     }
                                    // }}
                                    // input={<OutlinedInput />}
                                    //MenuProps={MenuProps}
                                    >
                                        {customerInfo?.availablePaymentModes ? paymentModeOptions.map((actionObj) => (
                                            customerInfo?.availablePaymentModes[actionObj.value] !== false && <MenuItem key={actionObj.value} value={actionObj.value}>{actionObj.label}</MenuItem>
                                        )) : <MenuItem key={paymentModeOptions[-1].value} value={paymentModeOptions[-1].value}>{paymentModeOptions[-1].label}</MenuItem>}
                                    </Select>
                                </FormControl>
                                <IconButton onClick={() => changePaymentMode(selectedPaymentMode)} disabled={paymentInfo?.paymentMode == selectedPaymentMode} sx={{ paddingX: 0 }}><SaveIcon color={paymentInfo?.paymentMode == selectedPaymentMode ? 'grey' : "primary"} /></IconButton>
                            </Box> : <Stack sx={{ direction: 'row' }}>
                            No other payment modes are available except "Pay Online".
                        </Stack>
                    }
                </Stack>
                <Stack sx={{ direction: 'row' }}>
                    <span style={{ flexShrink: 0, width: '120px', color: 'rgb(99, 115, 129)' }}>Payment Date</span>
                    {paymentInfo?.date}
                </Stack>
            </Stack>
        </Box>
    )
}

export default RightSide