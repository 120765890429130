import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import Iconify from "../../components/common/Iconify";
import CustomButton from "../../components/common/CustomButton";

const CartWarningDialog = ({ open, onClose, message }) => {

  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "350px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>Warning</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        {/* <CloseIcon /> */}
        <Iconify icon="material-symbols:cancel-outline" />
      </IconButton>
      <DialogContent>
        <Typography>
          {message}
        </Typography>
        <Stack direction={'row'} gap={'1rem'} justifyContent={'center'} alignItems={'center'} mt={4}>
          <CustomButton variant="contained" onClick={onClose} label={"Ok"} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CartWarningDialog