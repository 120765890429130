import {
  alpha,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { frozenImage } from "../../assets/categories";
import { useState } from "react";
import Iconify from "../../components/common/Iconify";
import { highlightText } from "../../utils/highlightedText";
import ItemsImageComponent from "../../components/common/ItemsImageComponent";
import AskForPrice from "../../components/common/AskForPrice";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
const label = { inputProps: { "aria-label": "Checkbox demo" } };


// ----------------------------------------------------------------------

const CardHeader = ({
  headLabel,
  isSaveProceed
}) => {

  const {isMobile} = useResponsiveStyles();

  return (
    <>
        {
        isMobile
        ?
        <List
          sx={{
            width: "100%",
            mt: "0.5rem",
            bgcolor: (theme) => theme.palette.grey[200],            
            ":hover": {              
              transition: "0.3s all",
            },
          }}
        >
      <ListItem sx={{ justifyContent: "space-between" }}>
        <Stack width={150}>
          <Typography fontWeight={700} fontSize={"14px"} color='text.disabled'>
            {"Item Name"}
          </Typography>
        </Stack>
        <Stack>
          <Typography fontWeight={700} fontSize={"14px"} color='text.disabled'>
            {"rate"}
          </Typography>
          <Typography fontWeight={700} fontSize={"14px"} color='text.disabled'>
            {"unit"}
          </Typography>
        </Stack>
        {
          !isSaveProceed &&  <Stack>
          <Typography fontWeight={700} fontSize={"14px"} color='text.disabled'>
            {"bulk_quantity"}
          </Typography>
          <Typography fontWeight={700} fontSize={"14px"} color='text.disabled'>
            {"bulk_rate"}
          </Typography>
        </Stack>
        }
        {<Stack sx={{width: isSaveProceed ? 50 : 0}}></Stack>}
      </ListItem>
    </List>
    :
    <List
      sx={{
        width: "100%",
        bgcolor: (theme) => theme.palette.paper,
        borderBottom: (theme) => "1px dashed rgba(145, 158, 171, 0.4)",
      }}
      disablePadding
    >
      <ListItem sx={{ width: '100%' }}>
        {
          headLabel.map((head, index) => {
            return (
              <ListItem key={index} sx={{ ...head?.style }} disablePadding={head?.disablePadding}>
                <ListItemText key={head?.id} sx={{ fontWeight: 600, fontSize: '14px' }} primary={<Typography sx={{ fontWeight: 700, fontSize: '14px', color: 'text.disabled' }}>
                  {head?.label}
                </Typography>}>
                </ListItemText>
              </ListItem>
            )
          })
        }
      </ListItem>
    </List>
      }
    </>
  );
}

export default CardHeader;


export const ItemRow1 = ({ row, columns, handleAddRemoveItem, currVendorId, selectedItems, isSaveProceed, handelRemoveItem, searchText }) => {


  const [checked, setChecked] = useState(false);

  //return itemId too
  const handleChange = (e, itemId) => {
    setChecked(e.target.checked);
    handleAddRemoveItem(itemId, e.target.checked, row)
  }

  //get check or not get ite

  const theme = useTheme();

  const isChecked = (itemId) => {
    const vendorItems = selectedItems?.find(vendor => vendor.vendorId == currVendorId)?.items;
    const isFind = vendorItems && vendorItems?.find(item => item?.item?.id == itemId);
    if (isFind) {
      return true;
    } else {
      return false;
    }
  };

  const onDeleteClick = (itemId) => {
    handelRemoveItem(itemId)
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'rate-popover' : undefined;

  const {isMobile} = useResponsiveStyles();


  return (
    <>
      {
        isMobile 
        ?
        <List
      sx={{
        width: "100%",
        mt: "0.5rem",
        ":hover": {
          bgcolor: (theme) => theme.palette.grey[200],
          transition: "0.3s all",
        },
      }}
    >
      <ListItem sx={{ justifyContent: "space-between" }}>
        <Stack width={150}>
          {/* <Avatar src={frozenImage} /> */}
          <ItemsImageComponent s3key={row?.item_image_key} name={row?.name} />
          <Typography variant="subtitle2">{row.name}</Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" fontWeight={500}>₹{row.rate}</Typography>
          <Typography variant="subtitle2" fontWeight={500}>{row.unit}</Typography>
        </Stack>
        {
          !isSaveProceed && <Stack>
          <ListItemText
                sx={{ display: 'flex', justifyContent: 'space-between', }}
              >
                <Typography variant="body2" >
                  {row?.itemQuantityPricesMappingDetails?.length > 0 && (
                    <span>
                      {'₹' + row?.itemQuantityPricesMappingDetails[0]?.itemPrice}
                      [<span>{row?.itemQuantityPricesMappingDetails[0]?.itemQuantity + row?.unit}</span>]
                    </span>
                  )}
                  {
                    row?.itemQuantityPricesMappingDetails?.length > 0 && <IconButton onClick={handleClick}>
                      <Iconify icon="material-symbols-light:keyboard-double-arrow-right" />
                    </IconButton>
                  }
                </Typography>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {row?.itemQuantityPricesMappingDetails?.map((rate, index) => (
                    <MenuItem>
                      <Typography>₹{rate.itemPrice}[{rate.itemQuantity + row.unit}]</Typography>
                    </MenuItem>                  
                  ))}
                </Popover>
              </ListItemText>
        </Stack>
        }
        
        <Stack>
          {/* <Checkbox {...label} defaultChecked /> */}
         { !isSaveProceed ? <ListItem disablePadding><Checkbox
              checked={isChecked(row.id)}
              onChange={(e) => handleChange(e, row.id)}
              inputProps={{ 'aria-label': 'controlled' }}
            /></ListItem> : <ListItem><IconButton onClick={() => onDeleteClick(row.id)}><Iconify icon="bi:trash" sx={{ color: 'red' }} /></IconButton></ListItem>}
        </Stack>
      </ListItem>
    </List>
    :
    <List
    sx={{
      width: "100%",
      bgcolor: (theme) => isChecked(row?.id) && alpha(theme.palette.primary.light, 0.3),
      ":hover": {
        bgcolor: (theme) => theme.palette.action.selected,
        transition: "0.3s all",
      },
    }}
    key={row?.id}
    disablePadding
  >
    <ListItem sx={{ width: '100%', height: "90px" }}>
      {columns.map((column, index) => {
        let value = null;
        let data = null;
        const style = column?.style;
        const disablePadding = column?.disablePadding
        switch (column?.id) {
          case "action": {
            data = !isSaveProceed ? <ListItem sx={{ ...style }} disablePadding={disablePadding}><Checkbox
              checked={isChecked(row.id)}
              onChange={(e) => handleChange(e, row.id)}
              inputProps={{ 'aria-label': 'controlled' }}
            /></ListItem> : <ListItem sx={{ ...style }}><IconButton onClick={() => onDeleteClick(row.id)}><Iconify icon="bi:trash" sx={{ color: 'red' }} /></IconButton></ListItem>
            break;
          }
          case "item_image_key": {
            data = <ListItem sx={{ ...style }} disablePadding={disablePadding}>
              <ItemsImageComponent s3key={row[column?.id]} name={row?.name} />
            </ListItem>
            break;
          }
          case "rate": {
            value = row[column?.id]
            data =
              <ListItem sx={{ ...style }} disablePadding={disablePadding}>
                <ListItemText
                  key={column?.id}
                // primary={}
                // sx={{ ...style }}
                >
                  {/* <Typography sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 400, lineHeight: '1.57143', fontSize: '0.875rem'
                }}> */}
                  {value == 0 ?
                    <AskForPrice /> :
                    <Typography variant="body2" noWrap>₹{value}</Typography>
                  }
                </ListItemText>
              </ListItem>
            break;
          }
          case 'itemQuantityPricesMappingDetails': {
            data = <ListItem sx={{ ...style }} disablePadding={disablePadding}>
              <ListItemText
                sx={{ display: 'flex', justifyContent: 'space-between', }}
              >
                <Typography variant="body2" >
                  {row?.itemQuantityPricesMappingDetails?.length > 0 && (
                    <span>
                      {'₹' + row?.itemQuantityPricesMappingDetails[0]?.itemPrice}
                      [<span>{row?.itemQuantityPricesMappingDetails[0]?.itemQuantity + row?.unit}</span>]
                    </span>
                  )}
                  {
                    row?.itemQuantityPricesMappingDetails?.length > 0 && <IconButton onClick={handleClick}>
                      <Iconify icon="material-symbols-light:keyboard-double-arrow-right" />
                    </IconButton>
                  }
                </Typography>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {row?.itemQuantityPricesMappingDetails?.map((rate, index) => (
                    <MenuItem>
                      <Typography>₹{rate.itemPrice}[{rate.itemQuantity + row.unit}]</Typography>
                    </MenuItem>                  
                  ))}
                </Popover>
              </ListItemText>
            </ListItem>
            break;
          }
          default:
            let splitArray = column.id.split(".");
            value = row;

            for (let key of splitArray) {

              if (value && value.hasOwnProperty(key)) {
                value = value[key];
                data = <ListItem sx={{ ...style }}><Typography key={column?.id} sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 400, lineHeight: '1.57143', fontSize: '0.875rem'
                }}>
                  {highlightText(
                    value,
                    searchText,
                    theme
                  )}
                </Typography></ListItem>
              } else {
                value = null;
                break; // Break the loop if a null or undefined value is encountered
              }
            }
            break;
        }
        return (
          <>
            {data}
          </>
        );
      })}
    </ListItem>
  </List>
    }
    </>
  );
};