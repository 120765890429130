import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import Iconify from "../../../components/common/Iconify";
import CreateNewOrganzation from "./CreateNewOrganzation";

const CreateNewOrganizationDialogue = ({ handelCloseDialogue, open, type, selectedOrgId, selectedRoleId, disabled, label }) => {

  return (
    <Dialog
      maxWidth={"lg"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handelCloseDialogue}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Iconify icon="material-symbols:cancel-outline" />
        </IconButton>
        <Typography variant="h4">
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: { xs: 1, sm: 3 } }}>
          <CreateNewOrganzation type={type} selectedOrgId={selectedOrgId} handelCloseDialogue={handelCloseDialogue} disabled={disabled} label={label} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateNewOrganizationDialogue