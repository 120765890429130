export const discountImage = require("./DD.png");
// export const banner1 = require("./imageNew/ban1.png");
// export const banner2 = require("./imageNew/ban2.png");
// export const banner3 = require("./imageNew/ban3.png");
export const banner1 = require("./mobileTopBanner1.png");
export const banner2 = require("./mobileTopBanner2.png");
export const banner3 = require("./mobileTopBanner3.png");
// export const bannerD1 = require("./banner1.png");
// export const bannerD2 = require("./banner2.png");
// export const bannerD3 = require("./banner3.png");
export const bannerD1 = require("./desktopTopBanner1.png");
export const bannerD2 = require("./desktopTopBanner2.png");
export const bannerD3 = require("./desktopTopBanner3.png");

export const cor1 = require("./imageNew/cor1.png");
export const cor2 = require("./imageNew/cor2.png");
export const cor3 = require("./imageNew/cor3.png");
export const cor4 = require("./imageNew/cor4.png");
export const cor5 = require("./imageNew/cor5.png");
export const cor6 = require("./imageNew/cor6.png");

export const newBanner1 = require("./bannerDesktop/deal1.png");
export const newBanner2 = require("./bannerDesktop/deal2.png");
export const newBanner3 = require("./bannerDesktop/deal3.png");
export const newBanner4 = require("./bannerDesktop/deal4.png");

export const mobileBanner1 = require("./bannerMobile/mdeal1.png");
export const mobileBanner2 = require("./bannerMobile/mdeal2.png");
export const mobileBanner3 = require("./bannerMobile/mdeal3.png");
export const mobileBanner4 = require("./bannerMobile/mdeal4.png");
export const mobileBanner5 = require("./bannerMobile/mdeal5.png");

export const ansariVendor = require("./Ansari.png");
export const greenBasket = require("./GreenBasket.png");
export const horeca1Image = require("./Horeca1.png");
export const shree = require("./Shree.png");
