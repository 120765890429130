import { Typography } from "@mui/material";

export default function CustomStatusIcon({ color, isNumber, backgroundColor, value, sx }) {
    return (
        <Typography
            sx={{
                fontSize: isNumber ? "14px" : "12px",
                borderRadius: "6px",
                fontWeight: 700,
                padding: "2px 6px",
                color: color ?? "black",
                backgroundColor: backgroundColor ?? "#ffffff",
                width:"fit-content",
                ...(sx)
            }}
        >
            {value}
        </Typography>
    )
}
