// types
import { createSlice } from "@reduxjs/toolkit";
import { generateItems } from "../../_mock/vendor";

// initial state
const initialState = {
  // mode: "light",
  isSideOpen: true,
  isTour: false,
  selectedCategories: [],
  isShowCategory: false,
  runningTour: false,
  currVendorId: '',
  createOrderList: {
    vendorId: '',
    selectedItems: [],
    selectedCategories: [],
  },
  items: generateItems(),
};

// ==============================|| SLICE - MENU ||============================== //

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSideOen(state, action) {
      state.isSideOpen= action.payload;
    },
  },
});

export default commonSlice.reducer;

export const { setCurrentMode, setSideOen} =
commonSlice.actions;