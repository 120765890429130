import React, { useEffect } from "react";
import { Breadcrumbs, useTheme } from "@mui/material";
import ShopProductSort from "./ShortBy";
import Iconify from "../../components/common/Iconify";
import { useSearchParams } from "react-router-dom";
import { color } from "framer-motion";
import { useSelector } from "react-redux";

const ItemsBreadCrumbMenu = ({ suitableVendorsCategories }) => {

  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const vendorId = searchParams.get("vendorId");
  const categoryId = searchParams.get("categoryId");
  const subCategoryId = searchParams.get("subCategoryId");
  

  //save in redux


  const handleSelectCategory = (category) => {
    const selectedCategoryWithSub = suitableVendorsCategories.find(
      (cat) => cat.id == category.id
    );
    if (
      selectedCategoryWithSub &&
      selectedCategoryWithSub?.subCategories &&
      selectedCategoryWithSub?.subCategories.length > 0
    ) {
      setSearchParams({
        vendorId,
        categoryId: category.id,
        subCategoryId: selectedCategoryWithSub?.subCategories[0].id,
      });
    } else {
      setSearchParams({ vendorId, categoryId: category.id });
    }
  };

  // Function to set the selected sub-category
  const handleSelectSubCategory = (subCategory) => {
    setSearchParams({
      vendorId,
      categoryId: categoryId,
      subCategoryId: subCategory?.id,
    });
  };


  return (
    <>
      <Breadcrumbs
        separator={<Iconify icon="fluent:divider-tall-20-regular" sx={{ color: theme.palette.grey[400] }} />}
        aria-label="breadcrumb"
      >
        <ShopProductSort
          type={"Categories"}
          // menuOptions={[...suitableVendorsCategories, { id: null, category_name: "None", category_image: null }]}
          // menuOptions={suitableVendorsCategories ? [...suitableVendorsCategories, {id: null, parent_category: 'None', parent_category_image: null, subCategories: [ {id: null, category_name: "None", category_image: null }]}]}
          menuOptions={
            suitableVendorsCategories ? [
                ...suitableVendorsCategories,
                {
                    id: null,
                    parent_category: 'None',
                    parent_category_image: null,
                    subCategories: [{
                        id: null,
                        category_name: "None",
                        category_image: null
                    }]
                }
            ] : []
        }
          selectedOption={suitableVendorsCategories?.find(
            (cat) => cat.id == categoryId
          )}
          handleSelectCategory={handleSelectCategory}
          name={"parent_category"}
        />
        {subCategoryId && (
          <ShopProductSort
            type={"Sub-Categories"}
            menuOptions={
              //     suitableVendorsCategories?.find((cat) => cat.id == categoryId)
              // ?.subCategories
              [...(suitableVendorsCategories?.find((cat) => cat.id == categoryId)?.subCategories || []), { id: null, category_name: "None", category_image: null }]
            }
            selectedOption={suitableVendorsCategories
              ?.find((cat) => cat.id == categoryId)
              ?.subCategories?.find((subCat) => subCat.id == subCategoryId)}
            handleSelectCategory={handleSelectSubCategory}
            name={"category_name"}
          />
        )}
      </Breadcrumbs>
    </>
  );
};

export default ItemsBreadCrumbMenu;
