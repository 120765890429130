import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { horecaApi } from "../api/api";


let savedToken = localStorage.getItem("token");
let currentRole = localStorage.getItem("currRole")

if(savedToken) {
    savedToken = JSON.parse(savedToken);
}

if(currentRole) {
    currentRole = JSON.parse(currentRole);
}

const authSlice = createSlice({
    name: "auth",
    initialState: {
        token: savedToken ? savedToken : null,
        user : savedToken ? jwtDecode(savedToken?.access_token) : null,
        userId: savedToken ? jwtDecode(savedToken?.access_token)?.userId : null,
        currOrgRole: currentRole ? currentRole : null,
        isDeliveryPerson: false,
        paymentModes: {},
        allSystemCategory: [],
    },
    reducers: {
        setCurrRole: (state, action) => {
            const {orgId, orgName, roleId, roleName, orgStatus} = action.payload;
            
            state.currOrgRole = {
                org: {id: orgId, name: orgName, status: orgStatus},
                role: {id: roleId, name: roleName},
                userId: state?.user?.userId,
            }
          },
          setInitialAuth:(state, {payload}) =>{
            const jwtToken = jwtDecode(payload?.access_token);
            const data ={
                org: {id: jwtToken?.orgDetail[0]?.id, name: jwtToken?.orgDetail[0]?.orgName, status: jwtToken?.orgDetail[0]?.status},
                role: {id: jwtToken?.orgDetail[0]?.roleDetail[0]?.id, name: jwtToken?.orgDetail[0]?.roleDetail[0]?.roleName},
                userId: jwtToken?.userId,
            };
            state.currOrgRole = data;
            state.token = payload;
            state.user = jwtToken;
            state.userId = jwtToken?.userId;
            localStorage.setItem("token", JSON.stringify(payload));
        },
        setLogOut: (state,action) => {
            localStorage.clear("token");
            state = {};
        },
        
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            horecaApi.endpoints.getMe.matchFulfilled,
            (state, {payload}) => {
                const {preferences, tokens, paymentModes} = payload;
                const jwtToken = jwtDecode(tokens?.access_token);
                state.user = jwtToken;
                state.token = tokens;
                state.paymentModes = paymentModes.length > 0 ? paymentModes[0] : {};
                state.isDeliveryPerson = jwtToken?.orgDetail[0]?.roleDetail[0]?.roleName == 'delivery_person'
               localStorage.setItem("token", JSON.stringify(tokens));
            }
        );
        builder.addMatcher(
            horecaApi.endpoints.getCategories.matchFulfilled,
            (state, {payload}) => {
                state.allSystemCategory= payload;
            }
        )
      }
})


export const {setCurrRole,setInitialAuth, setLogOut} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => (state.auth.token);