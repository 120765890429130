import React from "react";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";

import CustomTextField from "../../../../components/common/CustomTextField";
import { useFormik } from "formik";
import CustomLabel from "../../../../components/common/label/CustomLabel";
import ProfilePic from "../../../../components/common/ProfilePic";
import AddresDetail from "./AddressDetail";
import { horecaApi, useSaveOrganizationDataMutation } from "../../../../redux/api/api";
import Iconify from "../../../../components/common/Iconify";
import { useRouter } from "../../../../routes/hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import { openMessage } from "../../../../redux/slices/showMessageSlice";
import ContactPerson from "./ContactPerson";
import { useResponsive } from "../../../../hooks/useResponsive";
import { getOrgData } from "../../../../utils/org";
import { jwtDecode } from "jwt-decode";
import { approvalPending } from "../../../../assets/images";
import { useState } from "react";
import { organizationProfileSchema } from "../../../../schema/organization/organizationSchema";
import DeliversAt from "./DeliversAt";
import CustomButton from "../../../../components/common/CustomButton";
import CustomMuiTelInput from "../../../../components/common/CustomMuiTelInput";
import InvetoryAndZohoDetail from "./InvetoryAndZohoDetail";
import { useEffect } from "react";
import ItemsLoading from "../../../../components/common/ItemsLoading";

export const getStatusColor = (status) => {
  switch (status) {
    case "drafted":
      return "info";
    case "pending":
      return "warning";
    case "approved":
      return "success";
    case "rejected":
      return "error";
    case "void":
      return "error";
    case "scheduled":
      return "warning";
    case "confirmed":
      return "success";
    case "fulfilled":
      return "success";
    default:
      return "default";
  }
};

const zip = { zipcode: "" };

const add = [
  {
    billing: false,
    line1: "",
    line2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  },
  {
    billing: true,
    line1: "",
    line2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  },
];

const newPerson = { name: "", mobileNumber: "" };

export const setAddress = ({ orgData, type }) => {
  console.log(orgData);
  let addressesNew = [];

  let billingAddressNew = {
    billing: true,
    line1: "",
    line2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  };

  let shippingAddressNew = {
    billing: false,
    line1: "",
    line2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  };

  addressesNew = type == "customer" ? [billingAddressNew, shippingAddressNew] : [billingAddressNew];

  if (orgData && orgData?.addresses && orgData?.addresses?.length > 0) {
    const billingAddress = orgData?.addresses.find((address) => address.billing === true);

    const shippingAddress = orgData?.addresses.find((address) => address.billing === false);

    if (billingAddress && shippingAddress) {
      addressesNew = [billingAddress, shippingAddress];
    } else if (billingAddress) {
      addressesNew = type == "customer" ? [billingAddress, shippingAddressNew] : [billingAddress];
    } else if (shippingAddress) {
      addressesNew = [billingAddressNew, shippingAddress];
    }
  }

  return addressesNew;
};

export const vendorFunction = (orgData) => {
  let add1 = [];
  if (orgData && orgData?.addresses && orgData.addresses.length > 0) {
    const billingAddress = orgData.addresses.find((address) => address.billing === true);

    if (billingAddress) {
      // If billing address exists, set it to add1
      add1[0] = billingAddress;
    } else {
      // If no billing address, set the first address from orgData.addresses to add1
      add1[0] = add[1];
    }
  } else {
    // If no addresses exist, set add1 to an empty array
    add1[0] = add[1];
  }

  return add1;
  // Now add1 contains the billing address if it exists, otherwise the first address or an empty array
};

export const customerFunction = (orgData) => {
  console.log(orgData);
  let add1 = [];
  if (orgData && orgData?.addresses && orgData.addresses.length > 0) {
    const billingAddress = orgData.addresses.find((address) => address.billing === true);

    console.log(billingAddress);

    const shippingAddress = orgData.addresses.find((address) => address.billing === false);

    console.log(shippingAddress);

    if (billingAddress && shippingAddress) {
      add1 = [billingAddress, shippingAddress];
    } else if (billingAddress) {
      add1 = [billingAddress, add[0]];
    } else if (shippingAddress) {
      add1 = [shippingAddress, add[1]];
    } else {
      add1 = add;
    }
  } else {
    // If no addresses exist, set add1 to the default value of add or an empty array
    add1 = add.length >= 2 ? add.slice(0, 2) : [];
  }

  console.log(add1);

  return add1;
};

const OrganizationProfile = ({ handelClose }) => {
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);
  const router = useRouter();
  const dispatch = useDispatch();
  const [isShowMessage, setIsShowMessage] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const [getMe] = horecaApi.endpoints.getMe.useLazyQuery();
  const [getOrganizationById, { data: orgData, isLoading: getOrganizationByIdLoading }] = horecaApi.endpoints.getOrganizationById.useLazyQuery();
  const [saveOrganizationData, { isLoading }] = useSaveOrganizationDataMutation();

  const getProfile = getOrgData(user, orgId);

  React.useEffect(() => {
    (async () => {
      await getOrganizationById(orgId);
    })();
  }, [orgId, isShowMessage]);

  const formik = useFormik({
    initialValues: {
      id: orgId,
      orgName: orgData?.orgName ?? "",
      phoneNumber: orgData?.phoneNumber ?? "",
      type: orgData?.type,
      orgEmail: orgData?.orgEmail ?? "",
      businessDetail: {
        id: orgData?.businessDetail?.id ?? "",
        gstUin: orgData?.businessDetail?.gstUin ?? "",
        panNumber: orgData?.businessDetail?.panNumber ?? "",
        fssi: orgData?.businessDetail?.fssi ?? "",
      },
      addresses: setAddress({ orgData, type: orgData?.type }),
      // addresses: orgData?.type === "customer" ? customerFunction(orgData) : vendorFunction(orgData),
      deliversAt:
        orgData?.deliversAt && (orgData?.deliversAt).length > 0
          ? (orgData?.deliversAt).map((org) => {
              return {
                id: org?.id,
                zipcode: org?.zipcode,
              };
            })
          : [zip],
      contactPersons:
        orgData?.contactPersons && orgData?.contactPersons.length > 0
          ? (orgData?.contactPersons).map((orgPerson) => {
              return {
                id: orgPerson.id,
                name: orgPerson.name,
                mobileNumber: orgPerson.mobileNumber,
              };
            })
          : [{ name: orgData?.orgAdmin?.firstName + " " + orgData?.orgAdmin?.lastName, mobileNumber: orgData?.orgAdmin?.mobileNumber }],
    },
    validationSchema: organizationProfileSchema,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (orgData?.type === "vendor") {
        values.addresses = values.addresses.filter((address) => address.billing === true);
      }
      const result = await saveOrganizationData({ body: values });
      if (result.data) {
        dispatch(
          openMessage({
            message: "Organization Data Updated Successfully!",
            messageSeverity: "success",
          })
        );
        await getOrganizationById(orgId);
        const result = await getMe(user?.userId);
        if (result) {
          const jwtToken = jwtDecode(result.data?.tokens?.access_token);

          const orgData = getOrgData(jwtToken, orgId);

          if (orgData?.status !== "approved" && setIsShowMessage(false))
            if (orgData?.status === "confirmed") {
              setTimeout(async () => {
                handelClose && handelClose();
                router.push(`/`);
              }, 1000);
            }
        }
      } else if (result.error) {
        dispatch(
          openMessage({
            message: result.error.data.message,
            messageSeverity: "error",
          })
        );
      } else {
        dispatch(
          openMessage({
            message: "Not Able To Update Data",
            messageSeverity: "error",
          })
        );
      }
    },
  });

  const smDown = useResponsive("down", "sm");

  const handleSubmit = () => {
    if (!formik.values.businessDetail.gstUin && !formik.values.businessDetail.panNumber) {
      formik.setFieldError("businessDetail.panNumber", "PAN is required when GST/UIN is empty");
      dispatch(openMessage({ message: "PAN is required when GST/UIN is empty", messageSeverity: "error" }));
    } else {
      formik.handleSubmit();
    }
  };

  const handelClickShowMessage = () => {
    setIsShowMessage(true);
  };

  useEffect(() => {
    orgData?.status === "approved" ? setIsShowMessage(true) : setIsShowMessage(false);
  }, [orgData?.status]);

  return (
    <Box
      width={"100%"}
      // padding={4}
      // paddingRight={0}
      pt={4}
    >
      {getOrganizationByIdLoading ? (
        <Stack height={"40vh"}>
          <ItemsLoading />
        </Stack>
      ) : orgData?.status === "drafted" || isShowMessage ? (
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <CustomLabel color={getStatusColor(orgData?.status)}>{orgData?.status}</CustomLabel>
              </Stack>
              <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} gap={"0rem"}>
                <ProfilePic s3key={getProfile?.orgLogoKey} orgId={orgId} name={getProfile?.orgName} />
                <Typography variant="title" sx={{ color: "text.disabled" }}>
                  {orgData?.orgName}
                </Typography>
                <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>{orgData?.type}</Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper
              elevation={smDown ? 0 : 3}
              sx={{
                p: 2,
              }}
            >
              <Typography variant="title" mb={"1rem"}>
                Basic Details
              </Typography>
              <Grid container spacing={3} pt={"0.5rem"}>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    name={"orgName"}
                    label={orgData?.type === "customer" ? "Restaurant  name" : "Shop Name"}
                    formik={formik}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField name={"orgEmail"} label={orgData?.type === "customer" ? "Restaurant email" : "Shop Email"} formik={formik} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomMuiTelInput
                    name={"phoneNumber"}
                    label={orgData?.type === "customer" ? "Restaurant phone number" : "Shop Phone Number"}
                    formik={formik}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <ContactPerson formik={formik} organizationId={orgId} newPerson={newPerson} />
              {orgData?.type === "vendor" && (
                <Stack mt={"1rem"}>
                  <DeliversAt formik={formik} zipCode={zip} organizationId={orgId} />
                </Stack>
              )}
              <AddresDetail formik={formik} isShipping={orgData?.type === "customer" ? true : false} />
              <Stack mt={"1rem"}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="title">Business Details</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      name={"businessDetail.panNumber"}
                      label={"Pan number"}
                      formik={formik}
                      required={!formik.values.businessDetail.gstUin}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField name={"businessDetail.fssi"} label={"FSSAI"} formik={formik} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      name={"businessDetail.gstUin"}
                      label={"GST/UIN"}
                      formik={formik}
                      required={!formik.values.businessDetail.panNumber}
                    />
                  </Grid>
                </Grid>
                {orgData?.type === "vendor" && (
                  <Stack mt={"1rem"}>
                    <Typography variant="title">Inventory Details</Typography>
                    <InvetoryAndZohoDetail orgId={orgId} />
                  </Stack>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "2rem" }}>
                  <CustomButton onClick={handleSubmit} endIcon={<Iconify icon="ph:arrow-right-bold" />} variant="outlined" label={"Save"} />
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Stack sx={{ width: "100%", height: "100%", justifyContent: "center" }}>
          <img src={approvalPending} style={{ width: "350px", height: "350px", alignSelf: "center" }} />
          <Typography fontWeight={600} fontSize={"20px"} textAlign={"center"}>
            Application Status: <span style={{ color: orgData?.status ? "orange" : "red", textTransform: "capitalize" }}>{orgData?.status}</span>
          </Typography>
          <Typography fontWeight={600} fontSize={"28px"} textAlign={"center"}>
            {orgData?.status === "pending" ? (
              <>Waiting For Approval Please Hold On</>
            ) : (
              <>Your application rejected due to this reason {orgData?.rejection_reason}</>
            )}
          </Typography>
          <Stack alignItems={"flex-end"}>
            {orgData?.status === "pending" && (
              <CustomButton sx={{ mt: "1rem" }} disabled={isLoading} onClick={handelClickShowMessage} label={"Edit Organization Profile"} />
            )}
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default OrganizationProfile;
