export function getRole(userData, orgId) {
    // Check if jwtToken is not null or undefined and has orgDetail property
    if (userData?.orgDetail) {
      // Find the organization with the specified orgId
      const org = userData.orgDetail.find((org) => org.id === parseInt(orgId));
  
      // If organization is found, return its roleDetail; otherwise, return null
      return org ? org.roleDetail[0].roleName : null;
    }
    // Return null if jwtToken is null or undefined or doesn't have orgDetail property
    return null;
  }
  
  
  export function getOrgType (userData, orgId) {
    if (userData?.orgDetail) {
      // Find the organization with the specified orgId
      const org = userData.orgDetail.find((org) => org.id === parseInt(orgId));
  
      // If organization is found, return its roleDetail; otherwise, return null
      return org ? org.type : null;
    }
    // Return null if jwtToken is null or undefined or doesn't have orgDetail property
    return null;
  }
  
  export function getOrgData(userData, orgId) {
    if (userData?.orgDetail) {
      // Find the organization with the specified orgId
      const org = userData.orgDetail.find((org) => org.id === parseInt(orgId));
  
      // If organization is found, return its roleDetail; otherwise, return null
      return org ? org : null;
    }
    // Return null if jwtToken is null or undefined or doesn't have orgDetail property
    return null;
  }
  
  export function functionGetByDefaultOrg(userData) {
    // Check if jwtToken is not null or undefined and has orgDetail property
    if (userData?.orgDetail && userData?.orgDetail?.length > 0) {
      // Return the details of the first organization
      return userData.orgDetail[0];
    }
  
    // Return null if jwtToken is null or undefined or doesn't have orgDetail property
    return null;
  }
  
  //options array will be an -> [key: "", label: ""]
  //data -> is an array of data
  //id --> will pass
  //inKeys -> ["id", "orgName", "pinCode"]
  export const makeAnOptionArray = (data, keys, id = "id") => {
    let options = [];
  
    data?.forEach((row) => {
      let label = keys.map((key) => {
        // Splitting the key based on "."
        let splitArray = key.split(".");
        let value = row;
  
        // Traverse through the splitArray to get the corresponding value
        for (let splitKey of splitArray) {
          value = value[splitKey];
        }
  
        return value;
      });
  
      // If there are addresses in the row, find the pin code from the address with billing set to false
      if (row.addresses && Array.isArray(row.addresses) && row.addresses.length > 0) {
        const businessAddress = row.addresses.find(address => !address.billing);
        if (businessAddress) {
          label[1] = businessAddress.pincode; // Update the pin code value in the label array
        }
      }
  
      // Combine label parts into a single string
      let labelString = label.join(", ");
  
      // Pushing the value into the options array
      options.push({ key: row[id], label: labelString});
    });
  
    return options;
  };

  export const determineOrgType = (orgDetail) => {
    let hasVendor = false;
    let hasCustomer = false;
  
    // Iterate through orgDetail array to check for vendor and customer types
    orgDetail.forEach((org) => {
      if (org.type === 'vendor') {
        hasVendor = true;
      } else if (org.type === 'customer') {
        hasCustomer = true;
      }
    });
  
    // Determine the organization type based on the presence of vendor and customer types
    if (hasVendor && !hasCustomer) {
      return 'customer';
    } else if (hasCustomer && !hasVendor) {
      return 'vendor';
    } else if (hasVendor && hasCustomer) {
      return 'both';
    } else {
      return 'none';
    }
  }


  // export const getOrgAndAddresses = (organizations) => {
  //   const transformedOrganizations = organizations.flatMap(org => 
  //     org?.addresses?.map(address => ({
  //       orgName: org?.orgName,
  //       id: org?.id,
  //       phoneNumber: org?.phoneNumber,
  //       orgType: org?.type,
  //       address: address,
  //       orgLogoKey: org?.orgLogoKey
  //     }))
  //   );
  //   return transformedOrganizations;
  // }

  export const getOrgAndAddresses = (organizations) => {
    const transformedOrganizations = organizations.flatMap(org => {
      if (org?.addresses?.length > 0) {
        return org.addresses.map(address => ({
          orgName: org?.orgName,
          id: org?.id,
          phoneNumber: org?.phoneNumber,
          orgType: org?.type,
          address: address,
          orgLogoKey: org?.orgLogoKey
        }));
      } else {
        return [{
          orgName: org?.orgName,
          id: org?.id,
          phoneNumber: org?.phoneNumber,
          orgType: org?.type,
          address: null,
          orgLogoKey: org?.orgLogoKey
        }];
      }
    });
    return transformedOrganizations;
  };
  

  // export const getAllOrgId = (organizations) => {
  //   return organizations
  //     ?.filter(org => org?.type === 'customer')
  //     ?.map(org => org?.id);
  // };

  export const getAllOrgId = (organizations) => {
    return organizations?.map(org => org?.id);
  };