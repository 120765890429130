import { useMemo } from "react";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";

export const TableColumns = (tableName) => {

  const { isMobile } = useResponsiveStyles()

  const columns = useMemo(() => {
    switch (tableName) {
      case "inventoryItem":
        return [
          {
            id: "item_image_key",
            numeric: false,
            disablePadding: true,
            label: "Image",
            style: {
              // minWidth: 100,
              // maxWidth: 200,
              width: '8%',
              // textAlign: "justify",
              // textAlign:'left',
            },
            // align: "left",
          },
          {
            id: "name",
            numeric: false,
            disablePadding: false,
            label: "Item Name",
            style: {
              // minWidth: 200,
              // maxWidth: 300,
              bacgroundColor: 'red',
              width: '37%',
              // bgColor:'black'
              // textAlign: "justify",
              // textAlign:'left',
            },
            // align: "left",

          },
          // {
          //   id: "sku",
          //   numeric: false,
          //   disablePadding: false,
          //   label: "SKU",
          //   style: {
          //     // minWidth: 80,
          //     // maxWidth: 100,
          //     width: '10%',
          //     bacgroundColor: 'white'
          //     // textAlign: "justify",
          //     // textAlign:'left',
          //   },
          //   // align: "left",
          // },
          {
            id: "rate",
            numeric: false,
            disablePadding: false,
            label: "Rate",
            style: {
              // minWidth: 80,
              // maxWidth: 100,
              width: '15%',
              bgColor: 'blue'
              // textAlign: "justify",
            },
            // align: "left",
          },
          {
            id: "unit",
            numeric: false,
            disablePadding: false,
            label: "Unit",
            style: {
              // minWidth: 50,
              // maxWidth: 100,
              width: '10%',
              bgColor: 'pink'
              // textAlign: "justify",
            },
            // align: "left",
          },
          {
            id: "itemQuantityPricesMappingDetails",
            numeric: false,
            disablePadding: true,
            label: "Bulk Rate",
            style: {
              // minWidth: 100,
              // maxWidth: 200,
              width: '25%',
              bgColor: 'green',
              // textAlign: "justify",
              padding: "0px 16px"
              // '& ..MuiListItem-padding':{
              // }
            },
          },
          { id: "action", label: "", disablePadding: true, style: { width: '5%' } },
        ];
      case "orderList":
        return [
          {
            id: "item_image_key",
            numeric: false,
            disablePadding: true,
            label: "Image",
            style: {
              // minWidth: 100,
              // maxWidth: 200,
              width: '8%',
              // textAlign: "justify",
              // textAlign:'left',
            },
            // align: "left",
          },
          {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Item Name",
            style: {
              // minWidth: 200,
              // maxWidth: 300,
              bacgroundColor: 'red',
              width: '20%',
              // textAlign: "justify",
              // textAlign:'left',
            },
            // align: "left",

          },
          {
            id: "sku",
            numeric: false,
            disablePadding: true,
            label: "SKU",
            style: {
              // minWidth: 80,
              // maxWidth: 100,
              width: '8%',
              // textAlign: "justify",
              // textAlign:'left',
            },
            // align: "left",
          },
          {
            id: "quantity",
            numeric: false,
            disablePadding: true,
            label: "Quantity",
            style: {
              // minWidth: 80,
              // maxWidth: 100,
              width: '17%',
              // textAlign: "justify",
            },
            // align: "left",
          },
          {
            id: "previousQuantity",
            numeric: false,
            disablePadding: true,
            label: "Prev Qnt",
            style: {
              // minWidth: 80,
              // maxWidth: 100,
              width: '10%',
              // textAlign: "justify",
            },
          },
          {
            id: "rate",
            numeric: false,
            disablePadding: true,
            label: "Rate",
            style: {
              // minWidth: 80,
              // maxWidth: 100,
              width: '8%',
              // textAlign: "justify",
            },
            // align: "left",
          },
          {
            id: "unit",
            numeric: false,
            disablePadding: true,
            label: "Unit",
            style: {
              // minWidth: 50,
              // maxWidth: 100,
              width: '8%',
              // textAlign: "justify",
            },
            // align: "left",
          },
          {
            id: "itemQuantityPricesMappingDetails",
            numeric: false,
            disablePadding: true,
            label: "Bulk Rate",
            style: {
              // minWidth: 100,
              // maxWidth: 200,
              width: '13%',
              // textAlign: "justify",
            },
          },
          {
            id: "total",
            numeric: false,
            disablePadding: true,
            label: "Total",
            style: {
              // minWidth: 100,
              // maxWidth: 200,
              width: '8%',
              // textAlign: "justify",
            },
          },
          // { id: "action", label: "", style: {width: '8%'}},
        ];
      case "my-vendors":
        return [
          {
            id: "orgLogoKey",
            numeric: false,
            disablePadding: true,
            label: "Logo",
            style: {
              // width: '15%',
              // textAlign: "justify",
              // textAlign:'left',
              whiteSpace: "nowrap",
              padding: "16px 0px 16px 16px"
            },
            padding: "checkbox"
          },
          {
            id: "orgName",
            numeric: false,
            disablePadding: true,
            label: "Vendor Name",
            style: {
              // width: '20%',
              whiteSpace: "nowrap",
            },
            align: "left",
          },
          {
            id: "phoneNumber",
            numeric: false,
            disablePadding: true,
            label: "Phone Number",
            style: {
              // width: '30%',
              // minWidth: 200,
              // maxWidth: 300,
              whiteSpace: "nowrap",
            },
            align: "left",
          },
          {
            id: "isPrivateVendor",
            numeric: false,
            disablePadding: true,
            label: "Private Vendor",
            style: {
              // width: '30%',
              // minWidth: 200,
              // maxWidth: 300,
              whiteSpace: "nowrap",
            },
            align: "left",

          },
          {
            id: "active",
            numeric: false,
            disablePadding: true,
            label: "Active",
            style: {
              // width: '20%',
              // minWidth: 200, 
              // maxWidth: 300                 
              // bgColor:'black'
              // textAlign: "justify",
              // textAlign:'left',
              whiteSpace: "nowrap",
            },
            align: "left",

          },
          {
            id: "action",
            label: "",
            style: {
              // width: '15%',
              whiteSpace: "nowrap",
              padding: "0px 16px"
            },
            padding: "checkbox"
          },
        ];
      case "my-vendors-items":
        return [
          {
            id: "itemId",
            numeric: false,
            disablePadding: true,
            label: isMobile ? "#" : "#Item Id",
            style: {
              width: '10%',
              whiteSpace: "noWrap",
              padding: "4px"
            },
            align: "left"
          },
          {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: isMobile ? "Name" : "Item Name",
            style: {
              // width: '40%',
              width: '45%',
              padding: "4px"
            },
            align: "left"
          },
          {
            id: "quantity",
            numeric: false,
            disablePadding: true,
            label: isMobile ? "Qty" : "Quantity",
            style: {
              width: '20%',
              padding: "4px"
            },
            align: "left"
          },
          {
            id: "preQuantity",
            numeric: false,
            disablePadding: true,
            label: isMobile ? "Prev" : "Prev Qty",
            style: {
              width: '10%',
              padding: "4px",
              whiteSpace: "noWrap"
            },
            align: "center"
          },
          {
            id: "unit",
            numeric: false,
            disablePadding: true,
            label: "Unit",
            style: {
              // width: '10%',
              width: '15%',
              padding: "4px"
            },
            align: "left",

          },
          // {
          //   id: "action",
          //   label: "",
          //   style: {
          //     width: '10%',
          //     padding: "4px"
          //   },
          //   align: "left"
          // }
        ];
      case "my-vendor-items-without-prev":
        return [
          {
            id: "itemId",
            numeric: false,
            disablePadding: true,
            label: isMobile ? "#" : "#Item Id",
            style: {
              width: '10%',
              whiteSpace: "noWrap",
              padding: "4px"
            },
            align: "left"
          },
          {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: isMobile ? "Name" : "Item Name",
            style: {
              width: '55%',
              padding: "4px"
            },
            align: "left"
          },
          {
            id: "quantity",
            numeric: false,
            disablePadding: true,
            label: isMobile ? "Qty" : "Quantity",
            style: {
              width: '25%',
              padding: "4px"
            },
            align: "left"
          },
          {
            id: "unit",
            numeric: false,
            disablePadding: true,
            label: "Unit",
            style: {
              width: '10%',
              padding: "4px"
            },
            align: isMobile ? "right" : "left"
          },
          // { id: "action", label: "", style: { width: '10%' } },
        ];
      case "my-teams":
        return [
          {
            id: "firstName",
            numeric: false,
            disablePadding: true,
            label: "Name",
            style: {
            },
          },
          {
            id: "mobileNumber",
            numeric: false,
            disablePadding: true,
            label: "Mobile Number",
            style: {
              whiteSpace: "nowrap",
            },
            align: "center"
          },
          // {
          //   id: "userDetail.email",
          //   numeric: false,
          //   disablePadding: true,
          //   label: "Email",
          //   style: {
          //     // width:'20%',
          //     },
          // },
          {
            id: "roleDetail",
            numeric: false,
            disablePadding: true,
            label: "Role",
            style: {
              whiteSpace: "nowrap",
            },
            align: "left",
          },
          {
            id: "userDetail.active",
            numeric: false,
            disablePadding: true,
            label: "Status",
            style: {
              // width:'10%',
            },
            align: "center",
          },
          {
            id: "action",
            label: "",
            style: {
              whiteSpace: "nowrap",
              padding: "0px 16px"
            },
            padding: "checkbox"
          },
        ];
      case "my-roles":
        return [
          {
            id: "srno",
            numeric: false,
            disablePadding: true,
            label: "#Role Id",
            style: {
            },
          },
          {
            id: "roleName",
            numeric: false,
            disablePadding: true,
            label: "Name",
            style: {
            },
          },
          {
            id: "action",
            label: "",
            style: {
              whiteSpace: "nowrap",
              padding: "18px 16px"
            },
            padding: "checkbox"
          },
        ];
        case "category":
          return [
            {
              id: "srno",
              numeric: false,
              disablePadding: true,
              label: "#Category Id",
              style: {
              },
            },
            {
              id: "parent_category",
              numeric: false,
              disablePadding: true,
              label: "Name",
              style: {
              },
            },
            {
              id: "subCategoryDetails",
              numeric: false,
              disablePadding: true,
              label: "Sub Category",
              style: {
              },
            },
            {
              id: "action",
              label: "",
              style: {
                whiteSpace: "nowrap",
                padding: "18px 16px"
              },
              padding: "checkbox"
            },
          ];
      case "my-outlets":
        return [
          // {
          //   id: "orgLogo",
          //   numeric: false,
          //   disablePadding: true,
          //   label: "Logo",
          //   style: {
          //     whiteSpace: "nowrap",
          //     padding: "16px 0px 16px 16px",
          //   },
          //   padding: "checkbox",
          // },
          {
            id: "orgName",
            numeric: false,
            disablePadding: true,
            label: "Name",
            style: {
            },
          },
          {
            id: "phoneNumber",
            numeric: false,
            disablePadding: true,
            label: "Mobile Number",
            style: {
              whiteSpace: "nowrap",
            },
            align: "center"
          },
          {
            id: "address",
            numeric: false,
            disablePadding: true,
            label: "Address",
            style: {
            },
          },
          {
            id: "assignUser",
            numeric: false,
            disablePadding: true,
            label: "Assign Users",
            style: {
            },
            align: "center"
          },
          {
            id: "active",
            numeric: false,
            disablePadding: true,
            label: "Status",
            style: {
            },
            align: "center"
          },
          {
            id: "action",
            label: "",
            style: {
              whiteSpace: "nowrap",
              padding: "0px 16px"
            },
            padding: "checkbox"
          },
        ];
    }
  }, [tableName]);
  return columns;
};