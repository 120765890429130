import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GetImageComponent from "../../../components/common/GetImageComponent";
import Iconify from "../../../components/common/Iconify";
import { parseInt } from "lodash";
import ItemsImageComponent from "../../../components/common/ItemsImageComponent";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { horecaApi } from "../../../redux/api/api";
import AskForPrice from "../../../components/common/AskForPrice";
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";

export const OrderListHeader = ({ headLabel }) => {
  return (
    <List
      sx={{
        //width: "100%",
        minWidth: 700,
        bgcolor: (theme) => theme.palette.action.selected,
        borderBottom: (theme) => "1px dashed rgba(145, 158, 171, 0.2)",
      }}
    >
      <ListItem sx={{ width: "100%" }}>
        {headLabel.map((head, index) => {
          return (
            <ListItem sx={{ ...head.style, justifyContent: "flex-end" }}>
              <ListItemText
                key={head.id}
                primary={
                  <Typography variant="subtitle2" sx={{ textAlign: head?.align }}>
                    {head.label}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          );
        })}
      </ListItem>
    </List>
  );
};

export const OrderListRow = ({
  row,
  columns,
  handleQuantityChange,
  action,
  onDelete,
  prevOrders,
  disabled,
  handleIncrement,
  handleDecrement,
  handleRemove,
}) => {
  const { orgId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleQuantityChange1 = (event) => {
    let inputValue = event.target.value;
    // Remove leading zeros if the input is greater than zero
    if (parseInt(inputValue) > 0) {
      inputValue = parseInt(inputValue).toString();
    }
    handleQuantityChange(row?.id, row?.name, row?.unit, inputValue);
  };

  const [getItemPrice] = horecaApi.endpoints.getItemPrice.useLazyQuery();
  const [price, setPrice] = useState(0);

  const getPrice = async (itemId, itemQuantity) => {
    const result = await getItemPrice({ orgId, itemId, itemQuantity });
    setPrice(result?.data);
  };

  useEffect(() => {
    getPrice(row?.id, row?.quantity);
  }, [row]);

  const handleKeyDown = (event) => {
    if (event.key === "-" || event.key === "-") {
      event.preventDefault(); // Prevent the input of the minus sign
    }
  };

  const handleDeleteItem = () => {
    onDelete(row?.id);
  };

  const prevItem = prevOrders?.find((item) => item?.itemId === row?.id);

  const { isMobile } = useResponsiveStyles();

  return (
    <>
      {isMobile ? (
        <Stack mt={"1rem"} borderBottom={"1px dashed rgba(145, 158, 171, 0.2)"} gap={0.5} pb={0.5}>
          <Stack direction={"row"} gap={"0.5rem"} justifyContent="space-between">
            <ItemsImageComponent s3key={row?.item_image_key} name={row?.name} />
            <Box>
              <Typography sx={{ flexGrow: 1, textAlign: "right" }} variant="subtitle2">
                {row?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'grey', textAlign: "right" }}>{`HSN ${row?.hsn}`}</Typography>
            </Box>
          </Stack>
          <Stack direction={"row"} gap={"0.5rem"} justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              {row?.rate == 0 ? <AskForPrice /> : row?.rate}/{row?.unit}
            </Typography>
            <Typography sx={{ flexGrow: 1, textAlign: "right", fontWeight: 500 }} variant="subtitle2">
              ₹{price}{disabled && <span style={{ paddingLeft: 6 }}>/ {row?.quantity ?? 0}</span>}
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={"0.5rem"} justifyContent="space-between">
            <Typography>
              {
                <>
                  <Typography>
                    {row?.itemQuantityPricesMappingDetails?.length > 0 && (
                      <span>
                        {"₹" + row?.itemQuantityPricesMappingDetails[0]?.itemPrice}[
                        <span>{row?.itemQuantityPricesMappingDetails[0]?.itemQuantity + row?.unit}</span>]
                      </span>
                    )}
                    {row?.itemQuantityPricesMappingDetails?.length > 0 && (
                      <IconButton onClick={handleClick}>
                        <Iconify icon="material-symbols-light:keyboard-double-arrow-right" />
                      </IconButton>
                    )}
                  </Typography>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {row?.itemQuantityPricesMappingDetails?.map((rate, index) => (
                      <MenuItem key={index}>
                        <Typography>
                          {"₹" + rate.itemPrice}[{rate.itemQuantity + row.unit}]
                        </Typography>
                      </MenuItem>
                    ))}
                  </Popover>
                </>
              }
            </Typography>
            <Typography sx={{ flexGrow: 1, textAlign: "right" }}>
              {disabled ? (
                // <Typography>{row?.quantity ?? 0}</Typography>
                <></>
              ) : (
                <TextField
                  name="quantity"
                  size="small"
                  fullWidth
                  sx={{ width: "80%" }}
                  label="Quantity"
                  type="text"
                  disabled={disabled ? true : false}
                  value={row?.quantity ? row?.quantity : 0}
                  onChange={handleQuantityChange1}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    inputProps: {
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      style: { textAlign: "center" },
                    },
                    disableUpDown: true,
                    startAdornment: (
                      <InputAdornment position="start" onClick={() => handleDecrement(row?.id, row?.name, row?.unit, parseInt(row?.quantity) - 1)}>
                        {row?.quantity > 0 && <RemoveIcon style={{ cursor: "pointer" }} />}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" onClick={() => handleIncrement(row?.id, row?.name, row?.unit, parseInt(row?.quantity) + 1)}>
                        <AddIcon style={{ cursor: "pointer" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <List
          sx={{
            width: "100%",
            // bgcolor:(theme)=> isChecked(row?.id) && alpha(theme.palette.primary.light, 0.3),
            borderBottom: (theme) => "1px dashed rgba(145, 158, 171, 0.2)",
            ":hover": {
              // bgcolor: (theme) => theme.palette.grey[200],
              bgcolor: (theme) => theme.palette.action.selected,
              transition: "0.3s all",
            },
          }}
          key={row?.id}
        >
          <ListItem
            sx={{
              width: "100%",
              // borderBottom: (theme) => "1px dashed rgba(145, 158, 171, 0.2)",
              // '& .hover': {
              //   bgcolor: (theme) => theme.palette.action.hover
              // }
            }}
          >
            {columns.map((column, index) => {
              let value = null;
              let data = null;
              const style = { ...column?.style };

              switch (column?.id) {
                case "item_image_key": {
                  data = (
                    <ListItem sx={{ ...style }}>
                      <ItemsImageComponent s3key={row[column?.id]} name={row?.name} />
                    </ListItem>
                  );
                  break;
                }
                case "quantity": {
                  data = (
                    <ListItem key={column?.id} sx={{ ...style }}>
                      {disabled ? (
                        <Typography>{row?.quantity ?? 0}</Typography>
                      ) : (
                        <TextField
                          name="quantity"
                          size="small"
                          fullWidth
                          label="Quantity"
                          type="text"
                          disabled={true}
                          value={row?.quantity ? row?.quantity : 0}
                          onChange={handleQuantityChange1}
                          onKeyDown={handleKeyDown}
                          InputProps={{
                            inputProps: {
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              style: { textAlign: "center" },
                            },
                            disableUpDown: true,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                onClick={() => handleDecrement(row?.id, row?.name, row?.unit, parseInt(row?.quantity) - 1)}
                              >
                                {row?.rate ? row?.quantity > 0 && <RemoveIcon style={{ cursor: "pointer" }} /> : null}
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                onClick={() => handleIncrement(row?.id, row?.name, row?.unit, parseInt(row?.quantity) + 1)}
                              >
                                {row?.rate ? <AddIcon style={{ cursor: "pointer" }} /> : ""}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </ListItem>
                  );
                  break;
                }
                case "previousQuantity": {
                  data = (
                    <ListItem key={column?.id} sx={{ ...style }}>
                      <Typography>{prevItem?.quantity ? `${prevItem?.quantity}[${prevItem?.unit}]` : 0}</Typography>
                    </ListItem>
                  );
                  break;
                }
                case "total": {
                  data = (
                    <ListItem key={column?.id} sx={{ ...style }}>
                      {/* <Typography>₹{parseInt(row?.quantity) > 0 ? parseInt(row?.quantity) * parseInt(row?.rate) : 0}</Typography> */}
                      <Typography>₹{price}</Typography>
                    </ListItem>
                  );
                  break;
                }
                case "action": {
                  data = disabled
                    ? ""
                    : action && (
                      <ListItem key={column?.id} sx={{ ...style }}>
                        <IconButton onClick={handleDeleteItem}>
                          <Iconify icon="bi:trash" sx={{ color: "red" }} />
                        </IconButton>
                      </ListItem>
                    );
                  break;
                }
                case "itemQuantityPricesMappingDetails": {
                  data = (
                    <ListItem sx={{ ...style }}>
                      <Typography>
                        {row?.itemQuantityPricesMappingDetails?.length > 0 && (
                          <span>
                            {"₹" + row?.itemQuantityPricesMappingDetails[0]?.itemPrice}[
                            <span>{row?.itemQuantityPricesMappingDetails[0]?.itemQuantity + row?.unit}</span>]
                          </span>
                        )}
                        {row?.itemQuantityPricesMappingDetails?.length > 0 && (
                          <IconButton onClick={handleClick}>
                            <Iconify icon="material-symbols-light:keyboard-double-arrow-right" />
                          </IconButton>
                        )}
                      </Typography>
                      <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {row?.itemQuantityPricesMappingDetails?.map((rate, index) => (
                          <MenuItem key={index}>
                            <Typography>
                              {"₹" + rate.itemPrice}[{rate.itemQuantity + row.unit}]
                            </Typography>
                          </MenuItem>
                        ))}
                      </Popover>
                    </ListItem>
                  );
                  break;
                }
                case "rate": {
                  data = (
                    <ListItem key={column?.id} sx={{ ...style }}>
                      {/* <Typography>₹{parseInt(row?.quantity) > 0 ? parseInt(row?.quantity) * parseInt(row?.rate) : 0}</Typography> */}
                      {row?.rate == 0 ? <AskForPrice /> : <Typography>₹{row?.rate}</Typography>}
                    </ListItem>
                  );
                  break;
                }
                case "name": {
                  let splitArray = column.id.split(".");
                  value = row;

                  for (let key of splitArray) {
                    if (value && value.hasOwnProperty(key)) {
                      value = value[key];
                    } else {
                      value = null;
                      break;
                    }
                  }
                  data = (
                    <ListItem sx={{ ...style }}>
                      <ListItemText primary={<Typography>{value}</Typography>} secondary={<Typography variant="body2" sx={{ color: 'grey' }}>{`HSN ${row?.hsn}`}</Typography>} />
                    </ListItem>
                  );
                  break;
                }
                default: {
                  let splitArray = column.id.split(".");
                  value = row;

                  for (let key of splitArray) {
                    if (value && value.hasOwnProperty(key)) {
                      value = value[key];
                    } else {
                      value = null;
                      break;
                    }
                  }
                  data = (
                    <ListItem sx={{ ...style }}>
                      <Typography>{value}</Typography>
                    </ListItem>
                  );
                  break;
                }
              }
              return <React.Fragment key={index}>{data}</React.Fragment>;
            })}
          </ListItem>
        </List>
      )}
    </>
  );
};

const OrderListComp = () => {
  return <div>OrderListComp</div>;
};

export default OrderListComp;
