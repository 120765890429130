export const rolesConstant = [
    {role: 'vendor'},
    {role: 'cusomter'}
]

export const paymentMode = {
    payByCheque: 'Pay by Cheque',
    payOnDelivery: 'Pay on Delivery',
    payWithDiscco: 'Pay By Discco',
    payByGateway: 'Online'
}

export const paymentModeOptions = [
    {
        value: 'payByCheque',
        label: 'Pay by Cheque'
    },
    {
        value: 'payOnDelivery',
        label: 'Pay on Delivery'
    },
    {
        value: 'payWithDiscco',
        label: 'Pay By Discco'
    },
    {
        value: 'payByGateway',
        label: 'Pay By Gateway'
    },
]