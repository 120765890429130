import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

import Iconify from '../../components/common/Iconify';
import { IconButton, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));


export const StyledRoot1 = styled('div')(({ theme }) => ({
    zIndex: 999,
    opacity:'0.8',
    left: 0,
    display: 'flex',
    cursor: 'pointer',
    position: 'fixed',
    alignItems: 'center',
    top: theme.spacing(16),
    height: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.25),
    boxShadow: theme.customShadows.z20,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderTopRightRadius: Number(theme.shape.borderRadius) * 2,
    borderBottomRightRadius: Number(theme.shape.borderRadius) * 2,
    transition: theme.transitions.create('opacity'),
    '&:hover': { opacity: 0.72 },
  }));
// ----------------------------------------------------------------------


export const VendorNameWidget = ({open, setOpen}) => {
    return (
        <StyledRoot1 sx={{left:open ? '100%' : '0%', transition:'0.5s all'}}>
      <Typography sx={{fontWeight:'700', color: (theme)=>theme.palette.primary.main}}>Admin</Typography>
      
        {
            open
            ?
            <IconButton onClick={()=> setOpen(false)}>
            <Iconify icon="solar:alt-arrow-left-linear" width={24} height={24} />
            </IconButton>
            :
            <IconButton onClick={()=> setOpen(true)}>
            <Iconify icon="iconamoon:arrow-right-2-thin" width={24} height={24} />
            </IconButton>
        }
      
    </StyledRoot1>
    )
}

const CartWidget = ({itemsCount}) =>  {
  return (
    <StyledRoot >
      <Badge showZero badgeContent={itemsCount} color="primary" max={99}>
        <Iconify icon="eva:shopping-cart-fill" width={24} height={24} />
      </Badge>
    </StyledRoot>
  );
}


export default CartWidget;
