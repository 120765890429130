import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Typography, Stack, Grid, IconButton, TextField, Box, Input, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../components/common/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useFormik } from "formik";
import CustomTextField from "../../../components/common/CustomTextField";
import { useRemoveSubcategoryMutation, useUploadCategorySubCategoryImageMutation } from "../../../redux/api/api";
import ConfirmationModal from "../../../components/common/ConfirmationModal";


const RenderSubCategory = ({ catModal, item1, handleDeleteRow, handleAddRow, disabled, currType, uploadImageFunc1, currCategoryData, formik, index }) => {
  console.log(item1);

  const [removeSubcategory] = useRemoveSubcategoryMutation()

  const [uploadCategorySubCategoryImage] = useUploadCategorySubCategoryImageMutation();

  const [itemImage, setItemImage] = useState(null);
  // const upload12 = async (payload, subCategoryId) => {
  //   console.log("aaa", payload, subCategoryId);
  //   await uploadImageFunc1({ categoryId: currCategoryData[0]?.id, payload, subCategoryId: item1?.id });
  // };

  const [subCatId, setSubCatId] = useState(null);

  useEffect(() => {
    setItemImage(item1?.image);
    setSubCatId(item1?.id);
  }, [item1]);

  // const subCatId = item1?.id;
  const handlePicChange = async (event) => {
    console.log("heleloeo", subCatId);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setItemImage(e.target.result);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("file", file);
      console.log(subCatId);
      const result = await uploadCategorySubCategoryImage({
        payload: formData,
        categoryId: currCategoryData[0]?.id,
        subCategoryId: subCatId,
      });
      // console.log(result);
    }
  };

  const [confirmation, setConfirmation] = useState(false)

  const handleRemoveSubcategory = (index) => {
    setConfirmation(true)
  }

  const handleConfirmation = async (res) => {
    if (res === 'confirm') {
      setConfirmation(false)
      handleDeleteRow(index)
      try {
        await removeSubcategory({ categoryId: currCategoryData[0].id, subCategoryId: subCatId }).unwrap();
        // catModal()
      } catch (error) {
        console.log(`Error while deleting the sub category`, error)
      }
    } else {
      setConfirmation(false)
    }
  }

  const uploadFunc = (a, b) => {
    console.log(a, b);
    console.log("gello");
  };

  return (
    <>
      {/* {formik.values.rows?.map((item1, index) => ( */}
      <Grid item container spacing={2} key={item1?.id}>
        <Grid item xs={2}>
          {/* <CustomUploadImages s3key={item1.image} name={item1?.name} uploadFunc={uploadFunc} subCategoryId={subCatId} key={item1?.id} /> */}
          {/* <label htmlFor="icon-button-file">
            <Input accept="image/*" id="icon-button-file" type="file" onChange={handlePicChange} disabled={disabled} />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              // sx={{ borderRadius: "10%", border: (theme) => `1px dashed ${theme.palette.grey[300]}` }}
            >
              <Avatar src={itemImage ? itemImage : ""} sx={{ width: 40, height: 40 }}>
                <PhotoCamera fontSize="large" />
              </Avatar>
            </IconButton>
          </label> */}
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ display: 'flex' }}>
            <CustomTextField
              name={`rows[${index}].name`}
              placeholder="Sub category name"
              formik={formik}
              fullWidth
              variant="standard"
              disabled={disabled}
            />
            {currType === "edit" &&
              <IconButton disabled={disabled} color="primary" onClick={() => handleRemoveSubcategory(`rows[${index}]`)}>
                <DeleteIcon />
              </IconButton>}
          </Box>
        </Grid>
        <Grid item xs={2} sx={{ paddingY: 2 }}>
          <Box sx={{ display: "flex", columnGap: 0 }}>
            {currType === "create" && (
              <IconButton onClick={() => handleDeleteRow(index)} disabled={disabled}>
                <DeleteIcon />
              </IconButton>
            )}
            {(currType === "create" || currType === "edit") && index === formik.values.rows?.length - 1 && (
              <IconButton color="primary" onClick={() => handleAddRow()}>
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>
      {confirmation && <ConfirmationModal subCatData={{ name: item1?.name }} handleModal={handleConfirmation} open={confirmation} />}
    </>
  );
};

const CreateNewCategoryDialogue = ({ open, onClose, disabled, currCategoryData, currType, createNewCategory, isLoading }) => {
  const [uploadCategorySubCategoryImage] = useUploadCategorySubCategoryImageMutation();

  const initValues =
    currCategoryData &&
    currCategoryData[0]?.subCategoryDetails?.map((_obj) => {
      return {
        id: _obj.id,
        name: _obj.category_name,
        image: _obj.category_image,
      };
    });

  const callback = () => {
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      category_name: currCategoryData[0]?.parent_category ?? "",
      sub_cat_info: currCategoryData[0]?.sub_cat_info ?? "",
      rows: initValues && initValues.length > 0 ? initValues : [{ id: null, name: "" }],
    },
    // validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const dataToSave = values.rows.map((_obj) => {
        return { id: _obj?.id, category_name: _obj?.name, sub_cat_info: _obj.sub_cat_info };
      });
      const d = {
        id: currCategoryData[0]?.id ?? null,
        parent_category: values.category_name,
        sub_cat_info: values.sub_cat_info,
        subCategories: dataToSave,
      };
      createNewCategory(d, callback);
    },
  });

  const handleAddRow = () => {
    formik.setFieldValue("rows", [...formik.values.rows, { id: null, name: null }]);
  };

  const handleDeleteRow = (index) => {
    const newRows = [...formik.values.rows];
    newRows.splice(index, 1);
    formik.setFieldValue("rows", newRows);
  };

  const handleDialogueClose = () => {
    formik.resetForm();
    onClose();
  };

  const uploadImageFunc = async (payload, subCategoryId) => {
    await uploadCategorySubCategoryImage({ categoryId: currCategoryData[0]?.id, payload, subCategoryId });
  };

  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px", //Set your width here
            padding: "1rem",
            minHeight: "300px",
          },
        },
      }}
      key={currCategoryData[0]?.id}
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          {currType !== "create" ? "Category" : "Create new category"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleDialogueClose} // Call onClose function when close icon is clicked
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: "2rem" }}>
        <Grid container spacing={2}>
          {/* {currType === "edit" && (
            <Grid item xs={4}>
              <CustomUploadImages
                key={currCategoryData[0]?.id}
                name={currCategoryData[0]?.parent_category}
                uploadFunc={uploadImageFunc}
                s3key={currCategoryData[0]?.parent_category_image}
              />
            </Grid>
          )} */}

          <Grid item xs={currType === "create" ? 12 : 12}>
            <CustomTextField name="category_name" formik={formik} placeholder="Category name" disabled={disabled} variant={"standard"} />
          </Grid>
          <Grid item xs={currType === "create" ? 12 : 12}>
            <CustomTextField name="sub_cat_info" formik={formik} placeholder="Category information text" disabled={disabled} variant={"standard"} />
          </Grid>
        </Grid>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h6" sx={{ mt: "1rem", mb: "0.5rem" }}>
            Sub category
          </Typography>
          {formik.values.rows.length < 1 && <CustomButton label={"Add"} onClick={handleAddRow} />}
        </Stack>
        <Grid container spacing={2}>
          {formik?.values?.rows?.map((item1, index) => (
            <RenderSubCategory
              catModal={onClose}
              formik={formik}
              index={index}
              item1={item1}
              handleAddRow={handleAddRow}
              handleDeleteRow={handleDeleteRow}
              disabled={disabled}
              currType={currType}
              uploadImageFunc1={uploadImageFunc}
              currCategoryData={currCategoryData}
              key={item1?.id}
            />
          ))
          }
        </Grid>
        {currType === "create" ? (
          <Stack mt={"1rem"} alignItems={"center"}>
            <CustomButton label={"Save"} onClick={formik.handleSubmit} loading={isLoading} />
          </Stack>
        ) : currType === "edit" ? (
          <Stack mt={"1rem"} alignItems={"center"}>
            <CustomButton label={"Update"} onClick={formik.handleSubmit} loading={isLoading} />
          </Stack>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default CreateNewCategoryDialogue;
