import { Stack } from "@mui/material";
import Iconify from "./Iconify";

const ItemsLoading = () => {
    return (
        <Stack alignItems={'center'} justifyContent={'center'} width={"100%"} height={"100%"} padding={'auto'}>
            <Iconify icon="svg-spinners:3-dots-bounce" height={40} width={40} />
        </Stack>
    )
}

export default ItemsLoading;
