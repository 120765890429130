// ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// export const primaryFont = 'Public Sans, sans-serif';
// export const secondaryFont = 'Barlow, sans-serif';

// export const fontFamily = 'Public Sans, sans-serif'
// export const fontSecondaryFamily = 'Barlow, sans-serif'

export const primaryFont = 'Mulish, sans-serif';
export const secondaryFont = 'Mulish, sans-serif';

export const fontFamily = 'Mulish, sans-serif'
export const fontSecondaryFamily = 'Mulish, sans-serif'

export const fontWeightRegular = 400
export const fontWeightMedium = 500
export const fontWeightSemiBold = 600
export const fontWeightBold = 700
export const lineHeightRegular = 1.5
export const fontSizeRegular = '0.875rem'
export const fontSizeMedium = '1rem'
export const fontSizeSemiBold = '1.125rem'
export const fontSizeBold = '1.25rem'

// ----------------------------------------------------------------------

export const typography = {
  fontFamily: primaryFont,
  fontSecondaryFamily: secondaryFont,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 800,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'unset',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'unset',
  },
  pageTitle: {
    fontWeight: 800,
    lineHeight: 24 / 14,
    fontSize: pxToRem(24),
    textTransform: 'unset',
  },
  title: {
    fontWeight: 800,
    lineHeight: 24 / 14,
    fontSize: pxToRem(20),
    textTransform: 'unset',
  },
  subtitle: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(16),
    textTransform: 'unset',
  },
  label: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'unset',
  },
  pageTitleRegular: {
    WebkitFontSmoothing: 'antialiased',
    textSizeAdjust: '100%',
    color: 'rgb(33, 43, 54)',
    boxSizing: 'border-box',
    margin: '0px 0px 8px',
    fontWeight: fontWeightBold,
    lineHeight: lineHeightRegular,
    fontFamily: fontFamily,
    fontSize: fontSizeBold,
  },
  titleRegular: {
    WebkitFontSmoothing: 'antialiased',
    textSizeAdjust: '100%',
    color: 'rgb(33, 43, 54)',
    fontWeight: fontWeightBold,
    lineHeight: lineHeightRegular,
    fontFamily: fontFamily,
    fontSize: fontSizeSemiBold,
  },
  subTitleRegular: {
    WebkitFontSmoothing: 'antialiased',
    textSizeAdjust: '100%',
    color: 'rgb(33, 43, 54)',
    fontWeight: fontWeightSemiBold,
    lineHeight: lineHeightRegular,
    fontFamily: fontFamily,
    fontSize: fontSizeMedium,
  },
  labelRegular: {
    WebkitFontSmoothing: 'antialiased',
    textSizeAdjust: '100%',
    color: 'rgb(33, 43, 54)',
    lineHeight: lineHeightRegular,
    fontSize: fontSizeRegular,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    textTransform: 'unset',
  },
  labelBold: {
    WebkitFontSmoothing: 'antialiased',
    textSizeAdjust: '100%',
    color: 'rgb(33, 43, 54)',
    lineHeight: lineHeightRegular,
    fontSize: fontSizeRegular,
    fontFamily: fontFamily,
    fontWeight: fontWeightSemiBold,
    textTransform: 'unset',
  },
  captionRegular: {
    WebkitFontSmoothing: 'antialiased',
    textSizeAdjust: '100%',
    lineHeight: lineHeightRegular,
    fontSize: fontSizeRegular,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    color: 'rgb(145, 158, 171)',
    textTransform: 'unset',
  },
};
