import { Box, Typography } from "@mui/material";

const VendorHubComponent = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>Vendor Hub</Typography>
            <Box>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Sell On Horeca1</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Discover 24/7 Business.</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Discover 24/7 Profits.</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Discover the power of collaborative e-commerce.</Typography>
            </Box>
            <Typography variant="body2">
                Become an Horeca1 Vendor Partner and Sell 24/7 to an industry which never sleeps. Connect to new customers and expand your business. 24/7.
            </Typography>
            <Typography variant="body2">
                What’s more, there is no limitation on how many products you can sell. Or for that matter, how many orders you can handle. With our Automated Order Management systems, sellers can easily service a wider customer base and sell products even without being logged on all the time.
            </Typography>
            <Typography variant="body2">
                The system helps Sellers keep track of their orders and download and print standardized invoices and shipping labels. Automated order management enables sellers to invest more time on driving sales and offering better services to their customers.
            </Typography>
            <Typography variant="body2">
                In a nutshell, automated processes will accentuate your selling experience. You will save money, your products will have more visibility, you will have more freedom to take care of other important aspects of your business – say, marketing, product quality, product range, etc. Partner with Horeca1, and you can practically sell 24/7.
            </Typography>
            <Typography variant="body2">
                Our Vendor Partner Hub provides all the support you’ll ever need to sell 24/7. To earn 24/7.
            </Typography>
            <Typography variant="body2">
                Have queries? Check out our HCX Vendor Partner FAQs to get an answer to all your queries.
            </Typography>

            <Typography variant="h6" sx={{ marginTop: 1, textAlign: 'center', fontWeight: 'bold' }}>Horeca1 Vendor Partner FAQs</Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                FAQs - Getting Started: Registering & Selling
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Why should I sell on Horeca1?</Typography>
            <Typography variant="body2">
                If you are a hospitality supplier, HCX Global is the platform to be on, to grow your business and reach out to the entire Indian Hospitality industry. Being a vendor-partner of Horeca1 gives you access to institutional purchase decision makers in the HoReCa industry.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Who can sell on Horeca1?</Typography>
            <Typography variant="body2">
                Any bonafide entity providing hospitality F&B Supplies is welcome to become a Horeca1 Vendor-Partner.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>What are the documents required to register as a seller on Horeca1?</Typography>
            <Typography variant="body2">
                You are required to have the following documents:
                <ul>
                    <li>PAN Card (Personal PAN for “Proprietorship” businesses and Personal + Business PAN for businesses registered as “Company”)</li>
                    <li>GST / TIN Number</li>
                    <li>Bank account and supporting KYC documents (Address Proof, etc.)</li>
                    <li>FSSAI registration - for categories where it is mandatory</li>
                </ul>
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Does enlisting my business on Horeca1 cost me anything?</Typography>
            <Typography variant="body2">
                For the first Two Thousand business listings, it is absolutely FREE. For certain categories like Services, directory listing is a paid service. However, we have certain free slots for paid listings as well.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Will I get charged for listing products on Horeca1?</Typography>
            <Typography variant="body2">
                Once registered as a business, listing of any number of products on Horeca1 is absolutely free. Horeca1 does not charge anything for listing your catalog online. You only pay a small commission for what you sell.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>I do not have a GST / TIN, can I still register as a seller with only PAN?</Typography>
            <Typography variant="body2">
                Sorry. It is necessary for Vendors to have all the required documents, including GST registration. It increases your credibility as a valid business entity. Horeca1 recommends GST registration.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>What happens post registration on Horeca1?</Typography>
            <Typography variant="body2">
                Once you’ve registered and your account is verified by our team, you’ll automatically get a microsite dedicated to you. You can add Pincodes that you service, add Team, start listing Items with mandatory details and images. Our Vendor-Partner Support Team will be more than happy to assist you with detailed procedures on how to use the website.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>How do I sell on Horeca1?</Typography>
            <Typography variant="body2">
                To sell on Horeca1:
                <ul>
                    <li>Register yourself at www.horeca1.com</li>
                    <li>List your products under specific product categories.</li>
                    <li>Check for all the details once you’ve uploaded the product</li>
                    <li>Once an order is received, delivery instructions will be provided</li>
                    <li>Once an order is successfully delivered, HCX will settle your payment within 14-21 business days.</li>
                </ul>
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>When can I start selling?</Typography>
            <Typography variant="body2">
                After all the required documents have been verified and your seller profile is complete, you can start listing your products and start selling.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold', }}>How many listings are required to start selling?</Typography>
            <Typography variant="body2">
                You are required to have a minimum of 1 listing (unique product) to start selling on Horeca1.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Who takes care of the delivery of my products?</Typography>
            <Typography variant="body2">
                Horeca1 is designed to minimally interfere in your business. Horeca1 will provide you guidelines for logistics. Once you receive an order it will be your responsibility to deliver the product, unless otherwise specified by the Horeca1 Customer-Management Team.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Do I need to courier my products to Horeca1?</Typography>
            <Typography variant="body2">
                No. In most cases you will manage the logistics - unless otherwise specified - as per instructions provided. Horeca1 does not interfere with the current way of doing business. It just attempts to enhance the customer experience.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>How and when will I get paid?</Typography>
            <Typography variant="body2">
                The payment will be made directly to your bank account through NEFT transactions within 14-21 business days of dispatching an order. The actual payment period may vary on certain categories of products.
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                FAQs - Pricing and Payments
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Who decides the price of the product?</Typography>
            <Typography variant="body2">
                As a seller, you will set the price of your products.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold', }}>What are the fees charged?</Typography>
            <Typography variant="body2">
                Once an order is successfully delivered, the following deductions are made from the order item value:
                <ul>
                    <li>Horeca1 Marketplace commission: A pre-decided percentage of the item price paid by the customer; the marketplace commission percentage differs across product categories and sub-categories.</li>
                    <li>Shipping Fee (calculated on the basis of the product weight, dimension, delivery location, etc.): You can ship your products with ease using our Horeca1 Fulfilled Services.</li>
                    <li>GST (applicable on all of the above components)</li>
                </ul>
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', }}>What is Horeca1 Marketplace Commission and how much commission is charged?</Typography>
            <Typography variant="body2">
                Horeca1 Marketplace Commission is a certain percentage of the selling price of your product. It differs across categories and sub-categories.

            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', }}>How and when do I get paid?</Typography>
            <Typography variant="body2">
                All payments are made through NEFT transactions (online banking). The payment is made directly to your bank account within 7-21 business days of dispatching an order. The actual payment period depends on how long you have been selling on HCX, your customer ratings and number of orders fulfilled.
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                FAQs - Listings and Catalog
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>What is listing?</Typography>
            <Typography variant="body2">
                Listing a product refers to filling out all the necessary information and adding images of the product so that a customer can make an informed buying decision.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>How many products do I need to list to start selling?</Typography>
            <Typography variant="body2">
                You are required to have a minimum of 1 listing to start selling on Horeca1.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>How do I list my products on Horeca1?</Typography>
            <Typography variant="body2">
                We give you a step-by-step process of how to list your products on our website. It is important to choose the most suitable category to list your product as it will help customers find your products faster. Based on the category you choose, you'll be asked to include product details such as Pack size, HSN, etc.
                Our Vendor-Partner Support Team would be happy to guide you with the product listing process.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Can I get help for development of catalog (product images, description, etc.)?</Typography>
            <Typography variant="body2">
                Yes, we are happy to help you at every stage while doing business with us. We will guide you with the cataloging process. Our Vendor-Partner Support Team can connect you to our cataloging partners for attractive images and crisp content developed at unbeatable prices.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>How does a catalog partner help me?</Typography>
            <Typography variant="body2">
                Our catalog partners develop high-quality photographs of your products and crisp product descriptions for your product catalog. A good catalog gives your customers a better understanding of your products and helps boost your sales.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>How do I price my products?</Typography>
            <Typography variant="body2">
                When pricing products on Horeca1, please account for the applicable Marketplace Fee and include a suitable margin to arrive at the Selling Price. But keep in mind the competition, and promotional pricing to attract attention of the purchase managers.
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                FAQs - Order Management and Shipping
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Who takes care of the delivery of my products?</Typography>
            <Typography variant="body2">
                Horeca1 is designed to minimally interfere in your business. Horeca1 will provide you guidelines for logistics. Once you receive an order it will be your responsibility to deliver the product, unless otherwise specified so by the Horeca1 Customer-Management Team.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>What should I do if my area is not serviceable by Horeca1?</Typography>
            <Typography variant="body2">
                During registering, save the details of your pin code and click on the Continue button. You will be notified via e-mail when your pin code becomes serviceable.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>How do I manage my orders on Horeca1?</Typography>
            <Typography variant="body2">
                Through our seller dashboard, we make it really easy for you to manage your orders. Whenever a customer places an order, we send you a notification. You need to pack the order and dispatch it within the time frame provided by you.
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                FAQs - Returns and Seller Protection
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>What protection does Horeca1 offer in case of lost/damaged goods and fraudulent customer claims?</Typography>
            <Typography variant="body2">
                Horeca1 has a strong Seller Protection Plan to protect our sellers against fraud. You can request a claim through the seller dashboard. When the buyer or logistics partner is at fault, you will receive due compensation.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Would I get compensation if the customer has returned damaged products?</Typography>
            <Typography variant="body2">
                Yes, you can raise a claim through the Seller Protection Plan. Depending on the case and category, you will be given a refund provided you have adequate proof that you shipped an authentic/undamaged product. This will help us close the dispute in your favor.
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Would I get compensation if the customer has replaced the original product with a different item?</Typography>
            <Typography variant="body2">
                Yes, you can raise a claim through the Seller Protection Plan. Depending on the case and category, you will be given a refund provided you have adequate proof that you shipped the right product. This will help us close the dispute in your favor.
            </Typography>


        </Box>
    );
};

export default VendorHubComponent;
