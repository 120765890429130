import { Box, Paper, Stack, Tab, Tabs, Typography, styled } from '@mui/material'
import React, { useState } from 'react'
import { approvalPending, shoppingGif, thankyouGif, zomatoLogo } from '../../../../../assets/images';


const CustomTab = styled(Tab)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '85px',
    padding: theme.spacing(2),
    textTransform: 'none',
    '& .MuiTab-wrapper': {
      flexDirection: 'column',
    },
    '& .nav-icon img': {
      width: '40px',
      height: '40px',
    },
    '& .nav-text': {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1,
    },
    '& .bullet-custom': {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    //   height: '4px',
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-selected .nav-text': {
      color: theme.palette.text.primary,
    },
  }));

  const categories = [
    { label: 'T-shirt', icon: {shoppingGif} },
    { label: 'Gaming', icon: {thankyouGif} },
    { label: 'Watch', icon: {approvalPending} },
  ];


const RecentOrders = () => {

    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
      setSelectedTab(newValue);
    };


  return (
    <Paper sx={{borderRadius:'16px', padding:'1rem', height:'100%'}}>
        <Typography variant='h5'>Top Selling Items</Typography>
        <Box sx={{ width: '100%' }}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="category tabs"
      >
        {categories.map((category, index) => (
          <CustomTab
            key={index}
            label={
              <Box>
                <div className="nav-icon">
                  <img src={zomatoLogo} alt={category.label} />
                </div>
                <Typography className="nav-text">{category.label}</Typography>
                <span className="bullet-custom" />
              </Box>
            }
          />
        ))}
      </Tabs>
    </Box>
    </Paper>
  )
}

export default RecentOrders