import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { horecaLogo } from '../../assets/images';
import LoginDialogue from '../auth/sections/LoginDialogue';
import useResponsiveStyles from '../../hooks/useResponsiveStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useLocalStorageContext } from '../../hooks/LocalStorageProvider';

// Custom styled components
const HeaderWrapper = styled(Box)(({ theme, isScrolled, responsive }) => ({
  width: '100vw',
  //padding: responsive?.isMobile ? '0px' : '10px 0',
  display: 'flex',
  alignItems: 'center',
  borderBottom: isScrolled && '.5px solid #e8e8e8',
  position: 'sticky',//fixed
  height: responsive.isMobile ? '100px' : '88px',
  left: 0,
  top: 0,
  right: 0,
  zIndex: 999,
  background: isScrolled ? '#fff' : 'transparent',
  boxShadow: isScrolled ? '0 4px 4px rgba(0,0,0,.4)' : '0 4px 4px rgba(0,0,0,.4)',
  transition: 'box-shadow 0.3s ease-in-out, border-radius 0.3s ease-in-out',
  borderBottom: 'none',
  borderRadius: isScrolled ? '0 0 40px 40px' : 'none',
}));

const LogoWrapper = styled(Box)({
  width: 'fit-content',
  cursor: 'pointer',

});

const HeaderContent = styled(Box)(({ responsive }) => ({
  width: '100%',
  display: 'flex',
  padding: responsive.isMobile ? 16 : 24,
  alignItems: 'center',
  justifyContent: 'space-between',
}));


const StyledButton = styled(Button)(({ theme }) => ({
  // fontFamily: 'OurLexend-Regular, Helvetica, sans-serif',
  fontWeight: 700,
  height: '40px',
  borderRadius: '0.625rem'
}));

const Header = ({ setOpenLocation }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const { location } = useLocalStorageContext();
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [open, setOpen] = useState(false);

  const onCloseClick = () => {
    setOpen(false);
  }

  const responsive = useResponsiveStyles();

  if (responsive.isMobile) {
    return (
      <HeaderWrapper isScrolled={isScrolled} responsive={responsive}>
        <Box sx={{ width: '100%', padding: 2 }}>
          <Box sx={{display:'flex', justifyContent:'space-between' }}>
            <LogoWrapper component={Link} to="/">
              <Box>
                <img
                  alt="Logo"
                  loading="lazy"
                  width="130"
                  height="50"
                  decoding="async"
                  src={horecaLogo}
                  style={{ color: 'transparent' }}
                />
              </Box>
            </LogoWrapper>
            <Box sx={{ display: 'flex', gap: 3 }}>

              <StyledButton
                variant="text"
                onClick={() => setOpen(true)}
                size='large'>
                Login/Sign-up
              </StyledButton>
            </Box>
          </Box>
          <Box onClick={() => setOpenLocation(true)} sx={{
            display: 'flex', gap: 0.5, alignItems: 'center',justifyContent:'end',
            '&:hover': {
              cursor: 'pointer'
            },
          }}>
            <Typography variant='body2' sx={{ fontWeight: 'bold', color:'#2066D1' }}>{location.area} - </Typography>
            <Typography variant='body2' sx={{ fontWeight: 'bold', color:'#2066D1' }}>{location.pincode}</Typography>
            <KeyboardArrowDownIcon />
          </Box>

        </Box>
        {open && <LoginDialogue
          open={open}
          handelCloseDialogue={onCloseClick}
        />}
      </HeaderWrapper>
    );
  }
  return (
    <HeaderWrapper isScrolled={isScrolled} responsive={responsive}>
      <HeaderContent responsive={responsive}>
        <LogoWrapper component={Link} to="/">
          <Box>
            <img
              alt="Logo"
              loading="lazy"
              width="130"
              height="50"
              decoding="async"
              src={horecaLogo}
              style={{ color: 'transparent' }}
            />
          </Box>
        </LogoWrapper>
        {/* <Box sx={{marginRight:'20px', width:'25%', }}/> */}
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box onClick={() => setOpenLocation(true)} sx={{
            display: 'flex', gap: 0.5, alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            },
          }}>
            {/* <Typography variant='body2' sx={{ fontWeight: 'bold' }}>Select Location</Typography> */}
            <Typography variant='body2' sx={{ fontWeight: 'bold', color:'#2066D1' }}>{location.area} - </Typography>
            <Typography variant='body2' sx={{ fontWeight: 'bold', color:'#2066D1' }}>{location.pincode}</Typography>
            <KeyboardArrowDownIcon />
          </Box>
          <StyledButton
            variant="text"
            // component={Link} to='/login' 
            onClick={() => setOpen(true)}
            size='large'>
            Login/Sign-up
          </StyledButton>
        </Box>
      </HeaderContent>
      {open && <LoginDialogue
        open={open}
        handelCloseDialogue={onCloseClick}
      />}
    </HeaderWrapper>
  );
};

export default Header;
