import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VendorLogoImageComponent from "../../components/common/VendorLogoImageComponent";
import PrivateVendorOrderTable from "./PrivateVendorOrderTable";
import { horecaApi } from "../../redux/api/api";
import useQueryParams from "../../routes/hooks/useQueryParams";
import { convertToOrderListDetails } from "./PrivateVendorOrderDetail";
import axios from "axios";

const PrivateVendorOrderView = () => {
  const { orderId, access_token } = useQueryParams();
  const [orderData, setOrderData] = useState(null);

  // const [getPrivateVendorOrderDetails, { data: orderData }] = horecaApi.endpoints.getPrivateVendorOrderDetails.useLazyQuery();

  console.log(orderData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_URL}horeca1_backend/private-vendor/v3/order/${orderId}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setOrderData(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error); // Handle the error
      }
    };

    fetchData();
  }, [orderId, access_token]);

  return (
    <Container maxWidth="xl">
      <Stack mt={"1rem"} mb={"1rem"}>
        <Typography variant="h4">Order#{orderId}</Typography>
      </Stack>
      <Paper sx={{ padding: { xs: "1rem", sm: "2.5rem" } }}>
        <Grid item xs={12} md={6}>
          <VendorLogoImageComponent s3Key={orderData?.customerDetails?.orgLogoKey} name={orderData?.customerDetails?.orgName} />
          <Typography variant="h6" sx={{ mt: "1rem" }}>
            Customer Details
          </Typography>
          <Grid container>
            {[
              { label: "Name", value: orderData?.customerDetails?.orgName },
              { label: "Phone Number", value: orderData?.customerDetails?.phoneNumber },
              { label: "Email", value: orderData?.customerDetails?.orgEmail },
              {
                label: "Address",
                value: (
                  <span>
                    {orderData?.customerDetails?.addresses[0]?.line1
                      ? `${orderData?.customerDetails?.addresses[0]?.landmark ? orderData?.customerDetails?.addresses[0]?.landmark + ", " : ""}${
                          orderData?.customerDetails?.addresses[0]?.line1 ? orderData?.customerDetails?.addresses[0]?.line1 + ", " : ""
                        }${orderData?.customerDetails?.addresses[0]?.line2 ? orderData?.customerDetails?.addresses[0]?.line2 + ", " : ""}${
                          orderData?.customerDetails?.addresses[0]?.city ? orderData?.customerDetails?.addresses[0]?.city + ", " : ""
                        }${orderData?.customerDetails?.addresses[0]?.state ? orderData?.customerDetails?.addresses[0]?.state + ", " : ""}${
                          orderData?.customerDetails?.addresses[0]?.pincode ?? ""
                        }`
                      : ""}
                  </span>
                ),
              },
            ].map((vendorDet, index) => (
              <>
                <Grid item xs={6} container key={index}>
                  <Typography sx={{ color: "text.disabled" }} variant="subtitle2" gutterBottom>
                    {vendorDet.label}
                  </Typography>
                </Grid>
                <Grid item xs={6} padding={"auto"} key={index}>
                  <Typography variant="body2" gutterBottom sx={{ textWrap: "wrap", overflow: "hidden" }}>
                    {vendorDet.value}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <VendorLogoImageComponent s3Key={orderData?.vendorDetails?.orgLogoKey} name={orderData?.vendorDetails?.orgName} />
          <Typography variant="h6" sx={{ mt: "1rem" }}>
            Vendor Details
          </Typography>
          <Grid container>
            {[
              { label: "Name", value: orderData?.vendorDetails?.orgName },
              { label: "Phone Number", value: orderData?.vendorDetails?.phoneNumber },
              { label: "Email", value: orderData?.vendorDetails?.orgEmail },
              {
                label: "Address",
                value: (
                  <span>
                    {orderData?.vendorDetails?.addresses[0]?.line1
                      ? `${orderData?.vendorDetails?.addresses[0]?.landmark ? orderData?.vendorDetails?.addresses[0]?.landmark + ", " : ""}${
                          orderData?.vendorDetails?.addresses[0]?.line1 ? orderData?.vendorDetails?.addresses[0]?.line1 + ", " : ""
                        }${orderData?.vendorDetails?.addresses[0]?.line2 ? orderData?.vendorDetails?.addresses[0]?.line2 + ", " : ""}${
                          orderData?.vendorDetails?.addresses[0]?.city ? orderData?.vendorDetails?.addresses[0]?.city + ", " : ""
                        }${orderData?.vendorDetails?.addresses[0]?.state ? orderData?.vendorDetails?.addresses[0]?.state + ", " : ""}${
                          orderData?.vendorDetails?.addresses[0]?.pincode ?? ""
                        }`
                      : ""}
                  </span>
                ),
              },
            ].map((vendorDet, index) => (
              <>
                <Grid item xs={6} container key={index}>
                  <Typography sx={{ color: "text.disabled" }} variant="subtitle2" gutterBottom>
                    {vendorDet.label}
                  </Typography>
                </Grid>
                <Grid item xs={6} padding={"auto"} key={index}>
                  <Typography variant="body2" gutterBottom sx={{ textWrap: "wrap", overflow: "hidden" }}>
                    {vendorDet.value}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
        <PrivateVendorOrderTable
          name="my-vendor-items-without-prev"
          title="Ordered Items"
          items={
            convertToOrderListDetails(orderData?.orderItemMapping)
              ? convertToOrderListDetails(orderData?.orderItemMapping)[orderData?.orderListId]?.items
              : []
          }
          isRead={true}
          isPrevQuant={false}
        />
      </Paper>
    </Container>
  );
};

export default PrivateVendorOrderView;
