import { Badge, Box, Drawer, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import GetImageComponent from "../../../components/common/GetImageComponent";
import { useState } from "react";
import CategoryProducts from "./CategoryProducts";
import ProductSearchResult from "./ProductSearchResult";
import ClearIcon from '@mui/icons-material/Clear';
import Iconify from "../../../components/common/Iconify";
import ListAltIcon from '@mui/icons-material/ListAlt';
import WishlistMobile from "./WishlistMobile";

const VendorProducts = ({ vendorDetails, categoryDetails }) => {
    const [selectedCategory, setSelectedCategory] = useState();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [orderListDrawerOpen, setOrderListDrawerOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [wishList, setWishList] = useState([]);

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setIsDrawerOpen(open);
    }
    const toggleListDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOrderListDrawerOpen(open);
    }
    const handleClearSearch = () => {
        setSearchText('');
    };
    const addToWishlist = (_item) => {
        const indexToRemove = wishList.findIndex((_obj) => _obj.id == _item.id);
        if (indexToRemove > -1) {
            setWishList(wishList.filter((_, index) => index !== indexToRemove));
        } else {
            setWishList([...wishList, _item]);
        }
    }
    const onListCreated = () => {
        setWishList([]);
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }} >
                <TextField
                    variant="outlined"
                    fullWidth
                    size="medium"
                    value={searchText}
                    onChange={handleSearchChange}
                    placeholder={`Search in ${vendorDetails?.org_name}`}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 5, // Customize the border radius here
                            backgroundColor: '#F9FAFC', // Customize the background color here
                            border: '1px solid rgb(231, 232, 235)'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent', // Optional: make the border transparent
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'gray', // Optional: border color on hover
                        },
                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'blue', // Optional: border color when focused
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: '#595e75', // Customize the placeholder color here
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {/* <SearchIcon sx={{ color: '#171E30' }} /> */}
                                <Iconify icon="mingcute:search-line" />
                            </InputAdornment>
                        ),
                        endAdornment: searchText ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear search"
                                    onClick={handleClearSearch}
                                    edge="end"
                                    size="small"
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                    }}
                />
                <IconButton size="large" onClick={() => setOrderListDrawerOpen(true)}>
                    <Badge badgeContent={wishList.length} color="error" >
                        <ListAltIcon fontSize="large" sx={{ color: '#757575' }} />
                    </Badge>
                </IconButton>
            </Box>
            {
                searchText &&
                <ProductSearchResult wishList={wishList} addToWishlist={addToWishlist} searchText={searchText} />
            }
            {!selectedCategory && !searchText && <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="h6">
                    Shop by category
                </Typography>
                <Box>
                    <Grid container spacing={2}>
                        {
                            categoryDetails && categoryDetails?.map((cat, index) => {
                                return (
                                    <Grid key={`${index}cat`} item xs={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box onClick={() => setSelectedCategory(cat)} sx={{ flexGrow: 1 }}>
                                            <Box sx={{ backgroundColor: '#E6F3F3', borderRadius: 1.5, padding: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <GetImageComponent s3Key={cat?.parent_category_image} style={{ width: "60px", height: "60px" }} />
                                            </Box>
                                            <Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 12 }}>
                                                {cat?.parent_category}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Box>
            }
            {selectedCategory && <CategoryProducts wishList={wishList} addToWishlist={addToWishlist} selectedCategory={selectedCategory} setIsDrawerOpen={setIsDrawerOpen} />}
            <Drawer
                anchor="bottom"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                PaperProps={{
                    sx: { borderRadius: '20px 20px 0 0', p: 2 }, // Optional: Customize drawer styling
                }}
            >
                <Box
                    sx={{
                        width: 'auto',
                        padding: 1,
                    }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <Grid container spacing={2}>
                        {
                            categoryDetails && categoryDetails?.map((cat, index) => {
                                return (
                                    <Grid key={`${index}cat`} item xs={3}>
                                        <Box onClick={() => setSelectedCategory(cat)}>
                                            <Box sx={{ backgroundColor: '#E6F3F3', borderRadius: 1.5, padding: 2 }}>
                                                <GetImageComponent s3Key={cat?.parent_category_image} />
                                            </Box>
                                            <Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {cat?.parent_category}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Drawer>

            <Drawer
                anchor="bottom"
                open={orderListDrawerOpen}
                onClose={toggleListDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                      height: '80vh', // Full height
                      borderTopLeftRadius: 8, // Optional rounded corners
                      borderTopRightRadius: 8, // Optional rounded corners
                    },
                  }}
                PaperProps={{
                    sx: { borderRadius: '20px 20px 0 0', p: 1 }, // Optional: Customize drawer styling
                }}
            >
                <Box
                    sx={{
                        width: 'auto',
                        padding: 1,
                    }}
                    role="presentation"
                    //onClick={toggleListDrawer(false)}
                    //onKeyDown={toggleListDrawer(false)}
                >
                    {orderListDrawerOpen && <WishlistMobile onListCreated={onListCreated} addToWishlist={addToWishlist} onClose={() => setOrderListDrawerOpen(false)} wishList={wishList}/>}
                </Box>
            </Drawer>
        </Box>
    )
}

export default VendorProducts;