// types
import { createSlice } from "@reduxjs/toolkit";
import { getFilteredVendors, getSuitableVendors } from "../../_mock/vendor";
import { horecaApi, useSavePreferncesMutation } from "../api/api";
import { setScrollbarBackground } from "../../theme/css";
import { getOrgAndAddresses } from "../../utils/org";

// initial state
const initialState = {
  pageLoader: false,
  isSearchOpen: false,
  suitableVendors: [],
  fiteredVendors: [],
  selectedCategoryOnExplore: [],
  contactList: [],
  sessionExpiredDialog: false,
  rowsPerPageData: {
    inventoryPage: 5,
    salesOrderPage: 5,
    invoicePage: 5,
    orderHistoryPage: 5,
    vendorPage: 5,
    customerPage: 5
  },
  currSelectedPage: {
    inventoryPage: 0,
    salesOrderPage: 0,
    invoicePage: 0,
    orderHistoryPage: 0,
    vendorPage: 0,
    customerPage: 0
  },
  preference: {
    selectedOrgId: "",
    selectedRoleId: "",
    initialTour: "false",
    isOrderListTour: "false",
    isOrderList: "false",
    selectedCategories: [],
    selectedLanguage: "Hindi",
    mode: "light",
    brand: '',
    selectedPinCode: ''
  },
  orgWiseAddresses: [],
};

// ==============================|| SLICE - MENU ||============================== //

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    getSuitableVendorsList(state, action) {
      const { pincode, categories } = action.payload;
      state.suitableVendors = getSuitableVendors(pincode, categories);
    },
    getFilteredVendorsList(state, action) {
      const { pincode, categories, searchText } = action.payload;
      state.fiteredVendors = getFilteredVendors(
        pincode,
        searchText,
        categories
      );
    },
    setSavePrfernces(state, action) {
      const { key, value } = action.payload;
      state.preference[key] = value;
    },
    setInitialTour(state, action) {
      state.preference.initialTour = action.payload;
    },
    setOrderListTour(state, action) {
      state.preference.isOrderListTour = action.payload;
    },
    setSelectedCategory(state, action) {
      state.preference.selectedCategories = action.payload;
    },
    setCurrentMode(state, action) {
      state.preference.mode = action.payload.mode;
    },
    setSelectedCategoryOnExplore(state,action) {
      state.selectedCategoryOnExplore = action.payload;
    },
    setPageLoader(state, action) {
      state.pageLoader = action.payload;
    },
    setIsSearch(state,action) {
      state.isSearchOpen = action.payload;
    },
    openSessionExpiredDialog(state, action){
      state.sessionExpiredDialog = action.payload;
    },
    setRowsPerPage(state, action){
      const {name, value} = action.payload;
      state.rowsPerPageData[name] = value;
    },
    setCurrSelectedPage(state, action){
      const {name, value} = action.payload;
      state.currSelectedPage[name] = value;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      horecaApi.endpoints.getMe.matchFulfilled,
      (state, { payload }) => {
        const { preferences, access_token } = payload;
        state.preference.selectedOrgId = preferences?.selectedOrgId
          ? preferences?.selectedOrgId
          : "";
        state.preference.selectedRoleId = preferences?.selectedRoleId
          ? preferences?.selectedRoleId
          : "";
        state.preference.initialTour =
          preferences?.initialTour == "true"
            ? true
            : "false";
        state.preference.isOrderListTour =
          preferences?.isOrderListTour == "true" ? true : false;
          state.preference.isOrderList =
          preferences?.isOrderList == "true" ? true : false;
        state.preference.selectedPinCode = preferences?.selectedPinCode ? JSON.parse(preferences?.selectedPinCode) : '';
        state.preference.selectedCategories = preferences?.selectedCategories
          ? JSON.parse(preferences?.selectedCategories)
          : [];
        state.preference.selectedLanguage = preferences?.selectedLanguage
          ? preferences?.selectedLanguage
          : "en";
        state.preference.mode = preferences?.mode ? preferences?.mode : "light";
        state.preference.brand= preferences?.brand ? preferences?.brand : 'brand5';
        setScrollbarBackground(preferences?.mode); 
      }
    );
    builder.addMatcher(
      horecaApi.endpoints.getSuitableVendorList.matchFulfilled,
      (state, { payload }) => {
        state.suitableVendors = payload;
      }
    );
    builder.addMatcher(
      horecaApi.endpoints.getAllShippingAddressOutletWise.matchFulfilled,
      (state, { payload }) => {
        const data = getOrgAndAddresses(payload);
        state.orgWiseAddresses = data;
      }
    );
    builder.addMatcher(
      horecaApi.endpoints.getContactPersons.matchFulfilled,
      (state, { payload }) => {
        
        const filteredContacts = payload.filter(contact => contact.contactPersonName.trim() !== '' && contact.contactPersonNumber.trim() !== '');

        // Remove duplicate objects based on contactPersonName and contactPersonNumber
        const uniqueContacts = filteredContacts.reduce((unique, contact) => {
          const key = contact.contactPersonName.trim() + contact.contactPersonNumber.trim();
          if (!unique.has(key)) {
            unique.set(key, contact);
          }
          return unique;
        }, new Map()).values();
        state.contactList= Array.from(uniqueContacts);
      }
    );
  },
});

export default appSlice.reducer;

export const {
  getSuitableVendorsList,
  getFilteredVendorsList,
  setInitialTour,
  setOrderListTour,
  setSelectedCategory,
  setCurrentMode,
  setSavePrfernces,
  setSelectedCategoryOnExplore,
  setPageLoader,
  setIsSearch,
  openSessionExpiredDialog,
  setRowsPerPage,
  setCurrSelectedPage
} = appSlice.actions;
