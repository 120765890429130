import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, MenuList, Stack, Typography } from "@mui/material";
import OrganizationProfile from "./OrganizationProfile";
import CustomLabel from "../../../../components/common/label/CustomLabel";
import { useDispatch, useSelector } from "react-redux";
import { useSavePreferncesMutation } from "../../../../redux/api/api";
import { setSavePrfernces } from "../../../../redux/slices/appReducer";
import { useRouter } from "../../../../routes/hooks/useRouter";
import CustomButton from "../../../../components/common/CustomButton";
import { setLogOut } from "../../../../redux/slices/authSlice";
import UpdateOrganizationMenu from "./UpdateOrganizationMenu";
import useResponsiveStyles from "../../../../hooks/useResponsiveStyles";

const OrganizationProfileDialogue = ({ handelCloseDialogue, open }) => {
  const { isMobile } = useResponsiveStyles();

  const router = useRouter();
  const dispatch = useDispatch();
  const [savePrefernces] = useSavePreferncesMutation();
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);
  const user = useSelector((state) => state.auth.user);


  const filteredOrg = user.orgDetail?.filter(
    (org) => org?.id !== parseInt(orgId)
  );


  const handleChangeOrg = (orgId) => {
    const payload = { key: "selectedOrgId", value: orgId };
    dispatch(setSavePrfernces(payload));
    savePrefernces({ payload });
    router.reload(`/`);
  };


  // {user?.orgDetail.length > 1 && (
  //   <>
  //     <Stack direction={"row"} justifyContent={"left"}>
  //       {" "}
  //       <Button
  //         variant="text"
  //         style={{marginLeft:8}}
  //         onClick={() => handleChangeOrg(filteredOrg[0]?.id)}
  //       >
  //         {" "}
  //         Switch to {filteredOrg[0]?.type}
  //       </Button>{" "}
  //     </Stack>
  //     <Divider sx={{ borderStyle: "dashed", m: 0 }} />
  //   </>
  // )}

  const handelLogOut = () => {
    dispatch(setLogOut());
    router.reload("/");
  };

  return (
    <Dialog
      maxWidth={"lg"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            // maxWidth: "350px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle sx={{ padding: isMobile ? 1 : 2 }}>
        {/* {
          user?.orgDetail.length > 1 &&
          <CustomButton
            variant="contained"
            onClick={() => handleChangeOrg(filteredOrg[0]?.id)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            label={`Switch to ${filteredOrg[0]?.type}`}
          />
        } */}
        {!isMobile && <Box>
          <Typography variant="h4">
            <CustomLabel color={"warning"} sx={{ width: '70%', display: 'flex', justifyContent: 'flex-start' }}>Title : Update Your Organization Details!</CustomLabel>
          </Typography>
        </Box>}
        {
          isMobile ?
            <Box sx={{ display:'flex', alignItems:'center', gap: 1 }}>
              <MenuList >
                <UpdateOrganizationMenu variant={'outlined'} />
              </MenuList>
              <CustomButton
                variant="contained"
                onClick={handelLogOut}
                label={"Logout"}
              />
            </Box> :
            <Stack direction={'row'} alignItems={'center'} gap={4} sx={{
              position: "absolute",
              right: "15px",
              top: 8,
            }}>
              <MenuList >
                <UpdateOrganizationMenu variant={'outlined'} />
              </MenuList>
              <CustomButton
                variant="contained"
                onClick={handelLogOut}
                label={"Logout"}
              />
            </Stack>
        }
      </DialogTitle>
      <DialogContent>
        {isMobile && <Typography variant="h4">
          <CustomLabel color={"warning"} sx={{ width: '70%', display: 'flex', justifyContent: 'flex-start' }}>Title : Update Your Organization Details!</CustomLabel>
        </Typography>}
        <Grid container spacing={3}>
          <OrganizationProfile handelClose={handelCloseDialogue} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OrganizationProfileDialogue;
