import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { DotButton, PrevButton, NextButton } from "./EmblaCarasousalDotButton";
import { useResponsive } from "../../../hooks/useResponsive";
import { horecaApi } from "../../../redux/api/api";
import { base64ToArrayBuffer } from "../../../utils/base64toArray";

const EmblaCarousel = (props) => {
  const { options, isHome, bannerImages } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [images, setImages] = useState({ desktop: [], mobile: [] });
  const [getImage] = horecaApi.endpoints.getImage.useLazyQuery();
  const mdDown = useResponsive("down", "md");

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  const pairImages = (imageArray) => {
    if (!Array.isArray(imageArray)) return [];
    const pairedArray = [];
    for (let i = 0; i < imageArray.length; i += 2) {
      pairedArray.push(imageArray.slice(i, i + 2));
    }
    return pairedArray;
  };

  useEffect(() => {
    const fetchImages = async () => {
      const fetchImage = async (s3key) => {
        if (!s3key) return null;
        try {
          const result = await getImage(s3key).unwrap();
          if (result?.img) {
            const byteArray = base64ToArrayBuffer(result.img);
            const blob = new Blob([byteArray], { type: "application/octet-stream" });
            return URL.createObjectURL(blob);
          }
          return null;
        } catch (error) {
          console.error("Error fetching banner image:", error);
          return null;
        }
      };

      const desktopImages = [];
      if (Array.isArray(bannerImages?.desktop_prime_deal)) {
        for (const s3key of bannerImages.desktop_prime_deal) {
          const image = await fetchImage(s3key);
          desktopImages.push(image);
        }
      }

      const mobileImages = [];
      if (Array.isArray(bannerImages?.mobile_prime_deal)) {
        for (const s3key of bannerImages.mobile_prime_deal) {
          const image = await fetchImage(s3key);
          mobileImages.push(image);
        }
      }

      setImages({ desktop: pairImages(desktopImages), mobile: mobileImages });
    };

    if (bannerImages) fetchImages();
  }, [bannerImages, getImage]);

  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {mdDown
              ? (images.mobile || []).map((src, index) => (
                  <div className="embla__slide" key={index} style={{ display: "flex", height: 200 }}>
                    <img
                      className={isHome ? "embla__slide__img_home_mobile" : "embla__slide__img"}
                      src={src || ""}
                      alt={`Prime Deal Mobile ${index + 1}`}
                    />
                  </div>
                ))
              : (images.desktop || []).map((srcPair, index) => (
                  <div className="embla__slide" key={index} style={{ display: "flex", gap: "32px" }}>
                    {srcPair.map((src, subIndex) => (
                      <img
                        key={subIndex}
                        className={isHome ? "embla__slide__img_home2" : "embla__slide__img"}
                        src={src || ""}
                        alt={`Prime Deal Desktop ${index + 1} - ${subIndex + 1}`}
                      />
                    ))}
                  </div>
                ))}
          </div>
        </div>

        {!mdDown && (
          <div className="embla__buttons" style={{ justifyContent: "space-between", width: "100%" }}>
            <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled} />
            <NextButton onClick={scrollNext} disabled={nextBtnDisabled} />
          </div>
        )}
      </div>

      <div className="embla__dots_mobile">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => scrollTo(index)}
            className={`embla__dot${index === selectedIndex ? " embla__dot--selected" : ""}`}
          />
        ))}
      </div>
    </>
  );
};

export default EmblaCarousel;
