export const steps = [
    {
      target: ".select-category-item-0",
      content: "Click on a category to select.",
      placement: "bottom",
      title: "Categories",
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: true,
      // hideFooter: true,
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: ".select-category-item-1",
      content: "Click on a One More Category to select.",
      placement: "bottom",
      title: "Categories",
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: true,
      // hideFooter: true,
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: ".cart-icon",
      content:
        "This is your shopping cart. Click to view items or proceed to checkout.",
      placement: "bottom",
      title: "Categories Cart",
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: true,
      // hideFooter: true,
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        
        },
      },
    },
    {
      target: ".cart-icon-button",
      content:
        "By Clicking On This Button You will be One Step Closer To Your First Order List!",
      placement: "right",
      title: "Proceed",
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      placement: 'right',
      styles: {
        spotlight: {
          borderRadius: "1.5rem",
        },
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: ".cart-icon",
      content: "Click Again to Close the cart.",
      placement: "right",
      title: "Categories Cart",
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: true,
      // hideFooter: true,
      spotlightClicks: true,
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: ".view-vendor",
      content: "This is an Card For Popular Vendors in You Area!",
      disableBeacon: true,
      placement: "bottom",
      title: "Popular Vendors",
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: true,
      // hideFooter: true,
      placement: "top",
      spotlightClicks: true,
      styles: {
        spotlight: {
          borderRadius: "1.5rem",
        },
        options: {
          zIndex: 10000,
        },
      },
    },
  ];