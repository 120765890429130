import React from 'react'
import AddNewVendor from '../../../system-admin/vendor/AddNewVendor'

const AddNewOrg = () => {
  return (
    <>
        <AddNewVendor/>
    </>
  )
}

export default AddNewOrg