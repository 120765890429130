import { Box, Typography } from "@mui/material";

const PrivacyComponent = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>Privacy</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body2"><span style={{ fontWeight:'bold' }}>Your privacy is important to us and we work to keep your information secure..</span> Horeca1 (HCX Global Pvt. Ltd.) understands that you care about how information about you is used and shared; and we appreciate your trust in us to do that carefully and sensibly.</Typography>
                <Typography variant="body2">We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of personally identifiable customer information. As per our security procedures, we may occasionally request proof of identity before we disclose personal information to you.</Typography>
                <Typography variant="body2">It is important for you to protect against unauthorised access to your account and to your computer. Be sure to <span style={{ fontWeight:'bold' }}>sign out when you finish using a shared computer.</span></Typography>
                <Typography variant="body2">By using Horeca1 Services you agree to our use of your personal / business information (including any sensitive information) in accordance with this Privacy Notice, as may be amended from time to time by us at our discretion. You also agree and consent to us collecting, storing, processing, transferring, and sharing your personal information (including sensitive personal information) with third parties or service providers for the purposes of enhancing customer service and user experience, as set out in this Privacy Notice.</Typography>
            </Box>
        </Box>
    )
}

export default PrivacyComponent;