import PropTypes from 'prop-types';
import { Box, useTheme, alpha, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useResponsive } from '../hooks/useResponsive';
import { NAV, HEADER } from './common/configLayout';
import { bgGradient } from '../theme/css';
import ShowMessage from '../components/common/ShowMessage';
import useResponsiveStyles from '../hooks/useResponsiveStyles';
import Footer from '../pages/landing/Footer';

// ----------------------------------------------------------------------

const SPACING = 8;

const Main = ({ children, sx, ...other }) => {
  const lgUp = useResponsive('up', 'lg');
  const theme = useTheme();
  const { isMobile } = useResponsiveStyles();
  const location = useLocation();

  const footerPaths = [
    '/',
    '/org/:orgId/orders',
    '/org/:orgId/order-history',
    '/org/:orgId/invoice',
    '/org/:orgId/my-vendors',
    '/org/:orgId/my-outlets',
    '/org/:orgId/my-team',
    '/org/:orgId/dashboard',
    '/org/:orgId/settings',
  ];

  const showFooter = footerPaths.some((path) => {
    const regex = new RegExp(`^${path.replace(':orgId', '\\d+')}$`);
    return regex.test(location.pathname);
  });

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: `${HEADER.H_MOBILE + SPACING}px`,
        paddingBottom: '1rem',
        ...(lgUp && {
          px: 2,
          paddingTop: `${HEADER.H_DESKTOP + SPACING}px`,
          paddingBottom: '1rem',
          width: `calc(100% - ${NAV.WIDTH}px)`,
        }),
        ...sx,
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.96),
        }),
      }}
      {...other}
    >
      {children}
      {isMobile ? (
        <Typography
          variant="caption"
          color={"grey"}
          sx={{
            width: "100%",
            marginTop: "auto",
            display: "flex",
            justifyContent: "center",
            pr: "1.5rem",
            mb: "1rem",
            paddingTop: '2rem'
          }}
        >
          Copyright &copy; Horeca1 2023. All rights reserved.
        </Typography>
      ) : (
        <Box sx={{ pt: 3 }}>
          {showFooter && <Footer />}
        </Box>
      )}
      <ShowMessage />
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default Main;