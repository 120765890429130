import { Box, Stack } from "@mui/material";
import React from "react";

const OrderPrice = ({ priceDetails }) => {
  return (
    <Stack direction={"column"} gap={"16px"} alignItems={"flex-end"} marginTop={"24px"} marginBottom={"24px"} textAlign={"right"}>
      <Stack direction={"row"}>
        <Box sx={{ color: "rgb(99, 115, 129)" }}>Subtotal</Box>
        <Box sx={{ width: "160px", fontWeight: 600, lineHeight: 1.5713, fontSize: "0.875rem" }}>₹{priceDetails?.subTotal}</Box>
      </Stack>
      <Stack direction={"row"}>
        <Box sx={{ color: "rgb(99, 115, 129)" }}>Shipping</Box>
        <Box sx={{ width: "160px", fontWeight: 600, lineHeight: 1.5713, fontSize: "0.875rem", color: "red" }}>
          {priceDetails?.shpping ? `₹+${priceDetails?.shpping}` : "--"}
        </Box>
      </Stack>
      <Stack direction={"row"}>
        <Box sx={{ color: "rgb(99, 115, 129)" }}>Discount</Box>
        <Box sx={{ width: "160px", fontWeight: 600, lineHeight: 1.5713, fontSize: "0.875rem", color: "red" }}>
          {priceDetails?.discount ? `-₹+${priceDetails?.discount}` : "--"}
        </Box>
      </Stack>
      <Stack direction={"row"}>
        <Box sx={{ color: "rgb(99, 115, 129)" }}>Taxes</Box>
        <Box sx={{ width: "160px", fontWeight: 600, lineHeight: 1.5713, fontSize: "0.875rem" }}>
          {priceDetails?.taxes ? `₹+${priceDetails?.taxes}` : "--"}
        </Box>
      </Stack>
      <Stack direction={"row"}>
        <Box sx={{ color: "rgb(99, 115, 129)" }}>Total</Box>
        <Box sx={{ width: "160px", fontWeight: 600, lineHeight: 1.5713, fontSize: "0.875rem" }}>₹{priceDetails?.total}</Box>
      </Stack>
    </Stack>
  );
};

export default OrderPrice;
