import { Container, Grid, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import OrgnizationProfile from '../../settings/layout/OrgnizationProfile'
import CustomTextField from '../../../components/common/CustomTextField'
import { getIn, useFormik } from 'formik'
import { MuiTelInput } from 'mui-tel-input'
import ContactPerson from '../../settings/sections/organization/ContactPerson'
import { useParams } from 'react-router-dom'
import { horecaApi } from '../../../redux/api/api'
import AddresDetail from '../../settings/sections/organization/AddressDetail'
import BusinessDetail from '../../settings/sections/organization/BusinessDetail'
import BankDetails from '../../settings/sections/organization/BankDetails'
import { useSelector } from 'react-redux'
import CustomButton from '../../../components/common/CustomButton'
import CustomMuiTelInput from '../../../components/common/CustomMuiTelInput'

const add = [
  {
    billing: false,
    line1: "",
    line2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  },
  {
    billing: true,
    line1: "",
    line2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  },
];

const newPerson = { name: "", mobileNumber: "" };

const AddNewCustomer = () => {

  // const orgId = useParams();
  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const [getOrganizationById, { data: orgData }] = horecaApi.endpoints.getOrganizationById.useLazyQuery();

  const initialValues = {
    businessDetail: {
      id: orgData?.businessDetail?.id ? orgData?.businessDetail?.id : "",
      gstUin: orgData?.businessDetail?.gstUin
        ? orgData?.businessDetail?.gstUin
        : "",
      panNumber: orgData?.businessDetail?.panNumber
        ? orgData?.businessDetail?.panNumber
        : "",
      fssi: orgData?.businessDetail?.fssi ? orgData?.businessDetail?.fssi : "",
    },
    contactPersons: orgData?.contactPersons && orgData?.contactPersons.length > 1 ? (orgData?.contactPersons).map((orgPerson) => {
      return {
        id: orgPerson.id,
        name: orgPerson.name,
        mobileNumber: orgPerson.mobileNumber,
      };
    })
      : [{ name: orgData?.orgAdmin?.firstName + " " + orgData?.orgAdmin?.lastName, mobileNumber: orgData?.orgAdmin?.mobileNumber }],
  }

  const formik = useFormik({
    initialValues,
  })

  return (
    <Container maxWidth="xl">
      {/* <OrgnizationProfile /> */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Vendor</Typography>
      </Stack>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant='h5'>Basic Details</Typography>
        <Grid container spacing={3} mt={'0rem'}>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={"orgName"}
              label={"Shop  name"}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name={"orgEmail"}
              label={"Shop email"}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <MuiTelInput
              defaultCountry="IN"
              onChange={handleChange1}
              value={getIn(formik.values, "phoneNumber")}
              // disableDropdown
              forceCallingCode
              label={"Shop phone number"}
              name={"phoneNumber"}
              onBlur={formik.handleBlur}
              error={
                getIn(formik.touched, "phoneNumber") &&
                Boolean(getIn(formik.errors, "phoneNumber"))
              }
              helperText={
                getIn(formik.touched, "phoneNumber") &&
                getIn(formik.errors, "phoneNumber")
              }
              sx={{ width: "100%" }}
            /> */}
            <CustomMuiTelInput
              name={'phoneNumber'}
              label={'Shop phone number'}
              formik={formik}
            />
          </Grid>
        </Grid>
        <ContactPerson
          formik={formik}
          organizationId={orgId}
          newPerson={newPerson}
        />
        <AddresDetail formik={formik} isShipping={false} />
        <BusinessDetail formik={formik} isFssi={false} />
        <BankDetails formik={formik} />
        <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mt: '1rem' }}>
          <CustomButton variant="contained" label={"Save"} />
        </Stack>
      </Paper>
    </Container>
  )
}

export default AddNewCustomer