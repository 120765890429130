import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Paper } from "@mui/material";
import React, { useEffect } from "react";
import Iconify from "../../../../../components/common/Iconify";
import { TableColumns } from "../../../../order-list/TableColumns";
import { horecaApi, useChangeOrgStatusMutation, useGetOrgUsersQuery } from "../../../../../redux/api/api";
import { useParams } from "react-router-dom";
import VendorLogoImageComponent from "../../../../../components/common/VendorLogoImageComponent";
import CustomToggleSwitch from "../../../../../components/common/CustomToggleSwitch";
import CreateOutlerDialogue from "./CreateOutletDialogue";
import { useState } from "react";
import { openMessage } from "../../../../../redux/slices/showMessageSlice";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomMenu from "../../../../order/common/CustomMenu";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AssignUserDialogue from "./AssignUserDialogue";
import PageHeader from "../../../../../components/common/PageHeader";
import TableNoData from "../../../../../views/list/table-no-data";
import TableLoadingData from "../../../../../components/common/TableLoadingData";
import { getOrgData } from "../../../../../utils/org";

const MyOutlets = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const headLable = TableColumns("my-outlets");
  const [changeOrgStatus] = useChangeOrgStatusMutation();
  const [getOrgOutlets, { data: outlets, isLoading: getOrgOutletsLoading }] = horecaApi.endpoints.getOrgOutlets.useLazyQuery();
  const [open, setOpen] = useState(false);
  const { data: myUsers } = useGetOrgUsersQuery({ orgId });
  const user = useSelector((state) => state.auth.user);

  const orgData = React.useMemo(() => getOrgData(user, orgId));

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const [assignUserDialogue, setAssignUserDialogue] = useState(false);
  const [selectedOrgId, setSelecteOrgId] = useState(null);

  const [isView, setIsView] = useState(false);

  useEffect(() => {
    getOrgOutlets(orgId);
  }, [selectedOrgId, open]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelecteOrgId(row?.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleEditOutlet = () => {
    setIsView(false);
    setOpen(true);
  };

  const handelViewOutlet = () => {
    setIsView(true);
    setOpen(true);
  };

  const menuItems = [
    {
      id: "view",
      label: "View",
      icon: <Iconify width={20} icon="carbon:view-filled" />,
      handler: handelViewOutlet,
    },
    {
      id: "edit",
      label: "Edit",
      icon: <Iconify width={18} icon="fluent:edit-28-filled" />,
      handler: handleEditOutlet,
    },
  ];

  const handelActive = async (status, id) => {
    const result = await changeOrgStatus({ orgId: id, status });
    if (result.data) {
      dispatch(openMessage({ message: "Organization Status Changed Successfully!", messageSeverity: "success" }));
    } else if (result.error) {
      dispatch(openMessage({ message: result.error.data.message, messageSeverity: "error" }));
    } else {
      dispatch(openMessage({ message: "Not Able To Update The organization Status", messageSeverity: "error" }));
    }
  };

  const handleClose1 = () => {
    setIsView(false);
    setOpen(false);
    setSelecteOrgId("");
    setOpenMenu(false);
  };

  const handeAssignUser = (row) => {
    setAssignUserDialogue(true);
    setSelecteOrgId(row?.id);
  };

  const handelCloseAssignUserDialogue = () => {
    setSelecteOrgId(null)
    setAssignUserDialogue(false);
  };

  return (
    <Container maxWidth={"xl"}>
      {orgData?.primaryOrgId != null ? (
        <PageHeader title={"My Outlets"}  />
      ) : (
        <PageHeader
          title={"My Outlets"}
          //linkArray={[{ title: "Dashboard", path: `/` }, { title: "My Outlets" }]}
          onClick1={() => setOpen(true)}
          startIcon1={<Iconify icon="ic:round-plus" />}
          label1={"Create New Outlet"}
          variant1={"contained"}
        />
      )}
      <CustomMenu anchorEl={anchorEl} open={openMenu} onClose={handleClose} menuItems={menuItems} />
      <TableContainer component={Paper} sx={{ overflow:'auto' }}>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          //   size={dense ? 'small' : 'medium'}
          size="medium"
          stickyHeader
        >
          <TableHead sx={{ height: "60px" }}>
            {headLable?.map((column, index) => (
              <TableCell key={column?.id} align={column?.align ?? "left"} padding={column?.padding ?? "normal"} sx={column?.style}>
                {column?.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {getOrgOutletsLoading ? (
              <TableLoadingData colSpan={headLable?.length} />
            ) : outlets?.organizations?.length > 0 ? (
              outlets?.organizations?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {headLable.map((column) => {
                      let value = null;
                      // let columnId = null;
                      const style = { ...column?.style };
                      switch (column.id) {
                        case "srno": {
                          value = index + 1; // Serial number
                          break;
                        }
                        case "active": {
                          value = row[column?.id] ? true : false;
                          break;
                        }
                        default:
                          let splitArray = column.id.split(".");
                          value = row;

                          for (let key of splitArray) {
                            // Check for null or undefined values
                            if (value && value.hasOwnProperty(key)) {
                              value = value[key];
                            } else {
                              value = null;
                              break; // Break the loop if a null or undefined value is encountered
                            }
                          }
                          break;
                      }
                      return (
                        <TableCell
                          key={column?.id}
                          align={column?.align}
                          padding={column?.padding}
                          sx={{ border: outlets?.organizations?.length - 1 === index && "0px", ...column?.style }}
                        >
                          {value}
                          {column?.id === "active" && (
                            <CustomToggleSwitch checked={value} handelToggle={handelActive} id={row?.id} disable={row?.primaryOrgId ? false : true} />
                          )}
                          {column?.id === "orgLogo" && <VendorLogoImageComponent s3Key={row?.orgLogoKey} name={row?.orgName} />}
                          {column?.id === "address" && (
                            <Typography
                              variant="body2"
                              sx={{
                                wordWrap: "break-word",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                              }}
                            >
                              <span>
                                {row.addresses[0]?.pincode
                                  ? `${row.addresses[0]?.landmark ? row.addresses[0]?.landmark + ", " : ""}${
                                      row.addresses[0]?.line1 ? row.addresses[0]?.line1 + ", " : ""
                                    }${row.addresses[0]?.line2 ? row.addresses[0]?.line2 + ", " : ""}${
                                      row.addresses[0]?.city ? row.addresses[0]?.city + ", " : ""
                                    }${row.addresses[0]?.state ? row.addresses[0]?.state + ", " : ""}${row.addresses[0]?.pincode ?? ""}`
                                  : ""}
                              </span>
                            </Typography>
                          )}
                          {column?.id === "action" && (
                            <IconButton onClick={(event) => handleClick(event, row)}>
                              <MoreVertIcon />
                            </IconButton>
                          )}
                          {column.id === "assignUser" && (
                            <IconButton onClick={(event) => handeAssignUser(row)}>
                              <PersonAddAltIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableNoData query={"Outlets"} colSpan={headLable?.length} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateOutlerDialogue open={open} outletId={selectedOrgId} handelCloseDialogue={handleClose1} disabled={isView} setEnabled={handleEditOutlet} />
      <AssignUserDialogue
        open={assignUserDialogue}
        handleClose={handelCloseAssignUserDialogue}
        orgId={orgId}
        orgUsersList={myUsers}
        selectedOrgId={selectedOrgId}
      />
    </Container>
  );
};

export default MyOutlets;
