import React from "react";
import { Grid, Card, CardMedia, CardContent, Typography, Link, Stack } from "@mui/material";
import { noSticker } from "../../assets/images/landing";
import GetImageComponent from "../../components/common/GetImageComponent";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";

export const CategoryCard = ({ category, onSelectCategory, selectedCategory, fromLanding }) => {
  const subCategories = category?.subCategoryDetails?.map((subItem) => subItem?.category_name);
  // Join subcategories and limit to 4 items, adding "..." if there are more
  const subCategoriesText = subCategories?.join(", ");
  const responsive = useResponsiveStyles();
  if (responsive.isMobile) {
    return (
      <Grid item xs={6} sm={6} md={fromLanding ? 3 : 2.4} sx={{ marginBottom: 2 }}>
        <Card
          className="bg-shadow"
          onClick={() => onSelectCategory(category)}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: selectedCategory && category.id == selectedCategory.id ? '4px solid #c0d5f5' : 'none',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            },
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px'
          }}
        >
          <Stack direction={"row"} justifyContent={"center"} height={120}>
            <GetImageComponent
              s3Key={category?.parent_category_image}
              noSticker={noSticker}
              style={{
                opacity: 1,
                //maxWidth: 'none',
                willChange: "transform, opacity",
                //borderRadius: "inherit",
                filter: "unset",
                transition: "opacity 0.25s ease 0s, transform 0.25s ease 0s",
              }}
            />
          </Stack>
          <CardContent sx={{ textAlign: "center", padding: 1 }}>
            <Typography variant="h6" color="textPrimary">
              {category.parent_category}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {category.sub_cat_info ? category.sub_cat_info : subCategoriesText}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }
  return (
    <Grid item xs={12} sm={6} md={fromLanding ? 3 : 2.4} sx={{ marginBottom: 2 }}>
      <Card
        className="bg-shadow"
        onClick={() => onSelectCategory(category)}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: selectedCategory && category.id == selectedCategory.id ? '4px solid #c0d5f5' : 'none',
          transition: 'transform 0.3s, box-shadow 0.3s',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          },
          boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px'
        }}
      >
        <Stack direction={"row"} justifyContent={"center"} width={"100%"} height={190}>
          <GetImageComponent
            s3Key={category?.parent_category_image}
            noSticker={noSticker}
            style={{
              opacity: 1,
              maxWidth: 'none',
              willChange: "transform, opacity",
              //borderRadius: "inherit",
              filter: "unset",
              transition: "opacity 0.25s ease 0s, transform 0.25s ease 0s",
            }}
          />
        </Stack>
        <CardContent sx={{ textAlign: "center" }}>
          <Typography variant="h6" color="textPrimary" noWrap>
            {category.parent_category}
          </Typography>
          <Typography variant="body2" color="textSecondary">
          {category.sub_cat_info ? category.sub_cat_info : subCategoriesText}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const CategoryCards = ({ data, fromLanding = false, onSelectCategory }) => {
  const responsive = useResponsiveStyles();
  return (
    <Grid container spacing={responsive.isMobile ? 2 : fromLanding ? 3 : 2}>
      {data?.map((category, index) => (
        <CategoryCard key={index} category={category} fromLanding={fromLanding} onSelectCategory={onSelectCategory}/>
      ))}
    </Grid>
  );
};

export default CategoryCards;
