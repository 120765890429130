import React, { useEffect, useState } from 'react'
import { horecaApi } from '../../../../../../redux/api/api';
import { TableColumns } from '../../../../../order-list/TableColumns';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { openMessage } from '../../../../../../redux/slices/showMessageSlice';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../../../../../components/common/Iconify';
import CustomMenu from '../../../../../order/common/CustomMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RolesDialogue from '../../RolesDialogue';
import TableNoData from '../../../../../../views/list/table-no-data';
import TableLoadingData from '../../../../../../components/common/TableLoadingData';

const MyRoles = ({ refreshRole }) => {
  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const headLable = TableColumns('my-roles');

  const [roleId, setRoleId] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const [openRoleView, setOpenRoleView] = useState(false)
  const [openRoleEdit, setOpenRoleEdit] = useState(false)

  const menuItems = [
    {
      id: "view",
      label: "View",
      icon: <Iconify width={24} icon="carbon:view-filled" />,
      handler: () => handleOpenRoleView()
    },
    {
      id: "edit",
      label: "Edit",
      icon: <Iconify width={24} icon="fluent:edit-28-filled" />,
      handler: () => handleOpenRoleEdit()
    }
  ];

  const dispatch = useDispatch();
  const [myRoles, setMyRoles] = useState([])
  const [getRolesByOrgId, { data: getRolesData, isLoading: getRolesByOrgIdLoading }] = horecaApi.endpoints.getRolesByOrgId.useLazyQuery();

  const loadRoles = async () => {
    try {
      const result = await getRolesByOrgId(orgId)
      if (result?.data) {
        setMyRoles(result?.data)
      }
    } catch (error) {
      dispatch(openMessage({ message: error.data.message, messageSeverity: 'error' }));
    }
  }

  useEffect(() => {
    (
      async () => {
        await loadRoles()
      }
    )()
  }, [refreshRole, openRoleEdit, getRolesData])

  const handleClick = (event, roleId) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setRoleId(roleId)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
    setRoleId(null)
  };

  const handleOpenRoleView = () => {
    setOpenRoleView(true)
    setAnchorEl(null);
    setOpenMenu(false);
  }

  const handleCloseRoleView = () => {
    setOpenRoleView(false);
    setAnchorEl(null);
    setOpenMenu(false);
    setOpenRoleEdit(false)
  }

  const handleOpenRoleEdit = () => {
    setOpenRoleEdit(true)
    setAnchorEl(null);
    setOpenMenu(false);
    setOpenRoleView(false);
  }

  const handleCloseRoleEdit = () => {
    setOpenRoleEdit(false)
    setAnchorEl(null);
    setOpenMenu(false);
    setOpenRoleView(false);
  }

  return (
    <>
      <CustomMenu anchorEl={anchorEl} open={openMenu} onClose={handleClose} menuItems={menuItems} />
      <RolesDialogue open={openRoleView} handelCloseDialogue={handleCloseRoleView} data={myRoles?.find((role) => role?.id == roleId)} disabled={true} resetData={async () => { }} setEnable={() => handleOpenRoleEdit()} />
      <RolesDialogue open={openRoleEdit} handelCloseDialogue={handleCloseRoleEdit} data={myRoles?.find((role) => role?.id == roleId)} disabled={false} resetData={loadRoles} setEnable={() => { }} />
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          //   size={dense ? 'small' : 'medium'}
          size='medium'
          stickyHeader
        >
          <TableHead >
            {
              headLable?.map((column) => (
                <TableCell
                  key={column?.id}
                  align={column?.align ?? 'left'}
                  padding={column?.padding ?? 'normal'}
                  sx={column?.style}
                >
                  {column?.label}
                </TableCell>
              ))
            }
          </TableHead>
          <TableBody>
            {getRolesByOrgIdLoading ?
              <TableLoadingData colSpan={headLable?.length} /> :
              myRoles?.length > 0 ? (
                myRoles?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {
                        headLable.map((column) => {
                          let value = null;
                          // let columnId = null;
                          const style = { ...column?.style };
                          switch (column?.id) {
                            case "srno": {
                              value = index + 1; // Serial number
                              break;
                            }

                            default:
                              let splitArray = column?.id.split(".");
                              value = row;

                              for (let key of splitArray) {
                                // Check for null or undefined values
                                if (value && value.hasOwnProperty(key)) {
                                  value = value[key];
                                } else {
                                  value = null;
                                  break; // Break the loop if a null or undefined value is encountered
                                }
                              }
                              break;
                          }
                          return (
                            <TableCell key={column?.id} align={column?.align} padding={column?.padding} sx={{ border: (myRoles?.length - 1) === index && '0px', ...column?.style }}>
                              {value}
                              {column?.id === "action" && (
                                <IconButton onClick={(event) => handleClick(event, row?.id, row)}>
                                  <MoreVertIcon />
                                </IconButton>
                              )}
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  );
                })
              ) : (
                <TableNoData query={"Roles"} colSpan={headLable?.length} />
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default MyRoles