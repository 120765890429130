import { Box, Button, InputAdornment, OutlinedInput, Stack, TextField } from "@mui/material";
import React from "react";
import { cahracterImg } from "../../../assets/images";
import CustomButton from "../../../components/common/CustomButton";

const ReferAndEarn = () => {
  return (
    <Box>
      <img
        src={cahracterImg}
        alt="refer-earn"
        style={{
          position: "relative",
          left: "40px",
          zIndex: 9,
          width: "140px",
          filter: "drop-shadow(rgba(0, 0, 0, 0.24) 0px 12px 24px);",
        }}
      />
      <Box
        sx={{
          marginTop: "-120px",
          color: "rgb(255, 255, 255)",
          borderRadius: "16px",
          padding: "128px 40px 40px",
          background:
            "linear-gradient(135deg, rgb(0, 167, 111), rgb(0, 120, 103))",
        }}
      >
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            "-webkit-box-align": "center",
            "-webkit-box-pack": "justify",
          }}
        >
          <Box sx={{ fontSize: "1.5rem" }}>Invite friends and earn</Box>
          <Box sx={{ fontSize: "2.75rem" }}>₹50</Box>
        </Stack>
        <Box
          sx={{
            marginTop: "0px",
            marginBottom: "24px",
            lineHeight: 1.5712,
            fontSize: "0.875rem",
            fontWeight: 400,
          }}
        >
          It's that easy! The more friends you invite, the more rewards you can earn. Start sharing now and watch your rewards grow!
          <TextField
            placeholder="Email"
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* <Button
                      variant="contained"
                      color="warning"
                      size="small"
                      disableElevation
                    >
                      Invite
                    </Button> */}
                  <CustomButton
                    variant="contained"
                    color="warning"
                    size="small"
                    props={{ disableElevation: true }}
                    label={"Invite"}
                  />
                </InputAdornment>
              ),
              classes: {
                input:
                  "MuiInputBase-input MuiInputBase-inputAdornedEnd css-qbcrcl",
              },
            }}
          />
          {/* </OutlinedInput> */}
        </Box>
      </Box>
    </Box>
  );
};

export default ReferAndEarn;
