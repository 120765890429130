import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const OrderDetails = (props) => {
    return (
        <Box sx={{  }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: 2 }}>
                <IconButton size="medium">
                    <ArrowBackIosNewIcon fontSize="small" />
                </IconButton>
                <Box>
                    <Typography variant="h4">Order #6010</Typography>
                    <Typography sx={{ fontSize: 14, color: 'grey' }}>26 Apr 2024 11:18 PM</Typography>
                </Box>
                <Box sx={{ backgroundColor: '#EEEFF1', padding: 1, borderRadius: 1 }}>
                    <Typography sx={{ fontSize: 12, color: '#637381', fontWeight: 550 }}>Refunded</Typography>
                </Box>
            </Box>
            <Grid container spacing={2} sx={{ marginTop: 3 }}>
                <Grid item sm={9}>
                    <Card sx={{ padding: 3, border: '1px solid #F5F6F7' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h6">Details</Typography>
                            <IconButton size="small">
                                <ModeEditIcon />
                            </IconButton>
                        </Box>
                    </Card>
                </Grid>
                <Grid item sm={3}>
                    <Card sx={{ padding: 3, border: '1px solid #F5F6F7' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h6">Customer Info</Typography>
                            <IconButton size="small">
                                <ModeEditIcon />
                            </IconButton>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default OrderDetails;