import { Avatar, AvatarGroup } from '@mui/material'
import CustomLogo from './CustomLogo'

export default function CustomGroupLogo({ srcList, size, maxLogos }) {

    return (
        <>
            <AvatarGroup max={maxLogos ?? undefined} spacing={"medium"} variant='rounded'
                renderSurplus={(surplus) => (
                    <Avatar
                        variant="rounded"
                        sx={{
                            bgcolor: (theme) => theme.palette.primary.lighter,
                            color: (theme) => theme.palette.primary.main,
                            cursor: 'pointer',
                            borderRadius: "12px"
                        }}
                    >
                        +{surplus}
                    </Avatar>
                )}
                sx={{
                    '& .MuiAvatar-root': {
                        width: `${size / 16}rem` ?? '3.5rem',
                        height: `${size / 16}rem` ?? '3.5rem'
                    },
                    justifyContent: {
                        xs: "flex-end",
                        sm: "flex-start"
                    }
                }}
            >
                {srcList?.length > 0 && srcList?.map((src, index) => (
                    <CustomLogo key={index} size={size} src={src.parent_category_image ?? null} title={src.parent_category ?? ""} />
                ))}
            </AvatarGroup>
        </>
    )
}
