import React, { useEffect, useState } from 'react';
import { TableColumns } from '../../../../../order-list/TableColumns';
import { horecaApi, useChangeUserStatusMutation } from '../../../../../../redux/api/api';
import { Avatar, IconButton, MenuItem, Paper, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CustomToggleSwitch from '../../../../../../components/common/CustomToggleSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { openMessage } from '../../../../../../redux/slices/showMessageSlice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomMenu from '../../../../../order/common/CustomMenu';
import Iconify from '../../../../../../components/common/Iconify';
import GetImageComponent from '../../../../../../components/common/GetImageComponent';
import UserDialogue from '../../UserDialogue';
import { stringAvatar } from '../../../../../../utils/defaultIcon';
import TableNoData from '../../../../../../views/list/table-no-data';
import TableLoadingData from '../../../../../../components/common/TableLoadingData';

const MyUsers = ({ refreshUser }) => {

  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const headLable = TableColumns('my-teams');
  const dispatch = useDispatch();

  const [myUsers, setMyUsers] = useState([])
  const [getAllOrgUsers, { data: getAllOrgUsersData, isLoading: getAllOrgUsersLoading }] = horecaApi.endpoints.getAllOrgUsers.useLazyQuery();

  const [changeUserStatus] = useChangeUserStatusMutation();
  const [userId, setUserId] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const [openUserView, setOpenUserView] = useState(false)
  const [openUserEdit, setOpenUserEdit] = useState(false)

  const loadUsers = async () => {
    try {
      const result = await getAllOrgUsers(orgId)
      if (result?.data) {
        setMyUsers(result?.data)
      }
    } catch (error) {
      dispatch(openMessage({ message: error.data.message, messageSeverity: 'error' }));
    }
  }

  useEffect(() => {
    (
      async () => {
        await loadUsers()
      }
    )()
  }, [refreshUser, openUserEdit, getAllOrgUsersData])

  const handleClick = (event, userId, userDetail) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setUserId(userId)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
    setUserId(null)
  };

  const handleOpenUserView = () => {
    setOpenUserView(true)
    setAnchorEl(null);
    setOpenMenu(false);
  }

  const handleCloseUserView = () => {
    setOpenUserView(false)
    setAnchorEl(null);
    setOpenMenu(false);
    setOpenUserEdit(false)
  }

  const handleOpenUserEdit = () => {
    setOpenUserEdit(true)
    setAnchorEl(null);
    setOpenMenu(false);
    setOpenUserView(false)
  }

  const handleCloseUserEdit = () => {
    setOpenUserEdit(false)
    setAnchorEl(null);
    setOpenMenu(false);
    setOpenUserView(false)
  }


  const menuItems = [
    {
      id: "view",
      label: "View",
      icon: <Iconify width={24} icon="carbon:view-filled" />,
      handler: () => handleOpenUserView()
    },
    {
      id: "edit",
      label: "Edit",
      icon: <Iconify width={24} icon="fluent:edit-28-filled" />,
      handler: () => handleOpenUserEdit()
    }
  ];

  const handelActive = async (status, id) => {
    const result = await changeUserStatus({ id, status });
    if (result.data) {
      dispatch(openMessage({ message: "User Status Changed Successfully!", messageSeverity: 'success' }));
    } else if (result.error) {
      dispatch(openMessage({ message: result.error.data.message, messageSeverity: 'error' }));
    } else {
      dispatch(openMessage({ message: "Not Able To Update The User Status", messageSeverity: 'error' }));
    }
  };

  const [popovers, setPopovers] = useState({});

  const handleClickPopover = (event, rowIndex) => {
    setPopovers((prevState) => ({
      ...prevState,
      [rowIndex]: {
        anchorEl: event.currentTarget,
        open: true,
      },
    }));
  };

  const handleClosePopover = (rowIndex) => {
    setPopovers((prevState) => ({
      ...prevState,
      [rowIndex]: {
        anchorEl: null,
        open: false,
      },
    }));
  };

  return (
    <>
      <CustomMenu anchorEl={anchorEl} open={openMenu} onClose={handleClose} menuItems={menuItems} />
      <UserDialogue open={openUserView} handelCloseDialogue={handleCloseUserView} data={myUsers?.find((user) => user?.userId == userId)} disabled={true} resetData={async () => { }} setEnable={() => handleOpenUserEdit()} />
      <UserDialogue open={openUserEdit} handelCloseDialogue={handleCloseUserEdit} data={myUsers?.find((user) => user?.userId == userId)} disabled={false} resetData={loadUsers} setEnable={() => { }} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium" stickyHeader>
          <TableHead sx={{ height: '60px' }}>
            {headLable?.map((column) => (
              <TableCell
                key={column?.id}
                align={column?.align ?? 'left'}
                padding={column?.padding ?? 'normal'}
                sx={column?.style}
              >
                {column?.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {getAllOrgUsersLoading ?
              <TableLoadingData colSpan={headLable?.length} /> :
              myUsers?.length > 0 ? (
                myUsers?.map((row, rowIndex) => {
                  const popoverState = popovers[rowIndex] || { anchorEl: null, open: false };
                  let nameColor = stringAvatar({ name: `${row?.firstName} ${row?.lastName}`, color: "rgb" })?.item;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} >
                      {headLable.map((column, headLableIndex) => {
                        let value = null;
                        const style = { ...column?.style };
                        switch (column?.id) {
                          case "srno":
                            value = headLableIndex + 1;
                            break;
                          case 'userDetail.active':
                            value = row?.active ? true : false;
                            break;
                          case 'userDetail.firstName':
                            value = (
                              <Stack direction={'row'} gap={0.5}>
                                <Avatar> <GetImageComponent s3Key={row?.userDetail?.profilePicKey} /> </Avatar>
                                <Typography variant="body2">{"Arpit"}</Typography>
                              </Stack>
                            );
                            break;
                          default:
                            let splitArray = column?.id.split(".");
                            value = row;
                            for (let key of splitArray) {
                              if (value && value.hasOwnProperty(key)) {
                                value = value[key];
                              } else {
                                value = null;
                                break;
                              }
                            }
                            break;
                        }
                        return (
                          <TableCell key={column?.id} align={column?.align} padding={column?.padding} sx={{ border: ((myUsers?.length - 1) === rowIndex) && '0px', ...column?.style }}>
                            {(column?.id === 'action' && (row?.orgRoleMapping[0]?.roleName === "vendor" || row?.orgRoleMapping[0]?.roleName === "customer" ? false : true)) && (
                              <IconButton onClick={(event) => handleClick(event, row?.userId, row)}>
                                <MoreVertIcon />
                              </IconButton>
                            )}
                            {column?.id === 'mobileNumber' && <Typography variant='body2'>{row?.mobileNumber}</Typography>}
                            {column?.id === 'firstName' && (
                              <Stack direction={'row'} gap={'1rem'}>
                                {row?.profilePicKey ?
                                  <Avatar sx={{ width: 43, height: 43 }}>
                                    <GetImageComponent s3Key={row?.profilePicKey} />
                                  </Avatar> :
                                  <Avatar
                                    sx={{
                                      width: 43,
                                      height: 43,
                                      bgcolor: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},0.5)` : `rgba(${nameColor?.sx?.bgcolor},0.7)`,
                                      color: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},1)` : `rgba(255,255,255,0.9)`
                                    }}
                                    children={nameColor?.children}
                                  />}
                                <Typography variant="body2" sx={{ fontWeight: 550 }}>{row?.firstName + " " + row?.lastName}</Typography>
                              </Stack>
                            )}
                            {column?.id === "userDetail.active" && (
                              <CustomToggleSwitch checked={value} handelToggle={handelActive} id={(row?.userId)} disable={row?.orgRoleMapping[0]?.roleName === "vendor" || row?.orgRoleMapping[0]?.roleName === "customer" ? true : false} />
                            )}
                            {column?.id === "roleDetail" && (
                              <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  ...style, fontWeight: 400, lineHeight: '1.57143', fontSize: '0.875rem'
                                }}>
                                  {row?.orgRoleMapping?.length > 0 && (
                                    <span>
                                      {row?.orgRoleMapping[0]?.orgName}
                                      -
                                      {row?.orgRoleMapping[0]?.roleName}
                                    </span>
                                  )}
                                  {row?.orgRoleMapping?.length > 0 && (
                                    <IconButton onClick={(event) => handleClickPopover(event, rowIndex)}>
                                      <Iconify icon="material-symbols-light:keyboard-double-arrow-right" />
                                    </IconButton>
                                  )}
                                </Typography>
                                <Popover
                                  id={popoverState.open ? 'rate-popover' : undefined}
                                  open={popoverState.open}
                                  anchorEl={popoverState.anchorEl}
                                  onClose={() => handleClosePopover(rowIndex)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                >
                                  {row?.orgRoleMapping?.map((role, index) => (
                                    <MenuItem key={index}>
                                      <Typography variant='body2'>{role?.orgName}{'-'}{role?.roleName}</Typography>
                                    </MenuItem>
                                  ))}
                                </Popover>
                              </Stack>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableNoData query={"Users"} colSpan={headLable?.length} />
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MyUsers;