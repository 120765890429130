import { Box, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import CustomTextField from '../../../../components/common/CustomTextField'
import { useFormik } from 'formik'
import { useCreateNewRoleMutation, useGetAllPermissionsQuery } from '../../../../redux/api/api'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { openMessage } from '../../../../redux/slices/showMessageSlice'
import CustomButton from '../../../../components/common/CustomButton'
import CloseIcon from '@mui/icons-material/Close';
import { getOrgType } from '../../../../utils/org'


const CreateRoleDialogue = ({ open, handelCloseDialogue }) => {

  const { orgId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  // const { data: permissions } = useGetAllPermissionsQuery(user.orgDetail?.find((org) => org?.id == parseInt(orgId))?.type);
  const orgType = getOrgType(user, orgId)
  const { data: permissions } = useGetAllPermissionsQuery(orgType);
  const [createNewRole] = useCreateNewRoleMutation();


  const initialValues = {
    roleName: '',
    permissions: [],
    orgId: orgId
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const result = await createNewRole({ orgId, payload: values });
      if (result?.data) {
        dispatch(openMessage({ message: 'Role created!', messageSeverity: 'success' }));
        formik.resetForm();
        handelCloseDialogue();
      } else if (result?.error) {
        dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
      } else {
        dispatch(openMessage({ message: 'Not able to create an role!', messageSeverity: 'error' }));
      }
    },
  })


  const handleClose = () => {
    formik.resetForm();
    handelCloseDialogue()
  }

  const handleCheckboxChange = (permission) => (event) => {
    const isChecked = event.target.checked;
    formik.setFieldValue('permissions', isChecked
      ? [...formik.values.permissions, permission?.id]
      : formik.values.permissions.filter(item => item !== permission?.id)
    );
  };


  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      // onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose} // Call onClose function when close icon is clicked
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          // color: 'inherit',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'center'}>
          <Typography variant='h5'>Create Role</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: '1rem' }}>
          <Stack direction={'row'} gap={'1rem'}>
            <CustomTextField
              name={'roleName'}
              label="Role Name"
              formik={formik}
            />
            <CustomButton
              onClick={formik.handleSubmit}
              disabled={formik.values?.roleName ? false : true}
              label={"Add Role"}
              width={"100%"}
            />
          </Stack>
          <Stack sx={{ mt: '1rem' }}>
            <Typography variant='h6'>Permissions</Typography>
            <Grid container spacing={2}>
              {permissions?.map((permission, index) => (
                <Grid item xs={6}>
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={formik?.values?.permissions?.includes(permission.id)}
                        onChange={handleCheckboxChange(permission)}
                      />
                    }
                    label={permission.permissionName}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CreateRoleDialogue