

import { Box, Button, Grid, Typography } from "@mui/material";
import { brand7, brand9, product1, product10, product2, product3, product4, product5, product6, product7, product8, product9 } from "../../../assets/images";
import { Visibility } from "@mui/icons-material";
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";
import GetImageComponent from "../../../components/common/GetImageComponent";

const CategorywiseSection = ({ category, setLoginOpen }) => {
    const { categoryDetails, items } = category;
    const responsive = useResponsiveStyles();
    const data = [
        {
            name: 'Eastmade - Cumin (Jeera) Whole, 500 gm',
            quantity: '1 pc',
            discount: '23% OFF',
            image: product1,
            price: '189',
            originalPrice: '245.7'
        },
        {
            name: 'MDH - Jeera Powder, 100 gm',
            quantity: '1 pack',
            discount: '36% OFF',
            image: product2,
            price: '96.5',
            originalPrice: '139.65'
        },
        {
            name: 'MDH - Kitchen King Masala, 500 gm',
            quantity: '1 pack',
            discount: '24% OFF MRP',
            image: product3,
            price: '373.7',
            originalPrice: '455'
        },
        {
            name: 'Eastmade - Cassia (Dalchini/Taj), 100 gm',
            quantity: '1 pack',
            discount: '43% OFF',
            image: product4,
            price: '33.1',
            originalPrice: '57.75'
        },
        {
            name: 'Eagle - Yellow Chilli Powder, 100 gm',
            quantity: '1 pack',
            discount: '',
            image: product5,
            price: '57.4',
            originalPrice: ''
        },
        {
            name: 'Eagle - Yellow Chilli Powder, 100 gm',
            quantity: '1 pack',
            discount: '',
            image: product5,
            price: '57.4',
            originalPrice: ''
        }
    ]
    if (responsive?.isMobile) {
        return (
            categoryDetails && <Box>
                <Box sx={{ padding: 1, paddingX: 1, borderRadius: 1, border: '.5px solid #d3d3d3', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                        <Typography variant="h5" fontWeight={600} sx={{ textAlign: "center", color: '#373737' }}>
                            {categoryDetails.parent_category}
                        </Typography>
                    </Box>
                    <Grid container spacing={1}>
                        {
                            items.map((item, index) => {
                                return index < 6 && <Grid key={`${index}cs`} item xs={6} sm={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ flexGrow: 1, border: '.5px solid #d3d3d3', borderRadius: 2, backgroundColor: '#FBFBFB', padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <GetImageComponent s3Key={item.item_image_key} style={{ height: 80, width: 80 }} />
                                        <Box sx={{ flexGrow: 1, paddingBottom: 0, paddingTop: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <Typography variant="subtitle1" color="textSecondary">{item.name}</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 1 }}>
                                                <Typography variant="subtitle1">₹ {item.rate}</Typography>
                                                <Typography variant="body2" color="textSecondary">1 pack</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            })
                        }
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'end', paddingTop: 2 }}><Button size='large' onClick={() => setLoginOpen(true)}>View All</Button></Box>
                </Box>
            </Box>
        )
    }
    return (
        categoryDetails && <Box sx={{ paddingX: 6 }}>
            <Box sx={{ padding: 3, paddingX: 4, borderRadius: 1, border: '.5px solid #d3d3d3', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="h5" fontWeight={600} sx={{ textAlign: "center", color: '#373737' }}>
                        {categoryDetails.parent_category}
                    </Typography>
                    <Button size='large' onClick={() => setLoginOpen(true)}>View All</Button>
                </Box>
                <Grid container spacing={3}>
                    {
                        items.map((item, index) => {
                            return index < 6 && <Grid key={`${index}cs`} item sm={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ flexGrow: 1, border: '.5px solid #d3d3d3', borderRadius: 2, backgroundColor: '#FBFBFB', padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {/* <Box component="img" src={item.image} width={'60%'} height={100} /> */}
                                    <GetImageComponent s3Key={item.item_image_key} style={{ height: 100, width: '60%' }} />
                                    <Box sx={{ flexGrow: 1, paddingBottom: 0, paddingTop: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <Typography variant="subtitle1" color="textSecondary">{item.name}</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                                            <Typography variant="subtitle1">₹ {item.rate}</Typography>
                                            <Typography variant="body2" color="textSecondary">1 pack</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        })
                    }
                </Grid>
            </Box>
        </Box>
    )
}

export default CategorywiseSection;