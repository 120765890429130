import React, { createContext, useContext } from 'react';
import useLocalStorage from './useLocalStorage';

const LocalStorageContext = createContext();

export const LocalStorageProvider = ({ children }) => {
  const [location, setLocation] = useLocalStorage('location', { pincode: '400050', area: 'Bandra West' });

  return (
    <LocalStorageContext.Provider value={{ location, setLocation }}>
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorageContext = () => useContext(LocalStorageContext);
