// types
import { createSlice } from "@reduxjs/toolkit";
import { generateItems, getItemsByVendorId } from "../../_mock/vendor";
import { horecaApi } from "../api/api";


const summaryFilters = {
  totalOrderLists: true,
  draftedOrderLists: false,
  scheduledOrderLists: false
}

// initial state
const initialState = {
  items: [], //current items for vendors
  currItems: [],
  itemId: '',
  vendorId: '',
  searchText: '',
  orderListName: '',
  orderListCreation: [],
  slectedItems: [],
  isSaveProceed: false,
  orderList: {},
  summaryFilters: summaryFilters,
  orderListSearch: "",
  vendorFromMyVendors: null,
  editOrderList: [],
};

// ==============================|| SLICE - MENU ||============================== //

const orderListSlice = createSlice({
  name: "orderList",
  initialState,
  reducers: {
    setCurrSelected(state, action) {
      const { name, value } = action.payload;
      state.currSelected[name] = value;
    },
    setCurrentItems(state, action) {
      state.items = getItemsByVendorId(1);
    },
    updateOrderList(state, action) {
      state.orderListCreation = action.payload;
    },
    editOrderList(state, action) {
      state.editOrderList = action.payload;
    },
    updateSelectedItemList(state, action) {
      state.slectedItems = action.payload;
    },
    setSearchText(state, action) {
      state.searchText = action.payload;
    },
    setIsSaveProceed(state, action) {
      state.isSaveProceed = action.payload;
    },
    setOrderListName(state, action) {
      state.orderListName = action.payload;
    },
    setSelectedVendorId(state, action) {
      state.vendorId = action.payload;
    },
    setSelectedItemId(state, action) {
      state.itemId = action.payload;
    },
    setSummaryFilter(state, action) {
      if (state.summaryFilters.hasOwnProperty(action.payload)) {
        // Reset all filters to false except the one specified
        for (const filter in state.summaryFilters) {
          if (filter !== action.payload) {
            state.summaryFilters[filter] = false;
          } else {
            state.summaryFilters[filter] = true;
          }
        }
      }
    },
    setOrderListSearch(state, action) {
      state.orderListSearch = action.payload
    },
    setVendorFromMyVendors(state, action) {
      state.vendorFromMyVendors = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      horecaApi.endpoints.getVendorItems.matchFulfilled,
      (state, { payload }) => {
        state.currItems = payload;
      }
    )
    builder.addMatcher(
      horecaApi.endpoints.getOrderList.matchFulfilled,
      (state, { payload }) => {
        state.orderList = payload;
      }
    )
    builder.addMatcher(
      horecaApi.endpoints.getOrderListById.matchFulfilled,
      (state, {payload}) => {
        const newItem = payload?.items?.map((item)=>{
          return {item: item}
        })
        state.editOrderList = [
          {
            vendorId: payload?.vendorDetails?.id,
            name: payload?.vendorDetails?.name,
            items: newItem
          }
        ]
        state.orderList.orderListName = payload?.name;
      }
    )
  }
});

export default orderListSlice.reducer;

export const { setCurrSelected, setCurrentItems, updateOrderList, setSearchText, updateSelectedItemList, setIsSaveProceed, setOrderListName, setSelectedVendorId, setSelectedItemId, setSummaryFilter, setOrderListSearch, setVendorFromMyVendors, editOrderList} = orderListSlice.actions;
