import { Box, Typography } from "@mui/material";

const RefundComponent = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>Returns & Refunds</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body2">As a B2B F&B Supplies platform Horeca1 allows easy Returns in qualified cases, at the time of delivery only. An order once accepted, cannot be returned, unless: (a) The product delivered is damaged. (b) Is different from the one ordered.</Typography>
                <Typography variant="body2">To raise a ticket for return, replacement or refund, In case of any issues regarding a delivered product, please contact Customer Service at sales@horeca1.com. Once the claim is processed, as per the above guidelines, you’ll be eligible for a Replacement or Refund. </Typography>
            </Box>
        </Box>
    )
}

export default RefundComponent;