import { Breadcrumbs, useTheme } from "@mui/material";
import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import DotIcon from "./DotIcon";
import Iconify from "./Iconify";

const CustomBreadCrumb = ({ linkArray = [] }) => {
  const navigate = useNavigate();
  //   const responsive=useResponsiveStyles()

  const theme = useTheme()

  const handleClick = (path) => {
    if (path) navigate(path);
  };
  //   const { selectedJobPostingPage } = useSelector((state) => state.common);

  const breadcrumbs = linkArray.map((elem, index) => (
    <Typography
      key={index}
      onClick={() => handleClick(elem?.path)}
      sx={{
        color: index != linkArray.length - 1 ? "#8A8894" : theme.palette.mode !== "dark" ? "#202020" : "#fff",
        fontSize: "0.875rem !important",
        fontWeight: 400,
        cursor: "pointer",
      }}
      variant="body2"
    >
      {elem?.title}
    </Typography>
  ));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        lineHeight: 1.5,
        paddingTop: '0.5rem'
        // paddingBottom: "1rem",
      }}
    >
      <Breadcrumbs separator={<Iconify icon="mdi:dot" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </div>
  );
};

export default CustomBreadCrumb;
