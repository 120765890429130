import React from 'react'
import { Box, Button, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Radio, Stack, TextField, Typography } from '@mui/material'
import { emptyCart } from '../../assets/images/landing'
import CustomButton from '../../components/common/CustomButton'
import { useRouter } from '../../routes/hooks/useRouter'
import { Link, useNavigate, useParams } from 'react-router-dom'
import InstantOrderItemsCard, { InstantOrderItemsHead } from '../order/order-details/InstantOrderItemsCard'
import { horecaApi, useAddItemToCartMutation, useCreateNewOrderMutation, useGetAllPinCodesQuery, useGetCartItemsQuery, useGetItemsPriceDetailMutation, useRemoveAllItemFromCartMutation, useRemoveItemFromCartMutation, useUpdatePaymentStatusMutation } from '../../redux/api/api'
import { useDispatch, useSelector } from 'react-redux'
import { openMessage } from '../../redux/slices/showMessageSlice'
import { useEffect } from 'react'
import PaymentPage from '../order/order-details/PaymentPage'
import { useState } from 'react'
import Iconify from '../../components/common/Iconify'
import ClearCartDialgoue from './ClearCartDialgoue'
import { showMaxFloat } from '../../utils/formatNumbers'
import PriceDetailsComp from '../../components/common/PriceDetailsComp'
import useResponsiveStyles from '../../hooks/useResponsiveStyles'
import CustomSwipableDrawer from './CustomSwipeableDrawer'
import { setSelectedVendorId } from '../../redux/slices/orderListSlice'


export const OrderListNameDialogue = ({ name, setName, open, saveAndProceed, onClose, label = "Save and Proceed" }) => {
  return (
    <Dialog
      maxWidth={'xs'}
      scroll={"paper"}
      open={open}

      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "450px",  //Set your width here
            padding: '1rem',
            minHeight: '200px'
          },
        },
      }}
    >
      <DialogTitle>
        Order List Name
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Iconify icon="material-symbols:cancel-outline" />
      </IconButton>
      <DialogContent>
        <TextField
          sx={{ mt: '2rem' }}
          size='medium'
          variant='standard'
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <Stack sx={{ mt: '2rem', alignItems: 'flex-end' }}>
          <CustomButton
            label={label}
            onClick={saveAndProceed}
          /></Stack>
      </DialogContent>
    </Dialog>
  )
}


const InstantOrderCart = () => {

  const { orgId } = useParams();
  const router = useRouter();
  const { isMobile } = useResponsiveStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: allCartItemsData } = useGetCartItemsQuery(orgId);
  const [createNewOrder] = useCreateNewOrderMutation();
  const [getItemsPriceDetail] = useGetItemsPriceDetailMutation();
  // const [updatePaymentStatus] = horecaApi.endpoints.updatePaymentStatus.useLazyQuery();
  const [updatePaymentStatus] = useUpdatePaymentStatusMutation();
  const [openMobilePaymentDetails, setOpenMobilePaymentDetails] = useState(false);
  const [priceDetails, setPriceDetails] = useState(null);

  const [openPayment, setOpenPayment] = useState(false);
  const [thankYou, setThanYou] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [orderListName, setOrderListName] = useState('');
  const [isOrderList, setIsOrderList] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const [removeAllItemFromCart, { isLoading: removeAllItemFromCartLoading }] = useRemoveAllItemFromCartMutation();

  const [removeItemFromCart] = useRemoveItemFromCartMutation();

  const [addItemToCart] = useAddItemToCartMutation();
  const { data: add } = useGetAllPinCodesQuery(orgId);
  const selectedValue = useSelector((state) => state.app.preference.selectedPinCode);

  const [addressId, setAddressId] = useState(selectedValue?.id);

  useEffect(() => {
    if (selectedValue) {
      setAddressId(selectedValue?.id)
    }
  }, [selectedValue])

  const afterPayClick = async (paymentMode = 'payByGateway') => {
    setIsLoading(true)

    const result = await updatePaymentStatus({ orderId: selectedOrderId, status: 'success', paymentMode });
    if (result.data || result.isSuccess) {
      dispatch(horecaApi.util.invalidateTags(["item-removed"]));
      setTimeout(() => {
        setIsLoading(false);
        setThanYou(true);
      }, 2000)
      setTimeout(() => {
        router.push(`/org/${orgId}/order-history/${selectedOrderId}`)
      }, 3000)
    }
  }

  const handleAddToCart = async (itemId, quantity, vendorId) => {
    const payload = {
      items: {
        itemId: itemId,
        quantity: quantity
      },
      customerId: orgId,
      vendorId: vendorId
    }

    const result = await addItemToCart({ payload });
    if (result?.data) {
      // dispatch(openMessage({ message: 'Items added', messageSeverity: 'success' }))
    }
    else if (result?.error) {
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }))
    } else {
      dispatch(openMessage({ message: 'Not able to add an itme to cart at this moment', messageSeverity: 'error' }))
    }
  }


  const getPriceDetails = async () => {

    // Step 1: Group items by vendorId
    const groupedByVendor = allCartItemsData?.reduce((acc, item) => {
      const { vendorId, customerId, itemId, quantity } = item;

      if (!acc[vendorId]) {
        acc[vendorId] = {
          vendorId,
          customerId,
          addressId: 0, // Default value
          items: [],
        };
      }

      acc[vendorId].items.push({
        itemId,
        quantity
      });



      return acc;
    }, {});

    // Step 2: Convert grouped data to an array
    const transformedData = groupedByVendor ? Object?.values(groupedByVendor) : [];
    const result = await getItemsPriceDetail(transformedData);
    if (result?.data) {
      setPriceDetails(result.data);
    }

  }



  useEffect(() => {
    getPriceDetails();
  }, [allCartItemsData])


  const handleCheckout = async (type) => {
    const groupedByVendor = allCartItemsData?.reduce((acc, item) => {

      const { vendorId, customerId, itemId, quantity, inventoryDataDump } = item;

      if (!acc[vendorId]) {
        acc[vendorId] = {
          vendorId,
          type: type,
          customerId,
          // orderListName: orderListName,
          addressId: addressId, // Default value
          items: [],
        };
      }

      acc[vendorId].items.push({
        itemId,
        quantity,
        unit: inventoryDataDump?.unit
      });

      return acc;
    }, {});

    // Step 2: Convert grouped data to an array
    const transformedData = groupedByVendor ? Object?.values(groupedByVendor) : [];

    const result = await createNewOrder(transformedData);

    if (result?.data) {
      setPaymentData(result?.data);
      setSelectedOrderId(result?.data?.orderId);
      setOpenPayment(true);
    }

  }

  const handleCheckoutWithOrderList = async (type) => {

    const groupedByVendor = allCartItemsData?.reduce((acc, item) => {

      const { vendorId, customerId, itemId, quantity, inventoryDataDump } = item;

      if (!acc[vendorId]) {
        acc[vendorId] = {
          vendorId,
          type: type,
          customerId,
          orderListName: orderListName,
          addressId: addressId, // Default value
          items: [],
        };
      }

      acc[vendorId].items.push({
        itemId,
        quantity,
        unit: inventoryDataDump?.unit
      });

      return acc;
    }, {});

    // Step 2: Convert grouped data to an array
    const transformedData = groupedByVendor ? Object?.values(groupedByVendor) : [];


    const result = await createNewOrder(transformedData);

    if (result?.data) {
      setPaymentData(result?.data);
      setSelectedOrderId(result?.data?.orderId);
      setOpenPayment(true);
    }

  }

  const handlePaymentClose = () => {
    setOpenPayment(false);
  }



  const clearCart = async () => {
    const result = await removeAllItemFromCart(orgId);
    if (result?.data) {
      dispatch(openMessage({ message: 'Cart cleared!', messageSeverity: 'success' }));
      setOpenConfirm(false);
    }
  }

  const handleConfirmCancel = () => {
    setOpenConfirm(false);
  }



  const handleChange = (add) => {
    setAddressId(add?.id)
  };

  const controlProps = (item) => ({
    checked: selectedValue.pincode == item.pincode,
    onChange: (e) => handleChange(item),
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item.id },
  });



  const deliveryPincodes = priceDetails?.deliversAt?.flatMap(vendor => vendor.deliversAt);
  // const filteredAddresses = add?.addresses?.filter((address) =>
  //   deliveryPincodes?.includes(address.pincode)
  // );
  const filteredAddresses = add?.addresses?.filter((address) =>
    selectedValue?.pincode === address?.pincode
  );
  const removeCartItem = async (id) => {
    await removeItemFromCart(id);
  }

  const handleNavigate = (vendorId) => {
    dispatch(setSelectedVendorId(vendorId))
    navigate(`/org/${orgId}/explore-products?vendorId=${vendorId}`)
  }

  return (
    <Container maxWidth='xl'>
      {
        allCartItemsData && allCartItemsData?.length > 0
          ?
          <>
            <Stack direction={isMobile ? 'column' : 'row'} width={'100%'} justifyContent={'space-between'} alignItems={isMobile ? 'flex-start' : 'center'}>
              <Typography variant='h4' mb={'2rem'}>Cart Items({allCartItemsData?.length})</Typography>
              <Stack direction={'row'} gap={'1rem'} alignItems={'center'}>
                <CustomButton variant={'outlined'} label={'Clear Cart'} onClick={() => setOpenConfirm(true)} />
                <CustomButton variant={'contained'} label={'Continue Shopping'} onClick={() => handleNavigate(allCartItemsData[0].vendorId)} />
              </Stack>
            </Stack>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', }}>
              <Box container sx={{ position: 'relative', flex: '0 0 auto', width: '100%', }}>
                {!isMobile && <Box display={'flex'} width={'100%'} gap={'2rem'}>
                  <Box width={'80%'} sx={{ flex: '1 1', borderInlineEnd: isMobile ? '' : '1px solid #DCE1E5', display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'wrap', padding: isMobile ? '0 0.3rem' : '0 1rem' }}>
                    <InstantOrderItemsHead />
                    {
                      allCartItemsData?.map((item, index) => (
                        <InstantOrderItemsCard item={item} key={item?.id} handleAddToCart={handleAddToCart} removeCartItem={removeCartItem} />
                      ))
                    }
                  </Box>
                  <Box
                    sx={{ width: '20%' }} >
                    <Box sx={{
                      paddingBlockStart: '24px', display: 'flex', flexDirection: 'column', gap: '2rem',
                    }}>
                      <PriceDetailsComp priceDetails={priceDetails} />
                      <Stack spacing={1}>
                        <Button variant='contained' onClick={() => setIsOrderList(true)}>Create Shopping List & Checkout</Button>
                        <Button variant='text' onClick={() => handleCheckout('checkout_and_orderlist')}>Checkout</Button>
                      </Stack>
                    </Box>
                    <Box>
                      <Typography variant='h5' mt={'1rem'}>Addresses</Typography>
                      <Stack mt={'1rem'} spacing={2} width={'100%'}>
                        {
                          filteredAddresses?.map((data, index) => (
                            <Stack direction={'row'} gap={'1rem'} alignItems={'center'} key={index}>
                              <Radio {...controlProps(data)} />
                              <Typography variant='body1'>
                                <span>{data?.pincode ? `${data?.landmark ? data?.landmark + ", " : ""}${data?.line1 ? data?.line1 + ", " : ""}${data?.city ? data?.city + ", " : ""}${data?.state ? data?.state + ", " : ""}${data?.pincode ?? ""}` : ""}
                                </span>
                              </Typography>
                            </Stack>
                          ))
                        }
                      </Stack>
                    </Box>
                  </Box>
                </Box>}
                {
                  isMobile &&
                  <Box sx={{ pt: 2 }}>
                    <Typography variant='subtitle1'>Item Details</Typography>
                    {
                      allCartItemsData?.map((item, index) => (
                        <InstantOrderItemsCard item={item} key={item?.id} handleAddToCart={handleAddToCart} removeCartItem={removeCartItem} />
                      ))
                    }
                    <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                      <Button variant='outlined' onClick={() => setOpenMobilePaymentDetails(true)}>Proceed to Checkout</Button>
                    </Box>
                  </Box>
                }
              </Box>
            </Box>
            {
              isMobile && <CustomSwipableDrawer openMobilePaymentDetails={openMobilePaymentDetails} setOpenMobilePaymentDetails={setOpenMobilePaymentDetails} total={priceDetails?.total} totalItem={allCartItemsData?.length}>{<Box
              >
                <Box sx={{
                  paddingBlockStart: '24px', display: 'flex', flexDirection: 'column', gap: '2rem',
                }}>
                  <PriceDetailsComp priceDetails={priceDetails} />
                  <Stack spacing={1}>
                    <Button variant='contained' onClick={() => setIsOrderList(true)}>Create Shopping List & Checkout</Button>
                    <Button variant='text' onClick={() => handleCheckout('checkout')}>Checkout</Button>
                  </Stack>
                </Box>
                <Box>
                  <Typography variant='h5' mt={'1rem'}>Addresses</Typography>
                  <Stack mt={'1rem'} spacing={2} width={'100%'}>
                    {
                      filteredAddresses?.map((data, index) => (
                        <Stack direction={'row'} gap={'1rem'} alignItems={'center'} key={index}>
                          <Radio {...controlProps(data)} />
                          <Typography variant='body1'>
                            <span>{data?.pincode ? `${data?.landmark ? data?.landmark + ", " : ""}${data?.line1 ? data?.line1 + ", " : ""}${data?.city ? data?.city + ", " : ""}${data?.state ? data?.state + ", " : ""}${data?.pincode ?? ""}` : ""}
                            </span>
                          </Typography>
                        </Stack>
                      ))
                    }
                  </Stack>
                </Box>
              </Box>}</CustomSwipableDrawer>
            }
          </>
          :
          <Stack sx={{ justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
            <img src={emptyCart} width={400} />
            <Typography variant='subtitle1'>Your shopping cart is empty.</Typography>
            <CustomButton variant={'contained'} label={'Continue Shopping'} props={{ component: Link, to: `/` }} />
          </Stack>

      }
      <PaymentPage
        open={openPayment}
        handleClose={handlePaymentClose}
        isThankYou={thankYou}
        afterPayClick={afterPayClick}
        isLoading={isLoading}
        price={priceDetails?.total}
        paymentData={paymentData}
      />
      <ClearCartDialgoue
        open={openConfirm}
        handleOnCancel={handleConfirmCancel}
        handleOnConfirmed={clearCart}
        handleOnConfirmedLoading={removeAllItemFromCartLoading}
      />
      <OrderListNameDialogue
        name={orderListName}
        setName={setOrderListName}
        open={isOrderList}
        onClose={() => setIsOrderList(false)}
        saveAndProceed={() => handleCheckoutWithOrderList('checkout_and_orderlist')}
      />
    </Container >
  )
}

export default InstantOrderCart