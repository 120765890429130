import React from 'react'
import dayjs from 'dayjs';
import { Typography, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export const WeeklySchedule = ({ selectedDays, handleDayToggle }) => {
  return (
    <div>
      <Typography variant="h6">Select Days:</Typography>
      <div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap' }}>
        {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => (
          <div key={day} style={{ marginBottom: '8px', minWidth: '100px' }}> {/* Added minWidth to prevent overlapping */}
            <Typography variant="body1" style={{ marginRight: '8px', fontWeight: selectedDays.includes(day) ? 'bold' : 'normal' }}>
              {day}
            </Typography>
            <Checkbox
              checked={selectedDays.includes(day)}
              onChange={() => handleDayToggle(day)}
              // style={{ cursor: 'pointer', color: selectedDays.includes(day) ? 'green' : 'red' }}
            />
          </div>
        ))}
      </div>
      {/* Add time picker components for each selected day */}
    </div>
  );
};


export const DailySchedule = () => {
    return (
        <div>
          <Typography variant="h6">Day:</Typography>
          {/* Add time picker component */}
          <Typography variant='body1' sx={{fontWeight:500}}>Your order will be scehdule for every day.</Typography>
        </div>
      );
}

export const ParticularDateSchedule = ({ selectedDate, handleDateChange }) => {
  const minDate = dayjs().toDate();
    // const [value, setValue] = React.useState(dayjs('2022-04-17'));
    return (
      <div>
        <Typography variant="h6">Schedule Date:</Typography>        
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={[ 'DatePicker']}>
        <DatePicker
          label="Date picker"
          // value={value}
          onChange={(newValue) => handleDateChange(newValue)}
          // minDate={minDate}
          disablePast
        />
        </DemoContainer>
        </LocalizationProvider>
      </div>
    );
  };



