import React, { useEffect, useRef } from "react";
import { Avatar, Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import ProductCard from "./ProductCard";
import { horecaApi, useGetCartItemsQuery, useGetSuitableVendorListMutation, useRemoveAllItemFromCartMutation } from "../../redux/api/api";
import { SelecteCategoriesComp } from "../../layouts/common/Searchbar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GetImageComponent from "../../components/common/GetImageComponent";
import CustomRating from "../../components/common/CustomRating";
import CustomSearchbar from "../../components/common/CustomSearchbar";
import { stringAvatar } from "../../utils/defaultIcon";
import ItemsLoading from "../../components/common/ItemsLoading";
import NoVendorMessage from "../../components/common/NoVendorMessage";
import NoData from "../order-list/NoData";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import { openMessage } from "../../redux/slices/showMessageSlice";
import ClearCartDialgoue from "./ClearCartDialgoue";
import useQueryParams from "../../routes/hooks/useQueryParams";
import PageHeader from "../../components/common/PageHeader";
import VendorLogoImageComponent from "../../components/common/VendorLogoImageComponent";
import { useLocation } from "react-router-dom";
import ExploreProductMobile from "./ExploreProductMobile";
import ExploreProductNew from "./ExploreProductNew";

const CategoryItem = styled(Box)(({ theme, isMobile }) => ({
  display: isMobile ? "" : "flex",
  alignItems: "center",
  padding: "12px 0",
  paddingRight: 0,
  marginRight: 0,
  paddingLeft: "16px",
  color: "#707070",
  fontSize: "16px",
  cursor: "pointer",
  "&:hover": {
    color: theme.background,
  },
  "&.active": {
    color: theme.background,
    borderRight: "3px solid #e23744",
    height: "60px",
  },
}));

const CategoryImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  backgroundColor: "#e0e0e0",
  borderRadius: "50%",
  width: "50px",
  height: "50px",
}));

const ActiveFilterBlock = styled(CategoryImageContainer)({
  backgroundColor: "#f4f4f4",
});

const InactiveFilterBlock = styled(CategoryImageContainer)({
  backgroundColor: "transparent",
});

const newCategory = {
  id: null,
  category_name: "All",
  category_image: "https://assets.hyperpure.com/data/images/assets/sub_category_image_all_v2.png",
};

const ExplorePorducts = () => {
  const params = useQueryParams();
  const location = useLocation();
  const { isMobile } = useResponsiveStyles();
  const dispatch = useDispatch();
  const { item } = location.state || {};
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);
  const [getVendorItems, { data: vendorItems, isLoading: itemLoading }] = horecaApi.endpoints.getVendorItems.useLazyQuery();
  const [vendorItemData, setVendorItemData] = useState([]);
  const [getSuitableVendorList, { isLoading: vendorLoading }] = useGetSuitableVendorListMutation();
  const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
  const [vendors, setVendors] = useState([]);

  // const { data: allCartItemsData, isLoading: categoryLoading } = useGetCartItemsQuery(orgId, {
  //   skip: !orgId,  // This will skip the query until orgId is available
  // });

  const [cartData, setCartData] = useState([]);
  const rowRefs = useRef([]);
  // useEffect(() => {
  //   setCartData(allCartItemsData);
  // }, [allCartItemsData]);

  const [searchText, setSearchText] = useState("");
  const selectedValue = useSelector((state) => state.app.preference.selectedPinCode);
  const [selectedVendorId, setSelectedVendorId] = useState(vendors[0]?.vendorDetails?.id ? vendors[0]?.vendorDetails?.id : null);
  const [activeParentCategory, setActiveParentCategory] = useState(null);
  const [activeCategory, setActiveCategory] = React.useState(null);
  const [removeAllItemFromCart, { isLoading: removeAllItemFromCartLoading }] = useRemoveAllItemFromCartMutation();
  useEffect(() => {
    if (item && vendorItems) {
      let tempArr = [...vendorItems];
      const _ind = tempArr.findIndex((_obj) => _obj.id == item.id);
      const [item1] = tempArr.splice(_ind, 1);
      setVendorItemData([item1, ...tempArr]);
    } else if (vendorItems) {
      setVendorItemData(vendorItems);
    }
  }, [item, vendorItems])

  const fetchVendors = async () => {
    const result = await getSuitableVendorList({
      orgId,
      payload: {
        vendorOnly: "true",
        categories: [],
        subCategories: [],
        vendorOrgId: params?.vendorId ?? null,
        itemId: null,
        singleVendor: params?.vendorId ? true : false,
        pincode,
      },
    }, true);
    if (result?.data) {
      setVendors(result?.data);
      setSelectedVendorId(result?.data[0]?.vendorDetails?.id);
      setActiveParentCategory(null);
      setCate([newCategory]);
      setActiveCategory(null);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, [selectedValue, params.vendorId]);

  useEffect(() => {
    (async () => {
      if (selectedVendorId && !isMobile) {
        // await getVendorItems({
        //   customerOrgId: orgId,
        //   vendorOrgId: selectedVendorId,
        //   categoryId: activeParentCategory,
        //   subCategoryId: activeCategory,
        //   searchText,
        // }, true);
      }
    })();
  }, [orgId, selectedVendorId, activeCategory, activeParentCategory, searchText, selectedValue]);

  const handelSelectCategory = (category) => {
    setActiveParentCategory(category?.id);
    setActiveCategory(null);
  };

  const [cate, setCate] = useState([]);

  const handelRemoveCategory = (catId) => {
    setActiveParentCategory(null);
    const updatedCategories = [newCategory];
    setCate(updatedCategories);
  };

  const categories1 = vendors
    ?.find((vendor) => vendor?.vendorDetails?.id == selectedVendorId)
    ?.categoryDetails?.find((cat) => cat.id == activeParentCategory)?.subCategories;

  const [openConfirm, setOpenConfirm] = useState(false);

  const onConfirm = async () => {
    const result = await removeAllItemFromCart(orgId);
    if (result?.data) {
      setOpenConfirm(false);
      dispatch(openMessage({ message: "Cart cleared!", messageSeverity: "success" }));
    } else if (result?.error) {
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
    } else {
      dispatch(openMessage({ message: "Not able to clear the cart", messageSeverity: "error" }));
    }
  };

  const onCancel = () => {
    setOpenConfirm(false);
  };

  useEffect(() => {
    if (categories1) {
      // Create a new array with the new category at the start
      const updatedCategories = [newCategory, ...categories1];
      // Update the state with the new array
      setCate(updatedCategories);
    }
  }, [categories1, selectedValue]);

  useEffect(() => {
    if (searchText) {
      setActiveCategory(null);
      setActiveParentCategory(null);
    }
  }, [searchText]);

  if (isMobile) {
    return (
      vendors?.length > 0 && <ExploreProductMobile avgRating={vendors[0]?.avgRating} vendorDetails={vendors[0]?.vendorDetails} categoryDetails={vendors[0]?.categoryDetails} />
    )
  } else {
    return (
      params.vendorId && vendors?.length > 0 &&
      <ExploreProductNew
        avgRating={vendors[0]?.avgRating}
        vendorDetails={vendors[0]?.vendorDetails}
        categoryDetails={vendors[0]?.categoryDetails} />
    )
  }

  
};

export default ExplorePorducts;
