import { Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material'
import React from 'react'
import { useGetMyCustomersQuery } from '../../redux/api/api'
import { useParams } from 'react-router-dom'
import UserTableHead from '../system-admin/vendor/user-table-head'
import MyCustomerTableRow from './MyCustomerTableRow'
import { useRouter } from '../../routes/hooks/useRouter'
import PageHeader from '../../components/common/PageHeader'
import TableNoData from '../../views/list/table-no-data'
import TableLoadingData from '../../components/common/TableLoadingData'

const MyCustomer = () => {

  const router = useRouter();
  const { orgId } = useParams();
  const { data: myCustomersData, isLoading: getMyCustomersLoading } = useGetMyCustomersQuery(orgId);

  const handleClick = () => {

  }

  const onEdit = (selectedId) => {
    router.push(`/org/${orgId}/my-customers/${selectedId}`);
  }

  return (
    <Container maxWidth='xl'>
      <PageHeader
        title={"My Customers"}
        // linkArray={[
        //   { title: "Dashboard", path: `/` },
        //   { title: "My Customers" },
        // ]}
      />
      <TableContainer component={Paper} >
        <Table sx={{ minWidth: 750 }} stickyHeader>
          <UserTableHead
            headLabel={[
              { id: 'orgName', label: 'Org Name' },
              { id: 'phoneNumber', label: 'Phone Number' },
              { id: 'email', label: 'Org Email' },
              { id: 'userData', label: 'Admin Info', align: 'left' },
              { id: 'action' },
            ]}
          />
          <TableBody>
            {getMyCustomersLoading ?
              <TableLoadingData colSpan={5} /> :
              myCustomersData && myCustomersData?.length > 0 ? myCustomersData?.map((row, index) => (
                <MyCustomerTableRow
                  key={row.id}
                  orgId={row.id}
                  name={row.orgName}
                  role={row.orgEmail}
                  company={row.phoneNumber}
                  avatarUrl={row.orgLogoKey}
                  isVerified={row.active}
                  orgAdmin={row?.userData}
                  handleClick={(event) => handleClick(event, row.name)}
                  onEdit={onEdit}
                />
              ))
                :
                (
                  <TableNoData query={'Customers'} colSpan={5} />
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default MyCustomer