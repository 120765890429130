import { useEffect } from "react";
import { horecaApi } from "../../../redux/api/api";
import { Box, Grid, Typography } from "@mui/material";
import ProductCard from "../ProductCard";
import NoData from "../../order-list/NoData";
import { useSelector } from "react-redux";
import useQueryParams from "../../../routes/hooks/useQueryParams";
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";
import ProductDetails from "../ExploreProductNew/ProductDetails";

const ProductSearchResult = ({ searchText, wishList, addToWishlist }) => {
    const { isMobile } = useResponsiveStyles();
    const params = useQueryParams();
    const orgId = useSelector((state) => state.app.preference.selectedOrgId);
    const [getVendorItems, { data: vendorItemData, isLoading: itemLoading }] = horecaApi.endpoints.getVendorItems.useLazyQuery();
    
    useEffect(() => {
        if(searchText) {
            (async () => {
                await getVendorItems({
                    customerOrgId: orgId,
                    vendorOrgId: params?.vendorId,
                    categoryId: null,
                    subCategoryId: null,
                    searchText
                });
            })();
        }
    }, [searchText])
    if(isMobile) {
        return (
            <Box sx={{ display:'flex', flexDirection:'column', gap:2, paddingTop: 1 }}>
                <Typography  variant="h5">
                    Showing results for "{searchText}"
                </Typography>
                <Box sx={{ backgroundColor: '#FBFBFB' }}>
                    <Grid container={12} spacing={1} >
                        {vendorItemData?.length > 0 ? (
                            vendorItemData?.map((item, index) => (
                                <Grid key={`${index}product`} item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <ProductCard wishList={wishList} addToWishlist={addToWishlist} data={item} key={item.id} customerId={orgId} vendorId={params?.vendorId} allCartItemsData={[]} />
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12}>
                                <NoData query={searchText ? searchText : "Inventory Items"} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
        )
    }
    return (
        <Box sx={{ display:'flex', flexDirection:'column', gap:2, paddingTop: 1 }}>
            <Typography  variant="h5">
                Showing results for "{searchText}"
            </Typography>
            <Box sx={{ backgroundColor: '#FBFBFB' }}>
                <Grid container={12} spacing={2} >
                    {vendorItemData?.length > 0 ? (
                        vendorItemData?.map((item, index) => (
                            <Grid key={`${index}product`} item xs={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <ProductDetails wishList={wishList} addToWishlist={addToWishlist} data={item} key={item.id} customerId={orgId} vendorId={params?.vendorId} allCartItemsData={[]} />
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <NoData query={searchText ? searchText : ""} />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    )
}

export default ProductSearchResult;