export function stringToColorHex(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
        hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value?.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringToColorRgb(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
        hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    const rgb = { r: 0, g: 0, b: 0 };

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        if (i === 0) rgb.r = value;
        if (i === 1) rgb.g = value;
        if (i === 2) rgb.b = value;
    }
    /* eslint-enable no-bitwise */

    // return rgb;
    return `${rgb.r},${rgb.g},${rgb.b}`
}

export function stringAvatar({ name, color = "hex" }) {


    let nameInCapital =name ?  name?.toUpperCase() : 'Horeca 1'

    nameInCapital = (nameInCapital?.split(" "))?.filter((char) => char != "")
    

    return {
        item: {
            sx: {
                bgcolor: color === "hex" ? stringToColorHex(name) : stringToColorRgb(name),
            },
            children: (`${nameInCapital[0][0]}`)?.toUpperCase()
        },
        user: {
            sx: {
                bgcolor: color === "hex" ? stringToColorHex(name) : stringToColorRgb(name),
            },
            children: nameInCapital?.length > 1 ? (`${nameInCapital[0][0]}${nameInCapital[1][0]}`)?.toUpperCase() : (`${nameInCapital[0][0]}${nameInCapital[0][1]}`)?.toUpperCase()
        }
    };
}