import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from "@mui/material";
import Iconify from "../../../components/common/Iconify";
import { useRouter } from "../../../routes/hooks/useRouter";
import CustomButton from "../../../components/common/CustomButton";

const ThankYouDialogue = ({ open }) => {

  const router = useRouter();


  const handelCloseDialogue = () => {
    router.replace('/')
  }

  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "350px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>Thank You!</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handelCloseDialogue}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        {/* <CloseIcon /> */}
        <Iconify icon="material-symbols:cancel-outline" />
      </IconButton>
      <DialogContent>
        <Stack justifyContent={'center'} alignItems={'center'} gap={'1rem'}>
          <Typography textAlign={'center'} fontWeight={600}>You are successfully onboarded!</Typography>
          <Typography textAlign={'center'}>Click   on below button to login and fill further details to go in action🎉</Typography>
          <CustomButton
            onClick={handelCloseDialogue}
            variant={"contained"}
            label={"Login"}
            sx={{ mt: '1rem' }}
            width={"100%"}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};




export default ThankYouDialogue