import React from 'react'
import { SectionContainer } from './Section1'
import { Box, Button, Container, Grid, ImageList, ImageListItem, Stack, Typography } from '@mui/material'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CustomAllTypography from '../../components/common/CustomAllTypography';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { brand1, brand10, brand2, brand3, brand4, brand5, brand6, brand7, brand8, brand9 } from '../../assets/images';
import useResponsiveStyles from '../../hooks/useResponsiveStyles';


const brandImages = [
  "https://assets.hyperpure.com/data/images/data/images/campaign/24835ad5-3a0d-4572-a667-f5b6c466ef421671353854.png",
  "https://assets.hyperpure.com/data/images/data/images/campaign/20f254b7-6300-4ef0-9857-ebec0669844b1671353854.png",
  "https://assets.hyperpure.com/data/images/data/images/campaign/5c5bee5e-1362-4b43-8c51-8c15af0fb0271671353854.png",
  "https://assets.hyperpure.com/data/images/data/images/campaign/453d37a0-1fd4-4460-ab94-d02b9f5b7e011671353854.png",
  "https://assets.hyperpure.com/data/images/data/images/campaign/8e8965c7-8bcc-4946-a3b9-674f98e4ad4b1671353853.png",
  "https://assets.hyperpure.com/data/images/data/images/campaign/827dcda2-cc0c-483b-b73f-98ce6a06f9051671353854.png",
];

const responsive = {
  superLargeDesktop: {
    // The name can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },

    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


const PopularBrands = ({ setLoginOpen }) => {
  const responsive = useResponsiveStyles();
  if (responsive.isMobile) {
    return (
      <Container>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant='h3' fontWeight={600} textAlign={'center'} sx={{ marginBottom: 2 }}>Our popular brands</Typography>
          <ImageList variant="masonry" cols={2} rowHeight={100} gap={16}>
            {[brand1, brand2, brand3, brand4, brand5, brand6, brand7, brand8, brand9, brand10].map((item) => (
              <ImageListItem key={item}>
                <img
                  srcSet={`${item}`}
                  src={`${item}`}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 3 }}>
            <Typography variant='h5' fontWeight={600} textAlign={'center'} color={'text.disabled'}>Over 250+ brands available</Typography>
            <Button size='large' onClick={() => setLoginOpen(true)} endIcon={<KeyboardDoubleArrowRightIcon />}>Feel free to explore</Button>
          </Box>
        </Box>
      </Container>
    )
  }
  return (
    <Container>
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant='h3' fontWeight={600} textAlign={'center'}>Our popular brands</Typography>

      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Grid container spacing={1} sx={{ paddingX: 30, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <Grid item sm={4} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <Box sx={{ boxShadow: '0 0 15px rgba(135, 206, 235, 0.6)', backgroundColor: '#FFF', borderRadius: 2, padding: 3, width: '100%' }}>
              <Box component="img" src={brand1} width={'100%'} height={100} />
            </Box>
          </Grid>
          <Grid item sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ boxShadow: '0 0 15px rgba(135, 206, 235, 0.6)', backgroundColor: '#FFF', borderRadius: 2, padding: 3, width: '100%' }}>
              <Box component="img" src={brand2} width={'100%'} height={100} />
            </Box>
          </Grid>
          <Grid item sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ boxShadow: '0 0 15px rgba(135, 206, 235, 0.6)', backgroundColor: '#FFF', borderRadius: 2, padding: 3, width: '100%' }}>
              <Box component="img" src={brand3} width={'100%'} height={100} />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ paddingX: 15, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <Grid item sm={3} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <Box sx={{ boxShadow: '0 0 15px rgba(135, 206, 235, 0.6)', backgroundColor: '#FFF', borderRadius: 2, padding: 3, width: '100%' }}>
              <Box component="img" src={brand4} width={'100%'} height={100} />
            </Box>
          </Grid>
          <Grid item sm={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ boxShadow: '0 0 15px rgba(135, 206, 235, 0.6)', backgroundColor: '#FFF', borderRadius: 2, padding: 3, width: '100%' }}>
              <Box component="img" src={brand5} width={'100%'} height={100} />
            </Box>
          </Grid>
          <Grid item sm={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ boxShadow: '0 0 15px rgba(135, 206, 235, 0.6)', backgroundColor: '#FFF', borderRadius: 2, padding: 3, width: '100%' }}>
              <Box component="img" src={brand6} width={'100%'} height={100} />
            </Box>
          </Grid>
          <Grid item sm={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ boxShadow: '0 0 15px rgba(135, 206, 235, 0.6)', backgroundColor: '#FFF', borderRadius: 2, padding: 3, width: '100%' }}>
              <Box component="img" src={brand7} width={'100%'} height={100} />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ paddingX: 30, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <Grid item sm={4} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <Box sx={{ boxShadow: '0 0 15px rgba(135, 206, 235, 0.6)', backgroundColor: '#FFF', borderRadius: 2, padding: 3, width: '100%' }}>
              <Box component="img" src={brand8} width={'100%'} height={100} />
            </Box>
          </Grid>
          <Grid item sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ boxShadow: '0 0 15px rgba(135, 206, 235, 0.6)', backgroundColor: '#FFF', border: '1px solid grey', borderRadius: 2, padding: 3, width: '100%' }}>
              <Box component="img" src={brand9} width={'100%'} height={100} />
            </Box>
          </Grid>
          <Grid item sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ boxShadow: '0 0 15px rgba(135, 206, 235, 0.6)', backgroundColor: '#FFF', borderRadius: 2, padding: 3, width: '100%' }}>
              <Box component="img" src={brand10} width={'100%'} height={100} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Box sx={{ position: 'relative', paddingLeft: 3, marginBottom: 4, padding: 0, mt:2}}>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        showDots={false}
        arrows={false}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
      >
        {brandImages.map((image, index) => (
          <Box key={index} sx={{ padding: '20px' }}>
            <img
              src={image}
              alt={`Brand ${index}`}
              className="Home_brandCampaignImage__0DsQv"
              style={{ width: '100%', borderRadius: '16px' }}
            />
          </Box>
        ))}
      </Carousel>
    </Box> */}
      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 3 }}>
        <Button sx={{ visibility: 'hidden' }} size='large' endIcon={<KeyboardDoubleArrowRightIcon />}>Feel free to explore</Button>
        <Typography variant='h5' fontWeight={600} textAlign={'center'} color={'text.disabled'}>Over 250+ brands available</Typography>
        <Button size='large' onClick={() => setLoginOpen(true)} endIcon={<KeyboardDoubleArrowRightIcon />}>Feel free to explore</Button>
      </Box> */}
    </Container>
  )
}

export default PopularBrands



