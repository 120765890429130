import { Box, Dialog, DialogContent, DialogTitle, Drawer, Grid, IconButton, Menu, Radio, Slide, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomButton from "../../components/common/CustomButton";
import Iconify from "../../components/common/Iconify";
import { useState } from "react";
import {
  horecaApi,
  useAddUpadteAddressMutation,
  useDeleteAddressMutation,
  useGetAllShippingAddressOutletWiseMutation,
  useGetCartItemsQuery,
  useRemoveAllItemFromCartMutation,
  useSavePreferncesMutation,
} from "../../redux/api/api";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "../../components/common/CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { openMessage } from "../../redux/slices/showMessageSlice";
import { setSavePrfernces } from "../../redux/slices/appReducer";
import ClearCartDialgoue from "../../pages/instant-order/ClearCartDialgoue";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getAllOrgId, getOrgData } from "../../utils/org";
import Zoom from "@mui/material/Zoom";
import ChangeOrgDialogue from "./ChangeOrgDialogue";
import { useRouter } from "../../routes/hooks/useRouter";
import CustomLabel from "../../components/common/label/CustomLabel";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import UpdateOrganizationMenu from "../../pages/settings/sections/organization/UpdateOrganizationMenu";

const AddNewShippingAddressDialogue = ({ open, handelCloseDialogue, formik, addUpadteAddressLoading }) => {
  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          {formik.values?.id ? "Update Shipping Address" : "Add New Shipping Address"}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handelCloseDialogue}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Iconify icon="material-symbols:cancel-outline" />
      </IconButton>
      <DialogContent sx={{ p: { xs: 2, sm: 3 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomTextField name="line1" formik={formik} label="Address" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField name="landmark" formik={formik} label="Landmark" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField name="city" formik={formik} label="City" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField name="state" formik={formik} label="State" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField name="pincode" formik={formik} label="Pincode" />
          </Grid>
        </Grid>
        <Stack alignItems={"flex-end"} mt={"1rem"}>
          <CustomButton
            label={
              addUpadteAddressLoading && formik.values?.id
                ? "Updating..."
                : addUpadteAddressLoading && !formik.values?.id
                  ? "Saving..."
                  : formik.values?.id
                    ? "Update Address"
                    : "Save Address"
            }
            onClick={formik.handleSubmit}
            loading={addUpadteAddressLoading}
            endIcon={<Iconify icon="ic:outline-add-location-alt" width={14} />}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const SelectAddress = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isMobile } = useResponsiveStyles();
  const user = useSelector((state) => state.auth.user);
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);
  const selectedValue = useSelector((state) => state.app.preference.selectedPinCode);
  const { data: allCartItemsData } = useGetCartItemsQuery(orgId, {
    skip: !orgId,  // This will skip the query until orgId is available
  });

  const [savePrefernces] = useSavePreferncesMutation();
  const [getAllPinCodes] = horecaApi.endpoints.getAllPinCodes.useLazyQuery();
  const [removeAllItemFromCart, { isLoading: removeAllItemFromCartLoading }] = useRemoveAllItemFromCartMutation();
  const [openDialogue, setOpenDialogue] = useState(false);
  const [add, setAdd] = useState([]);
  const [open, setOpen] = useState(false);

  // const orgData = getOrgData(user, orgId);
  const [orgData, setOrgData] = useState(null);
  useEffect(() => {
    const fetchOrgData = () => {
      const data = getOrgData(user, orgId);
      setOrgData(data);
    };

    fetchOrgData();
  }, [user, orgId]);


  const [addUpadteAddress, { isLoading: addUpadteAddressLoading }] = useAddUpadteAddressMutation();

  const [deleteAddress] = useDeleteAddressMutation();

  const [getAllShippingAddressOutletWise] = useGetAllShippingAddressOutletWiseMutation();

  const addyy = useSelector((state) => state.app.orgWiseAddresses);

  useEffect(() => {

    (async () => {
      const orgId = await getAllOrgId(user?.orgDetail);
      await getAllShippingAddressOutletWise({ payload: { orgIds: orgId } }, true);
      if (addyy.length > 0) {
        if(!selectedValue.pincode){
        const ind=addyy[0]?.orgType == 'vendor' ? 1 :  0
        setSelectedPinCode(addyy[ind]?.address);
        const payload = { key: "selectedPinCode", value: { id: addyy[ind]?.address?.id, pincode: addyy[ind]?.address?.pincode } };
        dispatch(setSavePrfernces(payload));
        await savePrefernces({ payload: { key: payload?.key, value: JSON.stringify(payload?.value) } });
        }
      }
    })();
  }, []);
  const validationSchema = Yup.object({
    line1: Yup.string().required("Address line is required"),
    billing: Yup.boolean(),
    landmark: Yup.string().optional(),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    pincode: Yup.string()
      .required("Pincode is required")
      .matches(/^\d{6}$/, "Pincode must be exactly 6 digits"),
  });

  const formik = useFormik({
    initialValues: {
      line1: "",
      billing: false,
      landmark: "",
      state: "",
      city: "",
      pincode: "",
      id: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      const result = await addUpadteAddress({ orgId, payload: values });
      if (result?.data) {
        dispatch(openMessage({ message: result?.data?.message, messageSeverity: "success" }));
        setTimeout(async () => {
          const _orgId = await getAllOrgId(user?.orgDetail);
          await getAllShippingAddressOutletWise({ payload: { orgIds: _orgId } }, true);
          const result = await getAllPinCodes(orgId);
          setAdd(result?.data?.addresses);
          handelCloseDialogue();
        }, 1000);
      } else if (result?.error) {
        dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
      } else {
        dispatch(openMessage({ message: "Not able to add new address at this moment", messageSeverity: "error" }));
      }
    },
  });

  useEffect(() => {
    orgData?.status === "approved" && orgId &&
      (async () => {
        const result = await getAllPinCodes(orgId);
        if (result?.data) {
          setAdd(result?.data?.addresses);
          if (!selectedValue.pincode) {
            const ind=addyy[0]?.orgType == 'vendor' ? 1 :  0
            setSelectedPinCode(addyy[ind]?.address);
            const payload = { key: "selectedPinCode", value: { id: addyy[ind]?.address?.id, pincode: addyy[ind]?.address?.pincode } };
            dispatch(setSavePrfernces(payload));
            await savePrefernces({ payload: { key: payload?.key, value: JSON.stringify(payload?.value) } });

            // const payload = { key: "selectedPinCode", value: { id: result?.data?.addresses[0]?.id, pincode: result?.data?.addresses[0]?.pincode } };
            // dispatch(setSavePrfernces(payload));
            // savePrefernces({ payload: { key: payload?.key, value: JSON.stringify(payload?.value) } });
          }
        }
      })();
  }, [user, openDialogue, orgId]);

  function handleAddressEditDelete(address) {
    formik.setValues({
      line1: address?.line1 || "",
      billing: false,
      landmark: address?.landmark || "",
      state: address?.state || "",
      city: address?.city || "",
      pincode: address?.pincode || "",
      id: address?.id || null,
    });
  }

  async function handleDeleteAddress() {
    const result = await deleteAddress({ orgId: orgId, addressId: formik.values?.id });
    if (result?.data) {
      setContextMenu(null);
      dispatch(openMessage({ message: result?.data?.message, messageSeverity: "success" }));
      setTimeout(async () => {
        const _orgId = await getAllOrgId(user?.orgDetail);
        await getAllShippingAddressOutletWise({ payload: { orgIds: _orgId } }, true);
        const result = await getAllPinCodes(orgId);
        setAdd(result?.data?.addresses);
        handelCloseDialogue();
      }, 1000);
    } else if (result?.error) {
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
    } else {
      dispatch(openMessage({ message: "Not able to delete address at this moment", messageSeverity: "error" }));
    }
  }

  const handleOpen = async () => {
    const _orgId = await getAllOrgId(user?.orgDetail);
    await getAllShippingAddressOutletWise({ payload: { orgIds: _orgId } }, true);
    setOpen(true);
  };

  const handleClose = async () => {
    const result = await getAllPinCodes(orgId);
    if (result?.data) {
      setAdd(result?.data?.addresses);
    }
    setOpen(false);
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const [changeOrg, setChangeOrg] = useState(false);
  const [chooseOrgId, setChooseOrgId] = useState(null);

  const [selectedPincode, setSelectedPinCode] = useState();

  const handleChange = (item, data) => {
    if (data?.id == orgId) {
      if (selectedValue?.pincode === item?.pincode || allCartItemsData?.length < 1) {
        setSelectedPinCode(item);
        const payload = { key: "selectedPinCode", value: { id: item?.id, pincode: item?.pincode } };
        dispatch(setSavePrfernces(payload));
        savePrefernces({ payload: { key: payload?.key, value: JSON.stringify(payload?.value) } });
      } else {
        setOpenConfirm(true);
      }
    } else {
      setChooseOrgId(data?.id);
      setSelectedPinCode(item);
      setChangeOrg(true);
    }
  };

  const controlProps = (item, data) => ({
    // checked: selectedValue?.id && data.orgType == 'customer' ? selectedValue?.id == item?.id : data.orgType !== 'customer' ? orgId == data?.id : orgId == data?.id,
    checked: selectedValue?.pincode && data.orgType == 'customer' ? selectedValue?.pincode == item?.pincode : orgId == data?.id,
    onChange: (e) => handleChange(item, data),
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item?.id },
  });

  const handleOpenAddNewAddress = () => {
    setOpenDialogue(true);
  };

  const handelCloseDialogue = () => {
    setOpenDialogue(false);
    formik.resetForm();
  };

  const [pincode1, setPincode1] = useState();

  useEffect(() => {
    setPincode1(addyy?.find((data) => data?.address?.id == selectedValue?.id)?.address?.pincode);
  }, [addyy, selectedValue]);

  const onConfirm = async () => {
    const result = await removeAllItemFromCart(orgId);
    if (result?.data) {
      setOpenConfirm(false);
      dispatch(openMessage({ message: "Cart cleared!", messageSeverity: "success" }));
    } else if (result?.error) {
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
    } else {
      dispatch(openMessage({ message: "Not able to clear the cart", messageSeverity: "error" }));
    }
  };

  const [getMe, { isLoading: getMeLoading }] = horecaApi.endpoints.getMe.useLazyQuery();

  const onConfirm1 = async () => {
    const payload1 = { key: "selectedPinCode", value: { id: selectedPincode?.id, pincode: selectedPincode?.pincode } };
    dispatch(setSavePrfernces(payload1));
    savePrefernces({ payload: { key: payload1?.key, value: JSON.stringify(payload1?.value) } });
    const payload2 = { key: "selectedOrgId", value: chooseOrgId };
    dispatch(setSavePrfernces(payload2));
    savePrefernces({ payload: payload2 });
    setChangeOrg(false);
    setChooseOrgId(null);
    setOpen(false);
    setSelectedPinCode(null);
    router.push(`/`);
  };

  const onCancel = () => {
    setOpenConfirm(false);
  };

  const onCancel1 = () => {
    setChangeOrg(false);
  };

  const [contextMenu, setContextMenu] = useState(null);

  const handleOpenContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
    handleOpenAddNewAddress();
  };

  return (
    <>
      <CustomButton
        label={isMobile ? <>{orgData?.orgName}</> : <>{pincode1 && addyy.find((_obj) => _obj.id == orgId)?.orgType == 'customer' ? `${orgData?.orgName} - ${pincode1}` : `${orgData?.orgName}`}</>}
        variant={"text"}
        onClick={handleOpen}
        endIcon={<KeyboardArrowDownIcon sx={{ color: "black" }} />}
        sx={{
          "& .MuiButton-endIcon": {
            transform: open && "rotate(90deg)",
            transition: "transform 0.3s ease-in-out",
          },
        }}
      />
      <Drawer
        open={!!open}
        anchor={"right"}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: 300,
            borderRadius: 0,
            padding: "0.5rem",
          },
        }}
        transitionDuration={{ enter: 500, exit: 500 }} // Add transition duration
      >
        <Typography variant="h5" fontWeight={500} mt={"1rem"} ml={"0.8rem"}>
          Outlets
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <Iconify icon="material-symbols:cancel-outline" />
        </IconButton>
        <Stack mt={"1rem"} spacing={2} width={"100%"}>
          {addyy?.map((data, index) => (
            data.orgType == addyy.find((_obj) => _obj.id == orgId)?.orgType && <Slide
              key={`${index}`}
              direction="up"
              in={open}
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 200 + index * 150, exit: 600 + (add?.length - index) * 150 }}
            >
              <div
                onContextMenu={(e) => {
                  handleAddressEditDelete(data?.address);
                  handleOpenContextMenu(e);
                }}
                style={{ cursor: "context-menu" }}
              >
                <Stack direction={"row"} gap={"1rem"} alignItems={"center"} key={index}>
                  <Radio {...controlProps(data?.address, data)} />
                  <Tooltip title="Right click for more options" arrow TransitionComponent={Zoom} followCursor>
                    <Typography variant="body1" sx={{ "&:hover": { cursor: "pointer" } }}>
                      <span style={{ fontWeight: 500 }}>{data?.orgName} </span>
                      <span>
                        {data?.address?.pincode
                          ? `-  ${data?.address?.landmark ? data?.address?.landmark + ", " : ""}${data?.address?.line1 ? data?.address?.line1 + ", " : ""
                          }${data?.address?.city ? data?.address?.city + ", " : ""}${data?.address?.state ? data?.address?.state + ", " : ""}${data?.address?.pincode ?? ""
                          }`
                          : ""}
                      </span>
                      <CustomLabel color={"info"}>{data?.orgType}</CustomLabel>
                    </Typography>
                  </Tooltip>
                </Stack>
              </div>
            </Slide>
          ))}
          {addyy.find((_obj) => _obj.id == orgId)?.orgType == 'customer' && <Slide direction="left" in={open} mountOnEnter unmountOnExit timeout={{ enter: 200 + add?.length * 300, exit: 200 }}>
            <div>
              <CustomButton onClick={handleOpenAddNewAddress} label={"Add New Shipping Address"} variant={"text"} width={"100%"} />
            </div>
          </Slide>}
        </Stack>
        <Typography variant="h5" fontWeight={500} mt={"1rem"} ml={"0.8rem"}>
          Switch Organization
        </Typography>
        <Box sx={{ p: 2 }}>
          <UpdateOrganizationMenu />
        </Box>
      </Drawer>
      <AddNewShippingAddressDialogue
        open={openDialogue}
        formik={formik}
        handelCloseDialogue={handelCloseDialogue}
        addUpadteAddressLoading={addUpadteAddressLoading}
      />
      <ClearCartDialgoue
        open={openConfirm}
        handleOnCancel={onCancel}
        handleOnConfirmed={onConfirm}
        handleOnConfirmedLoading={removeAllItemFromCartLoading}
      />
      <ChangeOrgDialogue open={changeOrg} handleOnCancel={onCancel1} handleOnConfirmed={onConfirm1} handleOnConfirmedLoading={getMeLoading} />
      <Menu
        open={contextMenu !== null}
        onClose={() => setContextMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        sx={{
          "& .MuiList-root": {
            width: "130px",
            padding: "0.5rem",
          },
        }}
      >
        <CustomButton
          label={" Edit"}
          startIcon={<Iconify width={16} icon="fluent:edit-28-filled" />}
          props={{ component: "label", role: undefined, tabIndex: -1 }}
          variant="text"
          onClick={handleCloseContextMenu}
          width="100%"
          sx={{ justifyContent: "flex-start", paddingLeft: "12px" }}
          color={"warning"}
        />
        <CustomButton
          label={"Delete"}
          startIcon={<Iconify icon="material-symbols-light:delete-outline" />}
          props={{ component: "label", role: undefined, tabIndex: -1 }}
          variant="text"
          onClick={handleDeleteAddress}
          width="100%"
          sx={{ justifyContent: "flex-start" }}
          color={"error"}
        />
      </Menu>
    </>
  );
};

export default SelectAddress;

{
  /**
- will show all the outlets user have assigned if address have mutliple then with multiple addresses
- if it's vendor just select and organization
- if it's customer select organization and address too
- if it's vendor show only organization name and if customer show organization with addresses
- and show select option like first select organization which is selected if it's have addresses then match address id and also remove address form selected option
- if org id is only there then show selected org if there is address for there org then match it which org is matching with the organization
-by default show selected org
- give indication for organization of vendor or customer
- if not have address id then don't show edit delete option - even don't show tooltip
*/
}
