import {
  bakeryImage,
  beveragesImage,
  dairyCheeseImage,
  dipsaCondimentsImage,
  frozenImage,
  gravySauceImage,
  kiranaGroceryImage,
  masalaSeasoningImage,
  oilGheeImage,
  specialityProductImage,
} from "../assets/categories";
import { aysLogo, horecaLogo, zomatoLogo } from "../assets/images";
import _ from "lodash";


export const vendors = [
  {
    id: 1,
    name: "Horeca 1",
    logo: horecaLogo,
    pincode: "400607",
    deliveriesCount: 45,
    ratingPoint: 5,
    address: "Mumbai, Vaghbil Thane 400607",
    currentOffer: '50',
    currentOfferType: 'discount',
    categories: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    category: [
      {
        id: '1',
        name: 'Dips & Condiments',
        subCategory: null
      },
      {
        id: '2',
        name: 'Gravy, Sauces & Purees',
        subCategory: [
          {
            id: '1',
            name: 'Gravy 1',
          },
          {
            id: '2',
            name: 'Gravy 2',
          },
        ]
      }
    ]
  },
  {
    id: 2,
    name: "Ays",
    logo: aysLogo,
    pincode: "400607",
    deliveriesCount: 50,
    ratingPoint: 3,
    address: "Baner, Malpani Thane 400607",
    currentDicount: 23,
    categories: [4, 5, 3, 4, 1],
    category: [
      {
        id: '1',
        name: 'Dips & Condiments',
        subCategory: [
          {
            id: '1',
            name: 'Gravy 1',
          },
          {
            id: '2',
            name: 'Gravy 2',
          },
        ]
      },
      {
        id: '2',
        name: 'Gravy, Sauces & Purees',
        subCategory: null,
      }
    ]
  },
  // {
  //   id: 3,
  //   name: "Zomato",
  //   logo: zomatoLogo,
  //   pincode: "400607",
  //   deliveriesCount: 23,
  //   ratingPoint: 2,
  //   address: "vadgaon, Vaghbil Thane 400605",
  //   currentDicount: 50,
  //   categories: [6, 7, 2, 1],
  // },
  // {
  //   id: 4,
  //   name: "Horeca 2",
  //   logo: horecaLogo,
  //   pincode: "400607",
  //   deliveriesCount: 30,
  //   ratingPoint: 2,
  //   address: "katraj, Vaghbil Thane 400607",
  //   currentDicount: 50,
  //   categories: [8, 9, 10, 1],
  // },
  // {
  //   id: 6,
  //   name: "One Step",
  //   logo: horecaLogo,
  //   pincode: "400607",
  //   deliveriesCount: 30,
  //   ratingPoint: 2,
  //   address: "katraj, Vaghbil Thane 400607",
  //   currentDicount: 50,
  //   categories: [8, 9, 10, 1],
  // },
  // {
  //   id: 5,
  //   name: "Horeca Client 1",
  //   logo: horecaLogo,
  //   pincode: "400607",
  //   deliveriesCount: 30,
  //   ratingPoint: 2,
  //   address: "katraj, Vaghbil Thane 400607",
  //   currentDicount: 50,
  //   categories: [8, 9, 10, 1],
  // },
];

export const categories = [
  {
    id: 1,
    name: "Dips & Condiments",
    image: dipsaCondimentsImage,
  },
  {
    id: 2,
    name: "Gravy, Sauces & Purees",
    image: gravySauceImage,
  },
  {
    id: 3,
    name: "Speciality Products",
    image: specialityProductImage,
  },
  {
    id: 4,
    name: "Masala & Seasonings",
    image: masalaSeasoningImage,
  },
  {
    id: 5,
    name: "Kirana & Grocery",
    image: kiranaGroceryImage,
  },
  {
    id: 6,
    name: "Beverages",
    image: beveragesImage,
  },
  {
    id: 7,
    name: "Oil & Ghee",
    image: oilGheeImage,
  },
  {
    id: 8,
    name: "Bakery & Desserts",
    image: bakeryImage,
  },
  {
    id: 9,
    name: "Dairy & Cheese",
    image: dairyCheeseImage,
  },
  {
    id: 10,
    name: "Frozen",
    image: frozenImage,
  },
];

const subCategories = [
  {
    id: 1,
    name: "soya sauce",
    logo: "",
    categoryId: 1,
  },
  {
    id: 2,
    name: "katchup",
    logo: "",
    categoryId: 1,
  },
  {
    id: 3,
    name: "shezwan",
    logo: "",
    categoryId: 1,
  },
  {
    id: 4,
    name: "purrees",
    logo: "",
    categoryId: 2,
  },
  {
    id: 5,
    name: "purees 2",
    logo: "",
    categoryId: 2,
  },
  {
    id: 6,
    name: "special 1",
    logo: "",
    categoryId: 3,
  },
  {
    id: 7,
    name: "special 2",
    logo: "",
    categoryId: 3,
  },
  {
    id: 8,
    name: "Everst masala",
    logo: "",
    categoryId: 5,
  },
  {
    id: 9,
    name: "Mount Everts",
    logo: "",
    categoryId: 5,
  },
  {
    id: 10,
    name: "Beverages1",
    logo: "",
    categoryId: 6,
  },
  {
    id: 11,
    name: "Beverages2",
    logo: "",
    categoryId: 6,
  },
  {
    id: 12,
    name: "Beverages3",
    logo: "",
    categoryId: 6,
  },
  {
    id: 13,
    name: "Oil ghee 2",
    logo: "",
    categoryId: 7,
  },
  {
    id: 13,
    name: "dahi",
    logo: "",
    categoryId: 8,
  },
  {
    id: 13,
    name: "milk",
    logo: "",
    categoryId: 8,
  },
  {
    id: 14,
    name: "amul doodh",
    logo: "",
    categoryId: 8,
  },
  {
    id: 15,
    name: "cheesee2",
    logo: "",
    categoryId: 9,
  },
  {
    id: 16,
    name: "cheesee3",
    logo: "",
    categoryId: 9,
  },
  {
    id: 17,
    name: "meat",
    logo: "",
    categoryId: 10,
  },
  {
    id: 18,
    name: "fish",
    logo: "",
    categoryId: 10,
  },
];

//genrate mock items
export const generateMockItems = (vendorId, numItems, totalCount) => {
  const mockItems = [];
  const getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  for (let i = 0; i < numItems; i++) {
    totalCount++;
    const randomCategory = categories[getRandomInt(0, categories.length - 1)];
    const randomSubCategory = subCategories.filter(
      (sub) => sub.categoryId === randomCategory.id
    )[getRandomInt(0, subCategories.length - 1)];
    const itemName = `Item ${totalCount}`;
    const itemQuantity = `${getRandomInt(1, 100)}`;
    const itemPrice = `${getRandomInt(10, 100)}`;
    const itemRate = `${getRandomInt(1, 10)}`;
    const bulkRate = `${parseInt(itemRate) * 10}`;
    const bulkQuantity = "10";
    const unit = "ltr";

    const mockItem = {
      itemId: totalCount,
      itemName,
      itemImage: "",
      sku: `${getRandomInt(100, 999)}`,
      quantity: itemQuantity,
      rate: itemPrice,
      unit,
      bulkRate,
      bulkQuantity,
      categoryId: randomCategory.id,
      subCategoryId: randomSubCategory ? randomSubCategory.id : null,
      vendorId: vendorId,
    };

    mockItems.push(mockItem);
  }

  return mockItems;
}

//genrateItems
export const generateItems = () => {
  const items = [];
  let totalCount = 0;
  vendors.forEach((vendor) => {
    const numItems = Math.floor(Math.random() * 11) + 20; // Generate between 20 to 30 items per vendor
    const vendorItems = generateMockItems(vendor.id, numItems, totalCount);
    totalCount += numItems;
    items.push(...vendorItems);
  });
  return items;
};


export const getSuitableVendors = (pincode, categories1) => {

  let suitableVendors = _.filter(vendors, { pincode: pincode });

  if (categories1.length > 0) {
    suitableVendors = suitableVendors.filter((vendor) => {
      return categories1.some((categoryId) => {
        return vendor.categories.includes(categoryId);
      });
    });
  }

  suitableVendors = suitableVendors.map((vendor) => ({
    ...vendor,
    categories: vendor.categories.map((categoryId) =>
      categories.find((category) => category.id === categoryId)
    ),
  }));

  return suitableVendors;
};



export const getFilteredVendors = (pincode, searchText, categories1) => {

  let suitableVendors = _.filter(vendors, { 'pincode': pincode });

  if (categories1.length > 0) {
    suitableVendors = suitableVendors.filter((vendor) => {
      return categories1.some((categoryId) => {
        return vendor.categories.includes(categoryId);
      });
    });
  }

  // Filter vendors based on search text in vendor name
  if (searchText) {
    const searchResults = suitableVendors.filter((vendor) =>
      vendor.name.toLowerCase().includes(searchText.toLowerCase())
    );

    suitableVendors = suitableVendors.filter((vendor) => {
      const vendorItems = generateItems()
        .filter((item) => item.vendorId === vendor.id)
        .filter((item) => item.itemName.toLowerCase().includes(searchText.toLowerCase()));

      return vendorItems.length > 0;
    });

    suitableVendors.push(...searchResults);
  }

  // Attach items to vendors
  suitableVendors = suitableVendors.map((vendor) => {
    const vendorItems = generateItems()
      .filter((item) => item.vendorId === vendor.id)
      .filter((item) => item.itemName.toLowerCase().includes(searchText.toLowerCase()));

    return {
      ...vendor,
      items: vendorItems
    };
  });

  return suitableVendors;
};




export const getItemsByVendorId = (vendorId) => {
  // Filter items by vendor ID
  const items = generateItems();

  // Filter items by vendor ID
  const vendorItems = _.filter(items, { 'vendorId': vendorId });
  
  // Group items by category
  const itemsByCategory = _.groupBy(vendorItems, 'categoryId');

  // Map through categories to include subcategory data and items if available
  const categoriesWithData = _.map(categories, (category) => {
    const categoryItems = itemsByCategory[category.id] || [];
    if (categoryItems.length === 0) {
      // Category doesn't have any items, return it as is
      return {
        category: {
          id: category.id,
          name: category.name,
          items: []
        }
      };
    } else {
      const itemsWithSubcategories = _.groupBy(categoryItems, 'subCategoryId');
      const subcategoriesWithData = _.map(itemsWithSubcategories, (items, subCategoryId) => {
        const subCategory = _.find(subCategories, { 'id': subCategoryId });
        return {
          id: subCategory ? subCategory.id : null,
          name: subCategory ? subCategory.name : null,
          items
        };
      });
      return {
        category: {
          id: category.id,
          name: category.name,
          items: categoryItems
        },
        subcategories: subcategoriesWithData
      };
    }
  });

  return categoriesWithData;
};