import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Grid, IconButton, Box, MenuItem, Select, InputLabel, FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CustomButton from '../../../components/common/CustomButton';
import { useAddCategoryForItemMutation, useGetAllCategoriesWithSubCategoriesQuery, useRemoveCategoryFromItemMutation } from '../../../redux/api/api';
import { openMessage } from '../../../redux/slices/showMessageSlice';
import { useDispatch } from 'react-redux';





const validationSchema = Yup.object().shape({
    rows: Yup.array().of(
        Yup.object().shape({
            quantity: Yup.number().typeError('Qunatity must be a number')
                .required('Qunatity is required'),
            unit: Yup.string().required('Unit is required'),
            price: Yup.number().typeError('Price must be a number').required('Price is required')
        })
    ),
});

const RowForm = (props) => {
    const { manageOfferForm, updateOfferData, offerData } = props;

    const initValues = offerData && offerData?.map((_obj) => {
        return {
            quantity: _obj.itemQuantity || '',
            unit: _obj.unit || '',
            price: _obj.itemPrice || ''
        }
    })
    
    const formik = useFormik({
        initialValues: {
            rows: initValues && initValues.length > 0 ? initValues : [{ quantity: '', unit: '', price: '' }],
        },
        validationSchema,
        onSubmit: (values) => {
            const dataToSave = values.rows?.map((_obj) => {
                return {
                    item_quantity: _obj.quantity,
                    item_price: _obj.price,
                    unit: _obj.unit
                }
            })
            updateOfferData(dataToSave)
        },
    });

    const handleAddRow = () => {
        formik.setFieldValue('rows', [...formik.values.rows, { quantity: '', unit: '', price: '' }]);
    };

    const handleDeleteRow = async (index, clearAll = false) => {
        const newRows = [...formik.values.rows];
        newRows.splice(index, 1);
        formik.setFieldValue('rows', newRows);
        if(clearAll) {
            await updateOfferData([]);
            formik.setErrors({});
            formik.setTouched({});
            formik.setFieldValue('rows', [{ quantity: '', unit: '', price: '' }]);
        } else {
            formik.setFieldValue('rows', newRows);
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            {formik.values.rows?.map((row, index) => (
                <Grid container spacing={2} key={index}>
                    <Grid item xs={3.5} sx={{ paddingY: 2 }}>
                        <TextField
                            fullWidth
                            id={`quantity-${index}`}
                            name={`rows[${index}].quantity`}
                            label="Quantity"
                            value={row.quantity}
                            onChange={formik.handleChange}
                            error={formik.touched.rows?.[index]?.quantity && Boolean(formik.errors.rows?.[index]?.quantity)}
                            helperText={formik.touched.rows?.[index]?.quantity && formik.errors.rows?.[index]?.quantity}
                        />
                    </Grid>
                    <Grid item xs={3.5} sx={{ paddingY: 2 }}>
                        <FormControl fullWidth  error={formik.touched.rows?.[index]?.unit && Boolean(formik.errors.rows?.[index]?.unit)}>
                            <InputLabel>Unit</InputLabel>
                            <Select
                                fullWidth
                                id={`unit-${index}`}
                                name={`rows[${index}].unit`}
                                label="Unit"
                                sx={{
                                    height: 50,
                                    // backgroundColor: '#FFF',
                                    // '& .MuiOutlinedInput-notchedOutline': {
                                    //     borderColor: '#ABAAAE',
                                    // }
                                }}
                                size='medium'
                                value={row.unit}
                                onChange={formik.handleChange}
                            >
                                <MenuItem value="kg">KG</MenuItem>
                                <MenuItem value="lit">LIT</MenuItem>
                                <MenuItem value="vol">VOLUME</MenuItem>
                                <MenuItem value="pc">PC</MenuItem>
                            </Select>
                            {formik.touched.rows?.[index]?.unit && <FormHelperText>{formik.errors.rows?.[index]?.unit}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3.5} sx={{ paddingY: 2 }}>
                        <TextField
                            fullWidth
                            id={`price-${index}`}
                            name={`rows[${index}].price`}
                            label="Price"
                            value={row.price}
                            onChange={formik.handleChange}
                            error={formik.touched.rows?.[index]?.price && Boolean(formik.errors.rows?.[index]?.price)}
                            helperText={formik.touched.rows?.[index]?.price && formik.errors.rows?.[index]?.price}
                        />
                    </Grid>
                    <Grid item xs={1.5} sx={{ paddingY: 2 }}>
                        <Box sx={{ display: 'flex', columnGap: 0 }}>
                            {index > 0 && <IconButton onClick={() => handleDeleteRow(index)}>
                                <DeleteIcon />
                            </IconButton>}
                            {index == formik.values.rows.length - 1 && <IconButton color='primary' onClick={() => handleAddRow()}>
                                <AddCircleOutlineOutlinedIcon />
                            </IconButton>}
                        </Box>
                    </Grid>
                </Grid>
            ))}
            {/* <Button onClick={handleAddRow}>Add Row</Button> */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2, columnGap:2 }}>
                {/* <Button color="primary" onClick={() => manageOfferForm(false)}>
                    Cancel
                </Button> */}
                {
                    offerData.length == 1 && 
                    <CustomButton variant='outlined' onClick={() => handleDeleteRow(0, true)} label={"Delete All"}/>
                }
                <CustomButton variant='contained' props={{type:"submit"}} label={"Save"}/>
            </Box>

        </form>
    );
};

export default RowForm;


export const RowForm1 = (props) => {



    const dispatch = useDispatch();
    const { orgId, inventryItemId, categoryData, refetch } = props;

    const {data:  categories}= useGetAllCategoriesWithSubCategoriesQuery();
    const [removeCategoryFromItem] = useRemoveCategoryFromItemMutation();
    const [addCategoryForItem] = useAddCategoryForItemMutation();

    const initValues = categoryData &&  categoryData.map((_obj) => {
        return {
            id: _obj.id,
            category: _obj.categoryId || '',
            subcategory: _obj.subCategoryId || ''
        };
    })


    const formik = useFormik({
        initialValues: {
            rows: initValues && initValues.length > 0 ? initValues : [],
        },
        validationSchema: Yup.object({
            rows: Yup.array().of(
                Yup.object().shape({
                    category: Yup.string().required('Required'),
                })
            )
        }),
        enableReinitialize: true,
        
        onSubmit: async (values) => {
            const dataToSave = values.rows?.map((_obj) => {
                return {
                    id: _obj.id ?? null,        
                    categoryId: _obj.category,
                    subCategoryId: _obj.subcategory,
                    orgId: orgId,
                    inventry_item_id: inventryItemId
                };
            });
            const result = await addCategoryForItem(dataToSave);
            
            if(result.data){
            refetch();
            dispatch(openMessage({message: 'Category added!',
                    messageSeverity: "success",}))
            }
        },
    });

    const handleAddRow = () => {
        formik.setFieldValue('rows', [...formik.values.rows, { category: '', subcategory: '' }]);
    };

    const handleDeleteRow = async(index, id) => {
        if(id || id===0){
            const result = await removeCategoryFromItem(id);
            if(result.data){
                const newRows = [...formik.values.rows];
                newRows.splice(index, 1);
                formik.setFieldValue('rows', newRows);
                refetch();
            }
        }else{
            const newRows = [...formik.values.rows];
            newRows.splice(index, 1);
            formik.setFieldValue('rows', newRows);
        }
    };

    const handleCategoryChange = (index, event) => {
        const { value } = event.target;
      
        // Create a new array from the current form values
        const newRows = [...formik.values.rows];
      
        // Update the category and reset the subcategory of the specific row
        newRows[index] = {
          ...newRows[index],
          category: value,
          subcategory: '', // Reset the subcategory
        };
      
        // Update the form values using Formik's setFieldValue
        formik.setFieldValue('rows', newRows);
      };

    return (
        <>
            {
                formik.values.rows?.length > 0 
                ?
                <form onSubmit={formik.handleSubmit}>
            {
            formik.values.rows?.map((row, index) => {
                const selectedCategory = categories?.find(cat => cat?.id === parseInt(row.category)) || { subcategories: [] };
                const subcategories = selectedCategory.subCategoryDetails;
                return (
                    <Grid container spacing={2} 
                    key={row.id ? row.id : index}
                    >
                        <Grid item xs={4.5} sx={{ paddingY: 2 }}>
                            <FormControl fullWidth error={formik.touched.rows?.[index]?.category && Boolean(formik.errors.rows?.[index]?.category)}>
                                <InputLabel>Category</InputLabel>
                                <Select
                                    fullWidth
                                    id={`category-${index}`}
                                    name={`rows[${index}].category`}
                                    label="Category"
                                    size='medium'
                                    value={row.category}
                                    onChange={(event) => handleCategoryChange(index, event)}
                                >
                                    {categories?.map((cat) => (
                                        <MenuItem key={cat.id} value={cat.id}>
                                            {cat.parent_category}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.rows?.[index]?.category && <FormHelperText>{formik.errors.rows?.[index]?.category}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4.5} sx={{ paddingY: 2 }}>
                            <FormControl fullWidth error={formik.touched.rows?.[index]?.subcategory && Boolean(formik.errors.rows?.[index]?.subcategory)}>
                                <InputLabel>Subcategory</InputLabel>
                                <Select
                                    fullWidth
                                    id={`subcategory-${index}`}
                                    name={`rows[${index}].subcategory`}
                                    label="Subcategory"
                                    size='medium'
                                    value={row.subcategory}
                                    onChange={formik.handleChange}
                                >
                                    {subcategories?.map((subcat) => (
                                        <MenuItem key={subcat.id} value={subcat.id}>
                                            {subcat.category_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.rows?.[index]?.subcategory && <FormHelperText>{formik.errors.rows?.[index]?.subcategory}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} sx={{ paddingY: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', columnGap: 2 }}>
                                { <IconButton onClick={() => handleDeleteRow(index, row.id)}>
                                    <DeleteIcon />
                                </IconButton>}
                                {index === formik.values.rows.length - 1 && <IconButton color='primary' onClick={() => handleAddRow()}>
                                    <AddCircleOutlineOutlinedIcon />
                                </IconButton>}
                            </Box>
                        </Grid>
                    </Grid>
                );
            })}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2, columnGap: 2 }}>
                <CustomButton variant='contained' props={{ type: "submit" }} label={"Save"} />
            </Box>
        </form>
        :
        <Stack alignItems={'center'}>
            <Typography>there is no category mapping </Typography>
            <CustomButton variant='contained' label={"Add Category Mapping"} onClick={handleAddRow}/>
        </Stack>
            }
        </>
        
    );
};