import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import CustomTextField from '../../../../components/common/CustomTextField'
import { useAddNewUserToOrgMutation } from '../../../../redux/api/api'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { openMessage } from '../../../../redux/slices/showMessageSlice'
import CustomButton from '../../../../components/common/CustomButton'
import CustomMuiTelInput from '../../../../components/common/CustomMuiTelInput'
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { validatePhoneNumber } from '../../../auth/sections/LoginView'

const CreateUserDialogue = ({ open, handelCloseDialogue }) => {

  const { orgId } = useParams();
  const dispatch = useDispatch();
  const [addNewUserToOrg] = useAddNewUserToOrgMutation();

  const initialValues = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    role: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required('First name is required'),
      lastName: Yup.string()
        .required('Last name is required'),
      mobileNumber: Yup.string().test('isValidPhoneNumber', 'Invalid phone number', function (value) {
        const { country } = this.parent;
        return !validatePhoneNumber(value, country);
      }), // Adjust regex to your mobile number format
    }),
    validateOnBlur:  true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      values.mobileNumber = values.mobileNumber.replace(/\s/g, "");
      const result = await addNewUserToOrg({ orgId, payload: values })
      if (result.data) {
        formik.resetForm();
        handelCloseDialogue();
        dispatch(openMessage({ message: "User added Successfully!", messageSeverity: 'success' }))
      } else if (result.error) {
        dispatch(openMessage({ message: result.error.data.message, messageSeverity: 'error' }))
      } else {
        dispatch(openMessage({ message: 'Not Able to Add New User!', messageSeverity: 'error' }))
      }
    },
  })

  const handleClose = () => {
    handelCloseDialogue();
    formik.resetForm();
  }


  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      // onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            // maxWidth: "350px", // Set your width here
          },
        },
      }}
    >
      <IconButton
          aria-label="close"
          onClick={handleClose} // Call onClose function when close icon is clicked
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: 'inherit',
          }}
        >
          <CloseIcon />
      </IconButton>
      <DialogTitle>Create User</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: '1rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <CustomTextField
                name="firstName"
                label="First Name"
                formik={formik}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <CustomTextField
                name="lastName"
                label="Last Name"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomMuiTelInput
              name={'mobileNumber'}
              label={'Mobile Number'}
              formik={formik}
            
              />
            </Grid>
          </Grid>
          <CustomButton sx={{ mt: '2rem' }} onClick={formik.handleSubmit}
            label={"Add User"}
            width={"100%"}
            disabled={formik.isValid ? false : true}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CreateUserDialogue