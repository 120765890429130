import { Box, Container, Grid, Paper, Stack, Tab, Table, TableBody, TableContainer, TablePagination, Tabs, Typography } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import TableNoData from "../../views/list/table-no-data";
import TableHead from "../../views/list/table-head";
import { emptyRows, getComparator } from "../../views/list/utils";
import { faker } from '@faker-js/faker';
import { sample } from 'lodash';
import CustomTableRow from "../../views/list/table-row";
import moment from "moment";
import { useGetAllSaleOrderForParticularVendorMutation, useGetAllVendorOrdersMutation } from "../../redux/api/api";
import { useNavigate, useParams } from "react-router-dom";
import { convertDateFormat } from "../../utils/formatNumbers";
import PageHeader from "../../components/common/PageHeader";
import CustomDatePicker from "../../components/common/CustomDatePicker";
import CustomSearchbar from "../../components/common/CustomSearchbar";
import TableLoadingData from "../../components/common/TableLoadingData";
import CustomeSelectMenu from "../../components/common/CustomeSelectMenu";
import { useDispatch, useSelector } from "react-redux";
import { setCurrSelectedPage, setRowsPerPage } from "../../redux/slices/appReducer";


const SalesOrders = ({ other = true, custId, vendId, type }) => {

    const { orgId } = useParams();
    const navigate = useNavigate();
    const [getAllVendorOrders, { isLoading: getAllVendorOrdersLoading }] = useGetAllVendorOrdersMutation();
    const [getAllSaleOrderForParticularVendor] = useGetAllSaleOrderForParticularVendorMutation();
    // const [selectedTab, setSelectedTab] = useState('all');
    const [totalRecords, setTotalRecords] = useState(0);
    const [orderData, setOrderData] = useState([]);

    const [statusTabs, setStatusTabs] = useState([
        {
            key: 'all',
            lable: 'All',
            count: 0,
            textColor: '#FFFFFF',
            backgroundColor: '#212B36',
            hoverText: '#FFFFFF',
            hoverBackground: '#212B36'
        },
        // {
        //     key: 'drafted',
        //     lable: 'Drafted',
        //     count: 0,
        //     textColor: '#637381',
        //     backgroundColor: '#EEEFF1',
        //     hoverText: '#FFFFFF',
        //     hoverBackground: '#212B36'
        // },
        {
            key: 'confirmed',
            lable: 'Confirmed',
            count: 0,
            textColor: '#B86E00',
            backgroundColor: '#FFF2DE',
            hoverText: '#212B36',
            hoverBackground: '#FFAC00'
        },
        {
            key: 'packed',
            lable: 'Packed',
            count: 0,
            textColor: '#B86E00',
            backgroundColor: '#FFF2DE',
            hoverText: '#212B36',
            hoverBackground: '#FFAC00'
        },
        {
            key: 'shipped',
            lable: 'Shipped',
            count: 0,
            textColor: '#B86E00',
            backgroundColor: '#FFF2DE',
            hoverText: '#212B36',
            hoverBackground: '#FFAC00'
        },
        {
            key: 'delivered',
            lable: 'Delivered',
            count: 0,
            textColor: '#118D57',
            backgroundColor: '#E1F6E6',
            hoverText: '#FFFFFF',
            hoverBackground: '#22C55F'
        },
        {
            key: 'fulfilled',
            lable: 'Fulfilled',
            count: 0,
            textColor: '#118D57',
            backgroundColor: '#E1F6E6',
            hoverText: '#FFFFFF',
            hoverBackground: '#22C55F'
        },
        {
            key: 'void',
            lable: 'Void',
            count: 0,
            textColor: '#637381',
            backgroundColor: '#EEEFF1',
            hoverText: '#FFFFFF',
            hoverBackground: '#212B36'
        }
    ]);

    // const [page, setPage] = useState(0);
    const page = useSelector((state)=> state.app.currSelectedPage.salesOrderPage)

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('date');

    const [searchText, setSearchText] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const dispatch= useDispatch();

    // const [rowsPerPage, setRowsPerPage] = useState(5);
    const rowsPerPage = useSelector((state)=>state.app.rowsPerPageData.salesOrderPage)

    const [statusFilter, setStatusFilter] = useState(["all"])

    useEffect(() => {
        fetchData();
    }, [page, rowsPerPage, searchText,
        // selectedTab,
        startDate, endDate, order, statusFilter]);

    const fetchData = async () => {
        const payload = {
            page: page,
            rowsPerPage: rowsPerPage,
            searchText: searchText,
            sortOrder: order,
            startDate: startDate,
            endDate: endDate,
            // status: selectedTab
            status: statusFilter.length > 0 ? statusFilter[0] : null
        }
        const result = other ? await getAllVendorOrders({
            payload: { ...payload },
            vendorId: orgId,
        }, true) : await getAllSaleOrderForParticularVendor({
            payload: { ...payload },
            vendorId: vendId, customerId: custId, type: type
        }, true)

        const _orders = result?.data?.orderHistory.map((_rec, index) => {
            return {
                ..._rec,
                id: _rec.id,
                avatarUrl: type === 'customer' ? _rec?.vendorDetail?.orgLogoKey : _rec?.customerDetail?.orgLogoKey,
                order: _rec.id?.toString(),
                customer: type === 'customer' ? _rec?.vendorDetail?.orgName : _rec?.customerDetail?.orgName,
                customerEmail: type === 'customer' ? _rec.vendorDetail?.orgEmail : _rec.customerDetail?.orgEmail,
                date: moment(_rec.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
                price: Number(_rec.totalPrice)?.toFixed(2),
                status: _rec?.status,
            }
        })
        // setTotalRecords(result?.data?.orderCounts[selectedTab])
        setTotalRecords(result?.data?.orderCounts[statusFilter[0]])
        setOrderData(_orders);
        const tempStatusTabs = statusTabs.map((_obj) => {
            return {
                ..._obj,
                count: result?.data?.orderCounts[_obj.key]
            }
        })
        setStatusTabs(tempStatusTabs);
    }
    const onOrderDeliver = () => {
        fetchData();
    }
    const handleSort = (event, id) => {
        const isAsc = orderBy === id && order === 'asc';
        if (id == 'date') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    };

    const handleChangePage = (event, newPage) => {
        // setPage(newPage);
        dispatch(setCurrSelectedPage({name: 'salesOrderPage', value: newPage}))
    };

    const handleChangeRowsPerPage = (event) => {
        // setPage(0);
        dispatch(setCurrSelectedPage({name: 'salesOrderPage', value: 0}))
        // setRowsPerPage(parseInt(event.target.value, 10));
        dispatch(setRowsPerPage({name: 'salesOrderPage', value: parseInt(event.target.value, 10)}))
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orderData.map((n) => n.order);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // const handleChange = (event, newValue) => {
    //     setSelectedTab(newValue);
    // };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleFilterByName = (event) => {
        // setPage(0);
        dispatch(setCurrSelectedPage({name: 'salesOrderPage', value: 0}))
        setSearchText(event.target.value);
    };


    const handelViewOrder = (selectedOrderId) => {
        navigate(`/org/${orgId}/order-history/${selectedOrderId}`);
    }
    

    return (<>
        {other && <PageHeader
            title={"Sales Order"}
            // linkArray={[
            //     { title: "Dashboard", path: `/` },
            //     { title: "Sales-order", },
            // ]}
        />}
        <Box sx={{ width: '100%' }} component={Paper}>
            {/* <Tabs
                value={selectedTab}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
            >
                {
                    statusTabs.map((_obj, index) => (
                        <Tab key={_obj.key} value={_obj.key} label={
                            <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center' }}>
                                <Typography sx={{ fontSize: 14, fontWeight: 600, color: selectedTab == _obj.key ? '#212B36' : '#637381' }}>{_obj.lable}</Typography>
                                <Box sx={{
                                    backgroundColor: selectedTab == _obj.key ? _obj.hoverBackground : _obj.backgroundColor,
                                    height: 24, width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1
                                }}>
                                    <Typography sx={{ color: selectedTab == _obj.key ? _obj.hoverText : _obj.textColor, fontSize: 14, fontWeight: 600 }}>
                                        {_obj.count}
                                    </Typography>
                                </Box>
                            </Box>
                        } />
                    ))
                }
            </Tabs> */}
            {/* <Box sx={{ padding: 2, paddingY: 2 }}>
                <Grid container>
                    <Grid item sm={2.5}>
                        <Box sx={{ width: '95%' }}>
                            <CustomeSelectMenu
                                label={"Status"}
                                noDataMsg={"No Status"}
                                options={statusTabs.map((tab, value) => ({
                                    ...value,
                                    id: tab.key,
                                    label: tab.lable
                                }))}
                                optionFilter={statusFilter}
                                setOptionFilter={setStatusFilter}
                                width={"100%"}
                                multiSelect={false}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={2.5}>
                        <Box sx={{ width: '95%' }}>
                            <CustomDatePicker label={"Start date"} setDate={(date) => setStartDate(date)} maxDate={endDate} sx={{ width: "100%" }} />
                        </Box>
                    </Grid>
                    <Grid item sm={2.5}>
                        <Box sx={{ width: '95%' }}>
                            <CustomDatePicker label={"End date"} setDate={(date) => setEndDate(date)} minDate={startDate} sx={{ width: "100%" }} />
                        </Box>
                    </Grid>
                    <Grid item sm={4.5}>
                        <Box>
                            <CustomSearchbar
                                placeholder={"Search..."}
                                text={searchText}
                                handleTextChange={handleFilterByName}
                                handleTextClear={(e) => setSearchText("")}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box> */}
            <Stack
                spacing={2}
                padding={2}
                width={"100%"}
                direction={{
                    xs: "column",
                    sm: "row"
                }}
            >
                <CustomeSelectMenu
                    label={"Status"}
                    noDataMsg={"No Status"}
                    options={statusTabs.map((tab, value) => ({
                        ...value,
                        id: tab.key,
                        label: tab.lable
                    }))}
                    optionFilter={statusFilter}
                    setOptionFilter={(state)=>setStatusFilter(state[0] ? state : ['all'])}
                    width={{ xs: "100%", sm: "40%", md: "30%" }}
                    multiSelect={false}
                />
                <CustomDatePicker label={"Start date"} setDate={(date) => setStartDate(date)} maxDate={endDate} sx={{ width: { xs: "100%", sm: "40%", md: "30%" } }} />
                <CustomDatePicker label={"End date"} setDate={(date) => setEndDate(date)} minDate={startDate} sx={{ width: { xs: "100%", sm: "40%", md: "30%" } }} />
                <CustomSearchbar
                    placeholder={"Search..."}
                    text={searchText}
                    handleTextChange={handleFilterByName}
                    handleTextClear={(e) => setSearchText("")}
                />
            </Stack>
            <Box sx={{}}>
                <TableContainer >
                    <Table sx={{ minWidth: 750 }} size={'large'} stickyHeader>
                        <TableHead
                            order={order}
                            orderBy={orderBy}
                            rowCount={orderData?.length}
                            rowSelctionRequired={false}
                            numSelected={selected.length}
                            onRequestSort={handleSort}
                            onSelectAllClick={handleSelectAllClick}
                            headLabel={[
                                { id: 'order', label: 'Order' },
                                { id: type === 'customer' ? 'vendor' : 'customer', label: type === 'customer' ? 'Vendor' : 'Customer' },
                                { id: 'date', label: 'Date' },
                                { id: 'price', label: 'Price' },
                                other && { id: 'status', label: 'Status' },
                                { id: 'paymentMode', label: 'Payment Mode' },
                                { id: '' },
                            ]}
                        />
                        <TableBody>
                            {getAllVendorOrdersLoading ?
                                <TableLoadingData colSpan={other ? 6 : 5} /> :
                                orderData && orderData?.length > 0
                                    ?
                                    orderData
                                        //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <CustomTableRow
                                                rowSelctionRequired={false}
                                                key={row.id}
                                                onOrderDeliver={onOrderDeliver}
                                                rowData={

                                                    [
                                                        { headerKey: 'order', valueObj: { value: row?.id } },
                                                        { headerKey: type === 'customer' ? 'vendor' : 'customer', valueObj: { value: row?.customer, customerEmail: row?.customerEmail, avatarUrl: row.avatarUrl } },
                                                        { headerKey: 'date', valueObj: { value: row?.date } },
                                                        { headerKey: 'price', valueObj: { value: row?.price } },
                                                        other && { headerKey: 'status', valueObj: { value: row?.status } },
                                                        { headerKey: 'paymentMode', valueObj: { value: row?.paymentMode } },
                                                    ]
                                                }
                                                selectedId={row.id}
                                                selected={selected.indexOf(row.order) !== -1}
                                                handleEditRecord={handelViewOrder}
                                                handleClick={(event) => handleClick(event, row.order)}
                                            />
                                        ))
                                    :
                                    <TableNoData query={searchText} colSpan={other ? 7 : 6} />
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    page={page}
                    component="div"
                    count={totalRecords ?? 0}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    </>);
}

export default SalesOrders;