import { Grid, Typography } from '@mui/material'
import React from 'react'
import CustomTextField from '../../../../components/common/CustomTextField'

const BankDetails = ({ formik }) => {
  return (
    <Grid pt={'1rem'}>
      <Typography variant='h5'> Bank Details </Typography>
      <Grid container spacing={3} mt={'0rem'}>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name={"businessDetail.panNumber"}
            label={"Benificiary Name"}
            formik={formik}
            required={!formik.values.businessDetail.gstUin}
          // size={'small'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name={"businessDetail.fssi"}
            label={"Bank Name"}
            formik={formik}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <CustomTextField
            name={"businessDetail.gstUin"}
            // placeholder={"Enter Your gstUin number"}
            label={"Account Number"}
            formik={formik}
            required={!formik.values.businessDetail.panNumber}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <CustomTextField
            name={"businessDetail.gstUin"}
            // placeholder={"Enter Your gstUin number"}
            label={"Confirm Account Number"}
            formik={formik}
            required={!formik.values.businessDetail.panNumber}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <CustomTextField
            name={"businessDetail.gstUin"}
            // placeholder={"Enter Your gstUin number"}
            label={"IFSC Code"}
            formik={formik}
            required={!formik.values.businessDetail.panNumber}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BankDetails