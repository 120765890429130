import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

const CustomRating = ({name="read-only", value=0, fontSize}) => {
//   const [value, setValue] = React.useState(2);

  return (
    <Box
      sx={{
        '& > legend': { mt: 0, mb: 0, p: 0 },
      }}
    >
      <Rating
        name="half-rating"      
        value={value}
        precision={0.5}
        readOnly
        sx={{p:0,ml:'-0.12rem', fontSize:fontSize}}
      />
    </Box>
  );
}


export default CustomRating;