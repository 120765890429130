import React from "react";
import { SectionContainer } from "./Section1";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useGetAllCategoriesWithSubCategoriesQuery } from "../../redux/api/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import CategoryCards from "./CategoryCard";
import { useState } from "react";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";

const BuyJustAnything = ({ setLoginOpen }) => {
  const { data } = useGetAllCategoriesWithSubCategoriesQuery();
  const responsive = useResponsiveStyles();
  return (
    <SectionContainer sx={{ mb: 2, paddingX: responsive.isMobile ? 1 : 2 }}>
      <Stack spacing={1}>
        <Typography variant="h3" fontWeight={500} textAlign={"center"}>
          Buy just about anything
        </Typography>
        <Typography variant="h5" fontWeight={500} textAlign={"center"} color={"text.disabled"}>
          All your Restaurant Supplies - & Suppliers - under 1-roof
        </Typography>
      </Stack>
      <Stack spacing={0} mt={"1rem"}>
        <CategoryCards data={data} fromLanding={true} onSelectCategory={() => setLoginOpen(true)} />
      </Stack>
      <Stack zIndex={1} mt={"2rem !important"} alignItems={"center"}>
        <Button size="large" variant="contained" onClick={() => setLoginOpen(true)}>
          Explore Products
        </Button>
      </Stack>
    </SectionContainer>
  );
};

export default BuyJustAnything;
