import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import Iconify from "../../../components/common/Iconify";
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";
import CustomButton from "../../../components/common/CustomButton";
import axios from 'axios';
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useLocalStorageContext } from "../../../hooks/LocalStorageProvider";

const LocationDialog = ({ open, handelCloseDialogue }) => {
    //const [location, setLocation] = useLocalStorage('location', { pincode: '919192', area: 'landmark' });
    const { location, setLocation } = useLocalStorageContext();
    const [pincode, setPincode] = useState('');
    const [details, setDetails] = useState('');
    const [error, setError] = useState('');
    const responsive = useResponsiveStyles();
    const handlePincodeChange = (e) => {
        setPincode(e.target.value);
        setError('');
    };

    const validatePincode = (code) => {
        const pinRegex = /^[1-9][0-9]{5}$/; // Example regex for Indian pincode
        return pinRegex.test(code);
    };
    const handleSearch = (e) => {
        if (!validatePincode(pincode)) {
            setError('Please enter a valid 6-digit pincode.');
        } else {
            setError('');
            e.preventDefault();
            fetchAreaDetails();
        }
    };
    const fetchAreaDetails = async () => {
        try {
            const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
            if (response.data[0].Status === 'Success') {
                setDetails(response.data[0].PostOffice[0]);
                setError(null);
            } else {
                setDetails(null);
                setError('Invalid Pincode');
            }
        } catch (error) {
            setDetails(null);
            setError('Failed to fetch data');
        }
    };
    const confirmLocation = () => {
        setLocation({ ...location, pincode: pincode, area: details.Name });
        handelCloseDialogue();
    }

    return (
        <Dialog
            maxWidth={"sm"}
            scroll={"paper"}
            open={open}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        minWidth: '350px',
                        maxWidth: "450px", // Set your width here
                        // padding: responsive?.isMobile && '20px 0px'
                    },
                },
            }}
        >
            <DialogTitle>
                <Typography variant="h5" sx={{ textAlign: 'center' }}>Your Location</Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handelCloseDialogue}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <Iconify icon="material-symbols:cancel-outline" />
            </IconButton>
            <DialogContent sx={{ padding: 2 }}>
                <>
                    <Stack spacing={3} minWidth={'100%'}>
                        <Box
                            component="form"
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSearch();
                            }}
                        >
                            <TextField
                                label="Enter Pincode"
                                variant="outlined"
                                fullWidth
                                value={pincode}
                                onChange={handlePincodeChange}
                                error={!!error}
                                helperText={error}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={(e) => handleSearch(e)}
                            >
                                Search
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Typography variant="body2">Location:</Typography>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{details && details.Name}</Typography>
                        </Box>
                        <CustomButton
                            onClick={confirmLocation}
                            disabled={!details}
                            variant={"contained"}
                            width={'100%'}
                            label={'Confirm Location'}
                        />
                    </Stack>
                </>
            </DialogContent>
        </Dialog>
    );
};


export default LocationDialog