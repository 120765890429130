
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';
import { useUpdatePaymentOptionsMutation } from '../../../redux/api/api';

const paymentOptions = [
  { id: 'payWithDiscco', label: 'Pay By Discco' },
  { id: 'payByCheque', label: 'Pay by Cheque' },
  { id: 'payOnDelivery', label: 'Pay on Delivery' },
];

const PaymentDialog = ({ open, onClose, selectedPaymentOrg }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [updatePaymentOptions] = useUpdatePaymentOptionsMutation();
  useEffect(() => {
    if(selectedPaymentOrg?.paymentOptions) {
        const _options = [];
        if(selectedPaymentOrg.paymentOptions.payWithDiscco) _options.push('payWithDiscco');
        if(selectedPaymentOrg.paymentOptions.payByCheque) _options.push('payByCheque');
        if(selectedPaymentOrg.paymentOptions.payOnDelivery) _options.push('payOnDelivery');
        setSelectedOptions(_options)
    }
  }, [selectedPaymentOrg])

  const handleToggle = (option) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((item) => item !== option)
        : [...prevSelected, option]
    );
  };

  const handleSubmit = async () => {
    const paymentData = {
        payWithDiscco: selectedOptions.includes('payWithDiscco'),
        payByCheque: selectedOptions.includes('payByCheque'),
        payOnDelivery: selectedOptions.includes('payOnDelivery')
    }
    const result = await updatePaymentOptions({ payload: paymentData, orgId: selectedPaymentOrg.id })
    onClose(true);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Payment Options</DialogTitle>
      <DialogContent>
        {paymentOptions.map((option) => (
          <FormControlLabel
            key={option.id}
            control={
              <Checkbox
                checked={selectedOptions.includes(option.id)}
                onChange={() => handleToggle(option.id)}
                color="primary"
              />
            }
            label={option.label}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;