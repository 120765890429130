import { Container, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import PageHeader from '../../../components/common/PageHeader'
import Iconify from '../../../components/common/Iconify'
import CustomButton from '../../../components/common/CustomButton'
import MySubCategory from './MySubCategory'
import MyCatgeory from './MyCatgeory'
import CreateNewCategoryDialogue from './CreateNewCategoryDialogue'

const Category = () => {

    

    
  return (
    <Container maxWidth={'xl'} sx={{}}>
        <PageHeader
        title={"Category & Subcategory"}
        // linkArray={[
        //   { title: "Dashboard", path: `/` },
        //   { title: "Category", },
        // ]}
      />
      <MyCatgeory />
       
    </Container>
  )
}

export default Category