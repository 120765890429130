import React from "react";
import {
  Card,
  Typography,
  useTheme,
  Grid,
  Box,
  Button,
  Stack,
  Link,
  styled,
  IconButton,
  Tooltip,
} from "@mui/material";
import CustomRating from "../../../components/common/CustomRating";
import { useRouter } from "../../../routes/hooks/useRouter";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { discountImage } from "../../../assets/banners";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { useParams } from "react-router-dom";
import GetImageComponent from "../../../components/common/GetImageComponent";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVendorId } from "../../../redux/slices/orderListSlice";
import VendorLogoImageComponent from "../../../components/common/VendorLogoImageComponent";
import CustomButton from "../../../components/common/CustomButton";


const BackgroundLogo = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) rotate(-45deg)",
  width: "100px",
  height: "100px",
  padding: "1rem",
  opacity: 0.1,
  borderRadius: "8px",
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
});

export const RenderItems = ({ items }) => {
  // Ensure there are at least 9 items
  const filledItems = items?.concat(
    Array.from({ length: Math.max(9 - items.length, 0) }, () => "")
  );



  // Divide items into 3 columns
  const columns = [
    filledItems?.slice(0, 3),
    filledItems?.slice(3, 6),
    filledItems?.slice(6, 9),
  ];


  return (
    <Grid container>
      {columns?.map((columnItems, columnIndex) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={columnIndex}>
          {columnItems?.map((item, index) => (
            item.parent_category && <Stack direction="row" gap="0.5rem" alignItems="center" key={index}>
              <PanoramaFishEyeIcon style={{ width: 10, color: "green" }} />
              <Tooltip title={item?.parent_category} placement="top">
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    textTransform: "capitalize",
                    fontWeight: 550,
                    fontSize: "0.8rem",
                  }}
                >
                  {item?.parent_category}
                </Typography>
              </Tooltip>
            </Stack>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

const DiscountCoupanComponent = ({ offer }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "80px",
        height: "40px",
        borderRadius: "1rem 1rem 2rem 2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "-1rem",
        right: "-1rem",
      }}
    >
      <img src={discountImage} alt="discount" />
      <Box sx={{ position: "absolute", top: "-0.1rem" }}>
        <Typography
          variant="body2"
          fontSize={"12.5px"}
          fontWeight={"700"}
          color={"black"}
        >
          {offer}
        </Typography>
      </Box>
    </Box>
  );
};

const RenderVendorsCard = ({
  sx,
  vendorData
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(false);
  const router = useRouter();
  const dispatch = useDispatch();

  // const { orgId } = useParams();
  const orgId = useSelector((state) => state.app.preference.selectedOrgId)

  const handleViewVendor = () => {

    dispatch(setSelectedVendorId(vendorData?.vendorDetails?.id))
    // router.push(`/org/${orgId}/order-list/create?vendorId=${vendorData?.vendorDetails?.id}`)
    router.push(`/org/${orgId}/explore-products?vendorId=${vendorData?.vendorDetails?.id}`)
  }



  return (
    <Box
      key={vendorData?.vendorDetails?.id}
      sx={{
        p: 2,
        "&:hover": {
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Apply box shadow on hover
          transform: "scale(1.02)", // Scale up on hover
          transition: "0.3s ease", // Smooth transition effect
        },
        borderRadius: "0.75rem",
        boxShadow: theme.shadows[10],
        position: "relative",
        // backgroundColor: theme.palette.grey[200],
        ...sx,
        minHeight: '242px'
      }}
      className="view-vendor"
    >
      <BackgroundLogo
        style={{ backgroundImage: `url(/assets/horecaLogo.png)` }}
      />
      {vendorData?.vendorDetails?.vendorOffers?.length > 0 && <DiscountCoupanComponent offer={vendorData?.vendorDetails?.vendorOffers[0]?.offer} />}

      <Grid container spacing={2} onClick={() => handleViewVendor()}>
        <Grid item xs={9} md={12}>
          <Box sx={{}}>
            <Box display={"flex"}>
              <VendorLogoImageComponent s3Key={vendorData?.vendorDetails?.org_logo_key} name={vendorData?.vendorDetails?.org_name} />
              <Box ml={"1rem"}>
                <Typography variant="body1" fontWeight={550} textTransform={'capitalize'}>
                  {
                    vendorData?.vendorDetails?.org_name
                  }
                  {
                    parseInt(vendorData?.orders) > 10 && (
                      <>
                        <span style={{ color: theme.palette.primary.main }}> ({vendorData?.deliveries ? vendorData?.deliveries : 45}</span>{" "}
                        Deliveries)
                      </>)
                  }
                </Typography>
                <CustomRating value={vendorData?.vendorDetails?.vendorRating?.ratings ? parseFloat(vendorData?.vendorDetails?.vendorRating?.ratings) : 0} />
              </Box>
            </Box>
            <Typography
              variant="body1"
              sx={{
                wordWrap: "break-word",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              <span>{vendorData?.vendorDetails?.addresses[0]?.pincode ? `${vendorData?.vendorDetails?.addresses[0]?.landmark ? vendorData?.vendorDetails?.addresses[0]?.landmark + ", " : ""}${vendorData?.vendorDetails?.addresses[0]?.line1 ? vendorData?.vendorDetails?.addresses[0]?.line1 + ", " : ""}${vendorData?.vendorDetails?.addresses[0]?.line2 ? vendorData?.vendorDetails?.addresses[0]?.line2 + ", " : ""}${vendorData?.vendorDetails?.addresses[0]?.city ? vendorData?.vendorDetails?.addresses[0]?.city + ", " : ""}${vendorData?.vendorDetails?.addresses[0]?.state ? vendorData?.vendorDetails?.addresses[0]?.state + ", " : ""}${vendorData?.vendorDetails?.addresses[0]?.pincode ?? ""}` : ""}</span>
            </Typography>
          </Box>

          <Grid container>
            <Grid item lg={12} mt={"1.5rem"}>
              <RenderItems items={vendorData?.categoryDetails} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack sx={{ marginLeft: "auto", width: "100%" }}>
        <CustomButton
          onClick={handleViewVendor}
          endIcon={<KeyboardDoubleArrowRightIcon />}
          label={"View"}
          sx={{ marginLeft: "auto" }}
        />
      </Stack>
    </Box>
  );
};

const SuitableVendors = ({ suitableVendors }) => {


  const router = useRouter();
  const theme = useTheme();

  // const { orgId } = useParams();
  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const [vendor, setVendos] = React.useState([]);

  React.useEffect(() => {
    if (suitableVendors) {
      setVendos(suitableVendors);
    }
  }, [suitableVendors])

  // const memoizedComp =React.memo(<RenderVendorsCard/>)  

  return (
    <Box sx={{ background: "none", cursor: 'pointer' }}
    >
      <Stack direction={"row"} spacing={3} alignItems={"flex-start"}>
        <Typography
          variant="h5"
          sx={{ mb: "1rem", overflow: "hidden", textOverflow: "ellipsis" }}
        >
          Popular vendors in your area
        </Typography>
      </Stack>

      <Grid container spacing={5} mt={1}>
        {
          vendor && vendor.length > 0
            ?
            vendor.map((vendorData, index) => (
              <Grid key={vendorData?.vendorDetails?.id} item xs={12} md={6}>
                <RenderVendorsCard theme={theme} key={vendorData?.vendorDetails?.id} vendorData={vendorData} />
              </Grid>
            ))
            :
            <Stack direction={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
              <Typography variant="h4" sx={{ color: 'text.primary', alignSelf: 'center', color: 'red', }}>No Vendors Available on Basis of Filter!</Typography>
            </Stack>
        }
        {/* <Button
          variant="contained"
          sx={{
            alignSelf: "end",
            marginLeft: "auto",
            mt: "2rem",
            textTransform: "capitalize",
          }}
          component="Link"
          onClick={() => router.push(`/org/${orgId}/order-list/create?vendorId=${suitableVendors?.[0]?.vendorDetails?.id}`)}
          endIcon={<KeyboardDoubleArrowRightIcon />}
        >
          Explore more vendors
        </Button> */}
        <CustomButton
          variant="contained"
          sx={{
            alignSelf: "end",
            marginLeft: "auto",
            mt: "2rem",
            textTransform: "capitalize",
          }}
          props={{ component: "Link" }}
          onClick={() => router.push(`/org/${orgId}/order-list/create?vendorId=${suitableVendors?.[0]?.vendorDetails?.id}`)}
          endIcon={<KeyboardDoubleArrowRightIcon />}
          label={"Explore more vendors"}
        />
      </Grid>
    </Box>
  );
};

export default SuitableVendors;
