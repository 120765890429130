import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Convert queryParams to an object
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }

  return params;
};

export default useQueryParams;