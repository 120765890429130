export const horecaLogo = require('./horecaLogo.png');
export const aysLogo = require('./ays-logo.png');
export const swiggyLogo = require('./swiggy_logo.png');
export const zomatoLogo = require('./zomato-logo.png');
export const cahracterImg = require('./character.png')
export const noImage = require('./noImage.png')
export const orderProcessing = require('./inventory-management.png')
export const approvalPending = require('./approval_pending.jpg')
export const loaderGif = require('./loader.gif')
export const thankyouGif = require('./thank_you.gif')
export const shoppingGif = require('./cartAnimation.gif')
export const zohoLogo = require('./zohoLogo.png');
export const skuImage = require('./skuImage.png');

export const brand1 = require('./brands/5.png');
export const brand2 = require('./brands/6.png');
export const brand3 = require('./brands/8.png');
export const brand4 = require('./brands/9.png');
export const brand5 = require('./brands/11.png');
export const brand6 = require('./brands/Chings.png');
export const brand7 = require('./brands/Cornitos.png');
export const brand8 = require('./brands/DaVinci.png');
export const brand9 = require('./brands/LeeKumKee.png');
export const brand10 = require('./brands/Maggi.png');
export const brand11 = require('./brands/Manama.png');
export const brand12 = require('./brands/MDH.png');
export const brand13 = require('./brands/Namjai.png');
export const brand14 = require('./brands/RichCream.png');
export const brand15 = require('./brands/SanVito.png');
export const brand16 = require('./brands/Sarwar.png');
export const brand17 = require('./brands/Switz.png');
export const brand18 = require('./brands/Veeba.png');
export const brand19 = require('./brands/WohHup.png');

export const product1 = require('./products/product1.png');
export const product2 = require('./products/product2.png');
export const product3 = require('./products/product3.png');
export const product4 = require('./products/product4.png');
export const product5 = require('./products/product5.png');
export const product6 = require('./products/product6.png');
export const product7 = require('./products/product7.png');
export const product8 = require('./products/product8.png');
export const product9 = require('./products/product9.png');
export const product10 = require('./products/product10.png');

export const stepActive = require('./step-active.svg');
export const stepPending = require('./step-pending.svg');

export const deal1 = require('./deals/deal1.png');
export const deal2 = require('./deals/deal2.png');
export const deal3 = require('./deals/deal3.png');