import React, { useState } from 'react';
import { IconButton, Popover, Box } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
} from 'react-share';
import { useLocation } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';

const SocialMediaShare = (props) => {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const basePath = window.location.origin;
    const shareUrl = basePath + location.pathname; // The URL you want to share
    const title = props.title; // The message to accompany the URL

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'share-popover' : undefined;

    return (
        <div>
            <IconButton size='small' aria-describedby={id} onClick={handleClick} sx={{ border: '1px solid #E2E2EA' }}>
                <ReplyIcon fontSize='small' sx={{ color: '#1F9AFF', transform: 'scaleX(-1)' }}/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={1} display="flex" alignItems="center" sx={{ gap:2 }}>
                    <FacebookShareButton url={shareUrl} quote={title}>
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <WhatsappShareButton url={shareUrl} title={title} separator=" - ">
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </Box>
            </Popover>
        </div>
    );
};

export default SocialMediaShare;
