import { Outlet, Navigate, useRoutes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import SystemAdminDashboard from '../pages/dashboard/sections/view/SystemAdminDashboard';
import DashboardLayout from '../layouts/MainLayout';
import NotFound from '../pages/error/NotFound';
import { getOrgData, getOrgType } from '../utils/org';
import getRoleSpecificRoutes from './GetRoleSpecificRoutes';
import VendorDashboard from '../pages/dashboard/sections/view/VendorDashboard';
import CustomSettings from '../pages/settings/layout/CustomSettings';
import AppView from '../pages/dashboard/sections/view/AppView';
import IsPageLoading from '../components/common/IsPageLoading';
import { setSavePrfernces } from '../redux/slices/appReducer';
import { useSavePreferncesMutation } from '../redux/api/api';
import Home from '../pages/dashboard/sections/view/CustomerHome';
import QuickLinkDetails from '../pages/quick-links/QuickLinkDetails';

const Router = () => {
  
  const dispatch = useDispatch();
  const [savePrefernces] = useSavePreferncesMutation();
  const isAuthenticated = useSelector((state) => state.auth.user);
  const [orgType, setOrgType] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true); // Initialize loading state as true
  const currOrgId = useSelector((state) => state.app.preference.selectedOrgId);

  useEffect(() => {
    const initOrgType = async () => {
      if (currOrgId && isAuthenticated) {
        const type = await getOrgType(isAuthenticated, currOrgId);
        setOrgType(type);
      }
      // else{
      //   dispatch(setSavePrfernces({ key: "selectedOrgId", value:  isAuthenticated.orgDetail[0].id}));
      //   await savePrefernces({ payload: { key: 'selectedOrgId', value:  isAuthenticated.orgDetail[0].id} });
      // }
    };
    initOrgType();
  }, [currOrgId, isAuthenticated]);


  useEffect(() => {
    if (orgType !== null ) {
      setIsPageLoading(false); // Set loading to false when orgType is determined
    }
  }, [orgType]);

  const orgData = getOrgData(isAuthenticated, currOrgId);

  const privateRoutes = [
    {
      element: (
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      ),
      children: [
        { element: getDashboardView(orgData, orgType), index: true },
        ...getRoleSpecificRoutes(orgType),
        { path: '/org/:orgId/settings', element: <CustomSettings /> },
      ],
    },
    { element: <QuickLinkDetails />, path: '/company/:link'},
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to={isAuthenticated ? '/' : '404'} replace /> },
  ];


  const routes = useRoutes( privateRoutes);

  return isPageLoading ? <IsPageLoading/> : (
    routes
  );
  
};

const getDashboardView = (orgData, orgType) => {
  if (orgData?.status === 'approved') {
    switch (orgType) {
      case 'customer':
        return <Home />;
      case 'vendor':
        return <VendorDashboard />;
      case 'system_admin':
        return <SystemAdminDashboard />;
      default:
        return <div></div>;
    }
  } else {
    return <div></div>;
  }
};

export default Router;
