import { Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import Iconify from "./Iconify";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function CustomeSelectMenu({ label, noDataMsg, options, optionFilter, setOptionFilter, width = "100%", handleChangeOptionFilter, multiSelect = true }) {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
            },
            elevation: 8,
            sx: {
                backdropFilter: 'blur(20px)',
                backgroundImage: 'url("https://minimals.cc/assets/cyan-blur.png"), url("https://minimals.cc/assets/red-blur.png")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right top, left bottom',
                borderRadius: "10px",
                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
                transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                '& .MuiList-root': {
                    padding: "4px 4px 0px 4px"
                },
                '&::-webkit-scrollbar': {
                    width: '4px'
                }
            },
        },
    };

    const handleOptionSelection = (event) => {
        const {
            target: { value },
        } = event;
        multiSelect ? setOptionFilter(typeof value === 'string' ? value.split(',') : value,) : setOptionFilter(value?.length > 0 ? [value[value.length - 1]] : [value[0]])
    };

    return (
        <FormControl sx={{ m: 1, width: width, minWidth: 170, margin: "0px" }}>
            <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={optionFilter}
                onChange={handleOptionSelection}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => selected?.map(id => options?.find(option => option.id === id)?.label).join(', ')}
                MenuProps={MenuProps}
                IconComponent={KeyboardArrowDownIcon}
            >
                {options?.length ? (
                    options?.map((option) => (
                        <MenuItem key={option.id} value={option.id}
                            sx={{
                                borderRadius: "8px",
                                padding: "0px",
                                marginBottom: "4px"
                            }}
                        >
                            <Checkbox
                                icon={<Iconify width={24} icon="fluent:checkbox-unchecked-20-regular" />}
                                checkedIcon={<Iconify width={24} icon="fluent:checkbox-checked-20-filled" />}
                                checked={optionFilter.indexOf(option.id) > -1}
                            />
                            <Typography variant={optionFilter.indexOf(option.id) > -1 ? "subtitle2" : "inherit"} paddingRight={"10px"}>
                                {option.label}
                            </Typography>
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem
                        sx={{
                            borderRadius: "8px",
                            padding: "0px",
                            marginBottom: "4px"
                        }}
                    >
                        <Typography variant={"inherit"} padding={"6px 10px 6px"} sx={{ color: (theme) => theme.palette.error.main, textAlign: "center", width: "100%" }}>
                            {noDataMsg ?? "No Records"}
                        </Typography>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )
}