import { useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import FormControlLabel from '@mui/material/FormControlLabel';

import Iconify from '../../../components/common/Iconify';
import { Box, Typography } from '@mui/material';
import { useRouter } from '../../../routes/hooks/useRouter';
import { useToDoDoneMutation } from '../../../redux/api/api';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const ToDoTask = ({ title, subheader, list, data, ...other }) => {


  const [selected, setSelected] = useState(['2']);

  const handleClickComplete = (taskId) => {
    const tasksCompleted = selected.includes(taskId)
      ? selected.filter((value) => value !== taskId)
      : [...selected, taskId];

    setSelected(tasksCompleted);
  };


  return (
    <Card {...other} >
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{
        maxHeight: '430px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          height: '7px',
          width: '7px'
        },
        '&:hover': {
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b4bcc3',
            borderRadius: '8px',
          },
        },
        overflowY: 'auto',
        overflowX: 'auto'
      }}>
        {
          data?.length > 0
            ?
            data?.map((task) => (
              <TaskItem
                key={task.id}
                task={task}
                checked={
                  // selected.includes(task.id)
                  task?.isRead == '0' ? false : true
                }
                onChange={() => handleClickComplete(task.id)}
              />
            ))
            :
            <Typography textAlign={'center'} fontWeight={600} mb={'2rem'}>No To Do Found</Typography>
        }

      </Box>
    </Card>
  );
}

// AnalyticsTasks.propTypes = {
//   list: PropTypes.array,
//   subheader: PropTypes.string,
//   title: PropTypes.string,
// };

// ----------------------------------------------------------------------

const TaskItem = ({ task, checked, onChange }) => {

  const [open, setOpen] = useState(null);
  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const [toDoDone] = useToDoDoneMutation();

  const router = useRouter();
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleReadDone = () => {
    toDoDone({ orgId, todoId: task.id });
  }


  const handleMarkComplete = () => {
    onChange();
    handleReadDone();
    handleCloseMenu();
  };


  const handleView = () => {
    const parseData = JSON.parse(task.action);
    handleReadDone();
    router.push(`/org/${orgId}/order-history/${parseData.orderId}`)
    handleCloseMenu();
  };


  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          pl: 2,
          pr: 1,
          py: 1,
          '&:not(:last-of-type)': {
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          },
          ...(checked && {
            color: 'text.disabled',
            textDecoration: 'line-through',
          }),
        }}
      >
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={onChange} />}
          label={task.details}
          sx={{ flexGrow: 1, m: 0 }}
        />

        <IconButton color={open ? 'inherit' : 'default'} onClick={handleOpenMenu}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </Stack>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleMarkComplete}>
          <Iconify icon="eva:checkmark-circle-2-fill" sx={{ mr: 2 }} />
          Mark Complete
        </MenuItem>

        <MenuItem onClick={handleView}>
          <Iconify icon="carbon:view-filled" sx={{ mr: 2 }} />
          View
        </MenuItem>

      </Popover>
    </>
  );
}


export default ToDoTask;

// TaskItem.propTypes = {
//   checked: PropTypes.bool,
//   onChange: PropTypes.func,
//   task: PropTypes.object,
// };
