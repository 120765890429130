import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import Iconify from "../../../components/common/Iconify";
import { useRouter } from "../../../routes/hooks/useRouter";
import CustomButton from "../../../components/common/CustomButton";

const ChooseRolesDialogue = ({ handelSetRole, open, rolesData }) => {

  const router = useRouter();

  const handelButtonClick = (role) => {
    let r = role;
    let id;
    if (rolesData && rolesData.length > 0) {
      id = (rolesData.find(role => role.roleName == r) || {}).id
    }
    handelSetRole(id, r);
  };

  const handelCloseDialogue = () => {
    router.replace('/')
  }

  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "350px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>SignUp As</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handelCloseDialogue}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        {/* {/ <CloseIcon /> /} */}
        <Iconify icon="material-symbols:cancel-outline" />
      </IconButton>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <CustomButton
              onClick={() => handelButtonClick('vendor')}
              variant="contained"
              label={"Vendor"}
            />
          </Grid>
          <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <CustomButton
              onClick={() => handelButtonClick('customer')}
              variant="contained"
              label={"Customer"}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ChooseRolesDialogue;
