import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import { fNumber } from '../../../utils/formatNumbers';

import Chart, {useChart} from '../../../components/chart/index'
import { color } from 'framer-motion';

// ----------------------------------------------------------------------

 const AppConversionRates = ({ title, subheader, chart,...other }) => {
  const { colors, series, options } = chart;

  

  const chartSeries = series.map((i) => i.value);


  const chartOptions = useChart({
    colors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (value) => fNumber(value),
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '28%',
        borderRadius: 2,
      },
    },
    xaxis: {
      categories: series.map((i) => i.label),
    },
    ...options,
  });

  return (
    <Card {...other}>
    <CardHeader title={title} subheader={subheader} />
    <Box sx={{ mx: 3 }}>
      <Chart
        dir="ltr"
        type="bar"
        series={[{ data: chartSeries }]}
        options={chartOptions}
        width="100%"
        height={364}
      />
    </Box>
    
    {/* Overlay for "Coming Soon" */}
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent white background
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          fontSize: '2rem',
          color:(theme)=> theme.palette.primary.main,
          fontWeight: 700,
          animation: 'pulse 5s infinite alternate', // Animation applied
            '@keyframes pulse': { // Define the keyframes for the animation
            '0%': { transform: 'scale(0.8)' },
            '100%': { transform: 'scale(1.2)' },
          },
      }}
    >
      Coming Soon!
      </Box>
    </Box>
  </Card>
  );
}

AppConversionRates.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};

export default AppConversionRates;
