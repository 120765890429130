import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  TextField,
  Grid,
} from '@mui/material';
import Iconify from '../../../components/common/Iconify';
import CustomTextField from '../../../components/common/CustomTextField';
import CustomButton from '../../../components/common/CustomButton';



export const ShippingInfo = ({ formik }) => {

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={6}>
          <CustomTextField
             name={"email"}
             label={"Email"}
             formik={formik}
             isRequired={true}
          />
      </Grid>
      <Grid item xs={6}>
          <CustomTextField
            name='mobileNumber'
            label= 'Mobile number'
            formik={formik}
            isRequired={true}
          />
      </Grid> */}
      <Grid item xs={6}>
        <CustomTextField
          name='type'
          label='Type'
          formik={formik}

        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          name='shipBy'
          label='Shipped by'
          formik={formik}
          isRequired={true}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          name='trackingNo'
          label='Tracking number'
          formik={formik}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          name='trackingUrl'
          label='Tracking url'
          formik={formik}
        />
      </Grid>
    </Grid>
  )
}

export const FullFilled = ({ value, handleChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          name='type'
          label='Type'
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}


const OrderStatusDialogue = ({ title, message, handleChangeMessage, handelClose, handelConfirm, open, content }) => {

  const handleMessageChange = (event) => {
    handleChangeMessage(event.target.value);
  };


  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={handelClose}>
          <Iconify icon='zondicons:close-outline' />
        </IconButton>
      </Box>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={handelClose}
          variant={"contained"}
          label={"Cancel"}
          color={"primary"}
        />
        <CustomButton
          onClick={handelConfirm}
          variant={"contained"}
          label={"Confirm"}
          color={"secondary"}
        />
      </DialogActions>
    </Dialog>
  );
};

export default OrderStatusDialogue;