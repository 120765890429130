import { Card, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import Chart, { useChart } from '../../../../../components/chart'
import { fNumber } from '../../../../../utils/formatNumbers'




const SalesThisMonth = ({remainingToReachGoal, total, series}) => {



    const chartSeries = series.map((i) => i.value);


  const chartOptions = useChart({
    // colors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (value) => fNumber(value),
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '28%',
        borderRadius: 2,
      },
    },
    xaxis: {
      categories: series.map((i) => i.label),
    },
    // ...options,
  });


  return (
    <Paper sx={{borderRadius:'16px', padding:'1rem', height:'100%'}}>
        <Typography variant='h6'>Sales This Months</Typography>
        <Typography variant='h6' color={'text.disabled'}>Users from all channels</Typography>
        <Stack justifyContent={'flex-start'} mt={'2rem'}>
            <Typography variant='h2' fontWeight={550} >
                <span style={{fontSize:'20px', color:'gray', alignSelf:'flex-start', }}>₹ </span>{total}
            </Typography>
            <Typography variant='body1' color={'text.disabled'}>Another ₹{remainingToReachGoal} to Goal</Typography>
        </Stack>
        <Stack mt={'2rem'}>
            <Chart
            // dir="rlt"
            type="line"
            series={[{ data: chartSeries }]}
            options={chartOptions}
            width="100%"
            height={250}
        />
        </Stack>
    </Paper>
  )
}

export default SalesThisMonth