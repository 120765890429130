import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import AuthView from "../sections/view/AuthView";
import ShowMessage from "../../../components/common/ShowMessage";

const AuthLayout = ({children}) => {
  return (
    <>
      {/* <Helmet>Auth | View</Helmet> */}
      <Container maxWidth="xl" sx={{ paddingLeft: 0, paddingRight: 0 }} disableGutters>
        <AuthView children={children}/>
        <ShowMessage/>
      </Container>
    </>
  );
};

export default AuthLayout;
