import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { AvergaeDailySales, NewCustomerThisMonth, OrderThisMonth } from './VendorDashboardComponents/AvgNewOrder'
import SalesThisMonth from './VendorDashboardComponents/SalesThisMonth'
import RecentOrders from './VendorDashboardComponents/RecentOrders'
import { useSelector } from 'react-redux'
import { useAverageDailySalesQuery, useGetTopOrderingCustomersQuery, useNewCustomerThisMonthQuery, useOrderThisMonthQuery, useTopSellingItemsQuery, useTotalDailySalesQuery } from '../../../../redux/api/api'

const VendorDashboard = () => {

  const orgId = useSelector((state) => state.app.preference.selectedOrgId);

  const {data:  orderThisMonthData} = useOrderThisMonthQuery(orgId);
  const {data: customerThisMonthData} = useNewCustomerThisMonthQuery(orgId);
  const {data: topOrderCustomersData} = useGetTopOrderingCustomersQuery(orgId);
  const {data: averageDailySalesData} = useAverageDailySalesQuery({orgId});

  const {data: totalDailySales} =  useTotalDailySalesQuery({orgId});
  const {data: sellingItemsData} = useTopSellingItemsQuery({orgId});

  return (
    <Container maxWidth='xl'>
          <Typography variant="h4" sx={{ mb: 5 }}>
             Dashbaord
          </Typography>
          <Grid container spacing={3} >
                <Grid container item xs={12} md={6} spacing={3}>
                    <Grid item md={6} >
                        <OrderThisMonth data={orderThisMonthData}/>
                    </Grid>
                    <Grid item md={6} >
                      <NewCustomerThisMonth data={customerThisMonthData} topOrderCustomerData={topOrderCustomersData}/>
                    </Grid>
                    <Grid item md={6} >
                          <AvergaeDailySales
                              series={averageDailySalesData ? Object.entries(averageDailySalesData).map(([date, data]) => ({
                                label: date,
                                value: data?.averagePrice !== null ? data?.averagePrice : 0
                              })) : []}
                            />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} >
                    <SalesThisMonth series={totalDailySales
                        ? Object.entries(totalDailySales)
                            .filter(([key]) => !['total', 'remainingTotal', 'remainingPercent', 'goal'].includes(key)) // Exclude non-date keys
                            .map(([date, data]) => ({
                              label: date,
                              value: data.totalSales !== null ? data.totalSales : 0
                            }))
                        : []} total={totalDailySales?.total} remainingToReachGoal={totalDailySales?.remainingTotal}/>
                </Grid>
                {/* <Grid item xs={12} md={6} >
                    <RecentOrders />
                </Grid> */}
          </Grid>
    </Container>
  )
}

export default VendorDashboard