import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Stack, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../components/common/CustomButton';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import CustomTextField from '../../components/common/CustomTextField';
import { getIn, useFormik } from 'formik';

const labels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

function HoverRating ({formik}) {

  const [hover, setHover] = React.useState(-1);

  return (
    <Box
      sx={{
        width: 200,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Rating
        name="ratings"
        value={formik?.ratings}
        precision={0.5}
        getLabelText={getLabelText}
        onChange={(event, newValue) => {
          formik.setFieldValue('ratings', newValue)
        }}
        onBlur={formik.handleBlur}
        error={
          getIn(formik.touched, 'ratings') && Boolean(getIn(formik.errors, 'ratings'))
        }
        helperText={getIn(formik.touched, 'ratings') && getIn(formik.errors, 'ratings')}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {formik?.ratings !== null && (
        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : formik?.ratings]}</Box>
      )}
    </Box>
  );
}



const AddReviewDialogue = ({ open, onClose, heading = 'Add Review', formik}) => {


  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            padding: '1rem'
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant='h5' sx={{ textAlign: 'start' }}>{heading}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose} // Call onClose function when close icon is clicked
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: 'inherit',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: '2rem' }}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={0.5} mb={'2rem'}><Typography variant='body1'>Your review about this product: </Typography><HoverRating formik={formik}/></Stack>
        <CustomTextField
            formik={formik}
            name='comment'
            label="Review"
            multiline={true}
        />
        <Stack direction={'row'} gap={'1rem'} justifyContent={'flex-end'} mt={'2rem'}>
            <CustomButton
            label={'Close'}
            variant={'outlined'}
            onClick={onClose}
            />
        <CustomButton
        label={'Post'}
        onClick={formik.handleSubmit}
        />
        </Stack>
      </DialogContent>
    </Dialog>
  )
}


export default AddReviewDialogue