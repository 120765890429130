import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import Iconify from './Iconify'

export default function CustomSearchbar({ placeholder, text, handleTextChange, handleTextClear, autoFocus = true, fullWidth = true, props, sx }) {

    return (
        <OutlinedInput
            {...props}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            value={text}
            onChange={handleTextChange}
            placeholder={placeholder}
            startAdornment={
                <InputAdornment position="start" sx={{ '&:hover': { cursor: "pointer" } }}>
                    <Iconify icon="mingcute:search-line" />
                </InputAdornment>
            }
            endAdornment={
                text &&
                <InputAdornment position="start" sx={{ '&:hover': { cursor: "pointer" } }} >
                    <IconButton onClick={handleTextClear}>
                        <Iconify icon="ic:round-clear" width={26} />
                    </IconButton>
                </InputAdornment>
            }
            sx={{...sx, paddingRight:"0px"}}
        />
    )
}
