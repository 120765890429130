import React, { useState } from 'react'
import Header from './Header'
import { Box, Typography } from '@mui/material'
import Footer from './Footer'
import LocationDialog from '../auth/sections/LocationDialog'
import { LocalStorageProvider } from '../../hooks/LocalStorageProvider'

const MainLandingLayout = ({ children }) => {
  const [openLocation, setOpenLocation] = useState(false);
  const onCloseClick = () => {
    setOpenLocation(false);
  };
  return (
    <LocalStorageProvider>
    <Box sx={{
      flexGrow: 1,
      minHeight: 1, display: 'flex',
      // backgroundColor:'red',
      flexDirection: 'column',
    }}>
      <Header setOpenLocation={setOpenLocation} />
      {children}
      <Footer />
      {openLocation && <LocationDialog open={openLocation} handelCloseDialogue={onCloseClick} />}
    </Box>
    </LocalStorageProvider>
  )
}

export default MainLandingLayout