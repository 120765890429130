import { useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import { users } from '../../../_mock/user';

import Iconify from '../../../components/common/Iconify';
import ScrollBar from '../../../components/common/scroll-bar/ScrollBar';

import UserTableRow from './user-table-row';
import UserTableHead from './user-table-head';
import UserTableToolbar from './user-table-toolbar';
import { getComparator, applyFilter } from './utils';
import { useRouter } from '../../../routes/hooks/useRouter';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TableNoData from '../../../views/list/table-no-data';
import TableLoadingData from '../../../components/common/TableLoadingData';
// ----------------------------------------------------------------------

export default function UserPage({ data, setSelectedOrgId, onEdit, setSearch, rowsPerPage, page, setRowsPerPage, setPage, search, searchPlaceholder, isLoading = false }) {


  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  // const [rowsPerPage, setRowsPerPage] = useState(5);



  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setPage1(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    // setPage1(0)
    setRowsPerPage(parseInt(event.target.value, 10));
    // setRowsPerPage1(parseInt(event.target.value, 10))
  };

  const handleFilterByName = (event) => {
    setPage(0);
    // setPage1(0)
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: users,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const notFound = !dataFiltered.length && !!filterName;


  return (
    <Card>
      <UserTableToolbar
        numSelected={selected.length}
        filterName={search}
        onFilterName={handleFilterByName}
        setSearch={setSearch}
        searchPlaceholder={searchPlaceholder}
      />

      <TableContainer >
        <Table sx={{ minWidth: 800 }} stickyHeader>
          <UserTableHead
            order={order}
            orderBy={orderBy}
            rowCount={users.length}
            numSelected={selected.length}
            onRequestSort={handleSort}
            onSelectAllClick={handleSelectAllClick}
            headLabel={[
              { id: 'orgName', label: 'Org Name' },
              { id: 'phoneNumber', label: 'Phone Number' },
              { id: 'type', label: 'Type' },
              { id: 'isActive', label: 'active', align: 'center' },
              { id: 'status', label: 'Status' },
              { id: '' },
            ]}
          />
          <TableBody>
            {isLoading ?
              <TableLoadingData colSpan={6} /> :
              data?.organizations?.length > 0 ?
                data?.organizations?.map((row) => (
                  <UserTableRow
                    key={row.id}
                    orgId={row.id}
                    name={row.orgName}
                    role={row.type}
                    status={row.status}
                    company={row.phoneNumber}
                    avatarUrl={row.orgLogoKey}
                    isVerified={row.active}
                    selected={selected.indexOf(row.name) !== -1}
                    onEdit={onEdit}
                  />
                )) :
                <TableNoData query={search} colSpan={6} />
            }
          </TableBody>
        </Table>
      </TableContainer>


      <TablePagination
        page={page}
        component="div"
        count={data?.totalItems ?? 0}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>

  );
}
