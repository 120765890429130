import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'
import CustomButton from '../../components/common/CustomButton'

const ShowPopupDialogue = ({ open, handleClosePopup, handleClearData }) => {
  return (
    <>
      <Dialog open={open} onClose={() => handleClosePopup()}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to leave this page?
          </Typography>
          <Box sx={{ mt: 2 }}>
            <CustomButton
              onClick={() => handleClearData()}
              variant={"contained"}
              label={"Clear Data"}
              color={"error"}
            />
            <CustomButton
              onClick={() => handleClosePopup()}
              variant={"contained"}
              label={"Cancel"}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ShowPopupDialogue