import { alpha, Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { useGetZohoDataByOrgIdQuery, useUpdateZohoDetailMutation } from "../../../../redux/api/api";
import { useParams } from "react-router-dom";
import { zohoLogo } from "../../../../assets/images";
import CloseIcon from "@mui/icons-material/Close";
import Iconify from "../../../../components/common/Iconify";
import CustomTextField from "../../../../components/common/CustomTextField";
import { getIn, useFormik } from "formik";
import CustomButton from "../../../../components/common/CustomButton";
import { openMessage } from "../../../../redux/slices/showMessageSlice";

const ZohoDetailDialogue = ({ open, onClose, formik, handleSaveZohoDetail }) => {
  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            // maxWidth: "350px", // Set your width here
          },
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose} // Call onClose function when close icon is clicked
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          // color: 'inherit',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Zoho Detail</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={"1rem"}>
          <Grid item xs={12}>
            <CustomTextField name={"clientId"} label={"Client Id"} formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField name={"clientSecret"} label={"Client Secret"} formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField name={"zohoCode"} label={"Zoho Code"} formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField name={"organizationId"} label={"Organization Id"} formik={formik} />
          </Grid>
        </Grid>
        <Stack direction={"row"} justifyContent={"flex-end"} sx={{ mt: "2rem" }}>
          <CustomButton label={"Update Zoho Detail"} onClick={handleSaveZohoDetail} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const WeSupportInventory = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data: zohoOrgData } = useGetZohoDataByOrgIdQuery(orgId);
  const [updateZohoDetail] = useUpdateZohoDetailMutation();
  const [zohoDialgoue, setZohoDialogue] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: zohoOrgData?.id ?? "",
      clientId: zohoOrgData?.clientId ?? "",
      clientSecret: zohoOrgData?.clientSecret ?? "",
      zohoCode: zohoOrgData?.zohoCode ?? "",
      organizationId: zohoOrgData?.organizationId ?? "",
      orgId: orgId,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const result = await updateZohoDetail(values);
      if (result.data) {
        dispatch(openMessage({ message: "Zoho details updated!", messageSeverity: "success" }));
        setZohoDialogue(false);
      } else if (result.error) {
        dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
      } else {
        dispatch(openMessage({ message: "Not able to update details at this moment!", messageSeverity: "error" }));
      }
    },
  });

  const onClose = () => {
    setZohoDialogue(false);
  };

  const onOpen = () => {
    setZohoDialogue(true);
  };

  return (
    <Grid container mt={"2rem"}>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          bgcolor: alpha(theme.palette.primary.light, 0.1),
          boxShadow: "0px 0px 5px #c7c7c7",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: "16px",
          p: 2,
        }}
      >
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <IconButton
            aria-label="close"
            onClick={onOpen}
            sx={{
              cursor: "pointer",
            }}
          >
            <Iconify icon={"fluent:edit-16-filled"} />
          </IconButton>
        </Stack>
        <Typography
          sx={{
            fontSize: "3rem",
            color: "#ded7ff",
            fontWeight: 600,
            position: "relative",
            left: -5,
          }}
        >
          Zoho
        </Typography>

        <img
          src={zohoLogo}
          style={{
            width: "20%",
            position: "relative",
            top: -5,
            right: 0,
          }}
        />
        <Divider color="#e1dbfc" sx={{ height: "0.1px", width: "80%" }} />
        <Box sx={{ padding: 2, overflow: "auto", maxHeight: 400 }}>
          <Grid container spacing={1}>
            {Object.keys(formik?.values)
              .filter((label) => !["id", "orgId"].includes(label))
              .map((label) => (
                <Grid item xs={12} key={label}>
                  <Typography component="div">
                    <strong>{label}</strong>:<span style={{ filter: "blur(4px)", marginLeft: "8px" }}>{formik?.values[label]}</span>
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Grid>
      <ZohoDetailDialogue open={zohoDialgoue} onClose={onClose} formik={formik} handleSaveZohoDetail={formik.handleSubmit} />
    </Grid>
  );
};

export default WeSupportInventory;
