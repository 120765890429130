import { Container, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material';
import OrderListSummary from './summary/OrderListSummary';
import OrderListItem from './list-item/OrderListItem';
import { horecaApi, useGetSuitableVendorListMutation } from '../../../redux/api/api';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderListSearch, setSelectedVendorId, setSummaryFilter } from '../../../redux/slices/orderListSlice';
import { useEffect, useState } from 'react';
import Iconify from '../../../components/common/Iconify';
import { useRouter } from '../../../routes/hooks/useRouter';
import useQueryParams from '../../../routes/hooks/useQueryParams';
import CustomeSelectMenu from '../../../components/common/CustomeSelectMenu';
import PageHeader from '../../../components/common/PageHeader';
import CustomSearchbar from '../../../components/common/CustomSearchbar';
import ItemsLoading from '../../../components/common/ItemsLoading';
import { useNavigate } from 'react-router-dom';

function createVendorList(data) {
    const vendorsList = [];
    const vendorIds = new Set();  // Set to track unique vendor IDs

    data?.forEach(vendor => {
        const vendorId = vendor?.vendorDetail?.id;
        const vendorLabel = vendor?.vendorDetail?.orgName;

        // Check if the vendor ID is already in the set
        if (vendorId && !vendorIds?.has(vendorId)) {
            vendorsList.push({
                id: vendorId,
                label: vendorLabel
            });
            vendorIds.add(vendorId);  // Add the vendor ID to the set
        }
    });

    return vendorsList;
}


export function getFilteredOrderList({ orderList, filterButtons, searchText, vendorFilter }) {
    let newOrderList = []

    if (orderList) {
        newOrderList = orderList?.Total?.totalOrderLists

        if (Object.values(filterButtons).includes(true)) {
            const selectedButton = Object.keys(filterButtons).find(button => filterButtons[button]);

            switch (selectedButton) {
                case 'totalOrderLists':
                    newOrderList = orderList?.Total?.totalOrderLists;
                    break;
                case 'draftedOrderLists':
                    newOrderList = orderList?.Drafted?.draftedOrderLists;
                    break;
                case 'scheduledOrderLists':
                    newOrderList = orderList?.Scheduled?.scheduledOrderLists;
                    break;
                default:
                    break;
            }
        }

        if (searchText) {
            newOrderList = newOrderList.filter(order =>
                order.name.toLowerCase().includes(searchText?.toLowerCase()) ||
                order.vendorDetail?.orgName.toLowerCase().includes(searchText?.toLowerCase())
            );
        }

        if (vendorFilter?.length > 0) {
            newOrderList = newOrderList.filter(order =>
                vendorFilter.includes(order.vendorOrgId)
            );
        }
    }

    // Sorting by name
    // newOrderList.sort((a, b) => a.name.localeCompare(b.name));

    return newOrderList;
}


export function getFilteredOrderListByFilteredVendor({ orderList1, filterButtons, searchText, vendorId, vendorFilter }) {

    let orderList = orderList1?.Total?.totalOrderLists?.filter((order) => order?.vendorDetail?.id == vendorId);

    let newOrderList = []

    if (orderList) {
        newOrderList = orderList?.Total?.totalOrderLists

        if (Object.values(filterButtons).includes(true)) {
            const selectedButton = Object.keys(filterButtons).find(button => filterButtons[button]);

            switch (selectedButton) {
                case 'totalOrderLists':
                    newOrderList = orderList?.Total?.totalOrderLists;
                    break;
                case 'draftedOrderLists':
                    newOrderList = orderList?.Drafted?.draftedOrderLists;
                    break;
                case 'scheduledOrderLists':
                    newOrderList = orderList?.Scheduled?.scheduledOrderLists;
                    break;
                default:
                    break;
            }
        }

        if (searchText) {
            newOrderList = newOrderList.filter(order =>
                order.name.toLowerCase().includes(searchText?.toLowerCase()) ||
                order.vendorDetail?.orgName.toLowerCase().includes(searchText?.toLowerCase())
            );
        }

        if (vendorFilter?.length > 0) {
            newOrderList = newOrderList.filter(order =>
                vendorFilter.includes(order.vendorOrgId)
            );
        }
    }

    // Sorting by name
    // newOrderList.sort((a, b) => a.name.localeCompare(b.name));

    return newOrderList;
}

export function getCategoryLogos({ filterButtons, orderList }) {
    let selectedButton = Object.keys(filterButtons).find(button => filterButtons[button]);

    if (filterButtons[selectedButton]) {
        switch (selectedButton) {
            case "totalOrderLists":
                return orderList?.Total?.Categories;
            case "draftedOrderLists":
                return orderList?.Drafted?.Categories;
            case "scheduledOrderLists":
                return orderList?.Scheduled?.Categories;
            default:
                return [];
        }
    } else {
        return [];
    }
}

export default function OrderList() {
    const router = useRouter();
    const params = useQueryParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
    const [getSuitableVendorList, { isLoading }] = useGetSuitableVendorListMutation();
    const orderList = useSelector((state) => state.orderList.orderList)
    const orgId = useSelector((state) => state.app.preference.selectedOrgId)
    const suitableVendors = useSelector((state) => state.app.suitableVendors);
    const searchText = useSelector((state) => state.orderList.orderListSearch)

    const filterButtons = useSelector((state) => state.orderList.summaryFilters)

    const [orderListToShow, setOrderListToShow] = useState([])

    const vendorFromMyVendors = useSelector((state) => state.orderList.vendorFromMyVendors)

    const [vendorsList, setVendorsList] = useState([])

    const [vendorFilter, setVendorFilter] = useState([])

    const [getOrderList, { isLoading: getOrderListLoading }] = horecaApi.endpoints.getOrderList.useLazyQuery();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSelectVendor = (vendorId) => {
        dispatch(setSelectedVendorId(vendorId))
        navigate(`/org/${orgId}/explore-products?vendorId=${vendorId}`)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        (
            async () => {
                await getOrderList({ orgId, pincode })
            }
        )()
    }, [])

    useEffect(() => {
        if(pincode) {
            getSuitableVendorList({
                orgId,
                payload: {
                    vendorOnly: "true",
                    categories: [],
                    subCategories: [],
                    singleVendor: false,
                    pincode
                },
            }, true);
        }
    }, [pincode])

    useEffect(() => {
        if (vendorFromMyVendors) {
            setVendorFilter((prevState) => [
                ...prevState,
                vendorFromMyVendors,
            ]);
        }
    }, [vendorFromMyVendors])

    useEffect(() => {
        if (orderList) {
            if (params?.vendorId) {
                setOrderListToShow(getFilteredOrderListByFilteredVendor({ orderList1: orderList, filterButtons: filterButtons, searchText: searchText, vendorId: params?.vendorId, vendorFilter: vendorFilter }))
            } else {
                setOrderListToShow(getFilteredOrderList({ orderList: orderList, filterButtons: filterButtons, searchText: searchText, vendorFilter: vendorFilter }))
            }
        }
    }, [orderList, searchText, filterButtons, vendorFilter])

    useEffect(() => {
        if (suitableVendors) {
            setVendorsList(suitableVendors.map((_obj) => {
                return {
                    id: _obj.vendorDetails.id,
                    label: _obj.vendorDetails.org_name
                }
            }))
        }
    }, [suitableVendors])

    const handleCreateNewOrderList = async () => {
        const result = await getSuitableVendorList({
            orgId,
            payload: {
                vendorOnly: "true",
                categories: [],
                subCategories: [],
                singleVendor: false,
                pincode
            },
        });

        if (result.data) {
            if (result?.data[0]?.categoriesTobeSelected[0]?.subCategories?.length > 0) {
                router.push(`/org/2/order-list/create?vendorId=${result?.data[0]?.vendorDetails?.id}&categoryId=${result?.data[0]?.categoriesTobeSelected[0]?.id}&subCategoryId=${result?.data[0]?.categoriesTobeSelected[0]?.subCategories[0]?.id}`);
            } else {
                router.push(`/org/2/order-list/create?vendorId=${result?.data[0]?.vendorDetails?.id}&categoryId=${result?.data[0]?.categoriesTobeSelected[0]?.id}`);
            }
        }
    }

    return (
        <Container maxWidth='xl' >
            <PageHeader
                title={"Order Lists"}
                // linkArray={[
                //     { title: "Dashboard", path: `/` },
                //     { title: "Order List", },
                // ]}
                onClick1={handleClick}
                loading1={isLoading}
                startIcon1={<Iconify icon="eva:plus-fill" />}
                label1={"Create New Order List"}
                variant1={"contained"}
            />
            <Grid
                container
                gap={2}
            >
                <OrderListSummary totalOrderLists={orderList?.Total?.count} draftedOrderLists={orderList?.Drafted?.count} scheduledOrderLists={orderList?.Scheduled?.count} categoryLogos={getCategoryLogos({ filterButtons, orderList })} filterButtons={filterButtons} setFilterButtonsStatus={setSummaryFilter} />
                <Stack spacing={2}
                    width={"100%"}
                    direction={{
                        xs: "column",
                        sm: "row"
                    }}
                >
                    <CustomeSelectMenu label={"Vendors"} noDataMsg={"No Vendors"} options={vendorsList} optionFilter={vendorFilter} setOptionFilter={setVendorFilter} width={{ xs: "100%", sm: "40%", md: "30%" }} />
                    <CustomSearchbar
                        placeholder={"Search..."}
                        text={searchText}
                        handleTextChange={(e) => dispatch(setOrderListSearch(e.currentTarget.value))}
                        handleTextClear={(e) => dispatch(setOrderListSearch(""))}
                    />
                </Stack>
                {getOrderListLoading ? <Stack height={"30vh"} width={"100%"}><ItemsLoading /></Stack> :
                    orderListToShow?.length ? (
                        <Grid
                            container
                            gap={2}
                            maxHeight={490}
                            sx={{
                                overflow: "auto",
                                '&::-webkit-scrollbar': {
                                    height: '7px',
                                    width: '7px'
                                },
                                '&:hover': {
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#b4bcc3',
                                        borderRadius: '8px'
                                    }
                                }
                            }}
                        >
                            {orderListToShow?.map((listItem, index) => (
                                <OrderListItem key={index} responsive={true} listItem={listItem} />
                            ))}
                        </Grid>
                    ) : (
                        <Stack height={"30vh"} width={"100%"} alignItems={"center"} justifyContent={"center"}>
                            <Typography variant={"body2"} padding={"6px 10px 6px"} sx={{ color: (theme) => theme.palette.error.main, textAlign: "center", width: "100%" }}>
                                {"No Order Lists found"}
                            </Typography>
                        </Stack>
                    )
                }
            </Grid>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem disabled>
                    <Typography variant="subtitle2" component="div">
                        Select Vendor
                    </Typography>
                </MenuItem>
                {
                    vendorsList.map((_obj) => {
                        return <MenuItem onClick={() => handleSelectVendor(_obj.id)}>{_obj.label}</MenuItem>
                    })
                }
            </Menu>
        </Container>
    )
}