import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  OutlinedInput,
  Slide,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import _ from "lodash";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSavePrfernces, setSelectedCategory } from "../../redux/slices/appReducer";
import { getSuitableVendorsList } from "../../redux/slices/appReducer";
import CartWidget, { VendorNameWidget } from "./CartWidget";
import { SelecteCategoriesComp } from "../../layouts/common/Searchbar";
import Iconify from "../../components/common/Iconify";
import ItemsBreadCrumbMenu from "./ItemsBreadCrumbMenu";
import { bgBlur } from "../../theme/css";
import CaredHeader, {
  // ItemCard,
  // ItemHeader,
  // ItemRow,
  ItemRow1,
} from "./CardHeader";
import CardHeader from "./CardHeader";
import CustomRating from "../../components/common/CustomRating";
import { useResponsive } from "../../hooks/useResponsive";
import { TableColumns } from "./TableColumns";
import ItemFilters from "./ItemsFilter";
import {
  setCurrentItems,
  setIsSaveProceed,
  setOrderListName,
  setSearchText,
  updateOrderList,
} from "../../redux/slices/orderListSlice";
import { useEffect } from "react";
import { useRouter } from "../../routes/hooks/useRouter";
import { horecaApi, useCreateOrderListMutation, useGetSuitableVendorListMutation, useSavePreferncesMutation } from "../../redux/api/api";
import CreateOrderListDialogue from "./CreateOrderListDialogue";
import { openMessage } from "../../redux/slices/showMessageSlice";
import NoData from "./NoData";
import ReviewOrderListDialogue from "./ReviewOrderListDialogue";
import ShowPopupDialogue from "./ShowPopupDialogue";
import VendorLogoImageComponent from "../../components/common/VendorLogoImageComponent";
import CustomBreadCrumb from "../../components/common/CustomBreadCrumb";
import CustomButton from "../../components/common/CustomButton";
import CustomSearchbar from "../../components/common/CustomSearchbar";
import NoVendorMessage from "../../components/common/NoVendorMessage";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import ItemsLoading from "../../components/common/ItemsLoading";

const StyledDrawerForVendors = styled("div")(({ theme }) => ({
  ...bgBlur({
    // color: theme.palette.background.default,
    color: theme.palette.grey[300],
  }),
  top: 0,
  left: 0,
  zIndex: 99,
  // width: "180px",
  height: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  // padding: theme.spacing(0, 2),
  // overflowY: 'auto',
  // overflowX: 'hidden',
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up("md")]: {
    // height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export const SelectItemToolBar = ({
  numSelected,
  suitableVendorsCategories,
  isSaveProceed,
  itemsCount,
  handleSaveAndProceed,
  setSearchText1,
  searchText1
}) => {
  const smDown = useResponsive("down", "sm");
  const dispatch = useDispatch();

  const onTextSearch = (e) => {
    dispatch(setSearchText(e.target.value));
    setSearchText1(e.target.value)
  };


  return (
    <Toolbar
      sx={{
        height: !smDown && 97,
        display: smDown ? "block" : "flex",        
        justifyContent: !isSaveProceed ? "space-between" : "flex-end",
        p: (theme) => theme.spacing(0, 1, 0, 3),
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {!isSaveProceed && (
        <ItemsBreadCrumbMenu
          suitableVendorsCategories={suitableVendorsCategories}
        />
      )}

      <Stack direction={'row'} alignItems={'center'} gap={'2rem'} mt={smDown && '0.5rem'}>
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <CustomSearchbar
            placeholder={"Search Items..."}
            text={searchText1}
            handleTextChange={onTextSearch}
            handleTextClear={(e) => {
              dispatch(setSearchText(""));
              setSearchText1("")
            }}
          />
        )}
           <Stack direction={'row'} gap={'1rem'} alignItems={'center'}>
            <Stack>
              <IconButton onClick={() => handleSaveAndProceed()} disabled={itemsCount > 0 ? false : true}>
                <Badge showZero badgeContent={itemsCount} color="primary" max={99}>
                  <Iconify icon="eva:shopping-cart-fill" width={34} height={34} />
                </Badge>
              </IconButton>
            </Stack>
          </Stack>
      </Stack>


      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        // <Tooltip title="Filter list">
        //   <IconButton onClick={()=>handleOpenFilter()}>
        //     <Iconify icon="ic:round-filter-list" />
        //   </IconButton>
        // </Tooltip>
        ""
      )}
    </Toolbar>
  );
};

const VendorCard = ({
  vendorData,
  selectedVendorId,
  handelSetCurrVendor,
  getVendorItemsCount,
}) => {

  const {isMobile} = useResponsiveStyles();

  return (
    <>
      <List
        sx={{
          width: isMobile ? '200px' : "100%",
          bgcolor: (theme) =>
            vendorData?.vendorDetails?.id === selectedVendorId
              ? alpha(theme.palette.primary.light, 0.3)
              : theme.palette.paper,
          // boxShadow: (theme) =>
          //   vendorData?.vendorDetails?.id !== selectedVendorId && theme.shadows[1],
          borderRight: (theme) =>
            vendorData?.vendorDetails?.id === selectedVendorId &&
            `2px solid ${theme.palette.primary.main}`,
          borderBottom: (theme) => `2px dashed rgba(244, 246, 248, 0.2)`,
          ":hover": {
            transition: "0.3s all",
            // boxShadow: (theme) => theme.shadows[1],
            transform: "scale(1.05)"
          },
        }}
      >
        <ListItem
          alignItems="flex-start"
          sx={{ flexDirection: 'column' }}
          onClick={() => handelSetCurrVendor(vendorData)}
        >
          <Stack direction={'row'} justifyContent={'flex-start'} alignContent={'flex-start'}>
            <ListItemAvatar>
              {/* <Avatar
            alt={vendorData?.vendorDetails?.org_name}
            sx={{
              bgcolor:
                vendorData?.vendorDetails?.id === selectedVendorId ? "white" : "transparent",
            }}
          >
            <GetImageComponent s3Key={vendorData?.vendorDetails?.org_logo_key}/>
          </Avatar> */}
              <VendorLogoImageComponent s3Key={vendorData?.vendorDetails?.org_logo_key} style={{ width: '50px', height: '40px', padding: '0' }} name={vendorData?.vendorDetails?.org_name} />
            </ListItemAvatar>
            <Stack width={"100%"} direction={"column"} alignItems={"flex-start"}>
              <Typography sx={{
                fontWeight: 700, wordWrap: "break-word",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                textTransform: "capitalize",
                fontWeight: 550,
                color: "text.disbaled",
                fontSize: "0.8rem",
              }} variant="h6">
                {vendorData?.vendorDetails?.org_name}
              </Typography>
              <CustomRating value={parseFloat(vendorData?.vendorDetails?.vendorRating?.ratings)} fontSize={"small"} />
            </Stack>
          </Stack>
          {getVendorItemsCount(vendorData?.vendorDetails?.id) > 0 && (
            <Stack
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                position: "absolute",
                right: 5,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography color={"white"} fontSize={"14px"} fontWeight={"bold"}>
                {getVendorItemsCount(vendorData?.vendorDetails?.id)}
              </Typography>
            </Stack>
          )}
          {/* <Typography
            sx={{
              wordWrap: "break-word",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              textTransform: "capitalize",
              // fontWeight: 550,
              color: "text.disbaled",
              fontSize: "0.8rem",
            }}
          >
            <span>{vendorData?.vendorDetails?.addresses[0]?.pincode ? `${vendorData?.vendorDetails?.addresses[0]?.landmark ? vendorData?.vendorDetails?.addresses[0]?.landmark + ", " : ""}${vendorData?.vendorDetails?.addresses[0]?.line1 ? vendorData?.vendorDetails?.addresses[0]?.line1 + ", " : ""}${vendorData?.vendorDetails?.addresses[0]?.line2 ? vendorData?.vendorDetails?.addresses[0]?.line2 + ", " : ""}${vendorData?.vendorDetails?.addresses[0]?.city ? vendorData?.vendorDetails?.addresses[0]?.city + ", " : ""}${vendorData?.vendorDetails?.addresses[0]?.state ? vendorData?.vendorDetails?.addresses[0]?.state + ", " : ""}${vendorData?.vendorDetails?.addresses[0]?.pincode ?? ""}` : ""}</span>
          </Typography> */}
        </ListItem>
      </List>
    </>
  );
};

export const LeftSide = ({
  suitableVendors,
  selectedVendorId,
  handelSetCurrVendor,
  getVendorItemsCount,
  isSaveProceed,
  height = "60vh"
}) => {
  const {isMobile} = useResponsiveStyles();

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction= {isMobile ? 'row' : 'column'}
        sx={{
          overflowY: !isMobile && "auto",
          overflowX: isMobile && 'auto',
          height: height,
          "-webkit-overflow-scrolling": "touch",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        // direction="row"
        // sx={{
        //   overflowX: "auto",
        //   height: height,
        //   "-webkit-overflow-scrolling": "touch",
        //   scrollBehavior: "smooth",
        //   "&::-webkit-scrollbar": {
        //     display: "none",
        //   },
        // }}
      >
        {
          suitableVendors && suitableVendors?.map((vendor, index) => (
            <>
              <VendorCard
                vendorData={vendor}
                getVendorItemsCount={getVendorItemsCount}
                key={index}
                selectedVendorId={selectedVendorId}
                handelSetCurrVendor={handelSetCurrVendor}
                isSaveProceed={isSaveProceed}
              />
            </>
          ))}
      </Stack>
    </Box>
  );
};

//apply filter on basisi of categories and subcategories
//
export const RightSide = ({
  items,
  handleAddRemoveItem,
  isSaveProceed,
  currVendorId,
  selectedItems,
  handelRemoveItem,
  searchText,
  height = "60vh"
}) => {



  const columns = TableColumns("inventoryItem");
  const smDown = useResponsive("down", "sm");


  return (
    <Box>
      <CardHeader headLabel={columns} isSaveProceed={isSaveProceed}/>
      <Stack
        sx={{
          overflowY: "auto",
          height: height,
          "-webkit-overflow-scrolling": "touch",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            // display: "none",
            width: '7px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b4bcc3',
            borderRadius: '8px',
          },
        }}
      >
        {items?.length > 0 ?
          items?.filter(item => !searchText || item.name.toLowerCase().includes(searchText.toLowerCase()))?.map((item, index) =>
 
              isSaveProceed
                ?
                <ItemRow1
                  key={index}
                  row={item.item}
                  columns={columns}
                  handleAddRemoveItem={handleAddRemoveItem}
                  currVendorId={currVendorId}
                  selectedItems={selectedItems}
                  isSaveProceed={isSaveProceed}
                  handelRemoveItem={handelRemoveItem}
                  searchText={searchText}
                />
                :
                <ItemRow1
                  key={index}
                  row={item}
                  columns={columns}
                  handleAddRemoveItem={handleAddRemoveItem}
                  currVendorId={currVendorId}
                  selectedItems={selectedItems}
                  isSaveProceed={isSaveProceed}
                  handelRemoveItem={handelRemoveItem}
                  searchText={searchText}
                />
            // ) : (
            //   <ItemCard
            //     key={index}
            //     row={item}
            //     handleAddRemoveItem={handleAddRemoveItem}
            //   />
            // )
          ) :
          <NoData query={"no data"} />
        }
      </Stack>

    </Box>

  );
};

//explore items
const ExploreItems = () => {

  //hooks
  const dispatch = useDispatch();
  const router = useRouter();
  const mdDown = useResponsive("down", "md");
  const [searchParams, setSearchParams] = useSearchParams();
  const currVendorId = searchParams.get("vendorId");
  const categoryId = searchParams.get("categoryId");
  const subCategoryId = searchParams.get("subCategoryId");

  const [message, setMessage] = useState('');
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [searchText1, setSearchText1] = useState('');

  const selectedVendorId = useSelector((state) => state.orderList.vendorId)
  const selectedItemId = useSelector((state) => state.orderList.itemId)


  const searchText = useSelector((state) => state.orderList.searchText)

  const [createOrderList, { isLoading: isOrderListLoading }] = useCreateOrderListMutation();

  const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
  const [getSuitableVendorList, { data: suitableVendors, isLoading:vendorLoading}] = useGetSuitableVendorListMutation();

  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const [getVendorItems] = horecaApi.endpoints.getVendorItems.useLazyQuery();
  const [savePrefernces] = useSavePreferncesMutation();


  const isSaveProceed = useSelector((state) => state.orderList.isSaveProceed)
  const name = useSelector((state) => state.orderList.orderListName);

  const isOpen = useSelector((state) => state.app.isSearchOpen)



  const [vendors, setVendors] = useState();




  //states
  const selectedCategory = useSelector(
    (state) => state.app.preference.selectedCategories
  );


  const allCategories = useSelector((state) => state.auth.allSystemCategory);

  // const suitableVendors = useSelector((state) => state.app.suitableVendors);

  const items = useSelector((state) => state.orderList.currItems);

  const selectedItems = useSelector(
    (state) => state.orderList.orderListCreation
  );

  const [orderListDialogue, setOrderListDialogue] = useState(false);

  const totalItemsCount = selectedItems.reduce((totalCount, vendor) => {
    // Sum up the count of items for each vendor
    return totalCount + (vendor.items ? vendor.items.length : 0);
  }, 0);

  useEffect(() => {
    dispatch(setCurrentItems(currVendorId));
  }, [currVendorId]);

  React.useEffect(() => {
    (async () => {
      const result = await getSuitableVendorList({
        orgId,
        payload: {
          vendorOnly: "true",
          categories: selectedCategory,
          subCategories: [],
          vendorOrgId: selectedVendorId,
          itemId: selectedItemId ? selectedItemId : null,
          singleVendor: false,
          pincode
        },
      });

      // if(selectedVendorId){
      if (result.data) {
        setVendors(result?.data);
        if (result?.data[0]?.categoriesTobeSelected[0]?.subCategories?.length > 0) {
          setSearchParams({
            vendorId: result?.data[0]?.vendorDetails?.id,
            categoryId: result?.data[0]?.categoriesTobeSelected[0]?.id,
            subCategoryId: result?.data[0]?.categoriesTobeSelected[0]?.subCategories[0]?.id
          });
        } else {
          setSearchParams({
            vendorId: result?.data[0]?.vendorDetails?.id,
            categoryId: result?.data[0]?.categoriesTobeSelected[0]?.id,
          });
        }
      }
      // }
    })();
  }, [selectedCategory, isOpen, pincode]);
  // isOpen

  React.useEffect(() => {
    (async () => {
      await getVendorItems({ customerOrgId: orgId, vendorOrgId: currVendorId, categoryId: searchText1 ? null : categoryId, subCategoryId: searchText1 ? null : subCategoryId, searchText: searchText1 });
    })()
  }, [currVendorId, categoryId, subCategoryId, searchText1])



  const handelSelectCategory = (cat) => {
    const index = selectedCategory.indexOf(cat.id);
    if (index === -1) {
      const newCategory = [...selectedCategory, cat.id];
      dispatch(setSelectedCategory(newCategory));
      savePrefernces({ payload: { key: 'selectedCategories', value: JSON.stringify(newCategory) } });
    } else {
      const newSelectedCategories = [...selectedCategory];
      newSelectedCategories.splice(index, 1);
      dispatch(setSelectedCategory(newSelectedCategories));
      savePrefernces({ payload: { key: 'selectedCategories', value: JSON.stringify(newSelectedCategories) } });
    }
  };

  const handelRemoveCategory = (categoryName) => {
    const filterd = selectedCategory?.filter((catId) => catId !== categoryName);
    dispatch(setSelectedCategory(filterd));
    savePrefernces({ payload: { key: 'selectedCategories', value: JSON.stringify(filterd) } });
  };
  const [open, setOpen] = useState(false);


  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handelSetCurrVendor = (vendor) => {
    if (isSaveProceed) {
      setSearchParams({ vendorId: vendor.vendorDetails.id });
    } else {
      const subCategoryId = vendor?.categoryDetails[0]?.subCategories
        ? vendor?.categoryDetails[0]?.subCategories[0]?.id
        : "";
      if (subCategoryId) {
        setSearchParams({
          vendorId: vendor?.vendorDetails?.id,
          categoryId: vendor?.categoryDetails[0]?.id,
          subCategoryId,
        });
      } else {
        setSearchParams({
          vendorId: vendor?.vendorDetails?.id,
          categoryId: vendor?.categoryDetails[0]?.id,
        });
      }
    }
  };

  const handleAddRemoveItem = (itemId, isChecked, item1) => {
    const vendorIndex = selectedItems.findIndex(
      (vendor) => vendor.vendorId === currVendorId
    );

    if (isChecked) {
      if (vendorIndex !== -1) {
        // If vendor exists, add item to its items array
        const updatedOrderList = [...selectedItems];
        updatedOrderList[vendorIndex] = {
          ...updatedOrderList[vendorIndex],
          items: [...updatedOrderList[vendorIndex].items, { item: item1 }],
        };
        dispatch(updateOrderList(updatedOrderList));

      } else {
        // If vendor doesn't exist, create new one and add the item
        const vendorName = suitableVendors?.find((vendor) => vendor?.vendorDetails.id == currVendorId)?.vendorDetails?.org_name
        const newVendor = { vendorId: currVendorId, name: vendorName, items: [{ item: item1 }] };
        const updatedOrderList = [...selectedItems, newVendor];
        dispatch(updateOrderList(updatedOrderList));
      }
    } else {
      if (vendorIndex !== -1) {
        // If vendor exists, remove the item from its items array
        const updatedOrderList = [...selectedItems];
        updatedOrderList[vendorIndex] = {
          ...updatedOrderList[vendorIndex],
          items: updatedOrderList[vendorIndex].items.filter(
            (item) => item.item.id !== item1.id
          ), // Ensure that items array is a new array
        };
        // If the vendor has no more items, remove the vendor
        if (updatedOrderList[vendorIndex].items.length === 0) {
          updatedOrderList.splice(vendorIndex, 1);
        }
        dispatch(updateOrderList(updatedOrderList));
      }
    }
  };

  const handleSaveAndProceed = () => {
    dispatch(setIsSaveProceed(true));
    // setSearchParams({ vendorId: currVendorId });
  };

  const getVendorItemsCount = (vendorId) => {
    const items = selectedItems.find(
      (vendor) => vendor.vendorId == vendorId
    )?.items;

    return items?.length;
  };

  const handleBack = () => {
    dispatch(setIsSaveProceed(false));
  };

  const handelRemoveItem = (itemId) => {
    const vendorIndex = selectedItems?.findIndex(
      (vendor) => vendor.vendorId == currVendorId
    );
    if (vendorIndex !== -1) {
      // If vendor exists, remove the item from its items array
      const updatedOrderList = [...selectedItems];
      updatedOrderList[vendorIndex] = {
        ...updatedOrderList[vendorIndex],
        items: updatedOrderList[vendorIndex].items.filter(
          (item) => item.item.id !== itemId
        ), // Ensure that items array is a new array
      };
      // If the vendor has no more items, remove the vendor
      if (updatedOrderList[vendorIndex].items.length === 0) {
        updatedOrderList.splice(vendorIndex, 1);
      }
      dispatch(updateOrderList(updatedOrderList));
    }
  };


  const handleCreateOrderList = async () => {
    setIsShowMessage(true)
    setMessage('We Are Processing Your Creation For Order List Please Hold On')

    const data1 = selectedItems.map((vendor) => ({
      vendorOrgId: vendor.vendorId,
      name: `${name}/${vendor.name}`,
      items: vendor.items.map((item) => ({ item_id: item.item.item_id, inventry_item_id: item?.item?.id })),
    }));
    const result = await createOrderList({ orgId, payload: data1 })
    if (result?.data) {
      dispatch(openMessage({ message: 'Order list created', messageSeverity: 'success' }))
      const payload = { key: "isOrderList", value: 'true' };
      dispatch(setSavePrfernces(payload));
      await savePrefernces({ payload });
      setTimeout(() => {
        router.push(`/org/${orgId}/orders`)
        dispatch(updateOrderList([]));
        dispatch(setOrderListName(""))
        setOrderListDialogue(false);
        dispatch(setIsSaveProceed(false));
      }, [2000])
      setMessage('Your Order List Created, Congratulations🎉🎉')
      setIsShowMessage(true)
    } else if (result?.error) {
      setIsShowMessage(false)
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }))
    } else {
      setIsShowMessage(false)
      dispatch(openMessage({ message: 'There is some issue While creating an order list', messageSeverity: 'error' }))
    }

  }


  //show popup
  const [showPopup, setShowPopup] = useState(false);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClearData = () => {
    setShowPopup(false);
  };

  const goBack = () => {
    router.push(`/org/${orgId}/orders`)
  }

  const {isMobile} = useResponsiveStyles();

  return (
    <Container maxWidth="xl" sx={{ position: 'relative', paddingLeft: 0, paddingRight: 0}}>
      {
        vendors?.length > 0 || vendorLoading
        ?
        <>
        <Stack direction={'row'} gap={'8px'} alignItems={'flex-start'}>
        <IconButton onClick={goBack} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} size='small'>
          <Iconify icon="material-symbols:arrow-back-ios-rounded" />
        </IconButton>
        <Stack
          direction="row"
          spacing={1}
          rowGap={2}
          mb={2}
          sx={{
            overflowX: "auto",
            display: "flex",
            "-webkit-overflow-scrolling": "touch",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {allCategories?.map((category, index) => (
            <SelecteCategoriesComp
              cat={category}
              handleClick1={handelSelectCategory}
              handleDelete={handelRemoveCategory}
              index={index}
              key={index}
              selectedCategories={selectedCategory}
            />
          ))}
        </Stack>
      </Stack>
      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <StyledDrawerForVendors>
          <VendorNameWidget setOpen={setOpen} open={open} />
          <Box
            sx={{
              overflowY: "auto",
              "-webkit-overflow-scrolling": "touch",
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                marginTop: "97px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Vendors
            </Typography>
            {isSaveProceed
              ? ""
              : vendors && vendors?.map((vendor, index) => (
                <VendorCard
                  vendorData={vendor}
                  key={index}
                  selectedVendorId={parseInt(currVendorId)}
                  handelSetCurrVendor={handelSetCurrVendor}
                />
              ))}
          </Box>
        </StyledDrawerForVendors>
      </Slide>
      <Card >
        {!isSaveProceed && (
          <SelectItemToolBar
            handleOpenFilter={handleOpenFilter}
            suitableVendorsCategories={
              vendors &&
              vendors?.find(
                (vendor) =>
                  vendor.vendorDetails.id === parseInt(currVendorId)
              )?.categoryDetails
            }
            itemsCount={selectedItems.length > 0 ? totalItemsCount : 0}
            isSaveProceed={isSaveProceed}
            handleSaveAndProceed={handleSaveAndProceed}
            searchText1={searchText1}
            setSearchText1={setSearchText1}
          />
        )}
        <Stack direction={isMobile ? 'column' : "row"}>
            <Stack
              width={isMobile ? '100%' : "20%"}
              mt={isMobile && '1rem'}
              alignItems={"center"}
              sx={{
                borderRight: (theme) => !isMobile && `1px dashed ${theme.palette.grey[300]}`,
              borderBottom: (theme) => isMobile && `1px dashed ${theme.palette.grey[300]}`,
              }}
            >

              {isSaveProceed ? (
                <LeftSide
                height={isMobile ? '20vh' : '60vh'}
                  suitableVendors={vendors && vendors?.filter((vendor) =>
                    selectedItems?.some(
                      (item) => item?.vendorId == vendor?.vendorDetails?.id
                    )
                  )}
                  selectedVendorId={parseInt(currVendorId)}
                  handelSetCurrVendor={handelSetCurrVendor}
                  getVendorItemsCount={getVendorItemsCount}
                  isSaveProceed={isSaveProceed}
                />
              ) : (
                <LeftSide
                height={isMobile ? '20vh' : '60vh'}
                  suitableVendors={vendors}
                  selectedVendorId={parseInt(currVendorId)}
                  handelSetCurrVendor={handelSetCurrVendor}
                  getVendorItemsCount={getVendorItemsCount}
                />
              )}
            </Stack>
          
          <Stack  width={isMobile ? '100%' : "80%"} mt={isMobile && '1rem'}>
            {isSaveProceed ? (
              <RightSide
                isSaveProceed={isSaveProceed}
                handleOpenFilter={handleOpenFilter}
                items={
                  selectedItems?.find((vendor) => vendor.vendorId == parseInt(currVendorId))?.items
                }
                currVendorId={currVendorId}
                suitableVendorsCategories={
                  vendors &&
                  vendors?.find(
                    (vendor) =>
                      vendor.vendorDetails.id === parseInt(currVendorId)
                  )?.categoryDetails
                }
                handelRemoveItem={handelRemoveItem}
              />
            ) : (
              <RightSide
                isSaveProceed={isSaveProceed}
                searchText={searchText}
                handleOpenFilter={handleOpenFilter}
                items={items}
                currVendorId={currVendorId}
                suitableVendorsCategories={
                  vendors &&
                  vendors?.find(
                    (vendor) =>
                      vendor?.vendorDetails?.id === parseInt(currVendorId)
                  )?.categoryDetails
                }
                handleAddRemoveItem={handleAddRemoveItem}
                selectedItems={selectedItems}
              />
            )}
          </Stack>
        </Stack>
      </Card>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        mt={"1rem"}
        gap={"1rem"}
      >
        {isSaveProceed ? (
          <>
            <CustomButton
              onClick={handleBack}
              variant={"contained"}
              label={"Back"}
              sx={{ textTransform: "capitalize" }}
              disabled={totalItemsCount > 0 ? false : true}
            />
            <CustomButton
              onClick={() => setOrderListDialogue(true)}
              variant={"contained"}
              label={"Save and proceed"}
              sx={{ textTransform: "capitalize" }}
              disabled={totalItemsCount > 0 ? false : true}
            />
          </>
        ) : (
          <CustomButton
            onClick={handleSaveAndProceed}
            variant={"contained"}
            label={"Review"}
            sx={{ textTransform: "capitalize" }}
            disabled={totalItemsCount > 0 ? false : true}
          />
        )}
      </Stack>
  
      <ItemFilters
        openFilter={openFilter}
        onOpenFilter={handleOpenFilter}
        onCloseFilter={handleCloseFilter}
      />
      <CreateOrderListDialogue
        message={message}
        orderListDialogue={orderListDialogue}
        setOrderListDialogue={setOrderListDialogue}
        handleCreateOrderList={handleCreateOrderList}
        isOrderListLoading={isOrderListLoading}
        isShowMessage={isShowMessage}
      />
      {
        !isMobile  && <Stack sx={{ position: 'absolute', top: '40%', left: -20, padding: '1rem 0', paddingTop: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', transform: 'rotate(-180deg)', borderTopRightRadius: '2rem', borderBottomRightRadius: '2rem' }}>
        <Typography variant="h3" sx={{ writingMode: 'vertical-lr', display: 'inline', textAlign: 'center' }}>Vendors</Typography>
      </Stack>
      }


      <ReviewOrderListDialogue
        open={isSaveProceed}
        content={<>
          <Stack direction={isMobile ? 'column' : "row"}>
          <Stack
            width={isMobile ? '100%' : "20%"}
            mt={isMobile && '1rem'}
            alignItems={"center"}
            sx={{
              borderRight: (theme) => !isMobile && `1px dashed ${theme.palette.grey[300]}`,
              borderBottom: (theme) => isMobile && `1px dashed ${theme.palette.grey[300]}`,
            }}
          >
            <LeftSide
                  height={isMobile ? '20vh' : '60vh'}
                  suitableVendors={vendors && vendors?.filter((vendor) =>
                    selectedItems?.some(
                      (item) => item?.vendorId == vendor?.vendorDetails?.id
                    )
                  )}
                  selectedVendorId={parseInt(currVendorId)}
                  handelSetCurrVendor={handelSetCurrVendor}
                  getVendorItemsCount={getVendorItemsCount}
                  isSaveProceed={isSaveProceed}                  
                />
              </Stack>
            <Stack width={mdDown ? "100%" : "80%"}>
              <RightSide
                isSaveProceed={isSaveProceed}
                handleOpenFilter={handleOpenFilter}
                items={
                  selectedItems?.find((vendor) => vendor.vendorId == parseInt(currVendorId))?.items
                }
                currVendorId={currVendorId}
                suitableVendorsCategories={
                  vendors &&
                  vendors?.find(
                    (vendor) =>
                      vendor.vendorDetails.id === parseInt(currVendorId)
                  )?.categoryDetails
                }
                handelRemoveItem={handelRemoveItem}
                height="40vh"
              />
            </Stack>
          </Stack>
          <Stack>
          </Stack>
          <Stack direction={"row"}
            justifyContent={"center"}
            mt={"1rem"}
            gap={"1rem"}>
            <CustomButton
              onClick={() => setOrderListDialogue(true)}
              variant={"contained"}
              label={"Save and proceed"}
              sx={{ textTransform: "capitalize" }}
              disabled={totalItemsCount > 0 ? false : true}
            />
          </Stack>
        </>}
        onClose={handleBack}
      />

      <ShowPopupDialogue
        handleClosePopup={handleClosePopup}
        open={showPopup}
        handleClearData={handleClearData}
      />
      </>
      :
      <ItemsLoading/>
      }
    </Container>
  );
};

export default ExploreItems;