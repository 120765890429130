import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import { Box, Grid, Rating, Typography } from "@mui/material";
import { SectionContainer } from "./Section1";
import LoginDialogue from "../auth/sections/LoginDialogue";
import { useState } from "react";
import { ansariVendor, greenBasket, horeca1Image, shree } from "../../assets/banners";
import VendorSection from "../dashboard/sections/view/CustomerHome/VendorSection";
import { horecaApi } from "../../redux/api/api";
import GetImageComponent from "../../components/common/GetImageComponent";
import { useSelector } from "react-redux";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";

const PopularVendors = ({ location }) => {
  const sliderRef = useRef(null);
  const responsive = useResponsiveStyles();
  const [open, setOpen] = useState(false);
  const [getAllVendorsByPinCode, { data: suitableVendors }] = horecaApi.endpoints.getAllVendorsByPinCode.useLazyQuery();
  useEffect(() => {
    if (location) {
      getAllVendorsByPinCode({ pinCode: location.pincode })
    }
  }, [location])

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0); // Go to the first slide when component mounts
    }
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // Screen width of 1024px or less
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Screen width of 600px or less
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Array of customer data
  const customers = [
    { name: "Horeca1", image: horeca1Image },
    { name: "Ansari", image: ansariVendor },
    { name: "Green Basket", image: greenBasket },
    { name: "Shree", image: shree },
  ];

  const onCloseClick = () => {
    setOpen(false);
  };

  const handleOpneClick = () => {
    setOpen(true);
  };
  if (responsive?.isMobile) {
    return (
      <SectionContainer sx={{ mb: 2 }}>
        <Typography variant="h3" fontWeight={600} sx={{ mb: "2rem", textAlign: "center" }}>
          Prime Vendors
        </Typography>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {suitableVendors && suitableVendors.length > 0 ?
            <Grid container spacing={3}>
              {suitableVendors.map((vendor, index) => {
                //const vendorCategories = vendor.categoryDetails.map((_obj) => _obj.parent_category).join(', ');
                return (<Grid key={`${index}vendor`} item xs={6} sm={3}>
                  <Box sx={{
                    display: 'flex', flexGrow: 1, paddingY: 2, flexDirection: 'column', gap: 5,
                    background: '#E7F3F3',
                    height: '100%',
                    minHeight: 150,
                    //boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px',
                    // background: 'linear-gradient(to bottom, #e0e0e0, #74899A)',
                    // '&:hover': {
                    //   cursor: 'pointer',
                    //   transform: 'scale(0.95)',
                    // },
                    borderRadius: 2
                  }}>
                    {/* <Box sx={{ borderRadius: 2, boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px', background: 'linear-gradient(to bottom, #e0e0e0, #757575)' }}>
                      <GetImageComponent s3Key={vendor?.orgLogoKey} style={{ borderRadius: 16, height: 100, width: '100%' }} />
                    </Box> */}
                    <Box sx={{ paddingX: 1, display: 'flex', flexDirection: 'column', height: '100%',justifyContent: 'space-between' }}>
                      <Typography variant="h5">
                        {vendor?.orgName?.length > 40 ? `${vendor.orgName.slice(0, 40)}...` : vendor?.orgName}
                      </Typography>
                      <Box sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
                        {vendor?.ratings && <Typography variant="subtitle2">{vendor?.ratings}</Typography>}
                        <Rating sx={{
                          '& .MuiRating-iconFilled': {
                            color: '#ff6d75', // Color for filled stars
                          },
                          '& .MuiRating-iconEmpty': {
                            color: 'grey', // Color for empty stars
                          },
                          '& .MuiRating-iconHover': {
                            color: '#ff3d47', // Color for stars on hover
                          },
                        }} size="small" value={Number(vendor?.ratings) || null} readOnly />

                      </Box>
                      {/* <Typography noWrap sx={{ color: 'grey' }}>{vendorCategories}</Typography> */}
                    </Box>
                  </Box>
                </Grid>)
              })
              }
            </Grid>
            :
            <Typography variant="subtitle1" sx={{ color: 'grey', textAlign: 'center' }}>
              We currently don't have vendors in your area. We're expanding our network and hope to serve you soon.
            </Typography>
          }
        </Box>
        <LoginDialogue open={open} handelCloseDialogue={onCloseClick} />
      </SectionContainer>
    );
  }
  return (
    <SectionContainer sx={{ paddingX: '0 !important' }}>
      <Typography variant="h3" fontWeight={500} sx={{ mb: 1, textAlign: "center" }}>
        Prime Vendors
      </Typography>
      <Typography variant="h5" fontWeight={500} textAlign={"center"} color={"text.disabled"}>
        All the Restaurant Suppliers you’ll ever need
      </Typography>
      {/* <Slider ref={sliderRef} {...settings}>
        {customers.map((customer, index) => (
          <Box
            key={index}
            bgcolor={"red"}
            sx={{
              bgcolor: "white",
              //  mr: '1rem',
              p: "1rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // textAlign: "center",
              borderRadius: "8px",
            }}
          >
            <img src={customer.image} alt={customer.name} style={{ width: "100px", height: "auto", cursor: "pointer" }} onClick={handleOpneClick} />
            <Typography variant="h6" sx={{ mt: "1rem", cursor: "pointer" }} onClick={handleOpneClick}>
              {customer.name}
            </Typography>
          </Box>
        ))}
      </Slider> */}
      <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {suitableVendors && suitableVendors.length > 0 ?
          <Grid container spacing={3}>
            {suitableVendors.map((vendor, index) => {
              //const vendorCategories = vendor.categoryDetails.map((_obj) => _obj.parent_category).join(', ');
              return (<Grid key={`${index}vendor`} item xs={6} sm={3}>
                <Box sx={{
                  display: 'flex', flexGrow: 1, paddingY: 2, flexDirection: 'column', gap: 5,
                  transition: 'transform 0.3s ease-in-out',
                  minHeight: '130px',
                  //boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px',
                  background: '#E7F3F3',
                  // '&:hover': {
                  //   cursor: 'pointer',
                  //   transform: 'scale(0.95)',
                  // },
                  borderRadius: 2
                }}>
                  {/* <Box sx={{ borderRadius: 2, boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px, rgba(0, 0, 0, 0.07) 0px -1px 5px 0px', background: 'linear-gradient(to bottom, #e0e0e0, #757575)' }}>
                    <GetImageComponent s3Key={vendor?.orgLogoKey} style={{ borderRadius: 16, height: 180, width: '100%' }} />
                  </Box> */}
                  <Box sx={{ paddingX: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="h5">
                      {vendor?.orgName?.length > 40 ? `${vendor.orgName.slice(0, 40)}...` : vendor?.orgName}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {vendor?.ratings && <Typography variant="subtitle2">{vendor?.ratings}</Typography>}
                      <Rating sx={{
                        '& .MuiRating-iconFilled': {
                          color: '#ff6d75', // Color for filled stars
                        },
                        '& .MuiRating-iconEmpty': {
                          color: 'grey', // Color for empty stars
                        },
                        '& .MuiRating-iconHover': {
                          color: '#ff3d47', // Color for stars on hover
                        },
                      }} size="small" value={Number(vendor?.ratings) || null} readOnly />
                    </Box>
                    {/* <Typography noWrap sx={{ color: 'grey' }}>{vendorCategories}</Typography> */}
                  </Box>
                </Box>
              </Grid>)
            })
            }
          </Grid>
          :
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: 2 }}>
            <Typography variant="subtitle1" sx={{ color: 'grey', textAlign: 'center' }}>
              We currently don't have vendors in your area. We're expanding our network and hope to serve you soon.
            </Typography>
          </Box>
        }
      </Box>
      <LoginDialogue open={open} handelCloseDialogue={onCloseClick} />
    </SectionContainer>
  );
};

export default PopularVendors;
