import { Menu, MenuItem, Typography } from '@mui/material';

export default function CustomMenu({ anchorEl, open, onClose, menuItems }) {
    const handleClose = () => {
        onClose && onClose();
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                elevation: 8,
                sx: {
                    width: "140px",
                    marginTop: -6,
                    backdropFilter: 'blur(20px)',
                    backgroundImage: 'url("https://minimals.cc/assets/cyan-blur.png"), url("https://minimals.cc/assets/red-blur.png")',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right top, left bottom',
                    borderRadius: "10px",
                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
                    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    '& .MuiList-root': {
                        padding: "4px"
                    }
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {menuItems?.map((menu, index) => (
                <MenuItem key={index} onClick={menu.handler}
                    sx={{
                        gap: 2,
                        borderRadius: "8px"
                    }}
                >
                    {menu.icon}
                    <Typography variant="inherit">{menu.label}</Typography>
                </MenuItem>
            ))}
        </Menu>
    );
};


