import { Box, Typography } from "@mui/material";

const TermsComponent = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>Terms of Use</Typography>
            <Box>
                <Typography variant="body2" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    HORECA1 CUSTOMER / VENDORS TERMS AND CONDITIONS ("TERMS")
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                    These Terms form an integral part of the Customers / Vendors Enrolment Form for Horeca1 by HCX Global Pvt. Ltd. ("Form") and constitute a legally binding agreement made between you, whether personally or on behalf of an entity (the "Customers / Vendors"), and HCX Global Pvt. Ltd. Horeca1 Private Limited (formerly known as HCX Global Pvt. Ltd. Internet Private Limited) and its affiliates (collectively, "HCX Global Pvt. Ltd."), regarding the use of Horeca1 Platform (as defined below) for the purchase of Goods (as defined below) by the Customers / Vendors from HCX Global Pvt. Ltd..
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>1. Definitions</Typography>
                <ol>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Commencement Date'</strong> means the date on which the Customers / Vendors receives a confirmation email from HCX Global Pvt. Ltd. upon successful completion of the sign-up process on the Horeca1 Platform or the date of execution of the Form, whichever is earlier.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Credit Facility'</strong> means the option availed by the Customers / Vendors from the NBFC / Banking / Fintech Partners associated with the platform to place Order(s) on a credit basis during the Credit Period.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Credit Limit'</strong> means the Credit Limit amount as sanctioned by the respective NBFC / Banking / Fintech Partners that the Customers / Vendors is permitted to keep as outstanding during the Credit Period which may be revised by HCX Global Pvt. Ltd. or any of its NBFC / Banking / Fintech Partners at their sole discretion without any prior intimation. This Credit Limit shall be communicated by NBFC / Banking / Fintech Partners to the Customers / Vendors in writing from time to time.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Credit Period'</strong> means the period as communicated by HCX Global Pvt. Ltd. or the Lenders (NBFC / Banking / Fintech Partners) to the Customers / Vendors, within which the Customers / Vendors shall clear all its outstanding dues.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Goods'</strong> shall mean the goods available on the Horeca1 Platform.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Horeca1 Platform'</strong> means the online technology platform owned by HCX Global Pvt. Ltd. where the Goods are displayed and the Customers / Vendors can place Order(s) for the Goods.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Logistics Partner'</strong> means third party logistics service provider, who delivers the Goods to the Customers / Vendors.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Customers / Vendors'</strong> means the entity/individual being the legal owner/operator in the HORECA (hotel, restaurant or catering) sector as mentioned in the Form.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Customers / Vendors Content'</strong> means any content including reviews, images, photos, audio, video, location data, nearby places, and all other forms of information or data provided on the Horeca1 Platform.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Order(s)'</strong> means an order placed by the Customers / Vendors with HCX Global Pvt. Ltd. for the purchase of Goods via the Horeca1 Platform or otherwise through offline assisted sales.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Parties'</strong> means HCX Global Pvt. Ltd. and the Customers / Vendors.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Perishable Goods'</strong> shall mean goods including bakery items, fruits and vegetables, frozen products, dairy products, chicken and eggs, mutton and seafood etc. that have a limited shelf life and are prone to spoilage, decay, and/or deterioration.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Price'</strong> means the selling price of the Goods as indicated on the Horeca1 Platform and/or communicated by HCX Global Pvt. Ltd. to the Customers / Vendors at the time of placing Order(s) and/or as intimated by HCX Global Pvt. Ltd. to the Customers / Vendors from time to time.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Services'</strong> means the services offered by HCX Global Pvt. Ltd. to the Customers / Vendors through the Horeca1 Platform.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Website'</strong> means www.Horeca1.com (including the webpages contained or hyperlinked therein and owned or controlled by HCX Global Pvt. Ltd.), and such other media or media channels, devices, mobile applications, software, or technologies as HCX Global Pvt. Ltd. may choose from time to time.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Horeca1 Content'</strong> means the content that HCX Global Pvt. Ltd. creates and makes available in connection with the Horeca1 Platform and the Goods including, but not limited to, visual interfaces, interactive features, graphics, design, compilation, computer code, products, software, aggregate ratings, reports and other usage-related data in connection with the activities associated with the Customers / Vendors’s account.
                        </Typography>
                    </li>
                </ol>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>2. General Understanding</Typography>
                <ol>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Goods'</strong> means the products listed and displayed by HCX Global Pvt. Ltd. and associated Vendors on the Horeca1 Platform.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Prices'</strong> refer to the selling prices of the Goods as shown on the Horeca1 Platform.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Order(s)'</strong> refers to the request made by Customers / Vendors to purchase Goods via the Horeca1 Platform.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Delivery Time'</strong> means the estimated time for the delivery of Goods provided by HCX Global Pvt. Ltd. on the Horeca1 Platform after an Order is placed.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Logistics Partners'</strong> refers to third-party service providers responsible for delivering Goods to the Customers / Vendors, as well as Vendors themselves if applicable.
                        </Typography>
                    </li>
                </ol>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>3. Quality Standards; Compliance with Applicable Law</Typography>
                <ol>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Goods'</strong> means the products supplied by HCX Global Pvt. Ltd. based on the description and specification of the Order(s) placed by Customers / Vendors.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Compliance'</strong> refers to the requirement for all Parties to conduct themselves in accordance with these terms and all applicable laws.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Manufacturing Liability'</strong> indicates that HCX Global Pvt. Ltd. shall not be liable for manufacturing aspects of the Goods, as HCX Global Pvt. Ltd. sources Goods from third-party Vendors / Manufacturers. Any issues related to manufacturing, including quality, ingredients, shelf-life, and packaging, are the sole responsibility of the third-party manufacturer. HCX Global Pvt. Ltd. will support Customers / Vendors by connecting them with manufacturers but will not be party to disputes regarding manufacturing.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Direct Deliveries'</strong> denotes that HCX Global Pvt. Ltd. disclaims liability for Goods delivered directly from third-party manufacturers to Customers / Vendors' locations.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Cash Deposits'</strong> specifies that HCX Global Pvt. Ltd. does not accept cash deposits into its bank account. Customers / Vendors are solely responsible for any consequences arising from making cash deposits. In the event of cash deposits, HCX Global Pvt. Ltd. reserves the right to treat such deposits in accordance with applicable tax laws, and Customers / Vendors agree to indemnify HCX Global Pvt. Ltd. for any losses or consequences incurred due to such deposits.
                        </Typography>
                    </li>
                </ol>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>4. Term and Termination</Typography>
                <ol>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Term'</strong> refers to the duration of the arrangement between the Parties, which starts on the Commencement Date and continues indefinitely unless terminated in accordance with this clause.
                        </Typography>
                    </li>
                    <li>

                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Either party may terminate the arrangement: With or without cause, at any time, without giving prior written notice to the other party.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            <strong>'Termination by HCX Global Pvt. Ltd.'</strong> means HCX Global Pvt. Ltd. reserves the right to:
                            <ol>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        Terminate the arrangement or suspend access to the Horeca1 Platform with immediate effect if:
                                        <ol>
                                            <li>
                                                <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                                    Customers / Vendors default in making payment for two (2) consecutive times.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                                    Any insolvency events occur, such as bankruptcy, appointment of a receiver, administrator, liquidator, winding up, or dissolution.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                                    Fraudulent and/or suspicious activity is identified on the Customers / Vendors’s account.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                                    Failure to comply with applicable laws and/or these Terms.
                                                </Typography>
                                            </li>
                                        </ol>
                                    </Typography>
                                </li>
                            </ol>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Termination of the arrangement: (a) in accordance with its Terms, shall not affect the accrued rights or liabilities of the Parties at the date of termination; and (b) shall have no effect on: (i) the validity of Goods already supplied to the Customers / Vendors; or (ii) Customers / Vendors’s obligations to pay for Goods already purchased and/or delivered in accordance with these Terms.
                        </Typography>
                    </li>
                </ol>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>5. Pricing of Goods</Typography>
                <Typography variant="body2">HCX Global Pvt. Ltd. agrees to sell the Goods to the Customers / Vendors at the Price as indicated on the Horeca1 Platform at the time of placing Order(s) or at such other price as mutually agreed between the Parties from time to time. HCX Global Pvt. Ltd. reserves the right to revise the said Price at its sole discretion at any given time without prior notice to the Customers / Vendors prior to placing of Order(s).</Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>6. Quality of Goods</Typography>
                <ol>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            It is the responsibility of the Customers / Vendors to check the quality and quantity of the Goods at the time of dispatch/delivery. Failure to raise concerns about the quantity and/or quality of the Goods at the time of delivery means Customers / Vendors will not have the right to request a replacement thereafter. HCX Global Pvt. Ltd. will not be responsible for any deficiencies, damages, or claims regarding the quality or quantity of Goods after delivery and acceptance by Customers / Vendors.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Customers / Vendors must utilize the Goods ordered from the Horeca1 Platform before the specified expiry date. HCX Global Pvt. Ltd. will not be held liable for the quality of Goods if they are not utilized before the expiry date.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            If the delivery of Perishable Goods is delayed due to reasons attributable to Customers / Vendors and the quality of such Perishable Goods is affected, HCX Global Pvt. Ltd. will not issue refunds for Perishable Goods whose quality has been compromised.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Reverse pickup requests for Order(s) will be accepted by HCX Global Pvt. Ltd. only if a new Order request is placed simultaneously. The reverse pickup must be done at the time of delivery of the new Order. The reverse pickup request along with the new Order must be placed within a maximum period of 7 (seven) days from the delivery date of the Order requiring reverse pickup. Failure to initiate a reverse pickup within 7 (seven) days will result in the automatic expiration of the product return opportunity.
                        </Typography>
                    </li>
                </ol>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>7. Delivery Timeline</Typography>
                <Typography variant="body2">Order(s) placed by the Customers / Vendors through the Horeca1 Platform on any day will be delivered to the Customers / Vendors within a reasonable time from placing the Order(s). However, the Customers / Vendors agree that there can be a variation in the delivery timelines wherein an Order may be delivered earlier or later than the scheduled delivery and the same is communicated to the Customers / Vendors through the Horeca1 Platform. </Typography>
            </Box>

            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>6. Obligations of Customers / Vendors</Typography>
                <ol>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Customers / Vendors must make timely payment of invoices raised by HCX Global Pvt. Ltd. for the sale of Goods in accordance with the Form and/or these Terms.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Customers / Vendors must accept the delivery of Goods promptly upon arrival at the designated location. HCX Global Pvt. Ltd. will attempt delivery only once. If there is a delay of more than ten (10) minutes in accepting the delivery, HCX Global Pvt. Ltd. reserves the right to recall the Goods without refunding the payment.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Customers / Vendors must provide all necessary information and support requested by HCX Global Pvt. Ltd. to fulfill its duties under these Terms.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            If delivery is required on a higher floor (more than one floor), it is the Customers / Vendors’s responsibility to provide elevator service or assistance for the delivery of Goods to the designated address.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Customers / Vendors must ensure that the information provided to HCX Global Pvt. Ltd. is current and accurate, including but not limited to name, address, contact number, email, manager/contact person details, delivery address, delivery times, and other relevant information.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Customers / Vendors may request the cancellation of Order(s) placed through the Horeca1 Platform. HCX Global Pvt. Ltd. will review such requests based on factors including but not limited to the timing of the cancellation request. However, HCX Global Pvt. Ltd. does not guarantee the cancellation of Order(s) or related returns and/or refunds once the Order has been placed.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Customers / Vendors will be responsible for non-delivery of Goods if they fail to accept delivery at the designated location and within the agreed timelines due to reasons attributable to them. HCX Global Pvt. Ltd. reserves the right to levy a penalty of INR 1500/- (Indian Rupees one thousand five hundred only) for losses incurred due to such failure.
                        </Typography>
                    </li>
                </ol>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>7. Invoice and Payments</Typography>
                <ol>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            HCX Global Pvt. Ltd. will deliver the Goods along with the relevant invoice.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            In consideration for the Goods sold by HCX Global Pvt. Ltd., the Customers / Vendors shall be liable to pay HCX Global Pvt. Ltd. the Price, as reflected on the Invoice shared at the time of delivery of Goods to the Customers / Vendors.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Customers / Vendors availing the Credit Facility:
                            Customers / Vendors who are eligible and avail the credit facility from HCX Global Pvt. Ltd. or any of its Lending partners agree to be bound by the following terms:
                            <ol>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        They will clear the Invoices within the Credit Period as informed by HCX Global Pvt. Ltd. from time to time. Failure to do so will result in HCX Global Pvt. Ltd. having the right to disable the Customers / Vendors from placing any further Order(s) via Horeca1 Platform until outstanding dues are cleared and may lead to appropriate legal action. HCX Global Pvt. Ltd. also reserves the right to levy interest at the rate of 24% per annum for each day overdue until the outstanding dues are cleared.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        Notwithstanding any contrary provisions, the Customers / Vendors, on behalf of itself and all its affiliates, authorizes HCX Global Pvt. Ltd. and all HCX Global Pvt. Ltd. Group Companies to withhold and deduct any amounts payable to the Customers / Vendors under any agreement or arrangement and apply such amounts to set off any amounts owed by the Customers / Vendors to HCX Global Pvt. Ltd. “HCX Global Pvt. Ltd. Group Company” includes its parent company (HCX Global Pvt. Ltd. Limited) and any of its affiliates and subsidiaries.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        Any information provided, including PAN and GST details, for availing Services may be shared by HCX Global Pvt. Ltd. with third-party service providers for evaluating (i) whether HCX Global Pvt. Ltd. can extend the Credit Facility, and (ii) the Credit Limit to be sanctioned.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        For availing the Credit Facility, Customers / Vendors must (i) maintain sufficient funds in their bank account, and (ii) provide copies of undated cheques (“UDC”) and/or bank guarantees in favor of HCX Global Pvt. Ltd. as security. HCX Global Pvt. Ltd. reserves the right to encash the UDC and/or bank guarantees if the Customers / Vendors default on clearing any Invoice(s) within the Credit Period.
                                    </Typography>
                                </li>
                            </ol>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            The Customers / Vendors will provide Goods and Service Tax Identification Number ('GSTIN'), Permanent Account Number ('PAN'), and such other documents as may be required by HCX Global Pvt. Ltd. for the purpose of generating appropriate Invoices and for settlement of payments.</Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            HCX Global Pvt. Ltd. will raise the Invoice to the Customers / Vendors at the time of delivery of Goods containing such particulars as may be prescribed under the GST act and rules thereunder, as amended from time to time for the cost of Goods plus applicable taxes ('Invoice').
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            The tax collected at source under (“TCS”) u/s 206C(1H) of the Income Tax Act, 1961:
                            <ol>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        HCX Global Pvt. Ltd. is required to collect tax at source against consideration collected above the threshold limit prescribed under the Income Tax Act, 1961 at the applicable rate. TCS u/s 206C(1H) of the Income Tax Act, 1961 will be levied on the Invoice at the time of placing the Order(s) by the Customers / Vendors and on the full value as provided in the Invoice.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        HCX Global Pvt. Ltd. will remit the collected TCS u/s 206C(1H) of the Income Tax Act, 1961, to the respective government account and report against the PAN as available on HCX Global Pvt. Ltd. records. Such remittances and reportings will be considered as complete fulfillment of HCX Global Pvt. Ltd.'s obligations in this regard. HCX Global Pvt. Ltd. will share a quarterly TCS certificate with the Customers / Vendors within the time stipulated under the Income-tax Act.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        The Customers / Vendors will be solely responsible to provide correct PAN details to HCX Global Pvt. Ltd. and reconcile TCS u/s 206C(1H) of the Income Tax Act, 1961, with the tax statements and certificates provided by HCX Global Pvt. Ltd. from time to time.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        The Customers / Vendors may be eligible to claim credit of the TCS u/s 206C(1H) of the Income Tax Act, 1961, on the basis of tax returns filed by it with the relevant government tax authorities. Customers / Vendors acknowledges and agrees that it is the Customers / Vendors’s responsibility to reconcile and claim correct credit for the TCS u/s 206C(1H) of the Income Tax Act, 1961, for which the Customers / Vendors may be eligible. In the event of any discrepancy between the returns filed by the Customers / Vendors and those filed by HCX Global Pvt. Ltd., the values reported by HCX Global Pvt. Ltd. shall have precedence over the tax returns filed by the Customers / Vendors.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        Any discrepancy identified at the time of reconciliation by the Customers / Vendors should be brought to the attention of HCX Global Pvt. Ltd. within a period of fifteen (15) days from the date of receipt of the TCS certificate. Any delay on this account will relieve HCX Global Pvt. Ltd. of any obligation to undertake corrective action to resolve the discrepancy. Under no circumstances shall HCX Global Pvt. Ltd. entertain any discrepancy in the reported value after 31st July of the year following the financial year to which the transactions were undertaken and reported.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                        Customers / Vendors agree and acknowledge that HCX Global Pvt. Ltd. shall not be held liable in any manner in the event the Customers / Vendors does not receive the benefit of TCS due to incorrect particulars provided by the Customers / Vendors to HCX Global Pvt. Ltd.
                                    </Typography>
                                </li>
                            </ol>
                        </Typography>
                    </li>

                </ol>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>10. Effect of Termination</Typography>
                <Typography variant="body2">In case of termination of this arrangement, all pending undisputed Invoices will be paid by the Customers / Vendors to HCX Global Pvt. Ltd., within a period of seven (7) days from the date of such termination, failing which, HCX Global Pvt. Ltd. will levy an interest at the rate of 18% per annum for each day of overdue till the time the Customers / Vendors clears its outstanding dues in addition to taking appropriate legal action against the Customers / Vendors that may be available to HCX Global Pvt. Ltd. under these Terms or as per applicable laws.. </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>11. Ownership of HCX Global Pvt. Ltd. Content and Proprietary Rights:</Typography>

                <ol>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            HCX Global Pvt. Ltd. solely and exclusively owns the HCX Global Pvt. Ltd. Content and all the copyrights, trademarks, service marks, logos, trade names, distinctive brand features, and other intellectual and proprietary rights throughout the world associated with the Horeca1 Platform and HCX Global Pvt. Ltd. Content (the 'IP Rights'), which may be protected by applicable intellectual property and proprietary rights and laws. The Customers / Vendors acknowledges that the Horeca1 Platform contains original works and has been developed, compiled, prepared, revised, selected, and arranged by HCX Global Pvt. Ltd. through the application of methods and standards of judgment developed and applied through the expenditure of substantial time, effort, and money, and constitutes the valuable intellectual property of HCX Global Pvt. Ltd..
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Customers / Vendors agrees to protect HCX Global Pvt. Ltd.'s IP Rights and the proprietary rights of all others having rights in the Horeca1 Platform during and after the Term and to comply with all reasonable written requests made by HCX Global Pvt. Ltd. or its suppliers and licensors of content or otherwise to protect their and others' contractual, statutory, and common law rights in the Horeca1 Platform. Customers / Vendors acknowledges and agrees that HCX Global Pvt. Ltd. (or HCX Global Pvt. Ltd.'s licensors) own all legal rights, titles, and interests in and to Horeca1 Platform, including any IP Rights which subsist in the Horeca1 Platform (whether those rights happen to be registered or not, and wherever in the world those rights may exist). Unless the Customers / Vendors has agreed otherwise in writing with HCX Global Pvt. Ltd., nothing in these Terms gives Customers / Vendors a right to use any of HCX Global Pvt. Ltd.'s IP Rights.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                            Customers / Vendors agrees not to use any framing techniques to enclose any trademark or logo or other proprietary information of HCX Global Pvt. Ltd.; or remove, conceal, or obliterate any copyright or other proprietary notice or source identifier, including without limitation, the size, color, location, or style of any IP Rights and/or any other proprietary mark(s). Any infringement will lead to appropriate legal proceedings against the Customers / Vendors at the appropriate forum for seeking all available/possible remedies under applicable laws of the country. Customers / Vendors cannot modify, reproduce, publicly display or exploit in any form or manner whatsoever any of HCX Global Pvt. Ltd. Content in whole or in part except as expressly authorized by HCX Global Pvt. Ltd..
                        </Typography>
                    </li>
                </ol>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    12. Confidentiality
                </Typography>
                <Typography variant="body2">
                    Parties shall strictly protect the confidentiality of all information related to the other party, its affiliates, customers, or customers of affiliates including information relating to business plans, formulations, suppliers or Customers / Vendors, product details, packaging information, commercial details, and the facts and contents of these Terms, disclosed by either party or becoming known to either party during the term of these Terms, whether or not in writing and whether or not designated to be confidential ('Confidential Information'). Confidential Information does not include information that is or was, at the time of the disclosure:
                </Typography>
                <ol>
                    <li>
                        <Typography variant="body2">
                            Generally known or available to the public;
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            Received by the receiving Party from a third party;
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            Already in receiving Party’s possession prior to the date of receipt from disclosing Party; or
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            Independently developed by the receiving Party without the use of the other Party’s Confidential Information.
                        </Typography>
                    </li>
                </ol>
                <Typography variant="body2">
                    Customers / Vendors will immediately notify HCX Global Pvt. Ltd. if it becomes aware of or suspects any unauthorized use or access to the data or any other Confidential Information of HCX Global Pvt. Ltd., and shall cooperate with HCX Global Pvt. Ltd. in investigation of such breach and the mitigation of any damage
                </Typography>
            </Box>

            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>13. Disclaimers</Typography>
                <Typography variant="body2">To the fullest extent permitted by law, HCX Global Pvt. Ltd. and its affiliates, and each of their respective officers, directors, members, employees, and agents disclaim all warranties, express or implied, in connection with the Form, these Terms, and the Horeca1 Platform, and any use thereof, including, without limitation, the implied warranties of Customers / Vendorsability, fitness for a particular purpose and non-infringement. HCX Global Pvt. Ltd. makes no warranties or representations about the accuracy or completeness of the content and data on Horeca1 Platform or the HCX Global Pvt. Ltd. content or the content of any other websites linked to the website, and assumes no liability or responsibility for any (a) errors, mistakes, or inaccuracies of content and materials, (b) personal injury or property damage, of any nature whatsoever, resulting from the Customers / Vendors's access to and use of Horeca1 Platform, (c) any unauthorized access to or use of HCX Global Pvt. Ltd.'s servers and/or any and all personal information and/or financial information stored therein, (d) any interruption or cessation of transmission to or from the Horeca1 Platform (e) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the Horeca1 Platform by any third party, and/or (f) any errors or omissions in any content and materials or for any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via the Horeca1 Platform. </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>14. Indemnity</Typography>
                <Typography variant="body2">The Customers / Vendors hereby undertakes to indemnify, hold harmless, and defend HCX Global Pvt. Ltd. against any and all claims, costs, damages and losses that may arise on account of breach of any Customers / Vendors obligation and/or any act or omission of the Customers / Vendors or its employees, provided such claim, cost, damage, or loss has not resulted from any gross negligence or wilful misconduct directly and solely attributable to HCX Global Pvt. Ltd.. </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>15. Limitation of Liability
                </Typography>
                <Typography variant="body2">Under no circumstances shall either party be liable for incidental, special, or consequential damages (however arising), including, but not limited to, loss of profit, loss of use or loss of revenue, or damage to business or reputation arising hereunder, whether or not such Party has been made aware of the possibility of such loss.
                    Notwithstanding anything contrary contained in these Terms, the maximum liability of HCX Global Pvt. Ltd. under this Form shall not exceed the total value of the Order under which the claim arose. </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    16.Notices
                </Typography>
                <Typography variant="body2" >
                    All notices will be given in writing by registered post, hand delivery, or by way of email to the following addresses:
                </Typography>
                <Typography variant="body2" >
                    If to HCX Global Pvt. Ltd.:
                </Typography>
                <Typography variant="body2">
                    <Typography component="span" >Attention of:</Typography> Director
                </Typography>
                <Typography variant="body2" >
                    <Typography component="span" >Postal Address:</Typography>
                    <br />
                    C-003, Station Complex, Sanpada, Navi Mumbai - 400705
                </Typography>
                <Typography variant="body2">
                    <Typography component="span" >Email Address:</Typography>
                    <br />
                    sales@horeca1.com
                </Typography>
                <Typography variant="body2" >
                    If to the Customers / Vendors:
                </Typography>
                <Typography variant="body2">
                    To the email ID and address provided in the Form.
                </Typography>
                <Typography variant="body2">
                    The notices shall be deemed to be received on the date of receipt if by registered post, on the date of delivery, if by hand delivery, and at the time the email is received in the inbox of the addressee, if by email.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    17.Governing Law
                </Typography>
                <Typography variant="body2">
                    The Form and/or these Terms shall be governed by, and construed in accordance with, the laws of India, without regard to the principles of conflict of laws. The Parties hereto consent to and agree to submit to the exclusive jurisdiction of the courts of New Delhi for any action or proceeding arising under or by reason of the Form and/or these Terms and to the venue of such action or proceeding in such courts.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    18.Severability
                </Typography>
                <Typography variant="body2">
                    If any part or any provision of the Form and/or these Terms is or becomes illegal, invalid, or unenforceable, that part or provision shall be ineffective to the extent of such invalidity or unenforceability only, without in any way affecting the validity or enforceability of the remaining parts of said provision or the remaining provisions of Form and/or these Terms. The Parties hereby agree to attempt to substitute any invalid or unenforceable provision with a valid or enforceable provision, which achieves to the greatest extent possible the economic, legal, and commercial objectives of the invalid or unenforceable provision.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    19.Waiver
                </Typography>
                <Typography variant="body2">
                    Each Party agrees that any delay or omission on the part of the other party to exercise any right, power, or remedy under the Form and/or these Terms will not automatically operate as a waiver of such right, power, or remedy or any other rights, powers, or remedies and no waiver will be effective unless it is in writing and delivered to the other party in the manner. Further, the waiver or the single or partial exercise of any right, power, or remedy by either party hereunder on one occasion shall not be construed as a bar to a waiver of any successive or other rights, powers, or remedies on any other occasion.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    20.Entire Agreement
                </Typography>
                <Typography variant="body2">
                    Form and/or these Terms, together with the other documents specifically attached to the Form or referred to herein, constitutes the entire agreement between the Parties with respect to the subject matter hereof, and supersedes all prior understandings, promises, representations, agreements, and negotiations between the Parties, oral or written. These Terms are an electronic record and do not require any physical or digital signature, as per the Information Technology Act, 2000.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    21.No Third Party Rights
                </Typography>
                <Typography variant="body2">
                    No term of the Form and these Terms shall be enforceable by a third party.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    22.No Assignment
                </Typography>
                <Typography variant="body2">
                    The Customers/Vendors must not assign, transfer, charge or otherwise encumber, create any trust over, or deal in any manner with the Form or any right, benefit, or interest under it, nor transfer, novate, or sub-contract any of the Customers/Vendors’ obligations under it.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    23.Independent Contractors
                </Typography>
                <Typography variant="body2">
                    The Form does not create any agency, employment, partnership, joint venture, or other joint relationship. HCX Global Pvt. Ltd. and the Customers/Vendors are independent contractors, and neither has any authority to bind the other.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    24.Acceptance to HCX Global Pvt. Ltd.’s Privacy Policy
                </Typography>
                <Typography variant="body2">
                    By signing the Form, the Customers/Vendors acknowledge and agree to be bound by HCX Global Pvt. Ltd.’s privacy policy. The Customers/Vendors agree that all communications, including telephonic communications, between HCX Global Pvt. Ltd. and the Customers/Vendors may be recorded.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    25.Modification
                </Typography>
                <Typography variant="body2">
                    HCX Global Pvt. Ltd. may modify these Terms, including upgrading, modifying, or replacing the Services from time to time, and any such changes will (i) be reflected on the Horeca1 Platform and the Website, and (ii) be effective immediately upon the changes being reflected on the Horeca1 Platform and the Website. The Customers/Vendors agree to be bound by any such changes or modifications and understand and accept the importance of regularly reviewing these Terms as updated on the Horeca1 Platform and the Website from time to time.
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    26.Loans through Third Parties for Orders
                </Typography>
                <Typography variant="body2">
                    If the Customers/Vendors use any third-party credit facility, whether directly made available by any bank or financial institution either directly or through a lending service provider (“Lender”) for the purchase of any Goods on the Horeca1 Platform (“Loan”), the Customers/Vendors acknowledge and agree that:
                </Typography>
                <Typography variant="body2" component="ol" sx={{ pl: 2, mt: 1 }}>
                    <li>The Customers/Vendors shall be solely responsible for the repayment of the Loan at all times (including in case of termination of the arrangement, if any, between HCX Global Pvt. Ltd. and such bank/financial institution/lending service provider);</li>
                    <li>The Customers/Vendors shall deal with the lending service provider and/or their lending partners directly for all queries/grievances in relation to the Loan;</li>
                    <li>If any Loan is availed by the Customers/Vendors, and the Customers/Vendors default in their repayment obligations in relation to such Loan as communicated by the Lender or its representatives, HCX Global Pvt. Ltd. may, in its sole discretion, restrict the Customers/Vendors’ ability to make further purchases on the Horeca1 Platform. HCX Global Pvt. Ltd. shall not be responsible or held liable in any manner whatsoever for the same;</li>
                    <li>HCX Global Pvt. Ltd. and its Vendors are not and shall not be deemed to be a lender, a digital lending application or a lending service provider with respect to the Loan, as per the Guidelines on Digital Lending dated September 2, 2022, as amended from time to time.</li>
                </Typography>

            </Box>















        </Box>
    );
}

export default TermsComponent;
