import { Box, Container, FormControlLabel, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Iconify from '../../components/common/Iconify';
import CustomDatePicker from '../../components/common/CustomDatePicker';
import { useRouter } from '../../routes/hooks/useRouter';
import { setInvoiceListEndDateFilter, setInvoiceListSearch, setInvoiceListStartDateFilter } from '../../redux/slices/orderSlice';
import CustomMenu from '../order/common/CustomMenu';
import { AntSwitch } from '../order/common/AntSwitch';
import { formatDateAndTime, showMaxFloat } from '../../utils/formatNumbers';
import VendorLogoImageComponent from '../../components/common/VendorLogoImageComponent';
import { horecaApi, useGetInvoiceListMutation, useGetVendorsQuery } from '../../redux/api/api';
import CustomeSelectMenu from '../../components/common/CustomeSelectMenu';
import PageHeader from '../../components/common/PageHeader';
import CustomSearchbar from '../../components/common/CustomSearchbar';
import CustomStatusIcon from '../order/common/CustomStatusIcon';
import { capitalizeFirstLetter } from '../../utils/stringFormating';
import TableNoData from '../../views/list/table-no-data';
import TableLoadingData from '../../components/common/TableLoadingData';
import { base64ToArrayBuffer } from '../../utils/base64toArray';
import { openMessage } from '../../redux/slices/showMessageSlice';
import { setCurrSelectedPage, setRowsPerPage } from '../../redux/slices/appReducer';
import useResponsiveStyles from '../../hooks/useResponsiveStyles';

export function createVendorList(data) {
  const vendorsList = [];
  data.forEach(vendor => {
    vendorsList.push({
      id: vendor?.vendorDetails?.id,
      label: vendor?.vendorDetails?.orgName
    })
  });

  return vendorsList;
}


export const downloadPDF = (pdfData) => {

  var byteArray = base64ToArrayBuffer(pdfData);
  var a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(
    new Blob([byteArray], { type: "application/octet-stream" })
  );

  a.download = "invoice";

  document.body.appendChild(a);

  a.click();
};

export default function Invoices() {
  const { orgId } = useParams();
  const router = useRouter();
  const dispatch = useDispatch()
  const { isMobile } = useResponsiveStyles();
  const [invoiceList, setInvoiceList] = useState([])
  const [vendorsList, setVendorsList] = useState([])
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('placedDate');
  // const [page, setPage] = useState(0);
  const page = useSelector((state) => state.app.currSelectedPage.invoicePage)

  const [dense, setDense] = useState(false);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsPerPage = useSelector((state) => state.app.rowsPerPageData.invoicePage)

  const [vendorFilter, setVendorFilter] = useState([])

  const invoiceSearch = useSelector((state) => state.order.invoices.invoicesFilter.invoiceSearch)
  const invoiceDateFilter = useSelector((state) => state.order.invoices.invoicesFilter.dateFilter)

  const [invoiceId, setInvoiceId] = useState(null)
  const [orderNumber, setOrderNumber] = useState(null)

  function createInvoiceList(data) {
    const invoiceList = [];
    data.forEach(invoice => {
      invoiceList.push({
        invoiceId: invoice?.id,
        invoiceNo: invoice?.invoiceId,
        orderId: invoice?.orderId,
        vendorLogo: invoice?.orderDetails?.vendorDetail?.orgLogoKey,
        vendorName: invoice?.orderDetails?.vendorName,
        vendorAddress: invoice?.orderDetails?.vendorDetail ? invoice?.orderDetails?.vendorDetail?.addresses?.find(address => address?.billing === null) : null,
        placedDate: invoice?.createdAt,
        amount: invoice?.orderDetails?.totalPrice,
        invoiceStatus: invoice?.status
      })
    });

    return invoiceList;
  }

  const { data: getMyVendorsResponse } = useGetVendorsQuery(orgId)

  useEffect(() => {
    if (getMyVendorsResponse) {
      setVendorsList(createVendorList(getMyVendorsResponse))
    }
  }, [getMyVendorsResponse])

  const [downloadInvoice] = horecaApi.endpoints.downloadInvoice.useLazyQuery();

  const [getInvoiceList, { data: getInvoiceListResponse, isLoading: getInvoiceListLoading }] = useGetInvoiceListMutation();

  useEffect(() => {
    (
      async () => {
        await getInvoiceList({
          orgId, payload: {
            searchText: invoiceSearch,
            sortOrder: order.toUpperCase(),
            page: page,
            rowsPerPage: rowsPerPage,
            startDate: invoiceDateFilter?.startDate,
            endDate: invoiceDateFilter?.endDate,
            vendors: vendorFilter
          }
        });
      }
    )()
  }, [invoiceSearch, order, page, rowsPerPage, invoiceDateFilter, vendorFilter])

  useEffect(() => {
    if (getInvoiceListResponse) {
      setInvoiceList(createInvoiceList(getInvoiceListResponse?.invoiceDetails))
    }
  }, [getInvoiceListResponse])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    dispatch(setCurrSelectedPage({ name: 'invoicePage', value: newPage }))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage({ name: 'invoicePage', value: parseInt(event.target.value, 10) }));
    // setPage(0);
    dispatch(setCurrSelectedPage({ name: 'invoicePage', value: 0 }))
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = (event, invoiceId, orderNumber) => {
    setInvoiceId(invoiceId)
    setOrderNumber(orderNumber)
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleViewInvoice = () => {
    if (invoiceId) {
      router.push(`/org/${orgId}/invoice/${invoiceId}`);
    }
    setAnchorEl(null);
    setOpenMenu(false);
  }


  const handleDownloadInvoice = async () => {
    const result = await downloadInvoice(orderNumber);
    if (result?.data) {
      downloadPDF(result?.data?.img);
      dispatch(openMessage({ message: 'Invoice downloaded!', messageSeverity: 'success' }));
    } else if (result?.error) {
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
    } else {
      dispatch(openMessage({ message: 'Not able to download an invoice', messageSeverity: 'error' }));
    }
  }

  const handleInvoiceDownload = () => {
    handleDownloadInvoice();
    setAnchorEl(null);
    setOpenMenu(false);
  }

  const menuItems = [
    {
      id: "view",
      label: "View",
      icon: <Iconify width={20} icon="carbon:view-filled" />,
      handler: handleViewInvoice
    },
    {
      id: "download",
      label: "Download",
      icon: <Iconify width={22} icon="eva:cloud-download-fill" />,
      handler: handleInvoiceDownload
    }
  ]

  return (
    <>
      <Container maxWidth='xl'>
        <PageHeader
          title={"Invoices"}
          // linkArray={[
          //   { title: "Dashboard", path: `/` },
          //   { title: "Invoices", },
          // ]}
        />
        <Box component={Paper} >
          <CustomMenu anchorEl={anchorEl} open={openMenu} onClose={handleClose} menuItems={menuItems} />
          <Stack spacing={isMobile ? 1 : 2}
            padding={isMobile ? 0 : 2}
            width={"100%"}
            direction={{
              xs: "column",
              sm: "row"
            }}
          >
            <CustomeSelectMenu label={"Vendors"} noDataMsg={"No Vendors"} options={vendorsList} optionFilter={vendorFilter} setOptionFilter={setVendorFilter} width={{ xs: "100%", sm: "40%", md: "30%" }} />
            {
              isMobile ?
                <Box sx={{ display: 'flex', gap:1 }}>
                  <CustomDatePicker label={"Start date"} setDate={(date) => dispatch(setInvoiceListStartDateFilter(date))} maxDate={invoiceDateFilter.endDate} sx={{ width: { xs: "100%", sm: "40%", md: "30%" } }} />
                  <CustomDatePicker label={"End date"} setDate={(date) => dispatch(setInvoiceListEndDateFilter(date))} minDate={invoiceDateFilter.startDate} sx={{ width: { xs: "100%", sm: "40%", md: "30%" } }} />
                </Box> :
                <Box sx={{ display: 'flex', width:'60%', gap:1 }}>
                  <CustomDatePicker label={"Start date"} setDate={(date) => dispatch(setInvoiceListStartDateFilter(date))} maxDate={invoiceDateFilter.endDate} sx={{ width: { xs: "100%", sm: "40%", md: "50%" } }} />
                  <CustomDatePicker label={"End date"} setDate={(date) => dispatch(setInvoiceListEndDateFilter(date))} minDate={invoiceDateFilter.startDate} sx={{ width: { xs: "100%", sm: "40%", md: "50%" } }} />
                </Box>
            }
            <CustomSearchbar
              placeholder={"Search..."}
              text={invoiceSearch}
              handleTextChange={(e) => dispatch(setInvoiceListSearch(e.currentTarget.value))}
              handleTextClear={(e) => dispatch(setInvoiceListSearch(""))}
            />
          </Stack>
          <TableContainer >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              stickyHeader
            >
              <TableHead >
                <TableCell padding='checkbox' sx={{ whiteSpace: "nowrap", padding: "0px 16px" }} >Invoice No</TableCell>
                <TableCell />
                <TableCell sx={{ whiteSpace: "nowrap" }}>Vendor</TableCell>
                <TableCell >
                  <TableSortLabel
                    active
                    direction={order ?? 'asc'}
                    onClick={createSortHandler("placedDate")}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell >Amount</TableCell>
                <TableCell >Status</TableCell>
                <TableCell />
              </TableHead>
              <TableBody>
                {getInvoiceListLoading ?
                  <TableLoadingData colSpan={7} /> :
                  invoiceList?.length > 0 ? (
                    invoiceList?.map((invoice, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell padding='checkbox' sx={{ whiteSpace: "nowrap", padding: "0px 16px" }}>{invoice?.invoiceNo}</TableCell>
                          <TableCell padding='checkbox'>
                            <VendorLogoImageComponent s3Key={invoice?.vendorLogo} name={invoice?.vendorName} />
                          </TableCell>
                          <TableCell >
                            <Grid item >
                              <Typography variant={"body2"} noWrap>{invoice?.vendorName}</Typography>
                              <Typography sx={{ color: "text.disabled" }} variant={"body2"} noWrap >
                                {invoice?.vendorAddress?.pincode ? `${invoice?.vendorAddress?.landmark ? invoice?.vendorAddress?.landmark + ", " : ""}${invoice?.vendorAddress?.line1 ? invoice?.vendorAddress?.line1 + ", " : ""}${invoice?.vendorAddress?.line2 ? invoice?.vendorAddress?.line2 + ", " : ""}${invoice?.vendorAddress?.city ? invoice?.vendorAddress?.city + ", " : ""}${invoice?.vendorAddress?.state ? invoice?.vendorAddress?.state + ", " : ""}${invoice?.vendorAddress?.pincode ?? ""}` : ""}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell  >
                            <Grid item>
                              <Typography variant={"body2"} noWrap >
                                {formatDateAndTime(invoice?.placedDate).formattedDateNew}
                              </Typography>
                              <Typography sx={{ color: "text.disabled" }} variant={"body2"} noWrap >
                                {formatDateAndTime(invoice?.placedDate).formattedTimeNew}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }} >
                            {invoice?.amount ? `₹${showMaxFloat(invoice?.amount)}` : "N/A"}
                          </TableCell>
                          <TableCell>
                            {invoice?.invoiceStatus && (
                              <CustomStatusIcon
                                color={capitalizeFirstLetter(invoice?.invoiceStatus) === "Paid" ? "rgb(17, 141, 87)" : "rgb(183, 110, 0)"}
                                backgroundColor={capitalizeFirstLetter(invoice?.invoiceStatus) === "Paid" ? "rgba(34, 197, 94, 0.16)" : "rgba(255, 171, 0, 0.16)"}
                                value={capitalizeFirstLetter(invoice?.invoiceStatus)}
                              />
                            )}
                          </TableCell>
                          <TableCell padding='checkbox'>
                            <IconButton onClick={(e) => handleClick(e, invoice?.invoiceId, invoice?.orderId)}>
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableNoData query={invoiceSearch} colSpan={7} />
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingLeft={"24px"}
          >
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <AntSwitch
                    checked={dense}
                    onChange={handleChangeDense}
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                }
              />
              <Typography variant={"body2"} noWrap>Dense</Typography>
            </Box>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={getInvoiceListResponse?.count ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                '& .MuiTablePagination-selectLabel': {
                  display: {
                    xs: "none",
                    sm: "block"
                  }
                }
              }}
            />
          </Stack>
        </Box >
      </Container>
    </>
  );
}