import { useEffect, useMemo, useState } from "react";
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import CustomLabel from '../../components/common/label/CustomLabel';
import { Box, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import moment from "moment";
import BorderLinearProgress from "../../components/common/BorderLinearProgress";
import ItemsImageComponent from "../../components/common/ItemsImageComponent";
import VendorLogoImageComponent from "../../components/common/VendorLogoImageComponent";
import { getStatusColor } from "../../pages/system-admin/customer/user-table-row";
import { paymentMode } from "../../constants/constants";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

const CustomTableCell = (props) => {
    const { headerKey, valueObj, onPriceChange, selectedId, rowData, setOpenDelivery } = props;

    const [valueToRender, setValueToRender] = useState();
    const [price, setPrice] = useState(valueObj?.value);


    const handleChange = (value) => {
        setPrice(value);
        if (onPriceChange) {
            onPriceChange(value, selectedId);
        }
    };

    const deliveryToBeShown = useMemo(() => {
        const statusObj = rowData.find((_obj) => _obj.headerKey == 'status');
        if (statusObj) {
            return ['confirmed', 'packed', 'shipped'].includes(statusObj.valueObj.value);
        }
        return false
    }, [rowData]);

    useEffect(() => {
        if (headerKey && valueObj) {
            switch (headerKey) {
                case 'order':
                    setValueToRender(<TableCell padding="checkbox" sx={{ whiteSpace: "nowrap", padding: "16px" }}>#{valueObj.value}</TableCell>);
                    break;
                case 'customer':
                    setValueToRender(
                        <TableCell component="th" scope="row" padding="none" sx={{ pl: 2 }}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <VendorLogoImageComponent s3Key={valueObj?.avatarUrl} name={valueObj?.value} /> */}
                                <Box>
                                    <Typography sx={{ fontSize: 14 }} noWrap>
                                        {valueObj.value}
                                    </Typography>
                                    <Typography sx={{ fontSize: 13, color: 'grey' }} noWrap>
                                        {valueObj.customerEmail}
                                    </Typography>
                                </Box>
                                {deliveryToBeShown && <Tooltip title={'Mark order as delivered'}><IconButton onClick={() => { setOpenDelivery(true) }}><DeliveryDiningIcon color="error" /></IconButton></Tooltip>}
                            </Stack>
                        </TableCell>
                    );
                    break;
                case 'vendor':
                    setValueToRender(
                        <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <VendorLogoImageComponent s3Key={valueObj?.avatarUrl} name={valueObj?.value} />
                                <Box>
                                    <Typography sx={{ fontSize: 14 }} noWrap>
                                        {valueObj.value}
                                    </Typography>
                                    <Typography sx={{ fontSize: 13, color: 'grey' }} noWrap>
                                        {valueObj.customerEmail}
                                    </Typography>
                                </Box>
                            </Stack>
                        </TableCell>
                    );
                    break;
                case 'product':
                    setValueToRender(
                        <TableCell>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <ItemsImageComponent s3key={valueObj.imageS3Key} name={valueObj.value} />
                                <Box>
                                    <Typography sx={{ fontSize: 14 }} noWrap>
                                        {valueObj.value}
                                    </Typography>
                                    <Typography sx={{ fontSize: 13, color: 'grey' }} noWrap>
                                        {valueObj.productType}
                                    </Typography>
                                </Box>
                            </Stack>
                        </TableCell>
                    );
                    break;
                case 'date':
                    setValueToRender(
                        <TableCell>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: 14 }}>
                                    {moment(valueObj.value, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY')}
                                </Typography>
                                <Typography sx={{ fontSize: 13, color: 'grey' }}>
                                    {moment(valueObj.value, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A')}
                                </Typography>
                            </Box>
                        </TableCell>
                    );
                    break;
                case 'items':
                    setValueToRender(<TableCell align='center'>{valueObj.value}</TableCell>);
                    break;
                case 'price':
                    valueObj?.isTextField ?
                        setValueToRender(
                            <TextField
                                value={price}
                                variant="filled"
                                size="small"
                                sx={{ width: 150 }}
                                onChange={(e) => handleChange(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            Rs.
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )
                        :
                        setValueToRender(<TableCell align='left'>{valueObj.value}</TableCell>);
                    break;
                case 'stock':
                    setValueToRender(
                        <TableCell>
                            <Stack spacing={1} sx={{ width: '60%' }}>
                                <BorderLinearProgress variant="determinate" value={valueObj.value === 'out of stock' ? 0 : valueObj.value === '72 in stock' ? 50 : 10} />
                                <Typography sx={{ color: '#637381', fontSize: 12 }}>{valueObj.value}</Typography>
                            </Stack>
                        </TableCell>
                    );
                    break;
                case 'publish':
                    setValueToRender(
                        <TableCell>
                            <CustomLabel color={getStatusColor(valueObj.value)}>{valueObj.value}</CustomLabel>
                        </TableCell>
                    );
                    break;
                case 'status':
                    setValueToRender(
                        <TableCell>
                            <CustomLabel color={getStatusColor(valueObj.value)}>{valueObj.value}</CustomLabel>
                        </TableCell>
                    );
                    break;
                case 'paymentMode':
                    setValueToRender(
                        <TableCell>
                            <Typography variant="body2">{paymentMode[valueObj.value]}</Typography>
                        </TableCell>
                    );
                    break;
                default:
                    setValueToRender(<></>);
            }
        }
    }, [headerKey, valueObj]);

    return <>{valueToRender}</>;
}

export default CustomTableCell;
