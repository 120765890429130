import { Card, Container, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PageHeader from "../../components/common/PageHeader";
import { horecaApi, useGetSuitableVendorListMutation, useUpdateOrderListItemsMutation, useUpdateOrderListNameMutation } from "../../redux/api/api";
import { useParams, useSearchParams } from "react-router-dom";
import { useRouter } from "../../routes/hooks/useRouter";
import { useState } from "react";
import { LeftSide, RightSide, SelectItemToolBar } from "./ExploreItems";
import { editOrderList, setOrderListName, updateOrderList } from "../../redux/slices/orderListSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/common/CustomButton";
import ReviewOrderListDialogue from "./ReviewOrderListDialogue";
import CreateOrderListDialogue from "./CreateOrderListDialogue";
import { openMessage } from "../../redux/slices/showMessageSlice";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import { OrderListNameDialogue } from "../instant-order/InstantOrderCart";

const EditOrderList = () => {
  const router = useRouter();
  const { orgId, orderListId, vendorId } = useParams();

  const [getOrderListById, { data: orderListData }] = horecaApi.endpoints.getOrderListById.useLazyQuery();

  const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
  const [getSuitableVendorList, { data: suitableVendors }] = useGetSuitableVendorListMutation();

  const [getVendorItems] = horecaApi.endpoints.getVendorItems.useLazyQuery();

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState("");

  const [updateOrderListItems, { isLoading: isOrderListLoading }] = useUpdateOrderListItemsMutation();

  const [isShowMessage, setIsShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  const items = useSelector((state) => state.orderList.currItems);

  const categoryId = searchParams.get("categoryId");
  const subCategoryId = searchParams.get("subCategoryId");

  const selectedItems = useSelector((state) => state.orderList.editOrderList);

  const [isSaveProceed, setIsSaveProceed] = useState(false);

  const [vendors, setVendors] = useState();

  useEffect(() => {
    (async () => {
      await getOrderListById({ orgId, orderListId });
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      await getVendorItems({ customerOrgId: orgId, vendorOrgId: vendorId, categoryId, subCategoryId });
    })();
  }, [vendorId, categoryId, subCategoryId]);

  React.useEffect(() => {
    (async () => {
      const result = await getSuitableVendorList({
        orgId,
        payload: {
          vendorOnly: "true",
          categories: [],
          subCategories: [],
          vendorOrgId: vendorId,
          singleVendor: true,
        },
      });

      if (result.data) {
        setVendors(result?.data);
        if (result?.data[0]?.categoriesTobeSelected[0]?.subCategories?.length > 0) {
          setSearchParams({
            vendorId: result?.data[0]?.vendorDetails?.id,
            categoryId: result?.data[0]?.categoriesTobeSelected[0]?.id,
            subCategoryId: result?.data[0]?.categoriesTobeSelected[0]?.subCategories[0]?.id,
          });
        } else {
          setSearchParams({
            vendorId: result?.data[0]?.vendorDetails?.id,
            categoryId: result?.data[0]?.categoriesTobeSelected[0]?.id,
          });
        }
      }
    })();
  }, [pincode]);

  const goBack = () => {
    router.push(`/org/${orgId}/orders`);
  };

  const [orderListDialogue, setOrderListDialogue] = useState(false);

  const handleOpenFilter = () => {};

  const handleSaveAndProceed = () => {
    setIsSaveProceed(true);
  };

  const getVendorItemsCount = (vendorId) => {
    const items = selectedItems.find((vendor) => vendor.vendorId == vendorId)?.items;

    return items?.length;
  };

  const totalItemsCount = selectedItems.reduce((totalCount, vendor) => {
    // Sum up the count of items for each vendor
    return totalCount + (vendor.items ? vendor.items.length : 0);
  }, 0);

  const handelSetCurrVendor = () => {};

  const handleAddRemoveItem = (itemId, isChecked, item1) => {
    const vendorIndex = selectedItems.findIndex((vendor) => vendor.vendorId == parseInt(vendorId));

    if (isChecked) {
      if (vendorIndex !== -1) {
        // If vendor exists, add item to its items array
        const updatedOrderList = [...selectedItems];
        updatedOrderList[vendorIndex] = {
          ...updatedOrderList[vendorIndex],
          items: [...updatedOrderList[vendorIndex].items, { item: item1 }],
        };
        dispatch(editOrderList(updatedOrderList));
      } else {
        // If vendor doesn't exist, create new one and add the item
        const vendorName = suitableVendors?.find((vendor) => vendor?.vendorDetails.id == vendorId)?.vendorDetails?.org_name;
        const newVendor = { vendorId: vendorId, name: vendorName, items: [{ item: item1 }] };
        const updatedOrderList = [...selectedItems, newVendor];
        dispatch(editOrderList(updatedOrderList));
      }
    } else {
      if (vendorIndex !== -1) {
        // If vendor exists, remove the item from its items array
        const updatedOrderList = [...selectedItems];
        updatedOrderList[vendorIndex] = {
          ...updatedOrderList[vendorIndex],
          items: updatedOrderList[vendorIndex].items.filter((item) => item.item.id !== item1.id), // Ensure that items array is a new array
        };
        // If the vendor has no more items, remove the vendor
        if (updatedOrderList[vendorIndex].items.length === 0) {
          updatedOrderList.splice(vendorIndex, 1);
        }
        dispatch(editOrderList(updatedOrderList));
      }
    }
  };

  const handelRemoveItem = (itemId) => {
    const vendorIndex = selectedItems?.findIndex((vendor) => vendor.vendorId == vendorId);
    if (vendorIndex !== -1) {
      // If vendor exists, remove the item from its items array
      const updatedOrderList = [...selectedItems];
      updatedOrderList[vendorIndex] = {
        ...updatedOrderList[vendorIndex],
        items: updatedOrderList[vendorIndex].items.filter((item) => item.item.id !== itemId), // Ensure that items array is a new array
      };
      // If the vendor has no more items, remove the vendor
      if (updatedOrderList[vendorIndex].items.length === 0) {
        updatedOrderList.splice(vendorIndex, 1);
      }
      dispatch(editOrderList(updatedOrderList));
    }
  };

  const handleBack = () => {
    setIsSaveProceed(false);
  };

  const handleUpdateOrderList = async () => {
    setIsShowMessage(true);
    setMessage("We Are Processing Your Creation For Order List Please Hold On");

    const data1 = selectedItems.map((vendor) => ({
      customerOrgId: orgId,
      vendorOrgId: vendor.vendorId,
      items: vendor.items.map((item) => ({ item_id: item.item.item_id, inventry_item_id: item?.item?.id })),
    }));
    const result = await updateOrderListItems({ customerId: orgId, orderListId: orderListData?.id, payload: data1[0] });
    if (result?.data) {
      dispatch(openMessage({ message: "Order list Updated", messageSeverity: "success" }));
      setTimeout(() => {
        router.push(`/org/${orgId}/orders`);
        dispatch(updateOrderList([]));
        dispatch(setOrderListName(""));
        setOrderListDialogue(false);
        setIsSaveProceed(false);
      }, [2000]);
      setMessage("Your Order List Updated, Congratulations🎉🎉");
      setIsShowMessage(true);
    } else if (result?.error) {
      setIsShowMessage(false);
      dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
    } else {
      setIsShowMessage(false);
      dispatch(openMessage({ message: "There is some issue While creating an order list", messageSeverity: "error" }));
    }
  };

  const { isMobile } = useResponsiveStyles();

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(orderListData?.name);

  const handleClose = () => {
    setOpen(false);
  };

  const [updateOrderListName] = useUpdateOrderListNameMutation();

  const handleSaveAndProceed2 = async () => {
    const result = await updateOrderListName({ orderListId: orderListData?.id, payload: { name: name } });
    if (result?.data) {
      await getOrderListById({ orgId, orderListId });
      handleClose();
    }
  };

  const onEdit = () => {
    setOpen(true);
    setName(orderListData?.name);
  };

  return (
    <Container maxWidth="xl" sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <PageHeader handleBackClick={goBack} title={`Edit- ${orderListData?.name}`} isEdit={true} onEdit={onEdit} />
      <Card>
        {!isSaveProceed && (
          <SelectItemToolBar
            handleOpenFilter={handleOpenFilter}
            setSearchText1={setSearchText}
            searchText1={searchText}
            suitableVendorsCategories={vendors && vendors?.find((vendor) => vendor.vendorDetails.id === parseInt(vendorId))?.categoryDetails}
            itemsCount={selectedItems.length > 0 ? totalItemsCount : 0}
            isSaveProceed={isSaveProceed}
            handleSaveAndProceed={handleSaveAndProceed}
          />
        )}
        <Stack direction={isMobile ? "column" : "row"}>
          <Stack
            width={isMobile ? "100%" : "20%"}
            mt={isMobile && "1rem"}
            alignItems={"center"}
            sx={{
              borderRight: (theme) => !isMobile && `1px dashed ${theme.palette.grey[300]}`,
              borderBottom: (theme) => isMobile && `1px dashed ${theme.palette.grey[300]}`,
            }}
          >
            <LeftSide
              height={isMobile ? "20vh" : "60vh"}
              suitableVendors={vendors}
              selectedVendorId={parseInt(vendorId)}
              handelSetCurrVendor={handelSetCurrVendor}
              getVendorItemsCount={getVendorItemsCount}
              isSaveProceed={isSaveProceed}
            />
          </Stack>
          <Stack width={isMobile ? "100%" : "80%"} mt={isMobile && "1rem"}>
            {isSaveProceed ? (
              <RightSide
                isSaveProceed={isSaveProceed}
                handleOpenFilter={handleOpenFilter}
                items={selectedItems?.find((vendor) => vendor.vendorId == parseInt(vendorId))?.items}
                currVendorId={vendorId}
                suitableVendorsCategories={vendors && vendors?.find((vendor) => vendor.vendorDetails.id === parseInt(vendorId))?.categoryDetails}
                handelRemoveItem={handelRemoveItem}
              />
            ) : (
              <RightSide
                isSaveProceed={isSaveProceed}
                searchText={searchText}
                handleOpenFilter={handleOpenFilter}
                items={items}
                currVendorId={vendorId}
                suitableVendorsCategories={vendors && vendors?.find((vendor) => vendor?.vendorDetails?.id === parseInt(vendorId))?.categoryDetails}
                handleAddRemoveItem={handleAddRemoveItem}
                selectedItems={selectedItems}
              />
            )}
          </Stack>
        </Stack>
      </Card>
      <Stack direction={"row"} justifyContent={"center"} mt={"2rem"} gap={"1rem"}>
        {isSaveProceed ? (
          <>
            <CustomButton
              onClick={handleBack}
              variant={"contained"}
              label={"Back"}
              sx={{ textTransform: "capitalize" }}
              disabled={totalItemsCount > 0 ? false : true}
            />
            <CustomButton
              onClick={() => setOrderListDialogue(true)}
              variant={"contained"}
              label={"Save and proceed"}
              sx={{ textTransform: "capitalize" }}
              disabled={totalItemsCount > 0 ? false : true}
            />
          </>
        ) : (
          <CustomButton
            onClick={handleSaveAndProceed}
            variant={"contained"}
            label={"Review"}
            sx={{ textTransform: "capitalize" }}
            disabled={totalItemsCount > 0 ? false : true}
          />
        )}
      </Stack>

      <CreateOrderListDialogue
        message={message}
        orderListDialogue={orderListDialogue}
        setOrderListDialogue={setOrderListDialogue}
        handleCreateOrderList={handleUpdateOrderList}
        isOrderListLoading={isOrderListLoading}
        isShowMessage={isShowMessage}
      />
      <ReviewOrderListDialogue
        open={isSaveProceed}
        content={
          <>
            <Stack direction={isMobile ? "column" : "row"}>
              <Stack
                width={isMobile ? "100%" : "20%"}
                mt={isMobile && "1rem"}
                alignItems={"center"}
                sx={{
                  borderRight: (theme) => !isMobile && `1px dashed ${theme.palette.grey[300]}`,
                  borderBottom: (theme) => isMobile && `1px dashed ${theme.palette.grey[300]}`,
                }}
              >
                <LeftSide
                  height={isMobile ? "20vh" : "60vh"}
                  suitableVendors={vendors && vendors?.filter((vendor) => selectedItems?.some((item) => item?.vendorId == vendor?.vendorDetails?.id))}
                  selectedVendorId={parseInt(vendorId)}
                  getVendorItemsCount={getVendorItemsCount}
                  isSaveProceed={isSaveProceed}
                />
              </Stack>
              <Stack width={isMobile ? "100%" : "80%"} mt={isMobile && "1rem"}>
                <RightSide
                  isSaveProceed={isSaveProceed}
                  handleOpenFilter={handleOpenFilter}
                  items={selectedItems?.find((vendor) => vendor.vendorId == parseInt(vendorId))?.items}
                  currVendorId={vendorId}
                  suitableVendorsCategories={vendors && vendors?.find((vendor) => vendor.vendorDetails.id === parseInt(vendorId))?.categoryDetails}
                  handelRemoveItem={handelRemoveItem}
                />
              </Stack>
            </Stack>
            <Stack></Stack>
            <Stack direction={"row"} justifyContent={"center"} mt={"1rem"} gap={"1rem"}>
              <CustomButton
                onClick={handleUpdateOrderList}
                variant={"contained"}
                label={"Update Order List"}
                sx={{ textTransform: "capitalize" }}
                disabled={totalItemsCount > 0 ? false : true}
              />
            </Stack>
          </>
        }
        onClose={handleBack}
      />
      <OrderListNameDialogue
        name={name}
        setName={setName}
        onClose={handleClose}
        open={open}
        saveAndProceed={handleSaveAndProceed2}
        label={"Update"}
      />
    </Container>
  );
};

export default EditOrderList;
