import { format, getTime, formatDistanceToNow, parseISO } from 'date-fns';
import dayjs from 'dayjs';


export function fNumber(value) {
  return `₹${value}`
}


export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}


export function showMaxFloat(totalPrice) {
  let number = parseFloat(totalPrice);
  let formattedPrice = number.toFixed(2);
  return formattedPrice;
}

export function formatDateAndTime(inputDate) {
  // Parse the input date string
  let date = new Date(inputDate);

  // Format the date in dd/mm/yyyy format
  let formattedDate = format(date, 'dd/MM/yyyy');

  // Format the time in hh:mm:ss format
  let formattedTime = format(date, 'HH:mm:ss');

  let formattedDateNew = format(date, 'dd MMM yyyy');

  let formattedTimeNew = format(date, 'hh:mm a');

  return { formattedDate, formattedTime, formattedDateNew, formattedTimeNew }
}

export function convertDateFormat(inputData) {  
  const dayjsObject = dayjs(inputData)
  let formattedDate = dayjsObject.format("DD/MM/YYYY");
  return formattedDate;
}

export function getFormattedDate(date) {
  if (date) {
    return date?.format('DD/MM/YYYY')
  }
}


export function formatDate(timestamp) {
  // Check if timestamp is defined and not null
  if (!timestamp) {
    return 'Invalid Date';
  }

  // Manually parse the ISO 8601 timestamp string
  const date = new Date(timestamp);
  return format(date, 'dd/MM/yy hh:mm a');
}

export function dateFormatter(originalDate) {
  // const originalDate = '17/05/2024';
  if (!originalDate) {
    return 'Invalid Date';
  }
  const [day, month, year] = originalDate.split('/');
  const parsedDate = new Date(`${year}-${month}-${day}`);

  return format(parsedDate, 'dd MMM yyyy');
}