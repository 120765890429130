import React, { useState, useEffect, useRef } from "react";
import {
  Slide,
  Input,
  styled,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Card,
  Stack,
  Avatar,
  Typography,
  useTheme,
  Grid,
  Chip,
  Divider,
  OutlinedInput,
  Popover,
  MenuItem,
  alpha,
  Box,
  Tooltip
} from "@mui/material";
import Iconify from "../../components/common/Iconify";
import { bgBlur } from "../../theme/css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useRouter } from "../../routes/hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import { setIsSearch } from "../../redux/slices/appReducer";
import { setSelectedCategory } from "../../redux/slices/appReducer";
import { highlightText } from "../../utils/highlightedText";
import Zoom from "@mui/material/Zoom";
import { useGetSuitableVendorListMutation, useSavePreferncesMutation } from "../../redux/api/api";
import { setSelectedItemId, setSelectedVendorId, updateOrderList } from "../../redux/slices/orderListSlice";
import GetImageComponent from "../../components/common/GetImageComponent";
import { debounce } from "lodash";
import CustomButton from "../../components/common/CustomButton";
import useResponsiveStyles from "../../hooks/useResponsiveStyles";
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

export const StyledSearchbar = styled("div")(({ theme }) => ({
  ...bgBlur({
    color: theme.palette.background.default,
  }),
  top: 0,
  left: 0,
  zIndex: 99,
  // borderRadius: "16px",
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  // boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up("md")]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const SearchResultsCard = styled(Card)(({ theme, isMobile }) => ({
  ...bgBlur({
    color: theme.palette.background.default,
  }),
  position: "absolute",
  backgroundColor: theme.palette.paper,
  left: 0,
  zIndex: 98, // Ensure search results appear above background blur
  width: "100%",
  height: "100vh",
  overflow: "scroll",
  padding: isMobile ? "1rem" : "4rem 3rem 4rem 3rem",
  paddingTop: "3rem"
}));

//select category component
export const SelecteCategoriesComp = ({ index, cat, selectedCategories, handleClick1, handleDelete }) => {
  const theme = useTheme();

  const isSelected = selectedCategories?.includes(cat.id);
  const color = isSelected && alpha(theme.palette.primary.main, 0.7);
  return (
    <Chip
      key={index}
      avatar={
        <Avatar>
          <GetImageComponent s3Key={cat?.parent_category_image} />
        </Avatar>
      }
      label={cat?.parent_category}
      variant={isSelected ? "filled" : "outlined"}
      sx={{
        bgcolor: color,
      }}
      onClick={() => handleClick1(cat)}
      onDelete={isSelected ? () => handleDelete(cat.id) : undefined}
    />
  );
};

//search bar
const Searchbar = () => {
  const theme = useTheme();
  const router = useRouter();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allCategories = useSelector((state) => state.auth.allSystemCategory);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showAllVendors, setShowAllVendors] = useState(false);

  const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
  const [getSuitableVendorList, { data: suitableVendors }] = useGetSuitableVendorListMutation();

  const [savePrefernces] = useSavePreferncesMutation();

  const selectedItems = useSelector((state) => state.orderList.orderListCreation);

  const selectedCategories = useSelector((state) => state.app.preference.selectedCategories);

  const [selectedCat, setSelectedCat] = useState([...selectedCategories]);
  // const { orgId } = useParams();
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === "k") {
        // Check for Command (⌘) + K
        setOpen(true);
        dispatch(setIsSearch(true));
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    if (suitableVendors) {
      setVendors(suitableVendors);
    }
  }, [suitableVendors]);

  const debouncedSearch = useRef(
    debounce((searchText, selectedCat) => {
      getSuitableVendorList({
        orgId,
        payload: {
          vendorOnly: "false",
          categories: selectedCat,
          subCategories: [],
          singleVendor: false,
          pincode,
        },
        searchText,
      });
    }, 300)
  );

  useEffect(() => {
    return () => {
      // Cleanup function
      debouncedSearch.current.cancel();
    };
  }, [pincode]);

  useEffect(() => {
    // Call the debounced function when searchText or selectedCat changes
    open && debouncedSearch.current(searchText, selectedCat);
  }, [searchText, selectedCat, open]);

  const handleCancel = () => {
    setShowAllVendors(false);
    setOpen(false);
    dispatch(setIsSearch(false));
    setSelectedCat([]);
    setSearchText("");
  };

  const handleClick1 = (cat) => {
    const index = selectedCategories.indexOf(cat.id);
    if (index === -1) {
      dispatch(setSelectedCategory([...selectedCategories, cat.id]));
      savePrefernces({
        payload: {
          key: "selectedCategories",
          value: JSON.stringify([...selectedCategories, cat.id]),
        },
      });
      setSelectedCat([...selectedCat, cat.id]);
    } else {
      const newSelectedCategories = [...selectedCategories];
      newSelectedCategories.splice(index, 1);
      dispatch(setSelectedCategory(newSelectedCategories));
      savePrefernces({
        payload: {
          key: "selectedCategories",
          value: JSON.stringify(newSelectedCategories),
        },
      });
      setSelectedCat(newSelectedCategories);
    }
  };

  const handleDelete = (categoryName) => {
    dispatch(setSelectedCategory(selectedCategories?.filter((cat) => cat !== categoryName)));
    setSelectedCat(selectedCat.filter((cat) => cat !== categoryName));
    savePrefernces({
      payload: {
        key: "selectedCategories",
        value: JSON.stringify(selectedCategories.filter((cat) => cat !== categoryName)),
      },
    });
  };
  const handleNavigate = (vendorId, item = null, exploreVendor = false) => {
    setOpen(false);
    dispatch(setIsSearch(false));
    setSelectedCat([]);
    setSearchText("");
    dispatch(setSelectedVendorId(vendorId))
    navigate(`/org/${orgId}/explore-products?vendorId=${vendorId}`, {
      state: {
        item: item
      }
    })
  }
  const handleNavigateOld = async (vendorId, item = null, exploreVendor = false) => {
    setOpen(false);
    dispatch(setIsSearch(false));
    setSelectedCat([]);
    setSearchText("");
    navigate('/', {
      state: {
        vendorId: vendorId,
        item: item,
        exploreVendor: exploreVendor
      }
    })
  }

  const handleNavigate1 = async (vendorId, item = null) => {
    dispatch(setSelectedVendorId(vendorId));
    item && dispatch(setSelectedItemId(item?.id));
    setOpen(false);
    dispatch(setIsSearch(false));
    if (item) {
      //save thta item in orderList creation
      const vendorIndex = selectedItems.findIndex((vendor) => vendor?.vendorId == vendorId);
      if (vendorIndex !== -1) {
        // If vendor exists, add item to its items array
        const updatedOrderList = [...selectedItems];
        updatedOrderList[vendorIndex] = {
          ...updatedOrderList[vendorIndex],
          items: [...updatedOrderList[vendorIndex].items, { item: item }], // Ensure that items array is a new array
        };
        dispatch(updateOrderList(updatedOrderList));
      } else {
        const vendorName = vendors?.find((vendor) => vendor?.vendorDetails.id == vendorId)?.vendorDetails?.org_name;
        // If vendor doesn't exist, create new one and add the item
        const newVendor = {
          vendorId: `${vendorId}`,
          name: vendorName,
          items: [{ item: item }],
        };
        const updatedOrderList = [...selectedItems, newVendor];
        dispatch(updateOrderList(updatedOrderList));
      }
      router.push(`/org/${orgId}/order-list/create?vendorId=${vendorId}`);
    } else {
      router.push(`/org/${orgId}/order-list/create?vendorId=${vendorId}`);
    }
  };

  const handleClearSearch = () => {
    setShowAllVendors(false);
    setSearchText("");
  };

  const [openPopoverIndex, setOpenPopoverIndex] = useState(-1);
  const iconButtonRefs = useRef([]);

  const handleOpenMenu = (itemIndex) => {
    setOpenPopoverIndex(itemIndex);
  };

  const handleCloseMenu = () => {
    setOpenPopoverIndex(-1);
  };

  const { isMobile } = useResponsiveStyles();

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={handleCancel}>
        <div style={{ width: "50%" }}>
          {!open &&
            <IconButton
              onClick={() => {
                setOpen(true);
                dispatch(setIsSearch(true));
              }}
            >
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled", width: 20, height: 100 }} />
            </IconButton>}

          <Slide direction="down" in={open} mountOnEnter unmountOnExit>
            <StyledSearchbar>
              <OutlinedInput
                autoFocus
                fullWidth
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search by item or vendor...."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: "text.disabled", width: 20, height: 100 }} />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && ( // Render endAdornment only if searchText exists
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch}>
                        <Iconify icon={"ic:twotone-clear"} sx={{ color: "text.disabled", width: 20, height: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
              <CustomButton onClick={handleCancel} variant={"contained"} label={"Close"} sx={{ ml: "1rem" }} />
            </StyledSearchbar>
          </Slide>

          {open && (
            <Zoom in={open} style={{ transitionDelay: open ? "100ms" : "0ms" }}>
              <SearchResultsCard isMobile={isMobile}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>Popular Categories</Typography>
                {/* <Box sx={{ display: 'flex', marginBottom: 3, gap: 2 }}>
                  {allCategories.map((cat, index) => (
                    index < 5 && <Box key={`${index}cat`} onClick={() => handleClick1(cat)} sx={{ cursor: 'pointer', border: selectedCat.includes(cat.id) ? '1px solid black' : '1px solid #CFCFCF', padding: 2, paddingY: 1, borderRadius: 1 }}>
                      <Typography sx={{ color: selectedCat.includes(cat.id) ? '#2066D1' : '#696969', fontSize: 14 }}>{cat?.parent_category}</Typography>
                    </Box>
                  ))}
                </Box> */}
                <Grid container spacing={1}>
                  {allCategories.map((cat, index) => (
                    index < 6 &&
                    <Grid item xs={4}>
                      <Box key={`${index}cat`} onClick={() => handleClick1(cat)} sx={{ cursor: 'pointer', border: selectedCat.includes(cat.id) ? '1px solid black' : '1px solid #CFCFCF', padding: 1, borderRadius: 1 }}>
                        <Typography sx={{
                          color: selectedCat.includes(cat.id) ? '#2066D1' : '#696969',
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          lineClamp: 2,
                          textOverflow: 'ellipsis',
                          fontSize: 14
                        }}>{cat?.parent_category}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {<Box sx={{ paddingY: 3, width: '100%' }}><Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>Showing results for "{searchText}"</Typography>
                  {vendors?.length > 0 ? (
                    <><Grid container spacing={1}>
                      {vendors?.map((vendors, index) => {
                        return (
                          ((!showAllVendors && index < 3) || showAllVendors) && <Grid item xs={12} sm={12} md={6} lg={4} key={vendors?.vendorDetails?.id} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.04) 2px 2px 8px', border: '0.5px solid rgb(232, 232, 232)', padding: 2, borderRadius: 1 }}>
                              <Stack direction={"row"} alignItems={"center"} gap={"0.5rem"} mb={1}>
                                {/* <Stack
                                  sx={{
                                    height: "4rem",
                                    width: "4rem",
                                    padding: "0.5rem",
                                    paddingLeft: 0,
                                    borderRadius: "50%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={vendors?.vendorData?.org_logo_key} alt="asbaj" />
                                  <Avatar alt={vendors?.vendorDetails?.org_name} sx={{ backgroundColor: "#fff" }}>
                                    <img src={horecaLogo} />
                                    <GetImageComponent s3Key={vendors?.vendorDetails?.org_logo_key} />
                                  </Avatar>
                                </Stack> */}
                                <Typography
                                  variant="h6"
                                  // color="primary"
                                  onClick={() => handleNavigate(vendors?.vendorDetails?.id)}
                                  sx={{
                                    cursor: "pointer",
                                    transition: "color 0.3s ease-in-out", // Define the transition property
                                    "&:hover": {
                                      color: "primary.main",
                                      // color: 'secondary.main', // Change the color on hover
                                      transform: "scale(1.05)",
                                    },
                                  }}
                                >
                                  {vendors?.vendorDetails?.org_name && highlightText(vendors?.vendorDetails?.org_name, searchText, theme)}
                                </Typography>
                              </Stack>
                              {/* {vendors?.items?.map((item, itemIndex) => (
                                <>
                                  {itemIndex < 5 && <><Grid
                                    container
                                    spacing={2}
                                    key={`${itemIndex}item`}
                                    sx={{
                                      alignItems: "center",
                                      paddingY: 0.5,
                                      ":hover": {
                                        color: "primary.main",
                                      },
                                    }}
                                  >
                                    <Grid item xs={8} rowGap={2}>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          wordWrap: "break-word",
                                          overflow: "hidden",
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 2,
                                          textTransform: "capitalize",
                                          fontWeight: 400,
                                          fontSize: "0.8rem",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleNavigate(vendors?.vendorDetails?.id, item)}
                                      >
                                        {item?.name && highlightText(item?.name, searchText, theme)}
                                      </Typography>
                                    </Grid>

                                    {item?.itemQuantityPricesMappingDetails?.length > 0 ? (
                                      <Grid item xs={4} rowGap={2}>
                                        <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                                          <Typography variant="body2">
                                            {"₹" + item?.itemQuantityPricesMappingDetails[0].itemPrice}[
                                            {item?.itemQuantityPricesMappingDetails[0]?.itemQuantity + item?.unit}]
                                          </Typography>
                                          <IconButton
                                            ref={(ref) => (iconButtonRefs.current[itemIndex] = ref)}
                                            onClick={() => handleOpenMenu(itemIndex)}
                                            sx={{ fontSize: "5px" }}
                                          >
                                            <Iconify icon="material-symbols-light:keyboard-double-arrow-right" width={15} />
                                          </IconButton>
                                        </Stack>
                                        <Popover
                                          open={openPopoverIndex === itemIndex}
                                          anchorEl={iconButtonRefs.current[itemIndex]}
                                          onClose={handleCloseMenu}
                                          anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                          }}
                                          PaperProps={{ sx: { width: 140 } }}
                                        >
                                          {item?.itemQuantityPricesMappingDetails?.map((itemDet, index) => (
                                            <MenuItem key={`${index}itemDet`}>
                                              {"₹" + itemDet?.itemPrice}[{itemDet?.itemQuantity + item?.unit}]
                                            </MenuItem>
                                          ))}
                                        </Popover>
                                      </Grid>
                                    ) : (
                                      <Grid item xs={4} rowGap={2}>
                                        <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                                          <Typography variant="body2">
                                            {"₹" + item?.rate}[{item?.unit}]
                                          </Typography>
                                        </Stack>
                                      </Grid>
                                    )}
                                  </Grid>
                                    <Divider sx={{ borderStyle: "dashed", m: "0.3rem" }} /></>}
                                </> */}
                              {/* ))} */}
                              {/* <CustomButton variant='text' sx={{ marginTop: 1 }} onClick={() => handleNavigate(vendors?.vendorDetails?.id)} label={"View More"} /> */}
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                      {vendors && vendors.length > 3 && !showAllVendors && <Box sx={{ display: 'flex', justifyContent: 'center', paddingY: 2 }}>
                        <CustomButton
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                          }}
                          props={{ component: "Link" }}
                          onClick={() => setShowAllVendors(true)}
                          endIcon={<KeyboardDoubleArrowDownIcon />}
                          label={"Show all vendors"}
                        />
                      </Box>}
                    </>
                  ) : (
                    <Stack alignItems={"center"}>
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{searchText}&quot;</strong>.
                        <br /> Try checking for typos or using complete words.
                      </Typography>
                    </Stack>
                  )}
                </Box>}
              </SearchResultsCard>
            </Zoom>
          )}
        </div>
      </ClickAwayListener>
    )
  }

  return (
    <ClickAwayListener onClickAway={handleCancel}>
      <div style={{ width: "50%" }}>
        {!open &&
          (isMobile ? (
            <IconButton
              onClick={() => {
                setOpen(true);
                dispatch(setIsSearch(true));
              }}
            >
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled", width: 20, height: 100 }} />
            </IconButton>
          ) : (
            <OutlinedInput
              autoFocus
              fullWidth
              onClick={() => {
                setOpen(true);
                dispatch(setIsSearch(true));
              }}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search by item or vendor...."
              sx={{ ml: "1rem", mr: "1rem" }}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: "text.disabled", width: 20, height: 100 }} />
                </InputAdornment>
              }
            />
          ))}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <OutlinedInput
              autoFocus
              fullWidth
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search by item or vendor...."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: "text.disabled", width: 20, height: 100 }} />
                </InputAdornment>
              }
              endAdornment={
                searchText && ( // Render endAdornment only if searchText exists
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <Iconify icon={"ic:twotone-clear"} sx={{ color: "text.disabled", width: 20, height: 20 }} />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
            <CustomButton onClick={handleCancel} variant={"contained"} label={"Close"} sx={{ ml: "1rem" }} />
          </StyledSearchbar>
        </Slide>

        {open && (
          <Zoom in={open} style={{ transitionDelay: open ? "100ms" : "0ms" }}>
            <SearchResultsCard>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>Popular Categories</Typography>
              <Box sx={{ display: 'flex', marginBottom: 3, gap: 2 }}>
                {allCategories.map((cat, index) => (
                  index < 5 && <Box key={`${index}cat`} onClick={() => handleClick1(cat)} sx={{ cursor: 'pointer', border: selectedCat.includes(cat.id) ? '1px solid black' : '1px solid #CFCFCF', padding: 2, paddingY: 1, borderRadius: 1 }}>
                    <Typography sx={{ color: selectedCat.includes(cat.id) ? '#2066D1' : '#696969', fontSize: 14 }}>{cat?.parent_category}</Typography>
                  </Box>
                ))}
              </Box>
              {/* <Stack
                direction="row"
                spacing={1}
                rowGap={2}
                mb={4}
                // sx={{ flexWrap: "wrap" }}
                sx={{
                  // flexWrap: "wrap",
                  overflowX: "auto",
                  display: "flex",
                  "-webkit-overflow-scrolling": "touch", // Optional: Smooth scrolling for iOS devices
                  scrollBehavior: "smooth", // Optional: Smooth scrolling behavior
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar for WebKit browsers (e.g., Chrome, Safari)
                  },
                }}
              >
                {allCategories.map((cat, index) => (
                  <SelecteCategoriesComp
                    key={cat?.id}
                    handleDelete={handleDelete}
                    index={index}
                    cat={cat}
                    handleClick1={handleClick1}
                    selectedCategories={selectedCat}
                  />
                ))}
              </Stack> */}
              {<><Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>Showing results for "{searchText}"</Typography>
                {vendors?.length > 0 ? (
                  <><Grid container spacing={2} columnSpacing={10} sx={{ margin: 0, width: '100%' }}>
                    {vendors?.map((vendors, index) => {
                      return (
                        ((!showAllVendors && index < 3) || showAllVendors) && <Grid item xs={12} sm={12} md={6} lg={3} key={vendors?.vendorDetails?.id} sx={{ padding: 1, paddingX: '8px !important', display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.04) 2px 2px 8px', border: '0.5px solid rgb(232, 232, 232)', padding: 2, borderRadius: 1 }}>
                            <Stack direction={"row"} alignItems={'flex-start'} gap={"0.5rem"} mb={1}>
                              {/* <Stack
                                sx={{
                                  height: "4rem",
                                  width: "4rem",
                                  padding: "0.5rem",
                                  paddingLeft: 0,
                                  borderRadius: "50%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img src={vendors?.vendorData?.org_logo_key} alt="asbaj" />
                                <Avatar alt={vendors?.vendorDetails?.org_name} sx={{ backgroundColor: "#fff" }}>
                                  <GetImageComponent s3Key={vendors?.vendorDetails?.org_logo_key} />
                                </Avatar>
                              </Stack> */}
                              <Tooltip
                                title={vendors?.vendorDetails?.org_name || ""}
                                placement="top"
                                arrow
                              >
                                <Typography
                                  variant="h6"
                                  onClick={() => handleNavigate(vendors?.vendorDetails?.id)}
                                  sx={{
                                    cursor: "pointer",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    transition: "color 0.3s ease-in-out",
                                    "&:hover": {
                                      color: "primary.main",
                                      transform: "scale(1.05)",
                                    },
                                  }}
                                >
                                  {vendors?.vendorDetails?.org_name &&
                                    highlightText(vendors?.vendorDetails?.org_name, searchText, theme)}
                                </Typography>
                              </Tooltip>
                            </Stack>
                            {/* {vendors?.items?.map((item, itemIndex) => (
                              <>
                                {itemIndex < 5 && <><Grid
                                  container
                                  spacing={2}
                                  key={`${itemIndex}item`}
                                  sx={{
                                    alignItems: "center",
                                    paddingY: 0.5,
                                    ":hover": {
                                      color: "primary.main",
                                    },
                                  }}
                                >
                                  <Grid item xs={8} rowGap={2}>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        wordWrap: "break-word",
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2,
                                        textTransform: "capitalize",
                                        fontWeight: 400,
                                        fontSize: "0.8rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleNavigate(vendors?.vendorDetails?.id, item)}
                                    >
                                      {item?.name && highlightText(item?.name, searchText, theme)}
                                    </Typography>
                                  </Grid>

                                  {item?.itemQuantityPricesMappingDetails?.length > 0 ? (
                                    <Grid item xs={4} rowGap={2}>
                                      <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                                        <Typography variant="body2">
                                          {"₹" + item?.itemQuantityPricesMappingDetails[0].itemPrice}[
                                          {item?.itemQuantityPricesMappingDetails[0]?.itemQuantity + item?.unit}]
                                        </Typography>
                                        <IconButton
                                          ref={(ref) => (iconButtonRefs.current[itemIndex] = ref)}
                                          onClick={() => handleOpenMenu(itemIndex)}
                                          sx={{ fontSize: "5px" }}
                                        >
                                          <Iconify icon="material-symbols-light:keyboard-double-arrow-right" width={15} />
                                        </IconButton>
                                      </Stack>
                                      <Popover
                                        open={openPopoverIndex === itemIndex}
                                        anchorEl={iconButtonRefs.current[itemIndex]}
                                        onClose={handleCloseMenu}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                        }}
                                        PaperProps={{ sx: { width: 140 } }}
                                      >
                                        {item?.itemQuantityPricesMappingDetails?.map((itemDet, index) => (
                                          <MenuItem key={`${index}itemDet`}>
                                            {"₹" + itemDet?.itemPrice}[{itemDet?.itemQuantity + item?.unit}]
                                          </MenuItem>
                                        ))}
                                      </Popover>
                                    </Grid>
                                  ) : (
                                    <Grid item xs={4} rowGap={2}>
                                      <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                                        <Typography variant="body2">
                                          {"₹" + item?.rate}[{item?.unit}]
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  )}
                                </Grid>
                                  <Divider sx={{ borderStyle: "dashed", m: "0.3rem" }} /></>}
                              </> */}
                            {/* )) */}
                            {/* <CustomButton variant='text' sx={{ marginTop: 1 }} onClick={() => handleNavigate(vendors?.vendorDetails?.id)} label={"View More"} /> */}
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                    {vendors && vendors.length > 3 && !showAllVendors && <Box sx={{ display: 'flex', justifyContent: 'center', paddingY: 2 }}>
                      <CustomButton
                        variant="contained"
                        sx={{
                          textTransform: "capitalize",
                        }}
                        props={{ component: "Link" }}
                        onClick={() => setShowAllVendors(true)}
                        endIcon={<KeyboardDoubleArrowDownIcon />}
                        label={"Show all vendors"}
                      />
                    </Box>}
                  </>
                ) : (
                  <Stack alignItems={"center"}>
                    <Typography variant="h6" paragraph>
                      Not found
                    </Typography>

                    <Typography variant="body2">
                      No results found for &nbsp;
                      <strong>&quot;{searchText}&quot;</strong>.
                      <br /> Try checking for typos or using complete words.
                    </Typography>
                  </Stack>
                )}
              </>}
            </SearchResultsCard>
          </Zoom>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default Searchbar;
