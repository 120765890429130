import { Paper, Typography } from '@mui/material'
import React from 'react'

const NoData = ({query}) => {
  return (
    <Paper
          sx={{
            textAlign: 'center',
            mt:'2rem',
            boxShadow:"none",
            background:'none'
          }}
        >
          <Typography variant="h6" paragraph>
            No Data found
          </Typography>

          {query && <Typography variant="body2">
            No results found for &nbsp;
            <strong>&quot;{query}&quot;</strong>.
            <br /> Try checking for typos or using complete words.
          </Typography>}
        </Paper>
  )
}

export default NoData