import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import Iconify from "../../../components/common/Iconify";
import { useRouter } from "../../../routes/hooks/useRouter";
import LoginView from "./LoginView";
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";

const LoginDialogue = ({ open, handelCloseDialogue }) => {


const responsive= useResponsiveStyles();

  return (
    <Dialog
      maxWidth={"sm"}
      scroll={"paper"}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            //minWidth: '350px',
            //maxWidth: "450px", // Set your width here
            // padding: responsive?.isMobile && '20px 0px'
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>Login</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handelCloseDialogue}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Iconify icon="material-symbols:cancel-outline" />
      </IconButton>
      <DialogContent sx={{padding: responsive?.isMobile ? '20px 10px' : '20px 20px'}}>
        <LoginView/>
      </DialogContent>
    </Dialog>
  );
};


export default LoginDialogue