
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useCreateNewRoleMutation, useGetAllPermissionsQuery } from "../../../../redux/api/api";
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { openMessage } from "../../../../redux/slices/showMessageSlice";
import CustomTextField from "../../../../components/common/CustomTextField";
import CustomButton from "../../../../components/common/CustomButton";
import { getOrgType } from "../../../../utils/org";
import Iconify from "../../../../components/common/Iconify";

export default function RolesDialogue({ open, handelCloseDialogue, data, disabled, resetData, setEnable }) {
    const dispatch = useDispatch();
    const orgId = useSelector((state) => state.app.preference?.selectedOrgId);
    const user = useSelector((state) => state.auth.user);
    const orgType = getOrgType(user, orgId)
    const { data: permissions } = useGetAllPermissionsQuery(orgType);
    const [createNewRole, { isLoading: createNewRoleLoading }] = useCreateNewRoleMutation();

    const initialValues = {
        id: data?.id ?? null,
        roleName: data?.roleName ?? '',
        permissions: data?.permissions ?
            permissions?.filter(permission =>
                data?.permissions?.some(dataPermission => dataPermission.id === permission.id)
            ).map(permission => Number(permission.id)) : [],
        orgId: data?.orgId
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const result = await createNewRole({ orgId: values.orgId, payload: values });
            if (result?.data) {
                await resetData()
                dispatch(openMessage({ message: 'Role permissons updated!', messageSeverity: 'success' }));
                formik.resetForm();
                handelCloseDialogue();
            } else if (result?.error) {
                dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }));
            } else {
                dispatch(openMessage({ message: 'Not able to update role permissions!', messageSeverity: 'error' }));
            }
        },
    })

    const handleClose = () => {
        handelCloseDialogue();
        formik.resetForm();
    }

    const handleCheckboxChange = (permission) => (event) => {
        let permissionId = formik.values.permissions.find((id) => id === permission.id)
        if (permissionId) {
            formik.setFieldValue('permissions', formik.values.permissions.filter((id) => id !== permissionId))
        } else {
            formik.setFieldValue('permissions', [...formik.values.permissions, permission.id])
        }
    };

    return (
        <Dialog
            maxWidth={"sm"}
            scroll={"paper"}
            open={open}
            // onClose={handleClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                    },
                },
            }}
        >
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle>{disabled ? "Role Detail" : "Edit Role"}</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: '1rem' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <CustomTextField
                                name={'roleName'}
                                label="Role Name"
                                formik={formik}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <CustomButton
                                onClick={disabled ? setEnable : formik.handleSubmit}
                                label={disabled ? "Edit" : createNewRoleLoading ? "Saving..." : "Save"}
                                width={"100%"}
                                loading={createNewRoleLoading}
                                endIcon={disabled ? <Iconify width={14} icon="fluent:edit-28-filled" /> : <></>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} mt={2} mb={2}>
                        <Typography variant="h5" noWrap>Permission</Typography>
                    </Grid>
                    <Grid container spacing={2}>
                        {permissions?.map((permission, index) => (
                            <Grid item xs={6} key={index}>
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={formik?.values?.permissions?.includes(permission.id)}
                                            onChange={handleCheckboxChange(permission)}
                                            disabled={disabled}
                                        />
                                    }
                                    label={permission.permissionName}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
