import { Box, Card, Container, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import Iconify from '../../components/common/Iconify';
import { useState } from 'react';
import { useFormik } from 'formik'
import { onBaordPrivateVendorSchema } from '../../schema/organization/organizationSchema'
import { horecaApi, useOnBoardPrivateVendorMutation } from '../../redux/api/api'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { openMessage } from '../../redux/slices/showMessageSlice'
import CreateMyVendorDialogue from './CreateMyVendorDialogue'
import CustomeSelectMenu from '../../components/common/CustomeSelectMenu'
import { useEffect } from 'react'
import { TableColumns } from '../order-list/TableColumns'
import CustomLabel from '../../components/common/label/CustomLabel'
import { useRouter } from '../../routes/hooks/useRouter'
import VendorLogoImageComponent from '../../components/common/VendorLogoImageComponent';
import { setVendorFromMyVendors } from '../../redux/slices/orderListSlice';
import PageHeader from '../../components/common/PageHeader';
import CustomButton from '../../components/common/CustomButton';
import TableNoData from '../../views/list/table-no-data';
import TableLoadingData from '../../components/common/TableLoadingData';

const PrivateVendors = () => {

  const dispatch = useDispatch();
  const router = useRouter();
  const [onBoardPrivateVendor, { isLoading }] = useOnBoardPrivateVendorMutation();
  const { orgId } = useParams();
  const [myVendorDialogue, setMyVendorDialogue] = useState(false);

  const [getMyVendors, { data: vendorsList, isLoading: getMyVendorsLoading }] = horecaApi.endpoints.getMyVendors.useLazyQuery();

  const headCells = TableColumns('my-vendors');

  const [vendorFilter, setVendorFilter] = useState(['all']);


  useEffect(() => {
    (
      async () => {
        await getMyVendors(orgId);
      }
    )()
  }, [orgId])


  const handlePlaceOrder = (isPrivate, privateVendorId) => {
    if (isPrivate) {
      router.push(`/org/${orgId}/my-vendors/${privateVendorId}`)
    } else {
      dispatch(setVendorFromMyVendors(Number(privateVendorId)))
      router.push(`/org/${orgId}/explore-products?vendorId=${privateVendorId}`)
      
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactPersonNumber: "",
      phoneNumber: "",
      email: "",
      orgName: "",
      addresses: {
        billing: true,
        line1: "",
        landmark: "",
        city: "",
        state: "",
        zipcode: ""
      }
    },
    validationSchema: onBaordPrivateVendorSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      values.phoneNumber = values.phoneNumber.replace(/\s/g, "");
      values.contactPersonNumber = values.contactPersonNumber.replace(/\s/g, "");
      const result = await onBoardPrivateVendor({ orgId, payload: values });
      if (result?.data) {
        formik.resetForm();
        setMyVendorDialogue(false);
        dispatch(openMessage({ message: result?.data?.message ?? 'Private vendor added!', messageSeverity: "success" }));
      } else if (result?.error) {
        dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }));
      } else {
        dispatch(openMessage({ message: "Not able to create an private vendor", messageSeverity: "error" }));
      }
    }
  })


  const onCloseMyVendorDilogue = () => {
    setMyVendorDialogue(false)
  }

  const onOpenMyVendorDialogue = () => {
    setMyVendorDialogue(true)
  }


  const filteredVendorsList = vendorFilter?.length > 0
    ? vendorsList?.filter(vendor => {
      if (vendorFilter?.includes('private-vendor')) {
        return vendor?.isPrivateVendor === true;
      } else if (vendorFilter.includes('vendors')) {
        return vendor.isPrivateVendor === false;
      } else {
        return true; // Show all vendors if no specific filter is selected
      }
    })
    : vendorsList;


  const handleViewVendor = (id) => {
    router.push(`/org/${orgId}/myVendors/${id}`);
  }


  return (
    <Container maxWidth='xl'>
      <PageHeader
        title={"My vendors"}
        // linkArray={[
        //   { title: "Dashboard", path: `/` },
        //   { title: "My vendors", },
        // ]}
        onClick1={onOpenMyVendorDialogue}
        startIcon1={<Iconify icon="eva:plus-fill" />}
        label1={"Create Private Vendor"}
        variant1={"contained"}
      />
      <Box component={Paper} >
        <Stack spacing={2}
          padding={2}
          width={"100%"}
          direction={{
            xs: "column",
            sm: "row"
          }}
        >
          <CustomeSelectMenu label={"Vendors"} noDataMsg={"No Vendors"} options={[{ id: 'all', label: 'All' }, { id: 'private-vendor', label: 'Private Vendor' }, { id: 'vendors', label: 'Vendors' }]} optionFilter={vendorFilter} setOptionFilter={setVendorFilter} width={{ xs: "100%", sm: "30%", md: "20%" }} />
        </Stack>
        <TableContainer sx={{ maxHeight: '60vh' }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            // size={dense ? 'small' : 'medium'}
            stickyHeader
          >
            <TableHead>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell?.id}
                  align={headCell?.align ?? 'left'}
                  padding={headCell?.padding ?? 'normal'}
                  // sortDirection={orderBy === headCell.id ? order : false}
                  sx={headCell?.style}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {getMyVendorsLoading ?
                <TableLoadingData colSpan={headCells?.length} /> :
                filteredVendorsList && filteredVendorsList.length > 0 ? (
                  filteredVendorsList.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {
                          headCells.map((column) => {
                            let value = null;
                            const style = { ...column?.style };
                            switch (column.id) {
                              case "active": {
                                value = row[column.id] === true ? 'active' : 'not-active'
                                break;
                              }
                              case "orgLogoKey": {
                                value = <VendorLogoImageComponent s3Key={row[column.id]} name={row?.orgName} />
                                break;
                              }
                              case "isPrivateVendor": {
                                value = row[column.id] === true ? <CustomLabel color="info">Private</CustomLabel> : ''
                                break;
                              }
                              case "action": {
                                value = <Stack direction={'row'} gap={'1rem'}>
                                  <CustomButton variant='outlined' onClick={() => handlePlaceOrder(row['isPrivateVendor'], row?.id)} label={"Place Order"} />
                                  {
                                    !row['isPrivateVendor'] && <CustomButton variant='contained' onClick={() => handleViewVendor(row?.id)} label={"View"} />
                                  }
                                </Stack>
                                break;
                              }
                              default:
                                let splitArray = column.id.split(".");
                                value = row;

                                for (let key of splitArray) {
                                  // Check for null or undefined values
                                  if (value && value.hasOwnProperty(key)) {
                                    value = value[key];
                                  } else {
                                    value = null;
                                    break; // Break the loop if a null or undefined value is encountered
                                  }
                                }
                                break;
                            }
                            return (
                              <TableCell key={column.id} align={column.align}
                                sx={{ border: (filteredVendorsList.length - 1) === index && '0px', ...style }}
                                padding={column?.padding}>
                                {value}
                              </TableCell>
                            )
                          })
                        }
                      </TableRow>
                    )
                  })
                )
                  :
                  <TableNoData query={"Vendors"} colSpan={headCells?.length} />
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CreateMyVendorDialogue
        onCreateVendor={formik.handleSubmit}
        onClose={onCloseMyVendorDilogue}
        formik={formik}
        open={myVendorDialogue}
        isDisabled={isLoading}
      />
    </Container>
  )
}

export default PrivateVendors