import { Stack, Typography, Link } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OTPPanel from './OtpInput';
import { useRouter } from '../../../routes/hooks/useRouter';
import { horecaApi, useGetOtpMutation, useLoginMutation, useSavePreferncesMutation } from '../../../redux/api/api';
import { useDispatch } from 'react-redux';
import { setInitialAuth } from '../../../redux/slices/authSlice';
import { openMessage } from '../../../redux/slices/showMessageSlice';
import { jwtDecode } from 'jwt-decode';
import { setSavePrfernces } from '../../../redux/slices/appReducer';
import CustomButton from '../../../components/common/CustomButton';
import { parsePhoneNumberFromString, } from 'libphonenumber-js';
import CustomMuiTelInput from '../../../components/common/CustomMuiTelInput';
import { useState } from 'react';

export const validatePhoneNumber = (value, country) => {
  if (!value) {
    return 'Phone number is required';
  }

  const phoneNumber = parsePhoneNumberFromString(value, country);

  if (!phoneNumber || !phoneNumber.isValid()) {

    return 'Invalid phone number';

  }

  return null;
}

const LoginView = () => {

  const router = useRouter();
  const dispatch = useDispatch();

  const [getOtp, { isLoading: gettingOtp }] = useGetOtpMutation();
  const [login, { isLoading: verifyingOtp }] = useLoginMutation();
  const [isLogin, setIsLogin] = useState(false);
  const [getMe] = horecaApi.endpoints.getMe.useLazyQuery();

  const [savePrefernces] = useSavePreferncesMutation();

  const formik = useFormik({
    initialValues: {
      mobileNumber: '',
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string().test('isValidPhoneNumber', 'Invalid phone number', function (value) {
        const { country } = this.parent;
        return !validatePhoneNumber(value, country);
      }),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const payload = { mobileNumber: values.mobileNumber.replace(/\s/g, "") }
      const result = await getOtp(payload);
      if (result.data) {
        setIsLogin(true);
        dispatch(openMessage({ message: 'Otp Sent', messageSeverity: 'success' }))
      } else if (result.error) {
        dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: 'error' }))
      } else {
        dispatch(openMessage({ message: 'Not Able to sent an otp', messageSeverity: 'error' }))
      }
    }
  });

  const handelVerify = async (otp) => {
    const payload = { mobileNumber: formik.values.mobileNumber.replace(/\s/g, ""), otp: otp }
    const result = await login(payload);
    if (result.data) {
      {/**saving the data and also checking if any org have status pending or rejected or drafted  */ }
      const jwtToken = jwtDecode(result.data?.access_token);

      if(jwtToken?.userId && jwtToken?.orgDetail?.length > 0){
        dispatch(setInitialAuth(result.data))
        const result1 = await getMe(jwtToken?.userId);
       

        const foundOrg = jwtToken?.orgDetail.find(org => org.status === "pending" || org.status === "rejected" || org.status === "drafted");

        let currOrg = foundOrg ??  (jwtToken?.orgDetail.length > 0 ? jwtToken.orgDetail[0] : null);

        //call getMe to get previous selected org id if orf id is not there by default st first org id
        if (result1) {
        // const jwtToken = jwtDecode(result?.data?.tokens?.access_token);
        const orgId = result1?.data?.preferences?.selectedOrgId
        
        if (orgId) {
          dispatch(setSavePrfernces({ key: "selectedOrgId", value: orgId }));
          await savePrefernces({ payload: { key: 'selectedOrgId', value: orgId } });
          router.push(`/`)
        } else {
          dispatch(setSavePrfernces({ key: "selectedOrgId", value: currOrg?.id }));
          await savePrefernces({ payload: { key: 'selectedOrgId', value: currOrg?.id } });//make api call
          router.push(`/`)
        }
      }
      }else{
        dispatch(openMessage({
          message: jwtToken?.orgDetail?.length > 0 ? 'There is some issue at our end please login again!' : 'No Organization has assigned!',
          messageSeverity: "error",
        }))
      }
    } else if (result.error) {
      dispatch(openMessage({
        message: result.error.data.message,
        messageSeverity: "error",
      }))
    } else {
      dispatch(openMessage({
        message: 'Not able to verify an otp',
        messageSeverity: "error",
      }))
    }
  };


  const renderOtpForm = (
    <>
      <Stack spacing={3} minWidth={'80%'}>
        <Typography variant="h4">Sign in to Horeca1</Typography>
        <Stack display={'flex'} flexDirection={'row'} gap={'4px'}>
          <Typography variant="body2" sx={{ mb: 5 }}>
            Verifying an Otp For
            <Link variant="subtitle2" sx={{ ml: 0.5 }} underline="hover">
              {formik.values.mobileNumber}
            </Link>
          </Typography>
        </Stack>
        <OTPPanel otpVerified={handelVerify} handleResendOtp={formik.handleSubmit} verifyingOtp={verifyingOtp} />
      </Stack>
    </>
  );

  const renderForm = (
    <>
      <Stack spacing={3} minWidth={'100%'}>
        <Typography variant="h4">Sign in to Horeca1</Typography>
        <Stack display={'flex'} flexDirection={'row'} gap={'4px'}>
          <Typography variant="body2" sx={{ mb: 5 }}>
            New User?
            <Link
              variant="subtitle2"
              sx={{ ml: 0.5, cursor: 'pointer' }}
              underline="hover"
              onClick={() => router.push('/signup')}
            >
              Create an account
            </Link>
          </Typography>
        </Stack>
        <CustomMuiTelInput
          name={'mobileNumber'}
          label={'Mobile Number'}
          formik={formik}
          // autoFocus={true}
          onKeyUp={(e) => e.key === "Enter" && (formik.values.mobileNumber !== "" ? formik.handleSubmit() : dispatch(openMessage({ message: 'Please enter mobile no', messageSeverity: 'error' })))}
        />
        <CustomButton
          onClick={formik.handleSubmit}
          variant={"contained"}
          width={'100%'}
          label={gettingOtp ? "Sending OTP..." : "Login"}
          loading={gettingOtp}
          endIcon={gettingOtp && <></>}
        />
      </Stack>
    </>
  );

  return <>{isLogin ? renderOtpForm : renderForm}</>;
};

export default LoginView;