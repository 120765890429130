import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { horecaLogo } from '../../assets/images';
import {Avatar, Box, Link} from '@mui/material';
import RouterLink from '../../routes/components/RouterLink';
import { useSelector } from 'react-redux';
import { getOrgData } from '../../utils/org';
import { useParams } from 'react-router-dom';
import GetImageComponent from './GetImageComponent';


// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  // const {orgId} = useParams();
  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const user = useSelector((state)=> state.auth.user)

  const orgData = getOrgData(user, orgId);

 

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 100,
        height: 35,
        display: 'inline-flex',
        transition: 'width 0.3s, height 0.3s',
        
        ...sx,
      }}
      {...other}
    >
      {orgData?.orgLogoKey && orgData?.orgLogoKey!=='string' ? <GetImageComponent s3Key={orgData?.orgLogoKey}/> : <img src={horecaLogo} alt="Horeca1" /> }
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href={`/`} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
