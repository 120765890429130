import { Box } from "@mui/material";
import CategorywiseSection from "./CategorywiseSection";
import useResponsiveStyles from "../../../hooks/useResponsiveStyles";
import { horecaApi } from "../../../redux/api/api";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const CategoryProducts = ({ setLoginOpen, location }) => {
    const responsive = useResponsiveStyles();
    const [getCategoryProductByPinCode, { data }] = horecaApi.endpoints.getCategoryProductByPinCode.useLazyQuery();
    useEffect(() => {
        if (location) {
            getCategoryProductByPinCode({ pinCode: location.pincode })
        }
    }, [location])
    const categories = ['Fruits & Vegetables', 'Masala, Salt & Sugar', 'Sauces & Seasoning', 'Chicken & Eggs', 'Cleaning & Consumables'];
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: 1, paddingX: responsive?.isMobile ? 1 : 2 }}>
            {
                data && data.map((cat, index) => {
                    return <CategorywiseSection key={`${index}cp`} category={cat} setLoginOpen={setLoginOpen} />
                })
            }

        </Box>
    )
}

export default CategoryProducts;