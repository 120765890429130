import React from "react";
import { TextField, Typography, IconButton, Input, InputAdornment } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { getIn } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrderListName } from "../../redux/slices/orderListSlice";
import Iconify from "./Iconify";

const TextFiledWithPrefilledData = ({
  editName,
  handelEditName,
  handelSaveName,
  name,
  handleChange,
  value
}) => {

  // const dispatch = useDispatch();

  // const name = useSelector((state)=> state.orderList.orderListName);

  const handleChange1 = (e) => {
    // dispatch(setOrderListName(e.target.value));
    handleChange(e);
  };


  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        transition: "opacity 1s ease",
      }}
    >
      {editName ? (
        <Typography variant="h3" color="initial">
          {value ? value : "New Order List"}
        </Typography>
      ) : (
        // <TextField
        // variant="standard"
        // autoFocus
        // size="medium"
        // sx={{ fontSize: "2rem" }} // Change size to fontSize
        // value={name}
        // onChange={handleChange}
        // fullWidth
        // inputProps={{
        //   style: {
        //     border: "none",
        //     outline: "none",
        //     padding: "0",
        //     fontWeight: 700,
        //     lineHeight: "120%",
        //   },
        // }}
        // />
        <Input
              autoFocus
              fullWidth
              // disableUnderline
              placeholder="Enter Order List Name"
              
              value={value}
              onChange={handleChange1}
              // startAdornment={
              //   <InputAdornment position="start">
              //     <Iconify
              //       icon="eva:search-fill"
              //       sx={{ color: "text.disabled", width: 20, height: 100 }}
              //     />
              //   </InputAdornment>
              // }
              sx={{ mr: 1, fontWeight: "fontWeightBold", fontSize:'2rem'}}
            /> 
      )}
      {!editName ? (
        <IconButton onClick={() => handelEditName()}>
          <EditIcon />
        </IconButton>
      ) : (
        <IconButton onClick={() => handelSaveName()}>
          <CheckIcon />
        </IconButton>
      )}
    </div>
  );
};

export default TextFiledWithPrefilledData;
