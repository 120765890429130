import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';


const style = {

};


const ConfirmationModal = ({ subCatData, handleModal, open }) => {
    const handleConfirmation = (res) => {
        handleModal(res)
    }

    return (
        <div style={{ width: '100%' }}>
            <Modal
                keepMounted
                open={open}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '400px', md: '500px' },
                    bgcolor: 'background.paper',
                    borderRadius: '20px',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <WarningIcon sx={{color : 'red'}}/>
                    <Typography color='red' id="keep-mounted-modal-title" variant="h6" component="h2">
                        Are your sure !
                    </Typography>
                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                        "{subCatData?.name}" sub category will be permanently removed !
                    </Typography>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
                        <Button sx={{ ml: 2 }} variant='contained' onClick={() => handleConfirmation('cancel')}>Cancel</Button>
                        <Button sx={{ ml: 2 }} variant='contained' onClick={() => handleConfirmation('confirm')}>Confirm</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default ConfirmationModal