import React, { createContext, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import socketIO from "socket.io-client";
import { io, connect } from 'socket.io-client';
import { onMessageReadConfirmation, onNewNotification, onSocketConnect, readAllNotifications } from "../redux/slices/notificationSlice";


const WebSocketContext = createContext();

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const token = useSelector((state) => state.auth.token?.access_token);
  const orgId = useSelector((state) => state.app.preference.selectedOrgId)
  const socketRef = useRef(null);
  const dispatch = useDispatch();



  useEffect(() => {
    if(orgId){
      // const newSocket = socketIO.connect(
      //   process.env.REACT_APP_NOTIFICATION_ENDPOINT_URL,
      //   {
      //     path: '/notification-websocket/',
      //     auth: {
      //       token: token,
      //       orgId: orgId,
      //     },
      //   },
      // );

      const newSocket = io(
        process.env.REACT_APP_NOTIFICATION_ENDPOINT_URL,
        {
          reconnection: true,
          reconnectionAttempts: 5,
          reconnectionDelay: 1000,
          auth: {
            token: token,
            orgId: orgId,
          },
        },
      );
        
      socketRef.current = newSocket;
  
          newSocket.on("connect", () => {
            console.log("Connected to websockt");
          });
  
          newSocket.on("disconnect", () => {
            console.log("Disconnected from websocket");
          });
  

          newSocket.on("getAllNotifications", (data) => {
            console.log("Socket messages", data);
            dispatch(onSocketConnect(data));
          });
          

          newSocket.on("on-new-notification", (data) => {
            console.log("Socket message", data);
            dispatch(onNewNotification(data));
          });

          newSocket.on("all-message-read-confirmed", (data) => {
            console.log("read all Confirmed message", data);
            dispatch(readAllNotifications(data));
          });
        
          newSocket.on("message-read-confirmed", (data) => {
            console.log("read Confirmed message", data);
            dispatch(onMessageReadConfirmation(data));
          });

          //on pushed new chat


          //get all chatsa:
          
        
      return () => {
        newSocket.disconnect();
      };
    }
  }, [token, orgId]);

  return (
    <WebSocketContext.Provider value={socketRef.current}>
      {children}
    </WebSocketContext.Provider>
  );
};