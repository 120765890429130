import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Typography, IconButton, Box, Paper, TableContainer, TableCell, TableRow, TableHead, Table, TableBody, TextField, FormControl, Radio, FormControlLabel, RadioGroup, Select, InputLabel, MenuItem, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Iconify from '../../../components/common/Iconify';
import ItemsImageComponent from '../../../components/common/ItemsImageComponent';
import { horecaApi, useCreateOrderListMutation, useUpdateOrderListItemsMutation } from '../../../redux/api/api';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParams from '../../../routes/hooks/useQueryParams';
import { openMessage } from '../../../redux/slices/showMessageSlice';
import GetImageComponent from '../../../components/common/GetImageComponent';
import { noSticker } from '../../../assets/images/landing';
import DeleteIcon from '@mui/icons-material/Delete';

const WishlistMobile = ({ wishList, addToWishlist, onListCreated, onClose }) => {
    const params = useQueryParams()
    const dispatch = useDispatch();
    const [listVal, setListVal] = useState('new');
    const [listName, setListName] = useState('');
    const [existingListName, setExistingListName] = useState('');
    const [vendorOrderList, setvendorOrderList] = useState([]);
    const orderList = useSelector((state) => state.orderList.orderList)
    const pincode = useSelector((state) => state.app.preference.selectedPinCode?.pincode);
    const orgId = useSelector((state) => state.app.preference.selectedOrgId);
    const [getOrderList] = horecaApi.endpoints.getOrderList.useLazyQuery();
    const [updateOrderListItems] = useUpdateOrderListItemsMutation();
    const [createOrderList] = useCreateOrderListMutation();
    const [getOrderListById, { data: orderListData }] = horecaApi.endpoints.getOrderListById.useLazyQuery();

    useEffect(() => {
        (
            async () => {
                await getOrderList({ orgId, pincode })
            }
        )()
    }, [])
    useEffect(() => {
        if (orderList && orderList?.Total?.totalOrderLists?.length > 0) {
            setvendorOrderList(orderList?.Total?.totalOrderLists.filter((_obj) => _obj.vendorOrgId == params?.vendorId))
        }
    }, [orderList])
    const createList = async () => {
        let data1 = {
            vendorOrgId: params?.vendorId,
            name: listName,
            items: wishList.map((item) => ({ item_id: item.item_id, inventry_item_id: item.id })),
        };
        let result;
        if (listVal == 'new') {
            result = await createOrderList({ orgId, payload: [data1] });
        } else {
            let itemListToSave = orderListData.items.map((item) => ({ item_id: item.item_id, inventry_item_id: item.id }))
            data1 = {
                vendorOrgId: params?.vendorId,
                customerOrgId: orgId,
                items: wishList.map((item) => ({ item_id: item.item_id, inventry_item_id: item.id })).concat(itemListToSave),
            };
            result = await updateOrderListItems({ customerId: orgId, orderListId: existingListName, payload: data1 });
        }
        if (result.data) {
            onListCreated();
            dispatch(openMessage({ message: listVal == 'new' ? 'Order list created' : 'Order list updated', messageSeverity: 'success' }));
            onClose();
        }
    }
    const handleChange = (event) => {
        setListVal(event.target.value);
    };
    const handleListNameChange = async (event) => {
        await getOrderListById({ orgId, orderListId: event.target.value });
        setExistingListName(event.target.value);
    }

    return (
        <Box>
            <Box>
                <Typography variant='h5'>Review</Typography>
            </Box>
            <Box>
                <Box>
                    <FormControl>
                        <RadioGroup
                            row
                            value={listVal}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="new" control={<Radio size="small" />} label={<Typography variant="body2" sx={{ fontWeight: 'normal' }}>Create New Order List</Typography>} />
                            <FormControlLabel value="existing" control={<Radio size="small" />} label={<Typography variant="body2" sx={{ fontWeight: 'normal' }}>Add into Existing Order List</Typography>} />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box sx={{ paddingTop: 2 }}>
                    {listVal == 'new' && <TextField
                        value={listName}
                        fullWidth
                        label="Order List Name"
                        onChange={(event) => {
                            setListName(event.target.value);
                        }}
                    />}
                    {listVal == 'existing' && <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Order List</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            value={existingListName}
                            label="Select Order List"
                            onChange={handleListNameChange}
                        >
                            {
                                vendorOrderList.map((_list) => {
                                    return <MenuItem value={_list.id}>{_list.name}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>}
                </Box>
            </Box>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
                {
                    wishList.map((data, index) => (
                        <Grid key={`${index}list`} item xs={6}>
                        <Box sx={{ flexGrow: 1, border: '.5px solid #d3d3d3', borderRadius: 2, backgroundColor: '#FFF', position: 'relative', padding: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <GetImageComponent s3Key={data?.item_image_key} noSticker={noSticker} style={{ height: 80, width: 80 }} />
                            <Box sx={{ width: '100%', flexGrow: 1, padding: 0.5, paddingBottom: 0, paddingTop: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography variant="subtitle2" color="textSecondary" sx={{ fontSize: 13 }}>{data.name}</Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1, fontSize: 13 }}>1 {data?.unit}</Typography>
                                </Box>
                                {/* {data?.itemQuantityPricesMappingDetails?.length > 0 &&
                                    <Link
                                        component="button"
                                        sx={{ paddingBottom: 1 }}
                                        variant="body2"
                                        onClick={() => {
                                            setIsDrawerOpen(true)
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 'bold' }}>Bulk Offers</Typography>
                                    </Link>} */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingTop: 1, borderTop: '0.5px dashed rgb(181, 181, 181)' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="subtitle2" sx={{ fontSize: 13 }}>₹ {data.rate}</Typography>
                                        <IconButton onClick={() => addToWishlist(data)}><DeleteIcon sx={{ color:'#E95F62' }}/></IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        </Grid>
                    ))
                }
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 2 }}>
                <Button variant='contained' disabled={listVal == 'new' ? !listName : !existingListName} onClick={createList}>{listVal == 'new' ? 'Create Order List' : 'Update Order List'}</Button>
            </Box>
        </Box>
    )
}

export default WishlistMobile;