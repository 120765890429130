import { useMemo } from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import { palette } from './pallete';
import { shadows } from './shadows';
import { overrides } from './overrides';
import { fontFamily, typography } from './typography';
import { customShadows } from './customShadows';

// ----------------------------------------------------------------------

const ThemeProvider = ({ children, mode, brand }) => {


  const memoizedValue = useMemo(
    () => ({
      palette: palette(mode, brand),
      // fontFamily: 'Nunito Sans',
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      shape: { borderRadius: 8 },
    }),
    [mode, brand]
  );

  const theme = createTheme(memoizedValue);

  theme.components = overrides(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}

export default ThemeProvider;

ThemeProvider.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.oneOf(['light', 'dark']),
};
