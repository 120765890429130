import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { horecaApi, useGetRolesQuery } from "../../../redux/api/api";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../../components/common/Iconify";
import CreateNewOrganizationDialogue from "../common/CreateNewOrganizationDialogue";
import UserPage from "../vendor/user-view";
import PageHeader from "../../../components/common/PageHeader";
import useQueryParams from "../../../routes/hooks/useQueryParams";
import { useRouter } from "../../../routes/hooks/useRouter";
import { setCurrSelectedPage, setRowsPerPage } from "../../../redux/slices/appReducer";
import PaymentDialog from "../common/PaymentDialog";

const Customer = () => {
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.app.preference.selectedOrgId);
  const { data: rolesData } = useGetRolesQuery();
  const [getAllCustomers, { data: customerList, isLoading: getAllCustomersLoading }] = horecaApi.endpoints.getAllCustomers.useLazyQuery();
  const params = useQueryParams();
  // const [page, setPage] = useState(0);
  const page = useSelector((state) => state.app.currSelectedPage.customerPage);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsPerPage = useSelector((state) => state.app.rowsPerPageData.customerPage);
  const router = useRouter();
  const [search, setSearch] = useState("");

  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedPaymentOrg, setSelectedPaymentOrg] = useState();

  const [createOrganizationDialogue, setCreateOrganizationDialogue] = useState(false);
  const [isView, setIsView] = useState(false);
  const [paymentdialogOpen, setPaymentdialogOpen] = useState(false);

  const handleOpenPaymentDialog = () => {
    setPaymentdialogOpen(true);
  };

  const handleClosePaymentDialog = async (reload = false) => {
    setPaymentdialogOpen(false);
    if(reload) {
      await getAllCustomers({
        orgId,
        search,
        page,
        rowsPerPage,
      })
    }
  };

  const setPage1 = (val) => {
    dispatch(setCurrSelectedPage({ name: "customerPage", value: val }));
  };

  useEffect(() => {
    (async () =>
      await getAllCustomers({
        orgId,
        search,
        page,
        rowsPerPage,
      }))();
  }, [rowsPerPage, page, search, isView]);

  useEffect(() => {
    let r = "customer";
    let id;
    if (rolesData && rolesData?.length > 0) {
      id = (rolesData.find((role) => role.roleName == r) || {}).id;
    }
    setSelectedRoleId(id);
  }, []);

  const handleOpenOrganizationDialogue = () => {
    setCreateOrganizationDialogue(true);
  };

  const handleCloseOrganizationDialogue = () => {
    setSelectedOrgId(null);
    setCreateOrganizationDialogue(false);
    setIsView(false);
    router.push(`/org/${orgId}/customers`);
  };

  useEffect(() => {
    if (params?.customerId) {
      setSelectedOrgId(params?.customerId);
      setCreateOrganizationDialogue(true);
    }
  }, [params]);

  const handelOnEditClick = (selectedOrgId, type) => {
    if(type === "payment") {
      setSelectedPaymentOrg(customerList.organizations.find((_org) => _org.id == selectedOrgId))
      handleOpenPaymentDialog();
    } else {
      setSelectedOrgId(selectedOrgId);
      setCreateOrganizationDialogue(true);
      if (type === "edit") {
        setIsView(false);
      } else {
        setIsView(true);
      }
    }
  };

  const setRowsPerPage1 = (val) => {
    dispatch(setRowsPerPage({ name: "customerPage", value: val }));
  };

  return (
    <Container maxWidth="xl" sx={{}}>
      <PageHeader
        title={"Customers"}
        variant1={"contained"}
        onClick1={handleOpenOrganizationDialogue}
        startIcon1={<Iconify icon="eva:plus-fill" />}
        label1={"New Customer"}
        //linkArray={[{ title: "Dashboard", path: `/` }, { title: "Customers" }]}
      />
      <UserPage
        data={customerList}
        setSelectedOrgId={setSelectedOrgId}
        onEdit={handelOnEditClick}
        setPage={setPage1}
        setRowsPerPage={setRowsPerPage1}
        setSearch={setSearch}
        page={page}
        rowsPerPage={rowsPerPage}
        search={search}
        searchPlaceholder={"customer"}
        isLoading={getAllCustomersLoading}
      />
      <CreateNewOrganizationDialogue
        handelCloseDialogue={handleCloseOrganizationDialogue}
        open={createOrganizationDialogue}
        type={"customer"}
        selectedOrgId={selectedOrgId}
        selectedRoleId={selectedRoleId}
        disabled={isView ? true : false}
        label={isView ? "Customer Detail" : "Edit Customer Detail"}
      />
      {paymentdialogOpen && <PaymentDialog open={paymentdialogOpen} onClose={handleClosePaymentDialog} selectedPaymentOrg={selectedPaymentOrg}/>}
    </Container>
  );
};

export default Customer;
