import React from 'react'
import { Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { horecaApi } from '../../redux/api/api';
import { useEffect } from 'react';
import Iconify from '../../components/common/Iconify';
import { useRouter } from '../../routes/hooks/useRouter';
import { getStatusColor } from '../system-admin/customer/user-table-row';
import CustomLabel from '../../components/common/label/CustomLabel';
import { formatDate } from '../../utils/formatNumbers';
import PrivateVendorOrderTable from './PrivateVendorOrderTable';
import VendorLogoImageComponent from '../../components/common/VendorLogoImageComponent';
import PageHeader from '../../components/common/PageHeader';
import useQueryParams from '../../routes/hooks/useQueryParams';

export function convertToOrderListDetails(orderItemMapping) {
  return orderItemMapping?.reduce((orderListDetails, item) => {
    const orderListId = item.items.orderListId;

    // If the orderListId is not yet present in orderListDetails, create a new entry
    if (!orderListDetails[orderListId]) {
      orderListDetails[orderListId] = {
        itemId: orderListId,
        createdBy: item.items.createdBy,
        updatedBy: item.items.updatedBy,
        deletedBy: item.items.deletedBy,
        createdAt: item.items.createdAt,
        updatedAt: item.items.updatedAt,
        deletedAt: item.items.deletedAt,
        customerId: item.items.customerId,
        vendorId: item.items.vendorId,
        items: []
      };
    }

    // Push the item details to the items array under the corresponding orderListId
    orderListDetails[orderListId].items.push({
      itemId: item.items.id,
      name: item.items.name,
      quantity: item.quantity,
      unit: item.items.unit,
      createdBy: item.items.createdBy,
      updatedBy: item.items.updatedBy,
      deletedBy: item.items.deletedBy,
      createdAt: item.items.createdAt,
      updatedAt: item.items.updatedAt,
      deletedAt: item.items.deletedAt,
      orderListId: item.items.orderListId
    });

    return orderListDetails;
  }, {});
}

const PrivateVendorOrderDetail = () => {

 

  const router = useRouter();
  const { orgId, orderId } = useParams();

  const [getPrivateVendorOrderDetails, { data: orderData }] = horecaApi.endpoints.getPrivateVendorOrderDetails.useLazyQuery();


  const goBack = () => {
    router.push(`/org/${orgId}/order-history`)
  }


  useEffect(() => {
    (
      async () => {
        await getPrivateVendorOrderDetails(orderId);
      }
    )()
  }, [orderId])


  return (
    <Container maxWidth='xl'>
      <PageHeader
        handleBackClick={goBack}
        title={`Order #${orderData?.id}`}
        statusLabel={orderData?.status && <CustomLabel color={getStatusColor(orderData?.status)}>{orderData?.status}</CustomLabel>}
        titleBelowComponent={<Typography variant='body1' color={'text.disabled'}>
          {formatDate(orderData?.createdAt)}
        </Typography>}
      />
      <Paper sx={{ padding: { xs: "1rem", sm: '2.5rem' } }} >
        <Grid item xs={12} md={6} >
          <VendorLogoImageComponent s3Key={orderData?.vendorDetails?.orgLogoKey} name={orderData?.vendorDetails?.orgName} />
          <Typography variant='h6' sx={{ mt: '1rem' }}>Vendor Details</Typography>
          <Grid container >
            {
              [
                { label: "Name", value: orderData?.vendorDetails?.orgName },
                { label: "Phone Number", value: orderData?.vendorDetails?.phoneNumber },
                { label: "Email", value: orderData?.vendorDetails?.orgEmail },
                { label: "Address", value: <span>{orderData?.vendorDetails?.addresses[0]?.line1 ? `${orderData?.vendorDetails?.addresses[0]?.landmark ? orderData?.vendorDetails?.addresses[0]?.landmark + ", " : ""}${orderData?.vendorDetails?.addresses[0]?.line1 ? orderData?.vendorDetails?.addresses[0]?.line1 + ", " : ""}${orderData?.vendorDetails?.addresses[0]?.line2 ? orderData?.vendorDetails?.addresses[0]?.line2 + ", " : ""}${orderData?.vendorDetails?.addresses[0]?.city ? orderData?.vendorDetails?.addresses[0]?.city + ", " : ""}${orderData?.vendorDetails?.addresses[0]?.state ? orderData?.vendorDetails?.addresses[0]?.state + ", " : ""}${orderData?.vendorDetails?.addresses[0]?.pincode ?? ""}` : ""}</span> },
              ].map((vendorDet, index) => (
                <>
                  <Grid item xs={6} container key={index}>
                    <Typography sx={{ color: "text.disabled" }} variant="subtitle2" gutterBottom>
                      {vendorDet.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} padding={'auto'} key={index}>
                    <Typography variant="body2" gutterBottom>{vendorDet.value}</Typography>
                  </Grid>
                </>
              ))
            }
          </Grid>
        </Grid>
        <PrivateVendorOrderTable
          name="my-vendor-items-without-prev"
          title='Ordered Items'
          items={convertToOrderListDetails(orderData?.orderItemMapping) ? convertToOrderListDetails(orderData?.orderItemMapping)[orderData?.orderListId]?.items : []}
          isRead={true}
          isPrevQuant={false}
        />
      </Paper>
    </Container>
  )
}

export default PrivateVendorOrderDetail