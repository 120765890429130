export function capitalizeFirstLetter(str) {
    return str?.toLowerCase()?.charAt(0)?.toUpperCase() + str?.toLowerCase()?.slice(1);
}

export const formatAddress = (address) => {
    const { line2, landmark } = address;
    let formattedAddress = line2;

    if (landmark) formattedAddress += `, ${landmark}`;

    return formattedAddress;
};