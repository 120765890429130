import { Avatar, Box, Button, Divider, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import ListItemText from '@mui/material/ListItemText';
import CustomButton from '../../components/common/CustomButton'
import Iconify from '../../components/common/Iconify'
import CustomRating from '../../components/common/CustomRating'
import AddReviewDialogue from './AddReviewDialogue';
import { useFormik } from 'formik';
import { useCreateOrgRatingsMutation, useGetOrgRatingsMutation, useGiveRatingMutation } from '../../redux/api/api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openMessage } from '../../redux/slices/showMessageSlice';
import * as Yup from 'yup';
import VendorLogoImageComponent from '../../components/common/VendorLogoImageComponent';
import { formatDate } from '../../utils/formatNumbers';
import Pagination from '@mui/material/Pagination';

const validationSchema = Yup.object().shape({
    ratings: Yup.number()
        .min(1, 'Ratings must be greater than zero')
        .required('Rating is required'),
    comment: Yup.string()
        .required('Review is required')
});


const CommentsSection = ({ rating }) => {

    return (
        <Stack sx={{ pl: '20px', pr: '20px' }} direction={'row'} gap={'16px'} mt={'40px'}>
            <Stack sx={{ width: '240px', textAlign: 'center', alignItems: 'center' }}>
                <VendorLogoImageComponent s3Key={rating?.ratingByOrgDetail?.orgLogoKey} name={rating?.ratingByOrgDetail?.orgName} />
                <ListItemText primary={<Typography variant='subtitle1' sx={{ margin: '0 0 4px', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap' }}>{rating?.ratingByOrgDetail?.orgName}</Typography>} secondary={<Typography variant='body2' color={'text.disabled'}>{formatDate(rating?.updatedAt)}</Typography>} sx={{ flex: '1 1 auto', minWidth: 0 }} />
            </Stack>
            <Stack gap={'8px'} flexGrow={1}>
                <CustomRating value={rating?.ratings} fontSize={'20px'} />
                <Typography variant='body2'>{rating?.comment}</Typography>
            </Stack>
        </Stack>
    )
}


const RatingsSection = () => {


    const { orgId, selectedId } = useParams();


    const [openReview, setOpenReview] = useState(false);
    const dispatch = useDispatch();
    const [getOrgRatings, { data: ratingsData }] = useGetOrgRatingsMutation();
    const [createOrgRatings] = useCreateOrgRatingsMutation();
    const [giveRating] = useGiveRatingMutation();
    let [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (
            async () => {
                await getOrgRatings({
                    orgId: selectedId, payload: {
                        page: --page,
                        per_page: rowsPerPage
                    }
                });
            }
        )()
    }, [page, rowsPerPage]);





    const formik = useFormik({
        initialValues: {
            ratings: 0,
            comment: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            // const result = await createOrgRatings({orgId: selectedId, payload: values});
            const result = await giveRating({ toId: selectedId, fromId: orgId, payload: values })
            if (result?.data) {
                dispatch(openMessage({ message: result?.data, messageSeverity: "success" }))
                await getOrgRatings({
                    orgId: selectedId, payload: {
                        page: --page,
                        per_page: 10
                    }
                });
                setOpenReview(false);
                formik.resetForm();
            } else if (result?.error) {
                dispatch(openMessage({ message: result?.error?.data?.message, messageSeverity: "error" }))
            } else {
                dispatch(openMessage({ message: 'Not able to add an review', messageSeverity: "error" }))
            }
        }
    })




    const onReviewOpen = () => {
        setOpenReview(true);
    }

    const onReviewClose = () => {
        setOpenReview(false);
        formik.resetForm();
    }

    const handleChange = (e, newValue) => {
        setPage(newValue);
    }

    const totalPages = Math.ceil(ratingsData?.totalRatingsCount / rowsPerPage);

    return (
        <Box>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant='subtitle1'>Reviews</Typography>
                <CustomButton
                    startIcon={<Iconify icon="fluent:edit-16-filled" />}
                    label={'Write your reviews'}
                    onClick={onReviewOpen}
                />
            </Stack>
            <Stack sx={{ width: '100%', mt: '2rem' }} direction={'column'} alignItems={'center'}>
                <Typography variant='body1'>Average rating</Typography>
                <Typography variant='h1'>{ratingsData?.averageRating} /5</Typography>
                <CustomRating fontSize={30} value={ratingsData?.averageRating} />
                <Typography variant='body2' color={'text.disabled'}>({ratingsData?.totalRatingsCount} reviews)</Typography>
            </Stack>
            <Divider sx={{ borderStyle: "dashed", mt: '2rem', mb: '2rem' }} />
            {
                ratingsData?.allRatings?.map((rating, index) => (
                    <CommentsSection key={index} rating={rating} />
                ))
            }
            <Stack alignItems={'center'} mt={'2rem'}>
                <Pagination count={totalPages} onChange={handleChange} />
            </Stack>
            <AddReviewDialogue
                open={openReview}
                onClose={onReviewClose}
                formik={formik}
            />
        </Box>
    )
}

export default RatingsSection