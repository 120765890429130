import { Grid, Typography } from '@mui/material'
import React from 'react'
import { showMaxFloat } from '../../utils/formatNumbers'
import useResponsiveStyles from '../../hooks/useResponsiveStyles';

const PriceDetailsComp = ({ priceDetails, style, xs = 12 }) => {
  const { isMobile } = useResponsiveStyles();
  return (
    <Grid container spacing={2} sx={{ ...style }}>
      {priceDetails &&
        [
          { label: "Subtotal", value: priceDetails?.subtotal ? showMaxFloat(priceDetails?.subtotal) : '--' },
          { label: "Shipping", value: priceDetails?.shipping ? showMaxFloat(priceDetails?.shipping) : '--' },
          { label: "Discount", value: priceDetails?.discount ? showMaxFloat(priceDetails?.discount) : '--' },
          { label: "Taxes", value: priceDetails?.taxes ? showMaxFloat(priceDetails?.taxes) : '--' },
          { label: "Total", value: priceDetails?.total ? showMaxFloat(priceDetails?.total) : '--' },
        ].map((vendorDet, index) => (
          <Grid item xs={12} sx={{ display:'flex', justifyContent:'flex-end' }}>
            <Grid container spacing={2}  >
              <Grid item xs={6} sx={{ display:'flex', justifyContent:'end' }}>
                <Typography sx={{ color: "grey" }} variant="body2">
                  {vendorDet.label}(₹)
                </Typography>
              </Grid>
              <Grid item xs={5} padding={'auto'} sx={{ display:'flex', justifyContent:'end' }}>
                <Typography variant="body2">{vendorDet.value}</Typography>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        ))
      }
    </Grid>
  )
}

export default PriceDetailsComp