import { Alert, Avatar, Box, Card, Container, Divider, IconButton, Link, Paper, Snackbar, Stack, Typography } from "@mui/material";
import ItemsImageComponent from "../../../components/common/ItemsImageComponent";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import OfferForm, { RowForm1 } from "./OfferForm";
import RowForm from "./OfferForm";
import { useEffect, useState } from "react";
import ItemForm from "./ItemForm";
import * as Yup from 'yup';
import { useGetItemDetailsByIdQuery, useUpdateItemMutation, useUpdateItemOfferMutation } from "../../../redux/api/api";
import { useParams } from "react-router-dom";
import Iconify from "../../../components/common/Iconify";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useRouter } from "../../../routes/hooks/useRouter";
import { skuImage } from "../../../assets/images";

const ItemDetail = () => {

    const router = useRouter();
    const { orgId, itemId } = useParams();
    const [addOffer, setAddOffer] = useState(false);
    const [editProduct, setEditProduct] = useState(false);
    let { data: itemData, error, isLoading, refetch } = useGetItemDetailsByIdQuery({ orgId, itemId });
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [updateItem] = useUpdateItemMutation();
    const [updateItemOffer] = useUpdateItemOfferMutation();


    useEffect(() => {
        if (itemData?.itemQuantityPricesMappingDetails) {
            setAddOffer(true);
        }
    }, [itemData])


    const manageProductEditForm = (show) => {
        setEditProduct(show);
    }
    const manageOfferForm = (show) => {
        setAddOffer(show);
    }
    const updateItemData = async (itemData) => {
        const result = await updateItem(itemData)
        if (result.data) {
            handleSnackbar('Item updated successfully', 'success');
            manageProductEditForm(false);
            refetch();
        } else if (result.error) {
            handleSnackbar('Error occurred while processing your request.', 'error')
        }
    }
    const updateOfferData = async (offerData) => {
        const result = await updateItemOffer({ payload: offerData, itemId })
        if (result.data) {
            handleSnackbar('Bulk offer updated successfully', 'success');
        } else if (result.error) {
            handleSnackbar('Error occurred while processing your request.', 'error')
        }
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleSnackbar = (newMessage, newSeverity) => {
        setMessage(newMessage);
        setSeverity(newSeverity);
        setOpen(true);
    };


    return (
        <Container maxWidth="xl">
            <Stack direction={'row'} gap={'0.5rem'} mb={'2rem'}>
                <IconButton size="small" onClick={() => router.back()}>
                    <ArrowBackIosNewIcon sx={{ width: 24, height: 24 }} />
                </IconButton><Typography variant="h4">Inventory Detail</Typography>
            </Stack>
            <Paper >
                {!editProduct ? <Box sx={{ padding: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 2, borderBottom: (theme) => theme.palette.mode !== "dark" ? '1px solid #F5F6F7' : '1px solid rgb(64, 75, 85)' }}>
                        <Box sx={{ display: "flex" }}>
                            <ItemsImageComponent s3key={itemData?.item_image_key} name={itemData?.name} />
                            <Box>
                                <Typography>
                                    {itemData?.name}
                                </Typography>
                                <Typography sx={{ color: 'grey', fontSize: 14 }}>
                                    {itemData?.cf_parent_category}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', columnGap: 2 }}>
                            <Box sx={{ padding: 1, backgroundColor: '#E1F6E6', borderRadius: 1 }}>
                                <Typography sx={{ color: '#118D57', fontWeight: 600, fontSize: 14 }}>
                                    {itemData?.status}
                                </Typography>
                            </Box>
                            <IconButton size="small" onClick={() => manageProductEditForm(true)}>
                                <ModeEditIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', padding: 5 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <Avatar
                                alt="Available Stock"
                                src="/assets/images/avatars/stockIcon.png"
                                sx={{ width: 70, height: 70, padding: 1 }}
                            />
                            <Typography color={'primary'}>Stock <span style={{ fontSize: 12 }}>(unit)</span></Typography>
                            <Typography variant="h4" >{itemData?.quantity || 0} <span style={{ fontSize: 12 }}>({itemData?.unit})</span></Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <Avatar
                                alt="Low Stock"
                                src="/assets/images/avatars/lowStock.png"
                                sx={{ width: 70, height: 70, padding: 1 }}
                            />
                            <Typography color={'primary'}>Low Stock <span style={{ fontSize: 12 }}>(unit)</span></Typography>
                            {itemData?.low_stock_quantity && itemData?.low_stock_quantity_unit ? <Typography variant="h4" >{itemData?.low_stock_quantity} <span style={{ fontSize: 12 }}>({itemData?.low_stock_quantity_unit})</span></Typography> :
                                <Typography variant="h4" color={'grey'} >NA</Typography>}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <Avatar
                                alt="sku"
                                src={skuImage}
                                sx={{ width: 70, height: 70, padding: 1 }}
                            />
                            <Typography color={'primary'}>SKU</Typography>
                            {/* {itemData?.low_stock_quantity && itemData?.low_stock_quantity_unit ? <Typography variant="h4" >{itemData?.low_stock_quantity} <span style={{ fontSize: 12 }}>({itemData?.low_stock_quantity_unit})</span></Typography> : */}
                                <Typography variant="h4" color={'grey'} >{itemData?.sku}</Typography>
                                {/* // } */}
                        </Box>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <Avatar
                                alt="Available Stock"
                                src="/assets/images/avatars/pricePerUnit.png"
                                sx={{ width: 70, height: 70, padding: 1.5 }}
                            />
                            <Typography color={'primary'}>Price <span style={{ fontSize: 12 }}>/ unit</span></Typography>
                            <Typography variant="h4" >${itemData?.rate} <span style={{ fontSize: 12 }}>/ {itemData?.unit}</span></Typography>
                        </Box>
                    </Box>
                </Box> :
                    <ItemForm manageProductEditForm={manageProductEditForm} itemData={itemData} updateItemData={updateItemData} />}
            </Paper>
            <Stack direction={'row'} spacing={2} mt={2}>
            <Paper sx={{ padding: 2, marginTop: 3, width: '60%' }}>
                <Typography variant="h6">
                    Bulk Offer
                </Typography>
                <Divider sx={{ marginTop: 2 }} />
                {!addOffer && <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>

                    <Avatar
                        src="/assets/images/avatars/offerWarning.png"
                        sx={{ width: 70, height: 70, padding: 1 }}
                    />
                    <Typography sx={{ color: 'grey' }}>No offers available</Typography>
                    <Link component="button" variant="body2" underline="hover" onClick={() => manageOfferForm(true)}>
                        Click here to add an exiting offer
                    </Link>
                </Box>}
                {addOffer && <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: 300, padding: 2 }}>
                    <RowForm manageOfferForm={manageOfferForm} updateOfferData={updateOfferData} offerData={itemData?.itemQuantityPricesMappingDetails} />
                </Box>}
            </Paper>
            <Paper sx={{ padding: 2, marginTop: 3, width: '40%' }}>
                <Typography variant="h6">
                    Category Sub category Mapping 
                </Typography>
                <Divider sx={{ marginTop: 2 }} />
                {addOffer && <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: 300, padding: 2 }}>
                    <RowForm1 manageOfferForm={manageOfferForm} updateOfferData={updateOfferData} categoryData={itemData?.categoriesMappingDetails} inventryItemId={itemData?.id} orgId={orgId} refetch={refetch}/>
                </Box>}
            </Paper>
            </Stack>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} elevation={6} variant="filled" >
                    {message}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default ItemDetail;