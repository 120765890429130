import React, { useState } from "react";
import {
  Card,
  Grid,
  IconButton,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { WhatsApp as WhatsAppIcon, Phone as PhoneIcon } from "@mui/icons-material";
import Iconify from "../../../components/common/Iconify";
import CustomLabel from "../../../components/common/label/CustomLabel";
import { useGetRecentOrdersQuery } from "../../../redux/api/api";
import { useSelector } from "react-redux";

import { formatDate } from "../../../utils/formatNumbers";
import { useRouter } from "../../../routes/hooks/useRouter";
import { getStatusColor } from "../../system-admin/customer/user-table-row";
import CustomButton from "../../../components/common/CustomButton";


const OrdersCard = () => {

  const orgId = useSelector((state) => state.app.preference.selectedOrgId)

  const router = useRouter();

  const { data: recentOrdersData } = useGetRecentOrdersQuery({ orgId, type: 'customer' });

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleContactClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openWhatsAppChat = () => {
    // Add logic to open WhatsApp chat
  };

  const openPhoneChat = () => {
    // Add logic to open phone chat
  };

  const handleGoToClick = (order) => {
    router.push(`/org/${orgId}/order-history/${order?.id}`);
  }

  const handlePlaceOrder = () => {
    router.push(`/org/${orgId}/order-history`);
  }

  return (
    <Card sx={{ padding: '1rem' }}>
      <Typography variant="h4" sx={{}} mb={2}>
        Recent Orders
      </Typography>
      <Grid container spacing={2}
        sx={{
          maxHeight: '60vh',
          '&::-webkit-scrollbar': {
            height: '7px',
            width: '7px'
          },
          '&:hover': {
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#b4bcc3',
              borderRadius: '8px',
            }
          },
          overflowY: 'auto',
          overflowX: 'auto',
        }}
      >
        {
          recentOrdersData?.recentOrders?.length > 0
            ?
            recentOrdersData?.recentOrders?.map((recentOrder, index) => (
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    backgroundColor: "background.paper",
                    borderRadius: "0.75rem",
                    padding: "0.75rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    position: "relative",
                    transition: 'background-color 0.3s ease',
                    ':hover': {
                      backgroundColor: `rgba(${parseInt(theme.palette.primary.light.slice(1, 3), 16)}, ${parseInt(theme.palette.primary.light.slice(3, 5), 16)}, ${parseInt(theme.palette.primary.light.slice(5, 7), 16)}, 0.2)`
                    }
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography variant="h5" fontWeight={700} textTransform={'capitalize'}>
                      {recentOrder?.orderListDetail?.name}
                    </Typography>
                    <CustomLabel
                      color={getStatusColor(recentOrder?.status)}>
                      {recentOrder?.status}
                    </CustomLabel>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      color={"text.disabled"}
                      fontWeight={700}
                      variant="subtitle1"
                    >
                      {formatDate(recentOrder?.createdAt)}
                    </Typography>
                    <Typography fontWeight={700} variant="body1">
                      ₹{recentOrder?.totalPrice}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={700}
                      color={theme.palette.error.main}
                    >
                      {recentOrder?.vendorEdited && "#Order Updated by vendor"}
                    </Typography>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <IconButton onClick={handleContactClick}>
                        <Iconify icon="bitcoin-icons:contacts-filled" />
                      </IconButton>
                      <IconButton onClick={() => handleGoToClick(recentOrder)}>
                        <Iconify icon="material-symbols:call-made-rounded" />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            ))
            :
            <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
              <Typography>No recent orders available, Place your first order</Typography>
              <CustomButton
                onClick={handlePlaceOrder}
                variant={"contained"}
                label={"Place Order"}
                sx={{ mt: '1rem' }}
              />
            </Stack>
        }
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack direction="column" spacing={2} sx={{ p: 2 }}>
          <IconButton onClick={openWhatsAppChat} color="green">
            <WhatsAppIcon style={{ color: theme.palette.success.main }} />
          </IconButton>
          <IconButton >
            <Iconify icon="material-symbols-light:chat" sx={{ color: theme.palette.primary.main }} />
          </IconButton>
          <IconButton onClick={openPhoneChat}>
            <PhoneIcon style={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Stack>
      </Popover>
    </Card>
  );
};

export default OrdersCard;
