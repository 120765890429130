import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ThemeProvider from './theme';
import { useScrollToTop } from './hooks/useScrollToTop';
import { horecaApi } from './redux/api/api';
import IsPageLoading from './components/common/IsPageLoading';
import Router from './routes/AppRoutes';
import PublicRoutes from './routes/PublicRoutes';
import { useLocation } from 'react-router-dom';

const App = () => {
  useScrollToTop();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const currBrand = useSelector((state) => state.app.preference.brand);
  const themeMode = useSelector((state) => state.app.preference.mode);
  const [isPageLoading, setIsPageLoading] = useState(true); // Initialize loading state as true

  const [getCategories] = horecaApi.endpoints.getCategories.useLazyQuery();
  const [getMe, { isLoading }] = horecaApi.endpoints.getMe.useLazyQuery();

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        await getCategories();
        await getMe();
      }
      setIsPageLoading(false); // Set loading to false after data fetching
    };

    fetchData();
  }, []);

  if (isLoading || isPageLoading) {
    return <IsPageLoading />;
  }
  if (!user && location.pathname != '/') {
    const queryParams = new URLSearchParams(location.search);
    sessionStorage.setItem('redirectAfterLogin', location.pathname + location.search);
  } 
  return (
    <ThemeProvider mode={themeMode} brand={currBrand}>
      {user ? <Router /> : <PublicRoutes />}
    </ThemeProvider>
  );
};

export default App;
