import React from "react";
import { Box, Typography, Stack, Grid, Checkbox } from "@mui/material";
import { getIn } from "formik";
import CustomTextField from "../../../../components/common/CustomTextField";

const AddresDetail = ({
  formik,
  newAddress,
  organizationId,
  isShipping = true,
  disabled
}) => {
  const billingIndex = formik?.values?.addresses?.findIndex(
    (address) => address?.billing
  );

  const shipingIndex = formik?.values?.addresses?.findIndex(
    (address) => !address?.billing
  );

  const adjustedBillingIndex =
    billingIndex !== -1 ? billingIndex : shipingIndex === 1 ? 0 : 1;
  const adjustedShippingIndex =
    shipingIndex !== -1 ? shipingIndex : billingIndex === 1 ? 0 : 1;

  const handleCopyAddress = async (e) => {
    let fields = [
      {
        key: `addresses[${adjustedShippingIndex}].line1`,
        val: `addresses[${adjustedBillingIndex}].line1`,
      },
      {
        key: `addresses[${adjustedShippingIndex}].landmark`,
        val: `addresses[${adjustedBillingIndex}].landmark`,
      },
      {
        key: `addresses[${adjustedShippingIndex}].city`,
        val: `addresses[${adjustedBillingIndex}].city`,
      },
      {
        key: `addresses[${adjustedShippingIndex}].pincode`,
        val: `addresses[${adjustedBillingIndex}].pincode`,
      },
      {
        key: `addresses[${adjustedShippingIndex}].state`,
        val: `addresses[${adjustedBillingIndex}].state`,
      },
    ];

    if (e.target.checked) {
      let sourceFieldValue = getIn(
        formik.values,
        `addresses[${adjustedBillingIndex}].landmark`
      );
      await formik.setFieldValue(
        `addresses[${adjustedShippingIndex}].landmark`,
        sourceFieldValue
      );
      sourceFieldValue = getIn(
        formik.values,
        `addresses[${adjustedBillingIndex}].pincode`
      );
      await formik.setFieldValue(
        `addresses[${adjustedShippingIndex}].pincode`,
        sourceFieldValue
      );
      sourceFieldValue = getIn(
        formik.values,
        `addresses[${adjustedBillingIndex}].line1`
      );
      await formik.setFieldValue(
        `addresses[${adjustedShippingIndex}].line1`,
        sourceFieldValue
      );
      sourceFieldValue = getIn(
        formik.values,
        `addresses[${adjustedBillingIndex}].state`
      );
      await formik.setFieldValue(
        `addresses[${adjustedShippingIndex}].state`,
        sourceFieldValue
      );
      sourceFieldValue = getIn(
        formik.values,
        `addresses[${adjustedBillingIndex}].city`
      );
      await formik.setFieldValue(
        `addresses[${adjustedShippingIndex}].city`,
        sourceFieldValue
      );
    } else {
      await formik.setFieldValue(
        `addresses[${adjustedShippingIndex}].landmark`,
        ""
      );
      await formik.setFieldValue(
        `addresses[${adjustedShippingIndex}].pincode`,
        ""
      );
      await formik.setFieldValue(
        `addresses[${adjustedShippingIndex}].line1`,
        ""
      );
      await formik.setFieldValue(
        `addresses[${adjustedShippingIndex}].state`,
        ""
      );
      await formik.setFieldValue(
        `addresses[${adjustedShippingIndex}].city`,
        ""
      );
    }

    fields.forEach((field) => {
      return (
        formik.setFieldTouched(
          field.key,
          true, false
        )
      )
    });
  };



  return (
    <Stack mt={"1rem"} >
      <Typography variant="title">Address</Typography>
      <Grid container
        columnSpacing={3}
        rowSpacing={2}
      >
        <Grid item xs={12} md={6}>
          <Grid item xs={12} pt={1} pb={1.5}>
            <Typography variant="subtitle" >
              Business Address
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomTextField
                name={`addresses[${adjustedBillingIndex}].line1`}
                placeholder={"Address"}
                label={"Address"}
                formik={formik}
                required={true}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name={`addresses[${adjustedBillingIndex}].landmark`}
                placeholder="Landmark"
                label="Landmark"
                formik={formik}
                required={true}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name={`addresses[${adjustedBillingIndex}].city`}
                placeholder="City"
                label="City"
                formik={formik}
                required={true}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name={`addresses[${adjustedBillingIndex}].pincode`}
                placeholder="Zip Code"
                label="Zip Code"
                formik={formik}
                required={true}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name={`addresses[${adjustedBillingIndex}].state`}
                placeholder="State"
                label="State"
                formik={formik}
                required={true}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        {
          isShipping && <Grid item xs={12} md={6}>
            <Grid item xs={12} pt={1} pb={1.5}>
              <Typography variant="subtitle" >
                Shipping Address
                <Checkbox
                  onClick={handleCopyAddress}
                  sx={{ marginRight: { xs: 0, md: "0.5rem" }, py: "0px" }}
                  disabled={disabled}
                />
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CustomTextField
                  name={`addresses[${adjustedShippingIndex}].line1`}
                  placeholder={"Address"}
                  label={"Address"}
                  formik={formik}
                  required={true}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name={`addresses[${adjustedShippingIndex}].landmark`}
                  placeholder="Landmark"
                  label="Landmark"
                  formik={formik}
                  required={true}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name={`addresses[${adjustedShippingIndex}].city`}
                  placeholder="City"
                  label="City"
                  formik={formik}
                  required={true}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name={`addresses[${adjustedShippingIndex}].pincode`}
                  placeholder="Zip Code"
                  label="Zip Code"
                  formik={formik}
                  required={true}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name={`addresses[${adjustedShippingIndex}].state`}
                  placeholder="State"
                  label="State"
                  formik={formik}
                  required={true}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    </Stack>
  );
};

export default AddresDetail;