import React, { useEffect } from 'react'
import { horecaApi, useHandelApprovedMutation, useSaveOrganizationDataMutation } from '../../../redux/api/api';
import { customerFunction, setAddress, vendorFunction } from '../../settings/sections/organization/OrganizationProfile';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { openMessage } from '../../../redux/slices/showMessageSlice';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import CustomTextField from '../../../components/common/CustomTextField';
import AddresDetail from '../../settings/sections/organization/AddressDetail';
import { useResponsive } from '../../../hooks/useResponsive';
import Iconify from '../../../components/common/Iconify';
import CustomLabel from '../../../components/common/label/CustomLabel';
import { getStatusColor } from '../customer/user-table-row';
import DeliversAt from '../../settings/sections/organization/DeliversAt';
import { customerShema, organizationProfileSchemaSystemAdmin, vendorSchema } from '../../../schema/organization/organizationSchema';
import CustomButton from '../../../components/common/CustomButton';
import CustomMuiTelInput from '../../../components/common/CustomMuiTelInput';
import { capitalizeFirstLetter } from '../../../utils/stringFormating';

const zip = { zipcode: "" };

const add = [
  {
    billing: false,
    line1: "",
    line2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  },
  {
    billing: true,
    line1: "",
    line2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  },
];

const newPerson = { name: "", mobileNumber: "" };

const CreateNewOrganzation = ({ selectedOrgId, type, handelCloseDialogue, disabled, label }) => {

  const dispatch = useDispatch();
  const smDown = useResponsive('down', 'sm');

  const [getOrganizationById, { data: orgData }] = horecaApi.endpoints.getOrganizationById.useLazyQuery()
  const [saveOrganizationData, { isLoading: saveOrganizationDataLoading }] = useSaveOrganizationDataMutation();
  const [handelApproved] = useHandelApprovedMutation();

  useEffect(() => {
    (
      async () => {
        await getOrganizationById(selectedOrgId)
      }
    )()
  }, [selectedOrgId])

  const initialValues = {
    id: selectedOrgId ?? "",
    orgName: orgData?.orgName ?? "",
    type: orgData?.type ?? type,
    phoneNumber: orgData?.phoneNumber ?? "",
    isPrivateVendor: false,
    orgEmail: orgData?.orgEmail ?? "",
    // websiteUrl: orgData?.websiteUrl ?? "",
    // orgLogoKey: orgData?.orgLogoKey ?? "",
    // closing_time: orgData?.closing_time ?? "",
    orgAdmin: {
      id: orgData?.orgAdmin?.id ?? "",
      firstName: orgData?.orgAdmin?.firstName ?? "",
      lastName: orgData?.orgAdmin?.lastName ?? "",
      mobileNumber: orgData?.orgAdmin?.mobileNumber ?? "",
      role: type,
    },
    deliversAt: type === "vendor" ? (orgData?.deliversAt && (orgData?.deliversAt).length > 0
      ? (orgData?.deliversAt).map((org) => {
        return {
          id: org?.id,
          zipcode: org?.zipcode,
        };
      })
      : [zip]) : [],
    businessDetail: {
      id: orgData?.businessDetail?.id ?? "",
      gstUin: orgData?.businessDetail?.gstUin ?? "",
      panNumber: orgData?.businessDetail?.panNumber ?? "",
      fssi: orgData?.businessDetail?.fssi ?? "",
    },
    // contactPersons: orgData?.contactPersons && orgData?.contactPersons.length > 0
    //   ? (orgData?.contactPersons).map((orgPerson) => {
    //     return {
    //       id: orgPerson.id,
    //       name: orgPerson.name,
    //       mobileNumber: orgPerson.mobileNumber,
    //     };
    //   })
    //   : [newPerson],
    // addresses: type === "customer" ? customerFunction(orgData) : vendorFunction(orgData),
    addresses: setAddress({orgData, type}),
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: type === "vendor" ? vendorSchema : customerShema,
    validationSchema: organizationProfileSchemaSystemAdmin,
    onSubmit: async (values) => {
      values.phoneNumber = values?.phoneNumber?.replace(/\s/g, "");
      values.orgAdmin.mobileNumber = values?.orgAdmin?.mobileNumber?.replace(/\s/g, "");
      if (values.type === 'vendor') {
        values.addresses = values.addresses?.filter(address => address.billing === true);
      }
      const result = await saveOrganizationData({ body: values });
      if (result.data) {
        dispatch(
          openMessage({
            message: selectedOrgId
              ? "Outlet Data Updated successfully!"
              : "Outlet Added successfully!",
            messageSeverity: "success",
          })
        );
        handelCloseDialogue();
      } else if (result.error) {
        dispatch(
          openMessage({
            message: result.error.data.message,
            messageSeverity: "error",
          })
        );
      } else {
        dispatch(
          openMessage({
            message: false
              ? "Not Able to Update Outlet Details"
              : "Not Able to Add Outlet at this moment",
            messageSeverity: "error",
          })
        );
      }
    }
  })

  const handelUpdateOrgStatus = async (orgStatus) => {
    const result = await handelApproved({
      orgId: selectedOrgId,
      payload: { status: orgStatus },
    });
    if (result.data) {
      dispatch(
        openMessage({
          message: "Organization Application Status Updated!",
          messageSevirity: "success",
        })
      );
      // if (type === "vendor") {
      //   router.push(`/org/${orgId}/vendors`);
      // } else if(type === "customer") {
      //   router.push(`/org/${orgId}/customers`);
      // }
    } else if (result.error) {
      dispatch(
        openMessage({
          message: result.error.data.message,
          messageSevirity: "error",
        })
      );
    } else {
      dispatch(
        openMessage({
          message: "Not Able To Approved",
          messageSevirity: "error",
        })
      );
    }
  };

  return (
    <Box width={"100%"} >
      <Stack mb={'1rem'} direction={'row'} justifyContent={'space-between'}>
        <Typography variant='h5'>
          {selectedOrgId ? label : `Create New ${capitalizeFirstLetter(type)}`}
        </Typography>
        {selectedOrgId && <Stack gap={'2rem'} direction={'row'} justifyContent={'center'} alignItems={'center'}>
          <Typography variant="body1" color="initial" fontWeight={600}>Application Status: <CustomLabel color={getStatusColor(orgData?.status)}>{orgData?.status}</CustomLabel></Typography>
          {orgData?.status === 'pending' && <Stack direction={'row'} gap={0.5}>
            <CustomButton
              onClick={() => handelUpdateOrgStatus("approved")}
              variant='contained'
              label={"Approved"}
              color='success'
            />
            <CustomButton
              onClick={() => handelUpdateOrgStatus("rejected")}
              variant='outlined'
              label={"Reject"}
              color='error'
            />
          </Stack>}
        </Stack>}
      </Stack>
      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <Paper sx={{ padding: { xs: 1, sm: 3 } }}>
            <Typography variant="title" mb={'1rem'}>Org Admin Details</Typography>
            <Grid container spacing={3} pt={'0.5rem'}>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name={"orgAdmin.firstName"}
                  label={'First Name'}
                  formik={formik}
                  required={true}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name={"orgAdmin.lastName"}
                  label={'Last Name'}
                  formik={formik}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomMuiTelInput
                  name={'orgAdmin.mobileNumber'}
                  label={'Admin Phone Number'}
                  formik={formik}
                  disabled={disabled}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Typography variant="title" mb={'1rem'}>Basic Details</Typography>
            <Grid container spacing={3} pt={'0.5rem'}>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name={"orgName"}
                  label={type === 'customer' ? "Restaurant  name" : 'Shop Name'}
                  formik={formik}
                  disabled={disabled}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name={"orgEmail"}
                  label={type === 'customer' ? "Restaurant email" : 'Shop Email'}
                  formik={formik}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomMuiTelInput
                  name={'phoneNumber'}
                  label={type === 'customer' ? "Restaurant phone number" : 'Shop Phone Number'}
                  formik={formik}
                  disabled={disabled}
                  fullWidth
                />
              </Grid>
            </Grid>
            {
              type === 'vendor' && <Stack mt={"1rem"}>
                <DeliversAt formik={formik} zipCode={zip} organizationId={selectedOrgId} disabled={disabled} />
              </Stack>
            }
            <AddresDetail formik={formik} isShipping={type === 'customer' ? true : false} disabled={disabled} />
            <Stack mt={"1rem"}>
              <Typography variant="title">Business Details</Typography>
              <Grid container spacing={3} mt={'0.5rem'}>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    disabled={disabled}
                    name={"businessDetail.panNumber"}
                    label={"Pan number"}
                    formik={formik}
                    required={!formik.values.businessDetail.gstUin}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    disabled={disabled}
                    name={"businessDetail.gstUin"}
                    label={"GST/UIN"}
                    formik={formik}
                    required={!formik.values.businessDetail.panNumber}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    disabled={disabled}
                    name={"businessDetail.fssi"}
                    label={"FSSAI"}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '2rem' }}>
                <CustomButton
                  disabled={disabled}
                  onClick={formik.handleSubmit}
                  endIcon={<Iconify icon="ph:arrow-right-bold" />}
                  variant="outlined"
                  label={saveOrganizationDataLoading ? "Saving..." : "Save"}
                  loading={saveOrganizationDataLoading}
                />
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CreateNewOrganzation