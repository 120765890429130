import React from "react";
import EmblaCarousel from "./EmblaCarousel";
// import "../css/base.css";
import "../css/sandbox.css";
import "../css/embla.css";
import { useResponsive } from "../../hooks/useResponsive";

const Carasousal = ({ isHome = true,bannerImages }) => {
  const mdDown = useResponsive("down", "md");
  const OPTIONS = { slidesToScroll: 1, loop: true };
  // const SLIDE_COUNT = 3;
  // const SLIDE_COUNT = bannerImages.desktop_banner.length
  // const SLIDES = Array.from(Array(SLIDE_COUNT).keys());
  // console.log(SLIDES,SLIDES)

  return (
    <>
      <main style={{ width: mdDown ? '100%' : '90%' }}>
        <section className="sandbox__carousel">
          <EmblaCarousel options={OPTIONS} isHome={isHome} bannerImages={bannerImages}/>
        </section>
      </main>
    </>
  );
};

export default Carasousal;
