import { Box, Button, Container, Typography } from "@mui/material";
import { brand1, brand10, brand11, brand12, brand13, brand14, brand15, brand16, brand17, brand18, brand19, brand2, brand3, brand4, brand5, brand6, brand7, brand8, brand9 } from "../../../assets/images";
import "./brand.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const BrandSection = ({ setLoginOpen }) => {
    return (
        <Container sx={{ py: 4, px: '0 !important' }}>
            <Box sx={{ marginBottom: 4 }}>
                <Typography variant='h3' fontWeight={600} textAlign={'center'}>Our popular brands</Typography>
            </Box>
            <Box>
                <div className="brands-carousel">
                    <div className="brandslider">
                        <div className="brandslide-track">
                            <div className="brandslide"><img src={brand1} /></div>
                            <div className="brandslide"><img src={brand2} /></div>
                            <div className="brandslide"><img src={brand3} /></div>
                            <div className="brandslide"><img src={brand4} /></div>
                            <div className="brandslide"><img src={brand5} /></div>
                            <div className="brandslide"><img src={brand6} /></div>
                            <div className="brandslide"><img src={brand7} /></div>
                            <div className="brandslide"><img src={brand8} /></div>
                            <div className="brandslide"><img src={brand9} /></div>
                            <div className="brandslide"><img src={brand10} /></div>
                            <div className="brandslide"><img src={brand11} /></div>
                            <div className="brandslide"><img src={brand12} /></div>
                            <div className="brandslide"><img src={brand13} /></div>
                            <div className="brandslide"><img src={brand14} /></div>
                            <div className="brandslide"><img src={brand15} /></div>
                            <div className="brandslide"><img src={brand16} /></div>
                            <div className="brandslide"><img src={brand17} /></div>
                            <div className="brandslide"><img src={brand18} /></div>
                            <div className="brandslide"><img src={brand19} /></div>
                        </div>
                    </div>
                </div>
            </Box>
            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 3 }}>
                <Button sx={{ visibility: 'hidden' }} size='large' endIcon={<KeyboardDoubleArrowRightIcon />}>Feel free to explore</Button>
                <Typography variant='h5' fontWeight={600} textAlign={'center'} color={'text.disabled'}>Over 250+ brands available</Typography>
                <Button size='large' onClick={() => setLoginOpen(true)} endIcon={<KeyboardDoubleArrowRightIcon />}>Feel free to explore</Button>
            </Box> */}
        </Container>
    )
}

export default BrandSection;