import { useState } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CustomLabel from '../../../components/common/label/CustomLabel';
import Iconify from '../../../components/common/Iconify';
import GetImageComponent from '../../../components/common/GetImageComponent';
import { getStatusColor } from '../customer/user-table-row';
import { stringAvatar } from '../../../utils/defaultIcon';
import PaymentsIcon from '@mui/icons-material/Payments';
import { ListItemIcon, ListItemText } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

// ----------------------------------------------------------------------

export default function UserTableRow({
  selected,
  orgId,
  name,
  avatarUrl,
  company,
  role,
  isVerified,
  status,
  onEdit
}) {
  const [open, setOpen] = useState(null);

  let nameColor = stringAvatar({ name, color: "rgb" })?.item;

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const onEditClick = (opType) => {
    onEdit(orgId, opType)
    handleCloseMenu()
  }

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        {/* <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell> */}

        <TableCell component="th" scope="row" >
          <Stack direction="row" alignItems="center" spacing={2}>
            {avatarUrl ? <Avatar alt={name} sx={{ width: 43, height: 43 }}>
              <GetImageComponent s3Key={avatarUrl} />
            </Avatar> :
              <Avatar
                sx={{
                  width: 43,
                  height: 43,
                  bgcolor: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},0.5)` : `rgba(${nameColor?.sx?.bgcolor},0.7)`,
                  color: (theme) => theme.palette.mode === "light" ? `rgba(${nameColor?.sx?.bgcolor},1)` : `rgba(255,255,255,0.9)`
                }}
                children={nameColor?.children}
              />}
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>{company}</TableCell>

        <TableCell>{role}</TableCell>

        <TableCell align="center">{isVerified ? 'Yes' : 'No'}</TableCell>

        <TableCell>
          <CustomLabel color={getStatusColor(status)}>{status}</CustomLabel>
        </TableCell>

        <TableCell padding='checkbox'>
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      // PaperProps={{
      //   sx: { width: 140 },
      // }}
      >
        <MenuItem onClick={() => onEditClick('view')}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText><Typography variant='body2'>View</Typography></ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onEditClick('edit')}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText><Typography variant='body2'>Edit</Typography></ListItemText>
        </MenuItem>
        {role == 'customer' && <MenuItem onClick={() => onEditClick('payment')}>
          <ListItemIcon>
            <PaymentsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText><Typography variant='body2'>Payment Options</Typography></ListItemText>
        </MenuItem>}

        {/* <MenuItem onClick={handleCloseMenu} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>
    </>
  );
}

UserTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  company: PropTypes.any,
  handleClick: PropTypes.func,
  isVerified: PropTypes.any,
  name: PropTypes.any,
  role: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.string,
};