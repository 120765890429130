import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { convertDateFormat } from '../../utils/formatNumbers';

export default function CustomDatePicker({ label, setDate, minDate, maxDate, props, sx }) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        onChange={(newValue) => setDate(convertDateFormat(newValue) === "Invalid Date" ? null : convertDateFormat(newValue))}
        minDate={minDate ? dayjs(minDate, 'DD/MM/YYYY') : undefined}
        maxDate={maxDate ? dayjs(maxDate, 'DD/MM/YYYY') : undefined}
        format='DD/MM/YYYY'
        {...props}
        sx={sx}
      // slots={{
      //   inputAdornment: (slotProps) => (
      //     <IconButton
      //       edge="end"
      //       aria-label="toggle calendar"
      //     // onClick={slotProps.}
      //     >
      // <Iconify width={24} icon="fluent:checkbox-checked-20-filled" />
      //     </IconButton>
      //   ),
      // }}
      />
    </LocalizationProvider>
  )
}