import React from "react";
import { IconButton, Badge, Popover, Box, Typography, Divider, Avatar, ListItemButton, ListItemAvatar, ListItemText} from "@mui/material";
import Iconify from "../../components/common/Iconify";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { horecaApi } from "../../redux/api/api";
import GetImageComponent from "../../components/common/GetImageComponent";


function ContactItem({ contact }) {

  return (
    <ListItemButton
      sx={{
        py: 1,
        px: 1.5,
        mt: '1px',
      }}
    >
      <ListItemAvatar>
        {/* <Avatar sx={{ bgcolor: 'background.neutral' }} src={contact?.imageKey}>{!contact?.imageKey && 'N'}</Avatar> */}
        <Avatar>
          <GetImageComponent s3Key={contact?.orgLogoKey}/>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
          <Typography
            variant="body1"
            sx={{
              // mt: 0.5,
              fontSize:'15px',
              fontWeight:'700',
              display: 'flex',
              alignItems: 'center',
              // color: 'text.disabled',
            }}
          >
            {contact?.contactPersonName}
          </Typography>
          
          </>
        }
        secondary={
          <Typography
            variant="body1"
            sx={{
              mt: 0.5,
              fontSize:'12px',
              display: 'flex',
              alignItems: 'center',
              // color: 'text.disabled',
            }}
          >
            {contact?.contactPersonNumber}
          </Typography>
        }
        
      />
    </ListItemButton>
  );
}


const ContactListPopover = () => {


  const [open, setOpen] = React.useState(null);
  const orgId = useSelector((state)=> state.app.preference.selectedOrgId)
  const contactList = useSelector((state)=>state.app.contactList)
  const [getContactPersons] = horecaApi.endpoints.getContactPersons.useLazyQuery();

  

  useEffect(()=>{
    (
      async () => {
        orgId && await getContactPersons(orgId);
      }
    )()
  },[])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(null);
  };



  return (
    <>
      <IconButton color={open ? "primary" : "default"} onClick={handleOpen}>
        <Badge badgeContent={contactList?.length} color="primary">
          <Iconify width={24} icon="mdi:users" />
        </Badge>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 5,
            width: 250,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
        <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Contact List ({contactList?.length})</Typography>
          </Box>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        {contactList.map((contact, index) => (
            <ContactItem contact={contact} key={index}/>
        ))}
      </Popover>
    </>
  );
};

export default ContactListPopover;
