import { Box, Container, FormControlLabel, Grid, IconButton, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomStatusBar from '../common/CustomStatusBar';
import { setOrderHistoryEndDateFilter, setOrderHistorySearch, setOrderHistoryStartDateFilter, setOrderHistoryStatusFilter } from '../../../redux/slices/orderSlice';
import { AntSwitch } from '../common/AntSwitch';
import Iconify from '../../../components/common/Iconify';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomMenu from '../common/CustomMenu';
import { useGetOrderHistoryMutation, usePrivateVendorOrderHistoryMutation } from '../../../redux/api/api';
import { useRouter } from '../../../routes/hooks/useRouter';
import { convertDateFormat, dateFormatter, formatDateAndTime, showMaxFloat } from '../../../utils/formatNumbers';
import VendorLogoImageComponent from '../../../components/common/VendorLogoImageComponent';
import CustomLabel from '../../../components/common/label/CustomLabel';
import { getStatusColor } from '../../settings/sections/organization/OrganizationProfile';
import PageHeader from '../../../components/common/PageHeader';
import CustomDatePicker from '../../../components/common/CustomDatePicker';
import CustomSearchbar from '../../../components/common/CustomSearchbar';
import { capitalizeFirstLetter } from '../../../utils/stringFormating';
import TableNoData from '../../../views/list/table-no-data';
import TableLoadingData from '../../../components/common/TableLoadingData';
import CustomeSelectMenu from '../../../components/common/CustomeSelectMenu';
import { setCurrSelectedPage, setRowsPerPage } from '../../../redux/slices/appReducer';
import { paymentMode } from '../../../constants/constants';
import InfoIcon from '@mui/icons-material/Info';

// function findIdByValue(value, filterButtons) {
//     for (const key in filterButtons) {
//         if (filterButtons.hasOwnProperty(key)) {
//             if (filterButtons[key].value === value) {
//                 return key;
//             }
//         }
//     }
//     return null; // Return null if value is not found
// }
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function OrderHistory() {
    const { orgId } = useParams();
    const dispatch = useDispatch()

    const rows = useSelector((state) => state.order.orderHistory.orderHistoryList)

    const orderStatusFilterButtons = useSelector((state) => state.order.orderHistory.orderHistoryFilter.orderStatusFilterButtons)

    const orderHistorySearch = useSelector((state) => state.order.orderHistory.orderHistorySearch)

    const orderHistoryDateFilter = useSelector((state) => state.order.orderHistory.orderHistoryFilter.dateFilter)

    // const selectedStatusButton = useSelector((state) => state.order.orderHistory.orderHistoryFilter.orderStatusFilter)

    // const [selectedStatusButtonId, setSelectedStatusButtonId] = useState(
    //     findIdByValue(selectedStatusButton, orderStatusFilterButtons)
    // )

    const [statusFilter, setStatusFilter] = useState(["all"])

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('placedDate');
    // const [page, setPage] = useState(0);
    const page = useSelector((state) => state.app.currSelectedPage.orderHistoryPage)
    const [dense, setDense] = useState(false);
    const rowsPerPage = useSelector((state) => state.app.rowsPerPageData.orderHistoryPage)
    const router = useRouter();

    const [result, setResult] = useState([]);

    const [getOrderHistory, { data: orderHistoryData, isLoading: getOrderHistoryLoading }] = useGetOrderHistoryMutation();
    const [privateVendorOrderHistory] = usePrivateVendorOrderHistoryMutation();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        // setPage(newPage);
        dispatch(setCurrSelectedPage({ name: 'orderHistoryPage', value: newPage }))
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        dispatch(setRowsPerPage({ name: 'orderHistoryPage', value: parseInt(event.target.value, 10) }));
        // setPage(0);
        dispatch(setCurrSelectedPage({ name: 'orderHistoryPage', value: 0 }))
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedOrderId, setSelecteOrderId] = useState(null);
    const [selectedOrder, setSelecteOrder] = useState(null);
    const [currStatus, setCurrStatus] = useState(null);

    const handleClick = (event, orderId, row) => {
        setCurrStatus(row?.status);
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
        setSelecteOrderId(orderId);
        setSelecteOrder(row)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    const handelViewOrder = () => {
        if (selectedOrder?.status === "placed") {
            router.push(`/org/${orgId}/order-history/${selectedOrder?.id}/private`)
        }
        else if (selectedOrder?.status === "scheduled") {
            router.push(`/org/${orgId}/order-history/${selectedOrder?.id}?type=${selectedOrder?.status}`)
        }
        else {
            router.push(`/org/${orgId}/order-history/${selectedOrder?.id}`);
        }
        // if (currStatus === 'scheduled') {
        //     router.push(`/org/${orgId}/order-history/${selectedOrderId}?type=scheduled`);
        // }
        // else if (statusFilter[0] === "privatevendor") {
        //     router.push(`/org/${orgId}/order-history/${selectedOrderId}/private`);
        // }
        // else {
        //     router.push(`/org/${orgId}/order-history/${selectedOrderId}`);
        // }
        setAnchorEl(null);
        setOpenMenu(false);
    }

    const menuItems = [
        {
            id: "view",
            label: "View",
            icon: <Iconify width={24} icon="carbon:view-filled" />,
            handler: handelViewOrder
        }
    ]

    // useEffect(() => {
    //     setSelectedStatusButtonId(findIdByValue(selectedStatusButton, orderStatusFilterButtons))
    // }, [selectedStatusButton])

    useEffect(() => {
        (
            async () => {
                // if (selectedStatusButton === 10) {
                if (statusFilter.includes("privatevendor")) {
                    const res = await privateVendorOrderHistory({
                        customerId: orgId, payload: {
                            searchText: orderHistorySearch,
                            sortOrder: order.toUpperCase(),
                            page: page,
                            rowsPerPage: rowsPerPage,
                            startDate: orderHistoryDateFilter.startDate,
                            endDate: orderHistoryDateFilter.endDate,
                            // status: selectedStatusButtonId
                            status: statusFilter.length > 0 ? statusFilter[0] : null
                        }
                    });
                    setResult(res.data?.orderHistory)
                } else {
                    const res = await getOrderHistory({
                        orgId, payload: {
                            searchText: orderHistorySearch,
                            sortOrder: order.toUpperCase(),
                            page: page,
                            rowsPerPage: rowsPerPage,
                            startDate: orderHistoryDateFilter.startDate,
                            endDate: orderHistoryDateFilter.endDate,
                            // status: selectedStatusButtonId
                            status: statusFilter.length > 0 ? statusFilter[0] : null
                        }
                    });
                    setResult(res.data?.orderHistory)
                }
            }
        )()
    }, [orderHistorySearch, order, page, rowsPerPage, orderHistoryDateFilter,
        // selectedStatusButtonId,
        statusFilter])

    useEffect(() => {
        (
            async () => {
                await privateVendorOrderHistory({ customerId: orgId, payload: {} })
            }
        )()
    }, [])

    function removeTimestamp(name) {
        const parts = name.split('/');
        // Check if there are at least three parts (to remove the timestamp)
        if (parts.length > 2) {
          // Return the first and last parts (vendor name and instant-order)
          return `${parts[0].trim()} / ${parts[2].trim()}`;
        }
        // If not enough parts, return the original name
        return name.trim();
    }


    return (
        <Container maxWidth='xl'>
            <PageHeader
                title={"Order History"}
            // linkArray={[
            //     { title: "Dashboard", path: `/` },
            //     { title: "Order History", },
            // ]}
            />
            <Box component={Paper} >
                <CustomMenu anchorEl={anchorEl} open={openMenu} onClose={handleClose} menuItems={menuItems} />
                {/* <CustomStatusBar
                    selectedStatusButton={selectedStatusButton}
                    statusBarButtons={orderStatusFilterButtons}
                    setStatusBarButtons={setOrderHistoryStatusFilter}
                /> */}
                <Stack
                    spacing={2}
                    padding={2}
                    width={"100%"}
                    direction={{
                        xs: "column",
                        sm: "row"
                    }}
                >
                    <CustomeSelectMenu label={"Status"} noDataMsg={"No Status"}
                        options={Object.entries(orderStatusFilterButtons).map(([key, value]) => ({
                            ...value,
                            id: key,
                            label: value.name
                        }))}
                        optionFilter={statusFilter} setOptionFilter={(state) => setStatusFilter(state[0] ? state : ['all'])}
                        width={{ xs: "100%", sm: "40%", md: "30%" }}
                        multiSelect={false}
                    />
                    <CustomDatePicker label={"Start date"} setDate={(date) => dispatch(setOrderHistoryStartDateFilter(date))} maxDate={orderHistoryDateFilter.endDate} sx={{ width: { xs: "100%", sm: "40%", md: "30%" } }} />
                    <CustomDatePicker label={"End date"} setDate={(date) => dispatch(setOrderHistoryEndDateFilter(date))} minDate={orderHistoryDateFilter.startDate} sx={{ width: { xs: "100%", sm: "40%", md: "30%" } }} />
                    <CustomSearchbar
                        placeholder={"Search..."}
                        text={orderHistorySearch}
                        handleTextChange={(e) => dispatch(setOrderHistorySearch(e.currentTarget.value))}
                        handleTextClear={(e) => dispatch(setOrderHistorySearch(""))}
                    />
                </Stack>
                <TableContainer >
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        stickyHeader
                    >
                        <TableHead >
                            <TableCell padding='checkbox' sx={{ whiteSpace: "nowrap", padding: "0px 16px" }} >#Order</TableCell>
                            {/* <TableCell /> */}
                            <TableCell sx={{ whiteSpace: "nowrap" }}>Vendor / Order-List-Name</TableCell>
                            <TableCell >
                                <TableSortLabel
                                    active
                                    direction={order ?? 'asc'}
                                    onClick={createSortHandler("placedDate")}
                                >
                                    Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell >Amount</TableCell>
                            <TableCell >Status</TableCell>
                            <TableCell align='center'>Payment Mode</TableCell>
                            {/* <TableCell >Schedule</TableCell> */}
                            <TableCell />
                        </TableHead>
                        <TableBody>
                            {getOrderHistoryLoading ?
                                <TableLoadingData colSpan={8} /> : result?.length > 0 ? (
                                    result?.map((row, index) => {
                                        return (
                                            <StyledTableRow key={index} >
                                                <TableCell padding='checkbox' sx={{ whiteSpace: "nowrap", padding: "0px 16px" }}>#{row?.id}</TableCell>
                                                {/* <TableCell padding='checkbox'>
                                                    <VendorLogoImageComponent s3Key={row?.vendorDetail?.orgLogoKey} name={row?.vendorName} />
                                                </TableCell> */}
                                                <TableCell >
                                                    <Grid item>
                                                        <Typography variant={"body2"} noWrap textTransform={'capitalize'}>{removeTimestamp(row.orderListDetail?.name ?? `${row?.vendorDetail?.orgName} / Instant-order`)}</Typography>
                                                        {/* <Typography variant={"body2"} noWrap sx={{ color: 'text.disabled' }}>{row.vendorDetail?.phoneNumber}</Typography> */}
                                                        {
                                                            row?.otpInfo?.otp &&
                                                            <Box sx={{ display: 'flex', gap: 1, pt: 1, alignItems: 'center' }}>
                                                                <InfoIcon fontSize='small' sx={{ color: '#017185' }} />
                                                                <Typography sx={{ fontSize: 13, color: '#A9A9A9' }}>{`${row?.otpInfo?.otp} is your OTP for delivery.`}</Typography>
                                                            </Box>
                                                        }
                                                    </Grid>
                                                </TableCell>
                                                <TableCell >
                                                    <Grid item>
                                                        <Typography variant={"body2"} noWrap>{formatDateAndTime(row?.createdAt).formattedDateNew}</Typography>
                                                        <Typography variant={"body2"} noWrap sx={{ color: 'text.disabled' }}>{formatDateAndTime(row?.createdAt).formattedTimeNew}</Typography>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell >
                                                    {row?.totalPrice && (
                                                        <Typography variant={"body2"} noWrap>{`₹${showMaxFloat(row?.totalPrice)}`}</Typography>
                                                    )}
                                                </TableCell>
                                                {/* <TableCell padding='checkbox'>
                                                    {
                                                        row?.status === 'scheduled'
                                                        ?
                                                        <CustomLabel color={getStatusColor(row?.schedules[0]?.active ? 'confirmed' : 'void')}>{row?.schedules[0]?.active ? 'Activated' : 'Deactivated'}</CustomLabel>
                                                        :
                                                        <CustomLabel color={getStatusColor(row?.status)}>{row?.status}</CustomLabel>
                                                    }
                                                    
                                                    <Typography sx={{ fontWeight: '400', fontSize: '10px', color: 'text.disabled' }}>{
                                                        row?.nearestSchedule == 'No Upcoming Schedule' || !row?.nearestSchedule
                                                            ?
                                                            row?.nearestSchedule
                                                            :
                                                            convertDateFormat(row?.nearestSchedule)
                                                    }</Typography>
                                                </TableCell> */}
                                                <TableCell >
                                                    {
                                                        row?.status === 'scheduled'
                                                            ?
                                                            <CustomLabel color={getStatusColor(row?.schedules[0]?.active ? 'confirmed' : 'void')}>{row?.schedules[0]?.active ? 'Activated' : 'Deactivated'}</CustomLabel>
                                                            :
                                                            <CustomLabel color={getStatusColor(row?.status)}>{row?.status == 'void' ? 'cancelled' : row?.status}</CustomLabel>
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Typography variant={"body2"}>{paymentMode[row?.paymentMode]}</Typography>
                                                </TableCell>
                                                {/* <TableCell>
                                                    {(row?.nearestSchedule == 'No Upcoming Schedule' || !row?.nearestSchedule) ? <>
                                                        <Typography sx={{ color: "text.disabled", fontSize: "0.7rem" }} noWrap>No Upcoming</Typography>
                                                        <Typography sx={{ color: "text.disabled", fontSize: "0.7rem" }} noWrap>Schedule</Typography>
                                                    </> : <>
                                                        <Typography variant="body2" >{
                                                            row?.nearestSchedule == 'No Upcoming Schedule' || !row?.nearestSchedule ?
                                                                null : capitalizeFirstLetter(row?.schedules[0]?.type)
                                                        }</Typography>
                                                        <Typography variant="body2" sx={{ color: 'text.disabled' }} noWrap>{
                                                            row?.schedules[0]?.type === "day" ?
                                                                dateFormatter(row?.nearestSchedule) :
                                                                formatDateAndTime(row?.nearestSchedule).formattedDateNew
                                                        }</Typography>
                                                    </>}
                                                </TableCell> */}
                                                <TableCell padding='checkbox'>
                                                    <IconButton onClick={(event) => handleClick(event, row?.id, row)}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })
                                ) : (
                                    <TableNoData query={orderHistorySearch} colSpan={8} />
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    paddingLeft={"24px"}
                >
                    <Box display={"flex"}>
                        <FormControlLabel
                            control={
                                <AntSwitch
                                    checked={dense}
                                    onChange={handleChangeDense}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                />
                            }
                        />
                        <Typography variant={"body2"} noWrap>Dense</Typography>
                    </Box>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={orderStatusFilterButtons[statusFilter.length > 0 ? statusFilter[0] : "all"]?.count ?? 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '& .MuiTablePagination-selectLabel': {
                                display: {
                                    xs: "none",
                                    sm: "block"
                                }
                            }
                        }}
                    />
                </Stack>
            </Box >
        </Container>
    )
}