import { PhotoCamera } from "@mui/icons-material";
import { Avatar, Box, Button, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography, styled } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { horecaApi, useUploadItemImgMutation } from "../../../redux/api/api";
import { base64ToArrayBuffer } from "../../../utils/base64toArray";
import { useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";
import { useSelector } from "react-redux";

const Input = styled('input')({
    display: 'none',
});

const ItemForm = (props) => {
    const orgId = useSelector((state) => state.app.preference.selectedOrgId)
    const { manageProductEditForm, itemData, updateItemData } = props;
    const { itemId } = useParams();
    const [uploadItemImg] = useUploadItemImgMutation();
    const [itemImage, setItemImage] = useState(null);
    const [getImage] = horecaApi.endpoints.getImage.useLazyQuery();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        stock: Yup.number().typeError('Stock must be a number').required('Stock is required'),
        stockUnit: Yup.string().required('Unit is required'),
        lowStock: Yup.number().typeError('Low Stock must be a number').required('Low stock is required'),
        lowStockUnit: Yup.string().required('Unit is required'),
        pricePerUnit: Yup.number().typeError('Price must be a number').required('Price is required'),
    });
    const initialValues = {
        name: itemData?.name || '',
        stock: itemData?.quantity || 0,
        stockUnit: itemData?.unit || '',
        lowStock: itemData?.low_stock_quantity || '',
        lowStockUnit: itemData?.low_stock_quantity_unit || '',
        pricePerUnit: itemData?.rate || '',
    };
    const handleSubmit = async (values) => {
        // Handle form submission here
        updateItemData({
            itemId: itemId,
            payload: {
                name: values.name,
                quantity: values.stock,
                unit: values.stockUnit,
                rate: values.pricePerUnit,
                low_stock_quantity: values.lowStock,
                low_stock_quantity_unit: values.lowStockUnit,
            },
            orgId:orgId
        });

    };

    useEffect(() => {
        (async () => {
            if (itemData?.item_image_key) {
                const result = await getImage(itemData.item_image_key);
                if (result.data) {
                    const byteArray = result?.data?.img && base64ToArrayBuffer(result?.data?.img);
                    const rr = new Blob([byteArray], { type: "application/octet-stream" });
                    const reader = new FileReader();
                    reader.readAsDataURL(rr);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        setItemImage(base64data);
                    };
                }
            }
        })();
    }, [itemData])
    const handlePicChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setItemImage(e.target.result);
            };
            reader.readAsDataURL(file);
            const formData = new FormData();
            formData.append("file", file);
            const result = await uploadItemImg({
                payload: formData,
                itemId: itemData.id,
                orgId
            });
        }
    }
    return (
        <Box>
            <Box sx={{ borderBottom: '1px solid #F5F6F7', padding: 2 }}>
                <Typography variant="h4">Edit</Typography>
            </Box>
            <Grid container sx={{ padding: 2, paddingLeft: 0 }}>
                <Grid item sm={4} display={'flex'} justifyContent={'center'}>
                    <label htmlFor="icon-button-file">
                        <Input accept="image/*" id="icon-button-file" type="file" onChange={handlePicChange} />
                        <IconButton color="primary" component="span">
                            <Avatar
                                src={itemImage ? itemImage : ''}
                                sx={{ width: 150, height: 150 }}
                            >
                                <PhotoCamera fontSize='large' />
                            </Avatar>
                        </IconButton>
                    </label>
                </Grid>
                <Grid item sm={8}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item sm={12}>
                                        <Field
                                            name="name"
                                            as={TextField}
                                            label="Name"
                                            style={{ width: '100%' }}
                                            error={errors.name && touched.name}
                                            helperText={errors.name && touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Field
                                            name="stock"
                                            as={TextField}
                                            label="Stock Available"
                                            style={{ width: '100%' }}
                                            error={errors.stock && touched.stock}
                                            helperText={errors.stock && touched.stock && errors.stock}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <FormControl fullWidth error={errors.stockUnit && touched.stockUnit}>
                                            <InputLabel>Unit</InputLabel>
                                            <Field
                                                name="stockUnit"
                                                as={Select}
                                                label={'Unit'}
                                                style={{ height: 50 }}
                                            >
                                                <MenuItem value="kg">KG</MenuItem>
                                                <MenuItem value="lit">LIT</MenuItem>
                                                <MenuItem value="vol">VOLUME</MenuItem>
                                                <MenuItem value="pc">PC</MenuItem>
                                            </Field>
                                            {errors.stockUnit && touched.stockUnit && <FormHelperText>{errors.stockUnit}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item sm={6}>
                                        <Field
                                            name="lowStock"
                                            as={TextField}
                                            label="Low Stock"
                                            style={{ width: '100%' }}
                                            error={errors.lowStock && touched.lowStock}
                                            helperText={errors.lowStock && touched.lowStock && errors.lowStock}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <FormControl fullWidth error={errors.lowStockUnit && touched.lowStockUnit}>
                                            <InputLabel>Unit</InputLabel>
                                            <Field
                                                name="lowStockUnit"
                                                autoWidth={true}
                                                as={Select}
                                                label={'Unit'}
                                                style={{ height: 50 }}
                                            >
                                                <MenuItem value="kg">KG</MenuItem>
                                                <MenuItem value="lit">LIT</MenuItem>
                                                <MenuItem value="vol">VOLUME</MenuItem>
                                                <MenuItem value="pc">PC</MenuItem>
                                            </Field>
                                            {errors.lowStockUnit && touched.lowStockUnit && <FormHelperText>{errors.lowStockUnit}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Field
                                            name="pricePerUnit"
                                            as={TextField}
                                            label="Price per Unit"
                                            style={{ width: '100%' }}
                                            error={errors.pricePerUnit && touched.pricePerUnit}
                                            helperText={errors.pricePerUnit && touched.pricePerUnit && errors.pricePerUnit}
                                        />
                                    </Grid>

                                </Grid>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 2, columnGap: 2 }}>
                                    <CustomButton
                                        onClick={() => manageProductEditForm(false)}
                                        label={"Cancel"}
                                        color={"primary"}
                                    />
                                    {/* <Button type="submit" variant="contained" color="primary">
                                        Submit
                                    </Button> */}
                                    <CustomButton props={{type:"submit"}} variant="contained" color="primary" label={"Submit"} />
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ItemForm;